import React from 'react';

import LogoIwoka from '../../../../../assets/img/iwoka-logo-gray.png';
import LogoKIwoka from '../../../../../assets/img/iwoka-logo-k.png';

const DataPolicy = () => {
    return (
        <React.Fragment>
            <div
                className="main-content"
                style={{
                    padding: '20px 70px',
                    backgroundColor: '#ffffff !important',
                }}
            >
                <div>
                    <img
                        src={LogoIwoka}
                        // name="Imagen 5"
                        width={200}
                        style={{
                            opacity: '0.1',
                            position: 'fixed',
                            right: '10px',
                        }}
                        // height={1061}
                        // border={0}
                    />
                    <img
                        src={LogoKIwoka}
                        style={{
                            opacity: '0.1',
                            position: 'fixed',
                            right: '0',
                            bottom: '0',
                        }}
                        // name="Imagen 5"
                        // width={200}
                        height="85%"
                        // border={0}
                    />

                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="center"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="center"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font
                                size={2}
                                style={{
                                    fontSize: '11pt',
                                    fontWeight: 'bold !important',
                                }}
                            >
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        POLÍTICA DE TRATAMIENTO DE DATOS
                                        PERSONALES
                                    </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="center"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ol>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0.14in',
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                    padding: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>ACERCA DE NOSOTROS</b>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.25in',
                            marginBottom: '0in',
                            borderTop: 'none',
                            // borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            padding: '0in',
                        }}
                    >
                        <font>
                            <span lang="es-ES-u-co-trad">
                                El presente documento establece las Políticas de
                                Tratamiento de Datos Personales de la{' '}
                            </span>
                        </font>
                        <font>
                            <span lang="es-ES-u-co-trad">
                                <b>FUNDACIÓN IWOKA COLOMBIA, </b>
                            </span>
                        </font>
                        <font>
                            <span lang="es-ES-u-co-trad">
                                entidad sin ánimos de lucro, con domicilia en la
                                ciudad de Pereira (Risaralda), identificada con
                                el NIT{' '}
                            </span>
                        </font>
                        <font>
                            <span lang="es-ES-u-co-trad">
                                <b>901.212.803-6</b>
                            </span>
                        </font>
                        <font>
                            <span lang="es-ES-u-co-trad">, (en adelante </span>
                        </font>
                        <font>
                            <span lang="es-ES-u-co-trad">
                                <b>IWOKA</b>
                            </span>
                        </font>
                        <font>
                            <span lang="es-ES-u-co-trad">
                                ) fundación dedicada a desarrollar{' '}
                            </span>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    programas de formación integral a la
                                    población juvenil e infantil, aplicando
                                </span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    metodologías innovadoras para el
                                    mejoramiento de la convivencia y el
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    fortalecimiento del nivel educativo.{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <span lang="es-ES-u-co-trad">
                                <b>IWOKA</b>
                            </span>
                        </font>
                        <font>
                            <span lang="es-ES-u-co-trad">
                                se enfoca, entre otros aspectos, en:
                            </span>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    a) Promoción del{' '}
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                desarrollo integral (humano e intelectual) de
                                los jóvenes para que se conviertan en
                                transformadores de su entorno; con énfasis de
                                los niños de su misma institución educativa; b
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">)</span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">La</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">elaboraci</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">ó</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">n y aplicaci</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">ó</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    n de metodolog
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">í</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    as tendientes a prevenir la deserci
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">ó</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">n</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    escolar en niños y jóvenes; c)
                                </span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">El</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    apoyo, patrocinio y ejecución de ideas de
                                    emprendimiento presentadas por niños
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    y jóvenes participantes de los programas de
                                    la Fundación y cuyos propósitos y
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    objetivos concuerden con los de la
                                    Fundación; y d)
                                </span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">Actividades</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    de apoyo a las familias y al personal de las
                                    instituciones educativas,
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <span lang="es-ES-u-co-trad">
                                    incluidos el cuerpo docente, personal
                                    administrativo y padres de familia.
                                </span>
                            </font>
                        </font>
                        <font color="#3e545f">
                            <font>
                                <span lang="es-ES-u-co-trad">&nbsp;</span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.25in',
                            marginBottom: '0in',
                            borderTop: 'none',
                            // borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            padding: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.25in',
                            marginBottom: '0in',
                            borderTop: 'none',
                            // borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            padding: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    en cumplimiento con lo dispuesto en la
                                    Constitución Política de Colombia, la Ley
                                    Estatutaria 1581 de 2012 y el Decreto 1377
                                    de 2013 y sus normas reglamentarias y
                                    complementarias, garantiza de forma integral
                                    la protección y el ejercicio del derecho
                                    fundamental de Habeas Data de todos los
                                    titulares de la información de carácter
                                    personal de la cual sea responsable o
                                    encargada de su tratamiento, así mismo
                                    garantiza en todo momento los derechos
                                    fundamentales a la intimidad, el buen nombre
                                    y la privacidad de las personas, razón por
                                    la cual adopta y aplica la presente Política
                                    y Procedimiento de Protección de Datos
                                    Personales, la cual contiene todos los
                                    elementos esenciales, sencillos y seguros
                                    para el cumplimiento con la legislación
                                    correspondiente a la Protección de Datos
                                    Personales.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.25in',
                            marginBottom: '0in',
                            borderTop: 'none',
                            // borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            padding: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.25in',
                            marginBottom: '30px',
                            borderTop: 'none',
                            borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            padding: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    En esta política usted encontrará la razón
                                    social, domicilio, dirección, correo
                                    electrónico y teléfono de{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">, el </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    tratamiento al cual serán sometidos los
                                    datos y finalidad del mismo, los derechos
                                    que le asisten como Titular, el área
                                    responsable de la atención de peticiones,
                                    consultas y reclamos ante la cual puede
                                    ejercer sus derechos a conocer, actualizar,
                                    rectificar y suprimir el dato y revocar la
                                    autorización y la vigencia de la base de
                                    datos.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"> </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                            borderTop: 'none',
                            borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            paddingTop: '0in',
                            paddingBottom: '0.01in',
                            paddingLeft: '0in',
                            paddingRight: '0in',
                        }}
                    >
                        <a name="_Toc369548880" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2. DEFINICIONES</b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Para efectos de la Política de Tratamiento
                                    de Datos Personales de{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    , los términos que aparecen a continuación,
                                    y que en el texto de este documento aparecen
                                    escritos con su primera letra en mayúscula
                                    tendrán el siguiente significado, salvo que,
                                    por el contexto, el significado natural sea
                                    claramente otro.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <a name="_Toc369548881" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.1. Aviso de privacidad:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Comunicación verbal o escrita generada por
                                    el responsable, dirigida al titular para el
                                    tratamiento de sus datos personales,
                                    mediante la cual se le informa acerca de la
                                    existencia de las políticas de tratamiento
                                    de información que le serán aplicables, la
                                    forma de acceder a las mismas y las
                                    finalidades del tratamiento que se pretende
                                    dar a los datos personales.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <a name="_Toc369548882" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.2. Autorización:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Consentimiento previo, expreso e informado
                                    del titular de los datos personales para
                                    llevar a cabo el tratamiento de los datos
                                    personales.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.3 Base de datos:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Conjunto organizado de datos personales que
                                    sean objeto de Tratamiento.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <a name="_Toc369548883" />
                        <a name="_Toc369548884" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.4 Dato personal:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Cualquier información vinculada o que pueda
                                    asociarse a una o a varias personas
                                    naturales determinadas o determinables
                                    <a
                                        className="sdfootnoteanc"
                                        name="sdfootnote1anc"
                                        href="#sdfootnote1sym"
                                    >
                                        <sup>1</sup>
                                    </a>
                                    . Debe entonces entenderse el “dato
                                    personal” como una información relacionada
                                    con una persona natural (persona
                                    individualmente considerada).{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.5 Dato público</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    : Es el dato que no sea semiprivado, privado
                                    o sensible. Son considerados datos públicos,
                                    entre otros, los datos relativos al estado
                                    civil de las personas, a su profesión u
                                    oficio y a su calidad de comerciante o de
                                    servidor público. Por su naturaleza, los
                                    datos públicos pueden estar contenidos,
                                    entre otros, en registros públicos,
                                    documentos públicos, gacetas y boletines
                                    oficiales, y sentencias judiciales
                                    debidamente ejecutoriadas que no estén
                                    sometidas a reserva. También se entenderá
                                    que todos los datos que estén contenidos en
                                    los registros públicos tendrán esta misma
                                    naturaleza.
                                </span>
                            </font>
                        </font>
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    ></h2>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548887" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    2.6 Dato sensible:{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span style={{ fontWeight: 'normal' }}>
                                        Aquel dato que afecta la intimidad del
                                        Titular o cuyo uso indebido puede
                                        generar su discriminación, tales como
                                        aquellos que revelen el origen racial o
                                        étnico, la orientación política, las
                                        convicciones religiosas o filosóficas,
                                        la pertenencia a sindicatos,
                                        organizaciones sociales, de derechos
                                        humanos o que promueva intereses de
                                        cualquier partido político o que
                                        garanticen los derechos y garantías de
                                        partidos políticos de oposición así como
                                        los datos relativos a la salud, a la
                                        vida sexual y los datos biométricos
                                        <a
                                            className="sdfootnoteanc"
                                            name="sdfootnote2anc"
                                            href="#sdfootnote2sym"
                                        >
                                            <sup>2</sup>
                                        </a>
                                        .
                                    </span>
                                </span>
                            </font>
                        </font>
                    </h2>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <br />
                    </h2>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548888" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    2.7 Encargado del tratamiento:{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span style={{ fontWeight: 'normal' }}>
                                        La empresa actúa como encargada del
                                        tratamiento de datos personales en los
                                        casos, que por sí misma o en asocio con
                                        otros, realice el tratamiento de datos
                                        personales por cuenta de un responsable
                                        del tratamiento.
                                    </span>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548889" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    2.8 Responsable del tratamiento:{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span style={{ fontWeight: 'normal' }}>
                                        La empresa actúa como responsable del
                                        tratamiento de datos personales frente a
                                        todos los datos personales sobre los
                                        cuales decida directamente, en
                                        cumplimiento de las funciones propias
                                        reconocidas legalmente.
                                    </span>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                    </h2>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <br />
                    </h2>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548890" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    2.9 Titular:{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span style={{ fontWeight: 'normal' }}>
                                        Persona natural cuyos datos personales
                                        sean objeto de Tratamiento.
                                    </span>
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.10 Transferencia:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    La transferencia de datos tiene lugar cuando
                                    el responsable y/o encargado del tratamiento
                                    de datos personales, ubicado en Colombia,
                                    envía la información o los datos personales
                                    a un receptor, que a su vez es responsable
                                    del tratamiento y se encuentra dentro o
                                    fuera del país.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.11 Transmisión:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Tratamiento de datos personales que implica
                                    la comunicación de los mismos dentro o fuera
                                    del territorio de la República de Colombia
                                    cuando tenga por objeto la realización de un
                                    tratamiento por el encargado por cuenta del
                                    responsable.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.12 Tratamiento:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Cualquier operación o conjunto de
                                    operaciones que la empresa realice sobre
                                    datos de carácter personal tales como la
                                    recolección, procesamiento, publicidad,
                                    almacenamiento, uso, circulación o
                                    supresión.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.13 Oficial de protección de datos:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Es la persona dentro de la empresa que tiene
                                    como función la vigilancia y control de la
                                    aplicación de la Política de Protección de
                                    Datos Personales. El oficial será designado
                                    por el gerente o en su defecto por la Junta
                                    directiva.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.14. Beneficiarios: </b>
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            Es aquella persona a quienes están
                                            dirigidos los programas y/o
                                            Proyectos de{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>IWOKA.</span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            Los beneficiarios se clasifican a su
                                            vez en:
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.39in',
                            marginBottom: '0in',
                        }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>
                                                2.14.1. Beneficiarios directos:{' '}
                                            </span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            Son los niños y adolescentes
                                            vinculados a quienes están enfocados
                                            los programas y/o Proyectos de{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>IWOKA.</span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.39in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.39in',
                            marginBottom: '0in',
                        }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>
                                                2.14.2. Beneficiarios
                                                indirectos:
                                            </span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            Son las personas que sin participar
                                            directamente de los programas y/o
                                            Proyectos de{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>IWOKA</span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            de alguna manera reciben provecho de
                                            los mismos; tal es el caso de los
                                            padres, representantes y cuerpo
                                            docente de las instituciones por
                                            medio de la cual se ejecutan los
                                            programas y/o Proyectos de{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>IWOKA.</span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.39in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.39in',
                            marginBottom: '0in',
                        }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>
                                                2.14.3. Beneficiarios Activos:{' '}
                                            </span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            Son los beneficiarios de los
                                            programas y/o Proyectos que se
                                            encuentren en ejecución.
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.39in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.39in',
                            marginBottom: '0in',
                        }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>
                                                2.14.4. Beneficiarios de
                                                Egresados de IWOKA:{' '}
                                            </span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            Son los beneficiarios de los
                                            programas y/o Proyectos que ya hayan
                                            sido ejecutados y terminados.
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.15</b>
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>. Voluntario: </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            Es aquella persona que, de manera
                                            espontánea y libre, se compromete en
                                            realizar una labor social y para
                                            esto se vincula con{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>IWOKA</span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            ofreciendo su tiempo de forma
                                            desinteresada, responsable y a
                                            título gratuito (sin remuneración),
                                            para ayudar en actividades
                                            logísticas y operativas en los
                                            lugares donde se desarrollan los
                                            programas y/o proyectos de{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>IWOKA.</span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span></span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.16. Clientes: </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las personas naturales o jurídicas con
                                    las cuales{' '}
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>
                                            <span>IWOKA</span>
                                        </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            realiza alianza con el propósito de
                                            desarrollar los programas y/o
                                            Proyectos en ejecución de su objeto
                                            social. Dentro de estos encontramos
                                            principalmente a las Instituciones
                                            Educativas.{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.17. Instituciones Educativas:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">Son</span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b></b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            los establecimientos o
                                            instituciones, de carácter público o
                                            privado, aprobadas por el Estado
                                            colombiano para impartir la
                                            educación, que permiten a{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    ejecutar sus programas y/o proyectos sin
                                    exigir o entregar contraprestación alguna.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>2.18. Asistentes a eventos: </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las personas naturales que se interesan
                                    por algún evento programado por{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA,</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    quienes a fin de formar parte en dichas
                                    actividades pagan el valor establecido para
                                    la participación.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.19. Actores Sociales: </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">Son las </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Fundaciones con interés social misional en
                                    la educación.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>2.20. Filántropos Comprometidos: </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las personas naturales que{' '}
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            fundamentados en su amor a las
                                            personas en general, se dedican a
                                            trabajar por ellas y procurar su
                                            progreso y su bien de manera
                                            desinteresada; y en ese orden de
                                            ideas, se vinculan a{' '}
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            con el propósito de colaborar
                                            directa o indirectamente en los
                                            programas y/o proyectos
                                            desarrollados por la fundación.
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <b>
                                        <span>2.21. Empresario</span>
                                    </b>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span>
                                        : Son los propietarios o directivos de
                                        empresas, es decir, aquella persona
                                        natural que, de forma individual o
                                        colectiva, fija los objetivos y toma las
                                        decisiones estratégicas acerca de las
                                        metas, los medios, la administración y
                                        el control de una empresa, y asume la
                                        responsabilidad tanto comercial como
                                        legal frente a terceros.
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span>&nbsp;</span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span>
                                        También se tiene como empresario, a las
                                        personas naturales que se dedican al
                                        comercio formal, bien sea personalmente
                                        o mediante un establecimeinto de su
                                        propiedad, y que están debidamente
                                        inscritos en la Cámara de Comercio como
                                        tal.
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <b>
                                        <span>
                                            2.22. Empresario y/o Empresa Privada
                                            Socialmente Responsable:{' '}
                                        </span>
                                    </b>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span>
                                        Son aquellos empresarios o empresas de
                                        carácter privado, que tienen un
                                        compromiso voluntario con la sociedad, y
                                        en ese sentido,{' '}
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>se vinculan a </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            con el propósito de colaborar con
                                            los programas y/o proyectos
                                            desarrollados por la fundación,
                                            mediante donaciones.
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                            borderTop: 'none',
                            borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            paddingTop: '0in',
                            paddingBottom: '0.01in',
                            paddingLeft: '0in',
                            paddingRight: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        3. PRINCIPIOS PARA EL TRATAMIENTO DE
                                        DATOS PERSONALES{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Para dar cumplimiento a la Política de
                                    Protección de Datos Personales, se aplicarán
                                    de manera armónica e integral, los
                                    siguientes principios:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        3.1. Principio de legalidad en materia
                                        de Tratamiento de datos
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    : El Tratamiento es una actividad reglada
                                    que debe sujetarse a lo establecido en la
                                    Ley y sus decretos reglamentarios.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>3.2. Principio de finalidad:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El Tratamiento obedecerá a una finalidad
                                    legítima de acuerdo con la Constitución y la
                                    Ley y será informada al Titular.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>3.3 Principio de libertad:</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El Tratamiento sólo podrá ejercerse con el
                                    consentimiento, previo, expreso e informado
                                    del Titular. Los datos personales no podrán
                                    ser obtenidos o divulgados sin autorización,
                                    por mandato legal o judicial que obligue a
                                    relevar el consentimiento;
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>3.4 Principio de veracidad o calidad</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    : La información sujeta al Tratamiento será
                                    veraz, completa, exacta, actualizada,
                                    comprobada y comprensible. No se realizará
                                    el Tratamiento de datos parciales,
                                    incompletos, fraccionados o que induzcan a
                                    error.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>3.5 Principio de transparencia</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    : Se garantizará el derecho del Titular a
                                    obtener en cualquier momento y sin
                                    restricciones, información acerca de la
                                    existencia de los datos que le conciernen;
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        3.6 Principio de acceso y circulación
                                        restringida
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    : El Tratamiento se sujetará a los límites
                                    que se derivan de la naturaleza de los datos
                                    personales, de las disposiciones de la ley y
                                    la Constitución. En este sentido, el
                                    Tratamiento sólo se ejercerá por personas
                                    autorizadas por el Titular o por las
                                    personas previstas en la ley;
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Salvo la información pública, los datos no
                                    estarán disponibles en Internet u otros
                                    medios de divulgación o comunicación masiva,
                                    salvo que el acceso sea técnicamente
                                    controlable para brindar un conocimiento
                                    restringido sólo a los Titulares o terceros
                                    autorizados.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>3.7 Principio de seguridad</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    : La información se manejará con las medidas
                                    técnicas, humanas y administrativas
                                    necesarias para garantizar la seguridad de
                                    los registros evitando su adulteración,
                                    pérdida, consulta, uso o acceso no
                                    autorizado o fraudulento;
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>3.8 Principio de confidencialidad: </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Todas las personas que intervengan en el
                                    Tratamiento de datos personales que no
                                    tengan la naturaleza de públicos
                                    garantizarán la reserva de la información,
                                    inclusive después de finalizada su relación
                                    con alguna de las labores que comprenda el
                                    Tratamiento, pudiendo sólo realizar
                                    suministro o comunicación de datos
                                    personales cuando ello corresponda al
                                    desarrollo de las actividades autorizadas en
                                    la Ley.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                            borderTop: 'none',
                            borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            paddingTop: '0in',
                            paddingBottom: '0.01in',
                            paddingLeft: '0in',
                            paddingRight: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                            borderTop: 'none',
                            borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            paddingTop: '0in',
                            paddingBottom: '0.01in',
                            paddingLeft: '0in',
                            paddingRight: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4. TRATAMIENTO Y FINALIDAD DE DATOS
                                        PERSONALES{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>4.1. Tratamiento </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        obtendrá de manera libre, previa,
                                        expresa y voluntaria la autorización
                                        para transferir, almacenar, usar,
                                        circular, suprimir, compartir,
                                        actualizar y transmitir los datos
                                        personales a través de correo físico,
                                        electrónico, celular o dispositivo
                                        móvil, vía mensajes de texto, medios de
                                        comunicación, (televisión, diario y
                                        pagina web), redes sociales o cualquier
                                        medio análogo y/o digital de
                                        comunicación, conocido o por conocer de
                                        acuerdo con la presente Política de
                                        Protección de Datos Personales
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>4.2 Responsable de tratamiento </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>Razón social </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    FUNDACIÓN IWOKA COLOMBIA
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b></b>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>NIT.</b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    901.212.803-6
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>Domicilio </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        Pereira – Risaralda – Colombia
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>Dirección</b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span>
                                        Barrio El Jardín II Etapa. Manzana 8
                                        Casa 14
                                    </span>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>Correo electrónico </b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    administrativo@fundacioniwoka.org
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>Teléfono</b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        (6) 323 59 55{' '}
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{
                            lineHeight: '115%',
                            orphans: 0,
                            widows: 0,
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{
                            lineHeight: '115%',
                            orphans: 0,
                            widows: 0,
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>4.3 Tratamiento de datos públicos</b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    advierte que tratará sin previa autorización
                                    del Titular los datos de naturaleza pública
                                    y los contenidos en los registros públicos.
                                    Esta situación no implica que no se adopten
                                    las medidas necesarias que garanticen el
                                    cumplimiento de los otros principios y
                                    obligaciones contempladas en la Ley 1581 de
                                    2012 y demás normas que regulen esta
                                    materia, así como en el presente manual.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>4.4. Tratamiento de datos sensibles </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    solo tratará datos personales sensibles para
                                    lo estrictamente necesario, solicitando
                                    consentimiento previo y expreso a los
                                    titulares e informándoles sobre la finalidad
                                    exclusiva para su tratamiento.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    solo utilizará y tratará datos catalogados
                                    como sensibles, cuando:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El tratamiento haya sido autorizado
                                    expresamente por el Titular de los datos
                                    sensibles, salvo en los casos que, por Ley,
                                    no se requiera el otorgamiento de dicha
                                    autorización.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El Tratamiento sea necesario para
                                    salvaguardar el interés vital del titular y
                                    éste se encuentre física o jurídicamente
                                    incapacitado. En estos eventos, los
                                    representantes deberán otorgar la
                                    autorización.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El Tratamiento se refiera a datos que sean
                                    necesarios para el reconocimiento, ejercicio
                                    o defensa de un derecho en un proceso
                                    judicial;
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    En este sentido{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    cumplirá con las siguientes obligaciones:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Informar al titular que por
                                                tratarse de datos sensibles no
                                                está obligado a autorizar su
                                                tratamiento.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Informar al titular de forma
                                                explícita y previa, además de
                                                los requisitos generales de la
                                                autorización para la recolección
                                                de cualquier tipo de dato
                                                personal, cuáles datos objeto de
                                                Tratamiento son de carácter
                                                sensible y la finalidad del
                                                tratamiento, y obtener el
                                                consentimiento expreso.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                No condicionar ninguna actividad
                                                a que el titular suministre
                                                datos personales sensibles
                                                (salvo que exista una causa
                                                legal o contractual para
                                                hacerlo).
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.5. Tratamiento de datos de personas
                                        menores de edad.
                                    </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    tratará datos personales de menores de edad
                                    cuando:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ol start={4}>
                        <ol start={5}>
                            <ol>
                                <li>
                                    <p
                                        align="justify"
                                        style={{ marginBottom: '0.14in' }}
                                    >
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <font>
                                                    <span lang="es-ES-u-co-trad">
                                                        Éstos sean de naturaleza
                                                        pública, provengan de la
                                                        información suministrada
                                                        por empleados o
                                                        contratistas, al momento
                                                        de su vinculación
                                                        laboral o de prestación
                                                        de servicios con la
                                                        fundación.
                                                    </span>
                                                </font>
                                            </font>
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p
                                        align="justify"
                                        style={{ marginBottom: '0.14in' }}
                                    >
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <font>
                                                    <span lang="es-ES-u-co-trad">
                                                        Sean suministrados en
                                                        virtud de las
                                                        actividades que
                                                        desarrolla la fundación
                                                        en ejecución de su
                                                        objeto social, a través
                                                        de los proyectos y/o
                                                        programas que lleve a
                                                        cabo y en los que
                                                        participe el menor de
                                                        edad como beneficiario.
                                                    </span>
                                                </font>
                                            </font>
                                        </font>
                                    </p>
                                </li>
                            </ol>
                        </ol>
                    </ol>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Lo anterior, de conformidad con lo
                                    establecido en el artículo 7 de la Ley 1581
                                    de 2012 y, cuando el tratamiento cumpla con
                                    los siguientes parámetros y requisitos:{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Que responda y respete el
                                                interés superior de los niños,
                                                niñas y adolescentes.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Que se asegure el respeto de sus
                                                derechos fundamentales.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Cumplidos los anteriores requisitos,{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    exigirá al representante legal o tutor del
                                    niño, niña o adolescente la autorización del
                                    menor, previo ejercicio del menor de su
                                    derecho a ser escuchado, opinión que será
                                    valorada teniendo en cuenta la madurez,
                                    autonomía y capacidad para entender el
                                    asunto.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    La fundación y cualquier persona involucrada
                                    en el tratamiento de los datos personales de
                                    niños, niñas y adolescentes, velarán por el
                                    uso adecuado de los mismos. En cumplimiento
                                    de lo anterior, se aplican y desarrollan los
                                    principios y obligaciones establecidos en la
                                    Ley 1581 de 2012 y el Decreto 1377 de 2013.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6. Clasificación de las bases de datos
                                    </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    ha clasificado sus Bases de Datos de la
                                    siguiente manera:{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>4.6.1. Bases de datos de Clientes: </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas, que se encuentran
                                    estructuradas y que contienen datos de
                                    naturaleza pública, privada, datos sensibles
                                    y de menores de edad, sobre personas
                                    naturales o jurídicas que se encuentran
                                    vinculadas o se pretendan vincular a la
                                    fundación para recibir los productos,
                                    servicios, proyectos y/o programas en los
                                    términos y condiciones acordados entre las
                                    partes.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    A su vez, en esta base datos encontramos la
                                    siguiente clasificación:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            textIndent: '-0.49in',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        {' '}
                                        4.6.1.1. Base de datos de Instituciones
                                        Educativas:{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        Esta base de datos contiene información
                                        de las instituciones educativas
                                        vinculadas con{' '}
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>IWOKA</b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad"></span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            que permiten la ejecución de
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    sus programas y/o proyectos sin exigir o
                                    entregar contraprestación alguna.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            textIndent: '-0.49in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            textIndent: '-0in',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.1.2. Base de datos de Directivos de
                                        Instituciones Educativas:
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        Esta base de datos contiene datos de las
                                        personas naturales que forman parte de
                                        los cuerpos directivo de las
                                        instituciones educativas en las que{' '}
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>IWOKA</b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        desarrolla sus programas y/o proyectos,
                                        quienes prestan colaboración para su
                                        correcta ejecución.
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            textIndent: '-0in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            textIndent: '-0in',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.1.3. Base de datos de Docentes de
                                        Instituciones Educativas:
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        Esta base de datos contiene datos de las
                                        personas naturales que forman parte del
                                        cuerpo docente de las instituciones
                                        educativas en las que
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <b>IWOKA</b>
                                    </span>
                                </font>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        desarrolla sus programas y/o proyectos,
                                        quienes prestan colaboración para su
                                        correcta ejecución.
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Estas bases de datos contienen datos
                                    personales, públicos, privados y sensibles.
                                    El tratamiento de estos datos para fines
                                    diferentes al mantenimiento de la relación
                                    entre las partes o el cumplimiento de
                                    deberes de carácter legal, requiere de
                                    autorización previa del titular o del
                                    representante en el caso de los menores de
                                    edad.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    realizará el tratamiento de sus datos para
                                    los siguientes fines:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Gestionar trámites de
                                                    solicitudes, quejas y
                                                    reclamos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.49in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Realizar análisis de riesgo,
                                                    efectuar encuestas{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                para medir el impacto de los
                                                servicios, proyectos y/o
                                                programas{' '}
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    de la fundación, estudios
                                                    internos, evaluar la
                                                    calidad,
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    cambios en la metodología y
                                                    procesos
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                empleados en los servicios,
                                                proyectos y/o programas
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    , para la fundación y sus
                                                    aliados.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0.14in',
                        }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    comercial, publicitaria o
                                                    promocional sobre los
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                productos, servicios, proyectos
                                                y/o programas
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    , con el fin de impulsar,
                                                    invitar, dirigir, ejecutar,
                                                    informar y de manera
                                                    general, llevar a cabo
                                                    campañas, eventos,
                                                    promociones o concursos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información de
                                                    contacto a la fuerza
                                                    comercial y/o red de
                                                    distribución, telemercadeo,
                                                    investigación de mercados y
                                                    cualquier tercero con el
                                                    cual la fundación tenga un
                                                    vínculo contractual.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Dar a conocer, transferir
                                                    y/o trasmitir datos
                                                    personales dentro y fuera
                                                    del país a terceros como
                                                    consecuencia de un contrato,
                                                    ley o vínculo lícito que así
                                                    lo requiera, o para
                                                    implementar{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                los servicios, proyectos y/o
                                                programas
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    .
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0.14in',
                        }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Realizar a través de
                                                    cualquier medio en forma
                                                    directa o a través de
                                                    terceros, programación y
                                                    prestación de servicio
                                                    técnico, venta, compra,
                                                    facturación, gestión de
                                                    cartera, seguimiento al
                                                    desempeño del producto,
                                                    recaudo, inteligencia de
                                                    negocios, actividades de
                                                    mercadeo, promoción o
                                                    publicidad, mejoramiento del
                                                    servicio, seguimiento al
                                                    recaudo, verificación,
                                                    consulta y control,
                                                    habilitación de medios de
                                                    pago así como cualquier otra
                                                    relacionada con nuestros{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                productos, servicios, proyectos
                                                y/o programas{' '}
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    actuales y futuros, para el
                                                    cumplimiento de las
                                                    obligaciones contractuales y
                                                    del objeto social de la
                                                    fundación.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Consulta y reporte a
                                                    cualquier Central de Riesgos{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Información para controlar y
                                                    prevenir el fraude y LAFT en
                                                    cualquiera de sus
                                                    modalidades.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.5in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Utilización de datos
                                                    biométricos (datos
                                                    sensibles) como{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    huella digital, audio, video
                                                    y/o fotografía
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    o a través de cualquier otro
                                                    medio conocido o por
                                                    conocerse (se entiende como
                                                    “imagen” el nombre,
                                                    seudónimo, voz, firmas,
                                                    iniciales, figura,
                                                    fisionomía de cuerpo, cara o
                                                    cualquier signo que se
                                                    relacione con la identidad
                                                    de la persona), para
                                                    reproducción, comunicación o
                                                    transmisión en redes
                                                    sociales y medios de
                                                    comunicación como
                                                    televisión, periódicos,
                                                    pagina web y sistemas de
                                                    videovigilancia, para la
                                                    seguridad, eventos
                                                    institucionales, campañas
                                                    publicitarias, promociones y
                                                    concursos, entre otros.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>4.6.2. Bases de datos de Empleados: </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizados que contienen datos de las
                                    personas naturales que se vinculan
                                    laboralmente con la fundación, cuyo
                                    tratamiento tiene como finalidad cumplir con
                                    las disposiciones legales, reglamentarias y
                                    políticas institucionales. En esta base de
                                    datos, se incorporan información privada,
                                    pública, datos sensibles y de menores de
                                    edad. El tratamiento de los datos para los
                                    fines diferentes a las obligaciones
                                    derivadas de la relación laboral requerirá
                                    autorización previa del titular o su
                                    representante legal, según sea el caso. En
                                    ningún caso, la fundación dará tratamiento a
                                    los datos sensibles o de menores sin
                                    autorización previa.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    realizará el tratamiento de sus datos para
                                    los siguientes fines:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Dar cumplimiento a las
                                                    obligaciones contraídas por
                                                    la fundación con el Titular
                                                    de la Información, con
                                                    relación al pago de salario
                                                    y demás retribuciones
                                                    consagradas en el contrato
                                                    de trabajo o según lo
                                                    disponga la ley.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Ofrecer programas de
                                                    bienestar corporativo y
                                                    planificar actividades
                                                    institucionales, para el
                                                    titular y sus beneficiarios
                                                    (hijos, cónyuge, compañero
                                                    permanente).
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Envío de información a
                                                    entidades gubernamentales o
                                                    judiciales por solicitud
                                                    expresa de la misma.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.25in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Soporte en procesos de
                                                    auditoria externa/interna.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.25in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Registro de la información
                                                    en la base de datos de la
                                                    fundación y realizar
                                                    contacto a través de
                                                    cualquier medio para el
                                                    envío de información.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font color="#000000"> </font>
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Con propósitos de seguridad
                                                    o prevención de fraude.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    comercial, publicitaria o
                                                    promocional sobre los
                                                    productos y/o servicios, con
                                                    el fin de impulsar, invitar,
                                                    dirigir, ejecutar, informar
                                                    y de manera general, llevar
                                                    a cabo campañas, eventos,
                                                    promociones o concursos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Para tratar los datos
                                                    relativos a los exámenes
                                                    médicos de ingreso,
                                                    prevención y retiro, así
                                                    como las historias clínicas
                                                    en caso de requerirse.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Utilización de datos
                                                    biométricos (datos
                                                    sensibles) como{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    huella digital, audio, video
                                                    y/o fotografía
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    o a través de cualquier otro
                                                    medio conocido o por
                                                    conocerse (se entiende como
                                                    “imagen” el nombre,
                                                    seudónimo, voz, firmas,
                                                    iniciales, figura,
                                                    fisionomía de cuerpo, cara o
                                                    cualquier signo que se
                                                    relacione con la identidad
                                                    de la persona), para
                                                    reproducción, comunicación o
                                                    transmisión en redes
                                                    sociales y medios de
                                                    comunicación como
                                                    televisión, periódicos,
                                                    pagina web y sistemas de
                                                    videovigilancia, para la
                                                    seguridad, eventos
                                                    institucionales, campañas
                                                    publicitarias, promociones y
                                                    concursos, entre otros.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font color="#000000">
                                    {' '}
                                    <font>
                                        <font
                                            size={2}
                                            style={{ fontSize: '11pt' }}
                                        >
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Para recolectar, usar,
                                                    almacenar y circular su hoja
                                                    de vida.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Transferencia de datos a
                                                    terceros para realizar
                                                    procesos de selección,
                                                    afiliación a empresas de
                                                    salud, pensión, ARL,
                                                    aseguradoras y demás que se
                                                    requieran para la
                                                    formalización del proceso de
                                                    contratación.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Consulta y reporte a
                                                    cualquier Central de
                                                    Riesgos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Efectuar las gestiones
                                                    pertinentes para el
                                                    desarrollo del objeto social
                                                    de la fundación en lo que
                                                    tiene que ver con el
                                                    cumplimiento del objeto del
                                                    contrato celebrado con el
                                                    Titular de la información.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Información para controlar y
                                                    prevenir el fraude y LAFT en
                                                    cualquiera de sus
                                                    modalidades.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.3. Bases de datos de Proveedores y
                                        Contratistas:{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas naturales o jurídicas que
                                    vinculados con{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    de forma contractual y comercial, cuyo
                                    tratamiento tiene como finalidad cumplir con
                                    las disposiciones contractuales estipuladas
                                    por la fundación, para la adquisición de
                                    servicios y bienes demandados por ella para
                                    su normal funcionamiento o el cumplimiento
                                    de algunas de sus funciones. Esta base de
                                    datos contiene datos personales, públicos,
                                    privados y sensibles, los cuales tienen como
                                    finalidad el desarrollo de relaciones
                                    contractuales. El tratamiento de estos datos
                                    para fines diferentes al mantenimiento de la
                                    relación contractual o el cumplimiento de
                                    deberes de carácter legal, requiere de
                                    autorización previa del titular.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.25in', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    realizará el tratamiento de sus datos para
                                    los siguientes fines:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Dar cumplimiento a las
                                                    obligaciones contraídas por
                                                    la empresa con el Titular de
                                                    la Información, con relación
                                                    al pago de honorarios y
                                                    demás retribuciones
                                                    consagradas en el contrato
                                                    de trabajo o según lo
                                                    disponga la ley.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Realizar análisis,
                                                    evaluaciones y selección de
                                                    proveedores y/o contratistas
                                                    potenciales.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Contactar al Titular a
                                                    través de cualquier medio
                                                    para realizar encuestas,
                                                    estudios y/o confirmación de
                                                    datos personales necesarios
                                                    para la ejecución de una
                                                    relación contractual y para
                                                    el envío de noticias
                                                    relacionadas con campañas de
                                                    fidelización o mejora de
                                                    servicio.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Contactar al Titular a
                                                    través de cualquier medio
                                                    para el envío de extractos,
                                                    estados de cuenta o facturas
                                                    en relación con las
                                                    obligaciones derivadas del
                                                    contrato celebrado entre las
                                                    partes.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Realizar a través de
                                                    cualquier medio en forma
                                                    directa o a través de
                                                    terceros, programación y
                                                    prestación de servicio
                                                    técnico, venta, compra,
                                                    facturación, seguimiento al
                                                    desempeño del producto y/o
                                                    servicio prestado, recaudo,
                                                    inteligencia de negocios,
                                                    verificación, consulta y
                                                    control, habilitación de
                                                    medios de pago así como
                                                    cualquier otra relacionada
                                                    con nuestros requerimientos,
                                                    para el cumplimiento de las
                                                    obligaciones contractuales y
                                                    del objeto social de la
                                                    fundación.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.5in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Comunicación de nuestras
                                                    políticas y procedimientos
                                                    para la vinculación de
                                                    proveedores.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.5in',
                            textIndent: '-0.25in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Análisis de información
                                                    sobre calidad y niveles de
                                                    servicio recibidos de los
                                                    proveedores. Cumplimiento
                                                    legal en materia fiscal, de
                                                    aduanas y comercial con
                                                    entidades administrativas y
                                                    judiciales.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.5in',
                            textIndent: '-0.25in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Acuerdos de negocio para
                                                    adquirir bienes o servicios{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Labores de monitoreo,
                                                    control y registro contable
                                                    de las obligaciones
                                                    contraídas con los
                                                    proveedores. Consultas,
                                                    auditorias y revisiones
                                                    derivadas de los acuerdos
                                                    con los proveedores y/o
                                                    contratistas.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Información para controlar y
                                                    prevenir el fraude y LAFT en
                                                    cualquiera de sus
                                                    modalidades. Algunas de
                                                    estas labores se realizan en
                                                    cumplimiento de un deber
                                                    legal y contractual y por
                                                    tanto el tratamiento de
                                                    datos personales se entiende
                                                    incluido en las mismas.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.5in',
                            textIndent: '-0.25in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    comercial, publicitaria o
                                                    promocional sobre los
                                                    productos y/o servicios, con
                                                    el fin de impulsar, invitar,
                                                    dirigir, ejecutar, informar
                                                    y de manera general, llevar
                                                    a cabo campañas, eventos,
                                                    promociones o concursos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.5in',
                            textIndent: '-0.25in',
                            marginBottom: '0.14in',
                        }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Consulta y reporte a
                                                    cualquier Central de Riesgos{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Invitaciones a eventos e
                                                    información general
                                                    corporativa para el
                                                    fortalecimiento de las
                                                    relaciones.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.4. Bases de datos de órganos
                                        sociales:{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas naturales que hacen parte de los
                                    órganos sociales, tales como la Asamblea
                                    General, Consejo de Fundación y Dirección
                                    Ejecutiva, cuyo tratamiento tiene como
                                    finalidad cumplir con las disposiciones
                                    legales, reglamentarias y las políticas
                                    institucionales. En esta base de datos, se
                                    incorpora información pública, privada,
                                    pública y datos sensibles. Además,{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    se considerará información reservada, pues
                                    la misma está registrada en los libros de
                                    comercio y está sujeta a una protección
                                    especial por disposición legal.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    realizará el tratamiento de sus datos para
                                    los siguientes fines:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.2in', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Efectuar las gestiones
                                                    pertinentes para el
                                                    desarrollo del objeto social
                                                    de la fundación.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.49in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Recolectar, usar y almacenar
                                                    su hoja de vida.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0.14in',
                        }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Enviar información de la
                                                    fundación, incluyendo
                                                    convocatorias, citaciones,
                                                    invitaciones a reuniones,
                                                    eventos, boletines,
                                                    presentaciones, informe
                                                    anual y aquellas
                                                    comunicaciones relacionadas
                                                    con las actividades que
                                                    adelanta la fundación.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Emitir certificaciones
                                                    relativas a su participación
                                                    en la fundación.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Utilización de datos
                                                    biométricos (datos
                                                    sensibles) como{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    huella digital, audio, video
                                                    y/o fotografía
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    o a través de cualquier otro
                                                    medio conocido o por
                                                    conocerse (se entiende como
                                                    “imagen” el nombre,
                                                    seudónimo, voz, firmas,
                                                    iniciales, figura,
                                                    fisionomía de cuerpo, cara o
                                                    cualquier signo que se
                                                    relacione con la identidad
                                                    de la persona), para
                                                    reproducción, comunicación o
                                                    transmisión en redes
                                                    sociales y medios de
                                                    comunicación como
                                                    televisión, periódicos,
                                                    página web y sistemas de
                                                    videovigilancia, para la
                                                    seguridad, eventos
                                                    institucionales, campañas
                                                    publicitarias, promociones y
                                                    concursos, entre otros.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.49in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Información para controlar y
                                                    prevenir el fraude y LAFT en
                                                    cualquiera de sus
                                                    modalidades.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.5. Bases de datos de Voluntarios:{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas naturales o jurídicas vinculadas a
                                    la fundación para colaborar en la ejecución
                                    de los programas y/o proyectos en calidad de
                                    Voluntario, cuyo tratamiento tiene como
                                    finalidad cumplir con las disposiciones
                                    estipuladas por la fundación, para la
                                    ejecución de dichas actividades. Esta base
                                    de datos contiene datos personales,
                                    públicos, privados y sensibles, los cuales
                                    tienen como finalidad el correcto desarrollo
                                    de los programas y/o proyectos de{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    . El tratamiento de estos datos para fines
                                    diferentes al mantenimiento de la relación
                                    entre las partes o el cumplimiento de
                                    deberes de carácter legal, requiere de
                                    autorización previa del titular.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    realizará el tratamiento de sus datos para
                                    los siguientes fines:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Comunicación de nuestras
                                                    políticas y procedimientos
                                                    para la vinculación de
                                                    Voluntarios; así como para
                                                    realizar análisis y
                                                    seguimiento al desempeño.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Envío de información a
                                                    entidades gubernamentales o
                                                    judiciales por solicitud
                                                    expresa de la misma.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.5in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Envío de información
                                                    relativa a los programas y/o
                                                    proyectos, y demás
                                                    actividades desarrolladas
                                                    por{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>IWOKA.</b>
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad"></span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.25in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Soporte en procesos de
                                                    auditoría externa/interna.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Registro de la información
                                                    en la base de datos de la
                                                    fundación y realizar
                                                    contacto a través de
                                                    cualquier medio para el
                                                    envío de información
                                                    general.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    comercial, publicitaria o
                                                    promocional sobre los
                                                    productos, servicios,
                                                    programas y/o proyectos con
                                                    el fin de impulsar, invitar,
                                                    dirigir, ejecutar, informar
                                                    y de manera general, llevar
                                                    a cabo campañas, eventos,
                                                    promociones o concursos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Utilización de datos
                                                    biométricos (datos
                                                    sensibles) como{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    huella digital, audio, video
                                                    y/o fotografía
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    o a través de cualquier otro
                                                    medio conocido o por
                                                    conocerse (se entiende como
                                                    “imagen” el nombre,
                                                    seudónimo, voz, firmas,
                                                    iniciales, figura,
                                                    fisionomía de cuerpo, cara o
                                                    cualquier signo que se
                                                    relacione con la identidad
                                                    de la persona), para
                                                    reproducción, comunicación o
                                                    transmisión en redes
                                                    sociales y medios de
                                                    comunicación como
                                                    televisión, periódicos,
                                                    pagina web y sistemas de
                                                    videovigilancia, para la
                                                    seguridad, eventos
                                                    institucionales, campañas
                                                    publicitarias, promociones y
                                                    concursos, entre otros.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font color="#000000">
                                    {' '}
                                    <font>
                                        <font
                                            size={2}
                                            style={{ fontSize: '11pt' }}
                                        >
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Para recolectar, usar,
                                                    almacenar y circular su hoja
                                                    de vida.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Comunicación de nuestras
                                                    políticas y procedimientos
                                                    para la vinculación de
                                                    voluntarios.{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.6. Bases de datos de Beneficiarios:{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas naturales o jurídicas vinculados
                                    con{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    en virtud de su calidad de Beneficiarios de
                                    los programas y/o proyectos, cuyo
                                    tratamiento tiene como finalidad cumplir con
                                    las disposiciones estipuladas por la
                                    fundación, para la ejecución de sus
                                    actividades. Esta base de datos contiene
                                    datos personales, públicos, privados y
                                    sensibles, así como de niños y adolescentes,
                                    previa autorización del representante.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    A su vez, en esta base datos encontramos la
                                    siguiente clasificación:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font color="#ff0000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad"> </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.6.1. Bases de datos de Beneficiarios
                                        Activos:
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas menores de edad que, previa
                                    autorización de sus padres o representantes,
                                    se encuentran vinculados con{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    en virtud de su calidad de Beneficiarios de
                                    los programas y/o proyectos en ejecución.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.6.2. Bases de datos de Beneficiarios
                                        Egresados de IWOKA:
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas naturales vinculados con{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    en virtud de su calidad de Beneficiarios de
                                    los programas y/o proyectos que ya{' '}
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>
                                            hayan sido ejecutados y terminados.
                                        </span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.49in',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.6.3. Bases de datos de Padres de
                                        Familia:{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas naturales vinculados con{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    en virtud de su calidad de padre, madre o
                                    representante de los menores de edad que son
                                    o han Beneficiarios de los programas y/o
                                    proyectos de{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        <span>.</span>
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Estas bases de datos contienen datos
                                    personales, públicos, privados y sensibles,
                                    así como de niños y adolescentes, previa
                                    autorización del representante. El
                                    tratamiento de estos datos para fines
                                    diferentes al mantenimiento de la relación
                                    entre las partes o el cumplimiento de
                                    deberes de carácter legal, requiere de
                                    autorización previa del titular o del
                                    representante en el caso de los menores de
                                    edad.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    realizará el tratamiento de sus datos para
                                    los siguientes fines:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Vinculación del Beneficiario
                                                    al programa y/o proyecto.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.49in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Gestionar trámites de
                                                    autorizaciones, solicitudes,
                                                    quejas y reclamos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Envío de información
                                                    relativa a los programas y/o
                                                    proyectos, y demás
                                                    actividades desarrolladas
                                                    por{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>IWOKA,</b>
                                            </span>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                a la cual estén vinculados.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    respecto del desempeño de
                                                    los Beneficiarios, en el
                                                    desarrollo de los programas
                                                    y/o proyectos de los que
                                                    forma parte.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.49in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Realizar análisis de riesgo,
                                                    efectuar encuestas de
                                                    satisfacción respecto de los{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                programas y/o proyectos en que
                                                participa
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    , estudios internos, evaluar
                                                    la calidad, cambios de los{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                programas y/o proyectos
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    a ofrecer por la fundación.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0.14in',
                        }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    comercial, publicitaria o
                                                    promocional sobre los
                                                    productos, servicios,{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                programas y/o proyectos
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    con el fin de impulsar,
                                                    invitar, dirigir, ejecutar,
                                                    informar y de manera
                                                    general, llevar a cabo
                                                    campañas, eventos,
                                                    promociones o concursos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información de
                                                    contacto a la fuerza
                                                    comercial y/o red de
                                                    distribución, telemercadeo,
                                                    investigación y cualquier
                                                    tercero con el cual la
                                                    fundación tenga un vínculo
                                                    contractual.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Dar a conocer, transferir
                                                    y/o trasmitir datos
                                                    personales dentro y fuera
                                                    del país a terceros como
                                                    consecuencia de un contrato,
                                                    ley o vínculo lícito que así
                                                    lo requiera, o para
                                                    implementar los{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                programas y/o proyectos de la
                                                fundación
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    .
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Utilización de datos
                                                    biométricos (datos
                                                    sensibles) como{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    huella digital, audio, video
                                                    y/o fotografía
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    o a través de cualquier otro
                                                    medio conocido o por
                                                    conocerse (se entiende como
                                                    “imagen” el nombre,
                                                    seudónimo, voz, firmas,
                                                    iniciales, figura,
                                                    fisionomía de cuerpo, cara o
                                                    cualquier signo que se
                                                    relacione con la identidad
                                                    de la persona), para
                                                    reproducción, comunicación o
                                                    transmisión en redes
                                                    sociales y medios de
                                                    comunicación como
                                                    televisión, periódicos,
                                                    pagina web y sistemas de
                                                    videovigilancia, para la
                                                    seguridad, eventos
                                                    institucionales, campañas
                                                    publicitarias, promociones y
                                                    concursos, entre otros.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    respecto del desempeño de
                                                    los beneficiarios en el
                                                    desarrollo de los programas
                                                    y/o proyectos de los que
                                                    forma parte; así como
                                                    establecer contacto para
                                                    cualquier requerimiento en
                                                    pro de la mejor ejecución
                                                    del programa y/o proyecto.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.7. Bases de datos de Asistentes a
                                        Eventos:{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas naturales vinculados a la fundación
                                    en calidad de Asistentes a eventos. El
                                    tratamiento tiene como finalidad cumplir con
                                    las disposiciones estipuladas por la
                                    fundación, para la ejecución de los
                                    programas, proyectos y demás actividades.
                                    Esta base de datos contiene datos
                                    personales, públicos, privados y sensibles,
                                    así como de niños, niñas y adolescentes
                                    previa aprobación de sus padres. El
                                    tratamiento de estos datos para fines
                                    diferentes al mantenimiento de la relación
                                    entre las partes o el cumplimiento de
                                    deberes de carácter legal, requiere de
                                    autorización previa del titular.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Realizar análisis de riesgo,
                                                    efectuar encuestas de
                                                    satisfacción respecto de la
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                s actividades en que ha
                                                participado
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    , estudios internos, evaluar
                                                    la calidad, cambios de los{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                programas, proyectos y demás
                                                actividades
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    a ofrecer por la fundación.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0.14in',
                        }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    comercial, publicitaria o
                                                    promocional sobre los
                                                    productos, servicios,{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                programas y/o proyectos
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    con el fin de impulsar,
                                                    invitar, dirigir, ejecutar,
                                                    informar y de manera
                                                    general, llevar a cabo
                                                    campañas, eventos,
                                                    promociones o concursos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información de
                                                    contacto a la fuerza
                                                    comercial y/o red de
                                                    distribución, telemercadeo,
                                                    investigación y cualquier
                                                    tercero con el cual la
                                                    fundación tenga un vínculo
                                                    contractual.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Dar a conocer, transferir
                                                    y/o trasmitir datos
                                                    personales dentro y fuera
                                                    del país a terceros como
                                                    consecuencia de un contrato,
                                                    ley o vínculo lícito que así
                                                    lo requiera, o para
                                                    implementar los{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                programas y/o proyectos de la
                                                fundación
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    .
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Utilización de datos
                                                    biométricos (datos
                                                    sensibles) como{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    huella digital, audio, video
                                                    y/o fotografía
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    o a través de cualquier otro
                                                    medio conocido o por
                                                    conocerse (se entiende como
                                                    “imagen” el nombre,
                                                    seudónimo, voz, firmas,
                                                    iniciales, figura,
                                                    fisionomía de cuerpo, cara o
                                                    cualquier signo que se
                                                    relacione con la identidad
                                                    de la persona), para
                                                    reproducción, comunicación o
                                                    transmisión en redes
                                                    sociales y medios de
                                                    comunicación como
                                                    televisión, periódicos,
                                                    pagina web y sistemas de
                                                    videovigilancia, para la
                                                    seguridad, eventos
                                                    institucionales, campañas
                                                    publicitarias, promociones y
                                                    concursos, entre otros.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    comercial, publicitaria o
                                                    promocional sobre los
                                                    productos, servicios,
                                                    programas y/o proyectos con
                                                    el fin de impulsar, invitar,
                                                    dirigir, ejecutar, informar
                                                    y de manera general, llevar
                                                    a cabo campañas, eventos,
                                                    promociones o concursos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.6.8. Bases de datos de Empresarios:{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Son las bases de datos manuales o
                                    automatizadas que contienen datos de las
                                    personas naturales o jurídicas que
                                    vinculados con{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    de forma contractual y comercial, con el
                                    propósito de prestar ayuda económica, sea en
                                    dinero o en especie, para la ejecución de
                                    los programas, proyectos y demás actividades
                                    de{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA.</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Esta base de datos contiene datos
                                    personales, públicos, privados y sensibles,
                                    los cuales tienen como finalidad el
                                    desarrollo de relaciones entre las partes;
                                    el tratamiento de estos datos para fines
                                    diferentes al mantenimiento dicha relación o
                                    el cumplimiento de deberes de carácter
                                    legal, requiere de autorización previa del
                                    titular.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    realizará el tratamiento de sus datos para
                                    los siguientes fines:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Vinculación del empresario o
                                                    la empresa que representa, a{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>IWOKA</b>
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    .
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.49in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    sobre las distintas formas
                                                    de participación o
                                                    colaboración a los
                                                    programas, proyectos y demás
                                                    actividades de
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>IWOKA,</b>
                                            </span>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                incluyendo formas de patrocinio
                                                y alianzas estratégicas.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.49in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Gestionar trámites de
                                                    solicitudes, quejas y
                                                    reclamos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.49in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Realizar análisis de riesgo,
                                                    efectuar encuestas de
                                                    satisfacción respecto de los{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                productos, servicios, proyectos
                                                y/o programas{' '}
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    de la fundación, estudios
                                                    internos, evaluar la
                                                    calidad, cambios en los
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                productos, servicios, proyectos
                                                y/o programas
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    , para la fundación y sus
                                                    aliados;
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0.14in',
                        }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información
                                                    comercial, publicitaria o
                                                    promocional sobre los
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                productos, servicios, proyectos
                                                y/o programas
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    , con el fin de impulsar,
                                                    invitar, dirigir, ejecutar,
                                                    informar y de manera
                                                    general, llevar a cabo
                                                    campañas, eventos,
                                                    promociones o concursos.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Suministrar información de
                                                    contacto a la fuerza
                                                    comercial y/o red de
                                                    distribución, telemercadeo,
                                                    investigación de mercados y
                                                    cualquier tercero con el
                                                    cual la fundación tenga un
                                                    vínculo contractual.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Dar a conocer, transferir
                                                    y/o trasmitir datos
                                                    personales dentro y fuera
                                                    del país a terceros como
                                                    consecuencia de un contrato,
                                                    ley o vínculo lícito que así
                                                    lo requiera, o para
                                                    implementar{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                los servicios, proyectos y/o
                                                programas
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    .
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.49in',
                            textIndent: '-0.2in',
                            marginBottom: '0.14in',
                        }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Realizar a través de
                                                    cualquier medio en forma
                                                    directa o a través de
                                                    terceros, programación y
                                                    prestación de servicio
                                                    técnico, venta, compra,
                                                    facturación, gestión de
                                                    cartera, seguimiento al
                                                    desempeño del producto,
                                                    recaudo, inteligencia de
                                                    negocios, actividades de
                                                    mercadeo, promoción o
                                                    publicidad, mejoramiento del
                                                    servicio, seguimiento al
                                                    recaudo, verificación,
                                                    consulta y control,
                                                    habilitación de medios de
                                                    pago así como cualquier otra
                                                    relacionada con nuestros{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                productos, servicios, proyectos
                                                y/o programas{' '}
                                            </span>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    actuales y futuros, para el
                                                    cumplimiento de las
                                                    obligaciones contractuales y
                                                    del objeto social de la
                                                    fundación.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        style={{ marginLeft: '0.5in', marginBottom: '0.14in' }}
                    >
                        <br />
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Consulta y reporte a
                                                    cualquier Central de Riesgos{' '}
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Información para controlar y
                                                    prevenir el fraude y LAFT en
                                                    cualquiera de sus
                                                    modalidades.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '100%',
                            marginLeft: '0.5in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '100%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Utilización de datos
                                                    biométricos (datos
                                                    sensibles) como{' '}
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    huella digital, audio, video
                                                    y/o fotografía
                                                </span>
                                            </font>
                                        </font>
                                        <font color="#000000">
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    o a través de cualquier otro
                                                    medio conocido o por
                                                    conocerse (se entiende como
                                                    “imagen” el nombre,
                                                    seudónimo, voz, firmas,
                                                    iniciales, figura,
                                                    fisionomía de cuerpo, cara o
                                                    cualquier signo que se
                                                    relacione con la identidad
                                                    de la persona), para
                                                    reproducción, comunicación o
                                                    transmisión en redes
                                                    sociales y medios de
                                                    comunicación como
                                                    televisión, periódicos,
                                                    pagina web y sistemas de
                                                    videovigilancia, para la
                                                    seguridad, eventos
                                                    institucionales, campañas
                                                    publicitarias, promociones y
                                                    concursos, entre otros.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    4.7 Contratos{' '}
                                </span>
                            </font>
                        </font>
                    </h2>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <br />
                    </h2>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span style={{ fontWeight: 'normal' }}>
                                        En los contratos laborales,{' '}
                                    </span>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">IWOKA</span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span style={{ fontWeight: 'normal' }}>
                                        ha incluido cláusulas con el fin de
                                        autorizar de manera previa y general el
                                        tratamiento de datos personales
                                        relacionados con la ejecución del
                                        contrato, lo que incluye la autorización
                                        de recolectar, modificar o corregir, en
                                        momentos futuros, datos personales del
                                        Titular correspondientes a personas
                                        naturales. También ha incluido la
                                        autorización para que algunos de los
                                        datos personales, en caso dado, puedan
                                        ser entregados o cedidos a terceros con
                                        los cuales la empresa tenga contratos de
                                        prestación de servicios, para la
                                        realización de tareas tercerizadas. En
                                        estas cláusulas, se hace mención del
                                        presente Manual y de su ubicación en el
                                        sitio web institucional, para su debida
                                        consulta.{' '}
                                    </span>
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    En los contratos de prestación de servicios
                                    externos, cuando el contratista requiera de
                                    datos personales, la fundación le
                                    suministrará dicha información siempre y
                                    cuando exista una autorización previa y
                                    expresa del Titular de los datos personales
                                    para esta transferencia, quedando excluida
                                    de esta autorización, los datos personales
                                    de naturaleza pública definido en el numeral
                                    2° del artículo 3° del Decreto Reglamentario
                                    1377 de 2013 y los contenidos en los
                                    registros públicos.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    En estos casos, los terceros son Encargados
                                    del tratamiento de datos y sus contratos
                                    incluirán cláusulas que precisan los fines y
                                    los tratamientos autorizados por{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    y delimitan de manera precisa el uso que
                                    estos terceros le pueden dar a aquellos, así
                                    como las obligaciones y deberes establecidos
                                    en la Ley 1581 de 2012 y el Decreto
                                    Reglamentario 1377 de 2013, incluyendo las
                                    medidas de seguridad necesarias que
                                    garanticen en todo momento la
                                    confidencialidad, integridad y
                                    disponibilidad de la información de carácter
                                    personal encargada para su tratamiento.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Por su parte,{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    al momento de recibir datos de terceros y
                                    actuar como Encargada del tratamiento de
                                    datos de carácter personal, verifica que la
                                    finalidad, o finalidades, de los
                                    tratamientos autorizados por el titular o
                                    permitidos por causas legales, contractuales
                                    o jurisprudenciales se encuentran vigentes y
                                    que el contenido de la finalidad esté
                                    relacionada con la causa por la cual se va a
                                    recibir dicha información personal de parte
                                    del tercero, pues solo de este modo estará
                                    facultado para recibir y tratar dichos datos
                                    personales.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    4.8 Transferencia y transmisión de datos
                                    personales.
                                </span>
                            </font>
                        </font>
                        <font size={2} style={{ fontSize: '11pt' }}>
                            <span lang="es-ES-u-co-trad">&nbsp;</span>
                        </font>
                    </h2>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <br />
                    </h2>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span style={{ fontWeight: 'normal' }}>
                                        Para la transmisión y transferencia de
                                        datos personales, se aplicarán las
                                        siguientes reglas:
                                    </span>
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '10pt' }}>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    Las transferencias de datos
                                                    personales deberán observar
                                                    lo previsto en el artículo
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad"></span>
                                            </font>
                                        </font>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    26 de la Ley 1581 de 2012.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.5in',
                            textIndent: '-0.25in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '10pt' }}>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    Las transmisiones de datos
                                                    personales que se efectúen
                                                    entre un responsable y un
                                                    encargado para permitir que
                                                    el encargado realice el
                                                    tratamiento por cuenta del
                                                    responsable, no requerirán
                                                    ser informadas al Titular ni
                                                    contar con su consentimiento
                                                    cuando exista un contrato en
                                                    los siguientes términos:
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    ></p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '10pt' }}>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    El contrato que suscriba el
                                                    Responsable con los
                                                    encargados para el
                                                    tratamiento de datos
                                                    personales bajo su control y
                                                    responsabilidad señalará los
                                                    alcances del tratamiento,
                                                    las actividades que el
                                                    encargado realizará por
                                                    cuenta del responsable para
                                                    el tratamiento de los datos
                                                    personales y las
                                                    obligaciones del Encargado
                                                    para con el titular y el
                                                    responsable.
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    &nbsp;
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.98in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '10pt' }}>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    Mediante dicho contrato el
                                                    encargado se comprometerá a
                                                    dar aplicación a las
                                                    obligaciones del responsable
                                                    bajo la política de
                                                    Tratamiento de la
                                                    información fijada por este
                                                    y a realizar el Tratamiento
                                                    de datos de acuerdo con la
                                                    finalidad que los Titulares
                                                    hayan autorizado y con las
                                                    leyes aplicables.
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    &nbsp;
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.48in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '10pt' }}>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    El contrato incluirá las
                                                    siguientes obligaciones en
                                                    cabeza del encargado:
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    &nbsp;
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '10pt' }}>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    Dar Tratamiento, a nombre
                                                    del Responsable, a los datos
                                                    personales conforme a los
                                                    principios que los tutelan.
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    &nbsp;
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.99in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '10pt' }}>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    Salvaguardar la seguridad de
                                                    las bases de datos en los
                                                    que se contengan datos
                                                    personales.
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    &nbsp;
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginLeft: '0.75in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <a name="_Toc369548917" />
                                <font>
                                    <font size={2} style={{ fontSize: '10pt' }}>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    Guardar confidencialidad
                                                    respecto del tratamiento de
                                                    los datos personales.
                                                </span>
                                            </font>
                                        </font>
                                        <font>
                                            <font
                                                size={2}
                                                style={{ fontSize: '11pt' }}
                                            >
                                                <span lang="es-ES-u-co-trad">
                                                    &nbsp;
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <br />
                    </h2>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0.08in', marginBottom: '0.08in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    4.9 Sistemas de Videovigilancia{' '}
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Los Sistemas de Videovigilancia (SV) o
                                    cámaras de seguridad implementadas con la
                                    finalidad de garantizar la seguridad de
                                    bienes o personas en un lugar determinado,
                                    son considerados como un medio idóneo para
                                    realizar el monitoreo y la observación de
                                    actividades en el ámbito institucional,
                                    laboral y público.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Esta tarea de monitoreo y observación
                                    realizadas a través de los SV, implican la
                                    recopilación de imágenes de personas, es
                                    decir, de datos personales de acuerdo con la
                                    definición contenida en el literal c) del
                                    articulo 3 de la Ley 1581 de 2012, “Por la
                                    cual se dictan disposiciones generales para
                                    la protección de datos personales”,
                                    entendido como “cualquier información
                                    vinculada o que pueda asociarse a una o
                                    varias personas naturales determinadas o
                                    determinables”.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    En consecuencia, en el manejo o Tratamiento
                                    de datos{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    observará los principios establecidos en la
                                    norma, esto es, legalidad, finalidad,
                                    libertad, calidad o veracidad, seguridad,
                                    confidencialidad, acceso y circulación
                                    restringida, y transparencia, así como las
                                    demás disposiciones contenidas en el Régimen
                                    General de Protección de Datos Personales.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    La presente política aplica para la toma de
                                    imágenes de personas por medio de cámaras,
                                    videocámaras, análogas o digitales, cámaras
                                    IP o mini-cámaras, circuitos cerrados de
                                    televisión (CCTV) y, en general, cualquier
                                    medio por el cual se realice el Tratamiento
                                    de imágenes de Titulares de datos
                                    personales, en especial con fines de
                                    vigilancia.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.9.1 Derechos Del Titular De Los Datos
                                        Personales
                                    </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    a. Acceso a las imágenes por parte de los
                                    Titulares de datos personales
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    b. Supresión de las imágenes
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        4.9.2 Tratamiento de Imágenes de Niños,
                                        Niñas y Adolescentes en relación al
                                        empleo de{' '}
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>Sistemas de Videovigilancia</b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Para el Tratamiento de imágenes de niños,
                                    niñas y adolescentes{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    respetará los derechos prevalentes de los
                                    mismos y solo realizará su recolección
                                    cuando (i) responda y respete su interés
                                    superior, y (ii) asegure el respeto de sus
                                    derechos fundamentales.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    En todos los casos, cuando la fundación
                                    utilice SV que involucren el Tratamiento de
                                    imágenes de niños, niñas y/o adolescentes
                                    observará las siguientes reglas:
                                </span>
                            </font>
                        </font>
                    </p>
                    <ul>
                        <ul>
                            <li>
                                <p
                                    align="justify"
                                    style={{ marginBottom: '0in' }}
                                >
                                    <font>
                                        <font
                                            size={2}
                                            style={{ fontSize: '11pt' }}
                                        >
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Contar con la autorización
                                                    de los padres o
                                                    representantes legales de
                                                    los menores y con la
                                                    aquiescencia de estos,
                                                    teniendo en cuenta su
                                                    madurez, autonomía y
                                                    capacidad para entender el
                                                    asunto.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p
                                    align="justify"
                                    style={{ marginBottom: '0in' }}
                                >
                                    <font>
                                        <font
                                            size={2}
                                            style={{ fontSize: '11pt' }}
                                        >
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Informar a los padres o
                                                    representantes legales
                                                    acerca de la finalidad y el
                                                    Tratamiento al cual serán
                                                    sometidos los datos
                                                    personales de los menores,
                                                    así como los derechos que
                                                    les asisten.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p
                                    align="justify"
                                    style={{ marginBottom: '0in' }}
                                >
                                    <font>
                                        <font
                                            size={2}
                                            style={{ fontSize: '11pt' }}
                                        >
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Limitar la recolección y
                                                    demás Tratamiento de las
                                                    imágenes, de acuerdo con lo
                                                    que resulte proporcional y
                                                    adecuado en consideración a
                                                    la finalidad previamente
                                                    informada.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p
                                    align="justify"
                                    style={{ marginBottom: '0in' }}
                                >
                                    <font>
                                        <font
                                            size={2}
                                            style={{ fontSize: '11pt' }}
                                        >
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Garantizar la seguridad y
                                                    reserva de los datos
                                                    personales de los menores.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </p>
                            </li>
                            <li>
                                <p
                                    align="justify"
                                    style={{ marginBottom: '0.19in' }}
                                >
                                    <font>
                                        <font
                                            size={2}
                                            style={{ fontSize: '11pt' }}
                                        >
                                            <font>
                                                <span lang="es-ES-u-co-trad">
                                                    Restringir el acceso y la
                                                    circulación de las imágenes,
                                                    conforme a lo establecido en
                                                    la ley.
                                                </span>
                                            </font>
                                        </font>
                                    </font>
                                </p>
                            </li>
                        </ul>
                    </ul>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El padre y/o representante legal del niño,
                                    niña o adolescente solo podrá acceder a las
                                    imágenes de este. Así, en caso de que se
                                    pretenda dar acceso o circular imágenes de
                                    clases y/o actividades donde aparezcan otros
                                    niños, niñas o adolescentes, se solicitará
                                    la autorización de los padres y/o
                                    representantes legales de todos ellos.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0.19in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Siempre que la implementación de SV
                                    involucre también el Tratamiento de datos
                                    personales de otros Titulares, como
                                    directivos, personal administrativo, padres
                                    de familia, etc., la empresa respetará los
                                    derechos de aquellos y cumplirá las
                                    obligaciones que dicha calidad les impone.
                                </span>
                            </font>
                        </font>
                    </p>
                    <h1
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    5. DEBERES Y DERECHOS
                                </span>
                            </font>
                        </font>
                    </h1>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548909" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    5.1. Generalidades sobre la autorización{' '}
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    solicitará previamente la autorización para
                                    el tratamiento de datos personales por
                                    cualquier medio que permita ser utilizado
                                    como prueba. Según el caso, dicha
                                    autorización podrá ser incorporada en un
                                    documento como por ejemplo un contrato,
                                    formato, formulario, otrosí, factura, etc.
                                    La autorización contendrá como mínimo:{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ol type="a">
                        <li>
                            <h2
                                lang="es-ES"
                                className="western"
                                style={{ marginTop: '0in' }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            <span
                                                style={{ fontWeight: 'normal' }}
                                            >
                                                El Tratamiento al cual serán
                                                sometidos los datos personales y
                                                la finalidad del mismo;
                                            </span>
                                        </span>
                                    </font>
                                </font>
                            </h2>
                        </li>
                    </ol>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{
                            fontWeight: 'normal',
                            marginLeft: '0.5in',
                            marginTop: '0in',
                        }}
                    >
                        <br />
                    </h2>
                    <ol type="a" start={2}>
                        <li>
                            <h2
                                lang="es-ES"
                                className="western"
                                style={{ marginTop: '0in' }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            <span
                                                style={{ fontWeight: 'normal' }}
                                            >
                                                El carácter facultativo de la
                                                respuesta a las preguntas que le
                                                sean hechas, cuando estas versen
                                                sobre datos sensibles o sobre
                                                los datos de las niñas, niños y
                                                adolescentes;
                                            </span>
                                        </span>
                                    </font>
                                </font>
                            </h2>
                        </li>
                    </ol>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{
                            fontWeight: 'normal',
                            marginLeft: '0.5in',
                            marginTop: '0in',
                        }}
                    >
                        <br />
                    </h2>
                    <ol type="a" start={3}>
                        <li>
                            <h2
                                lang="es-ES"
                                className="western"
                                style={{ marginTop: '0in' }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            <span
                                                style={{ fontWeight: 'normal' }}
                                            >
                                                Los derechos que le asisten al
                                                Titular de la información;
                                            </span>
                                        </span>
                                    </font>
                                </font>
                            </h2>
                        </li>
                    </ol>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{
                            fontWeight: 'normal',
                            marginLeft: '0.5in',
                            marginTop: '0in',
                        }}
                    >
                        <br />
                    </h2>
                    <ol type="a" start={4}>
                        <li>
                            <h2
                                lang="es-ES"
                                className="western"
                                style={{ marginTop: '0in' }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            <span
                                                style={{ fontWeight: 'normal' }}
                                            >
                                                La identificación, dirección
                                                física o electrónica y teléfono
                                                del Responsable del Tratamiento.
                                            </span>
                                        </span>
                                    </font>
                                </font>
                            </h2>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548910" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    5.2. Del derecho de acceso{' '}
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    garantiza a los Titulares de datos
                                    personales el derecho de acceso conforme a
                                    la Ley 1581 de 2012, previa acreditación de
                                    la identidad del titular, legitimidad, o
                                    personalidad de su representante, poniendo a
                                    disposición de éste, sin costo o erogación
                                    alguna, de manera pormenorizada y detallada,
                                    los respectivos datos personales tratados, a
                                    través de cualquier medio de comunicación,
                                    incluyendo los electrónicos que permitan el
                                    acceso directo del titular. El acceso, se
                                    sujeta a los límites establecidos en el
                                    artículo 21 del Decreto Reglamentario 1377
                                    de 2013.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548911" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    5.3. Del derecho de consulta{' '}
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"> </span>
                            </font>
                        </font>
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    garantiza el derecho de consulta conforme a
                                    lo dispuesto en la Ley 1581 de 2012
                                    exclusivamente sobre los datos personales
                                    privados, sensibles y de menores de edad
                                    correspondientes a personas naturales,
                                    suministrando a los Titulares de estos datos
                                    personales la información contenida en cada
                                    una de las bases de datos correspondientes y
                                    que estén bajo el control de la fundación,
                                    la cual establecerá las medidas de
                                    autenticación que permiten identificar de
                                    manera segura al titular de los datos
                                    personales que realiza la consulta o
                                    petición.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Con respecto a la atención de solicitudes de
                                    consulta{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">garantiza:</span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Habilitar medios de comunicación
                                                electrónica u otros que
                                                considere pertinentes y seguros;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Establecer formularios, sistemas
                                                y otros métodos que se
                                                informarán en la autorización o
                                                en el Aviso de Privacidad;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Utilizar los servicios de
                                                atención al titular o de
                                                reclamaciones que se encuentren
                                                en operación.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Independientemente del mecanismo
                                    implementado para la atención de solicitudes
                                    de consulta, éstas serán tramitadas en un
                                    término máximo de diez (10) días hábiles
                                    contados a partir de la fecha de su recibo.
                                    En el evento en el que una solicitud de
                                    consulta no pueda ser atendida dentro del
                                    término antes señalado, se informará al
                                    interesado antes del vencimiento del plazo
                                    las razones por las cuales no se ha dado
                                    respuesta a su consulta, la cual en ningún
                                    caso podrá superar los cinco (5) días
                                    hábiles siguientes al vencimiento del primer
                                    término.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <h2
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <br />
                    </h2>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548912" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    5.4. Del derecho a reclamar{' '}
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El Titular que considere que los datos
                                    personales pueden ser objeto de corrección,
                                    actualización o supresión, o cuando
                                    adviertan el presunto incumplimiento de
                                    cualquiera de los deberes y principios
                                    contenidos en la normatividad sobre
                                    Protección de Datos Personales, podrán
                                    presentar reclamación ante{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">.</span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El reclamo lo podrá presentar el titular,
                                    teniendo en cuenta la información señalada
                                    en el artículo 15 de la Ley 1581 de 2012,
                                    así:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ol>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                El reclamo se formulará mediante
                                                solicitud dirigida al
                                                Responsable del Tratamiento o al
                                                Encargado del Tratamiento, con
                                                la identificación del Titular,
                                                la descripción de los hechos que
                                                dan lugar al reclamo, la
                                                dirección, y acompañando los
                                                documentos que se quiera hacer
                                                valer. Si el reclamo resulta
                                                incompleto, se requerirá al
                                                interesado dentro de los cinco
                                                (5) días siguientes a la
                                                recepción del reclamo para que
                                                subsane las fallas.
                                                Transcurridos dos (2) meses
                                                desde la fecha del
                                                requerimiento, sin que el
                                                solicitante presente la
                                                información requerida, se
                                                entenderá que ha desistido del
                                                reclamo.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        align="justify"
                        style={{ marginLeft: '0.25in', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <font>
                                    <span lang="es-ES-u-co-trad">
                                        En caso de que quien reciba el reclamo
                                        no sea competente para resolverlo, dará
                                        traslado a quien corresponda en un
                                        término máximo de dos (2) días hábiles e
                                        informará de la situación al interesado.
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ol start={2}>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Una vez recibido el reclamo
                                                completo, se incluirá en la base
                                                de datos una leyenda que diga
                                                "reclamo en trámite" y el motivo
                                                del mismo, en un término no
                                                mayor a dos (2) días hábiles.
                                                Dicha leyenda deberá mantenerse
                                                hasta que el reclamo sea
                                                decidido.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ol start={3}>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                El término máximo para atender
                                                el reclamo será de quince (15)
                                                días hábiles contados a partir
                                                del día siguiente a la fecha de
                                                su recibo. Cuando no fuere
                                                posible atender el reclamo
                                                dentro de dicho término, se
                                                informará al interesado los
                                                motivos de la demora y la fecha
                                                en que se atenderá su reclamo,
                                                la cual en ningún caso podrá
                                                superar los ocho (8) días
                                                hábiles siguientes al
                                                vencimiento del primer término.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548913" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    5.5. Del derecho a la rectificación y
                                    actualización de datos
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    se obliga a rectificar y actualizar a
                                    solicitud del Titular, la información de
                                    carácter personal que corresponda a personas
                                    naturales, que resulte incompleta o
                                    inexacta, de conformidad con el
                                    procedimiento y lo términos antes señalados.
                                    La fundación tendrá en cuenta lo siguiente:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                En las solicitudes de
                                                rectificación y actualización de
                                                datos personales, el Titular
                                                debe indicar las correcciones a
                                                realizar y aportar la
                                                documentación que avale su
                                                petición.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>IWOKA</b>
                                            </span>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                tiene plena libertad de
                                                habilitar mecanismos que le
                                                faciliten el ejercicio de este
                                                derecho, siempre y cuando
                                                beneficien al Titular de los
                                                datos personales. En
                                                consecuencia, se podrán
                                                habilitar medios electrónicos u
                                                otros que la fundación considere
                                                pertinentes y seguros.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>IWOKA</b>
                                            </span>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                podrá establecer formularios,
                                                formatos, sistemas y otros
                                                métodos, que serán informados en
                                                la presente política y/o
                                                autorización y/o Aviso de
                                                Privacidad y que se pondrán a
                                                disposición de los interesados
                                                en la página web u oficinas de
                                                la fundación.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548914" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    5.6. Del derecho a la supresión de datos.
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El Titular de datos personales, tiene el
                                    derecho en todo momento de solicitar la
                                    supresión (eliminación) de sus datos
                                    personales. La fundación tendrá en cuenta
                                    los siguientes supuestos:{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Que los mismos no están siendo
                                                tratados conforme a los
                                                principios, deberes y
                                                obligaciones previstas en la
                                                normatividad vigente sobre
                                                Protección de Datos Personales.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Que hayan dejado de ser
                                                necesarios o pertinentes para la
                                                finalidad para la cual fueron
                                                recabados.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Que se haya superado el periodo
                                                necesario para el cumplimiento
                                                de los fines para los que fueron
                                                recogidos.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Esta supresión implica la eliminación o
                                    borrado seguro, total o parcial, de la
                                    información personal de acuerdo con lo
                                    solicitado por el titular en los registros,
                                    archivos, bases de datos o tratamientos
                                    realizados por la fundación.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El derecho de supresión no es un derecho
                                    absoluto, y la fundación como responsable
                                    del tratamiento de datos personales, puede
                                    negar o limitar el ejercicio del mismo
                                    cuando:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                El titular de los datos tenga el
                                                deber legal o contractual de
                                                permanecer en la base de datos.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                La eliminación de datos
                                                obstaculice actuaciones
                                                judiciales o administrativas
                                                vinculadas a obligaciones
                                                fiscales, la investigación y
                                                persecución de delitos o la
                                                actualización de sanciones
                                                administrativas.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Los datos sean necesarios para
                                                proteger los intereses
                                                jurídicamente tutelados del
                                                titular; para realizar una
                                                acción en función del interés
                                                público, o para cumplir con una
                                                obligación legalmente adquirida
                                                por el titular.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h2
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548915" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    5.7. Del derecho a revocar la autorización{' '}
                                </span>
                            </font>
                        </font>
                    </h2>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    Todo titular de datos personales que
                                    correspondan a personas naturales, puede
                                    revocar en cualquier momento, el
                                    consentimiento al tratamiento de éstos,
                                    siempre y cuando, no lo impida una
                                    disposición legal o contractual. Para ello,{' '}
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    ha establecido mecanismos sencillos y
                                    gratuitos que le permiten al titular revocar
                                    su consentimiento.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    En los casos que sea posible la revocatoria
                                    de la autorización, se atenderá bajo las
                                    siguientes dos modalidades:{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>Total:</b>
                                            </span>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Sobre la totalidad de
                                                finalidades consentidas, esto
                                                es, que la empresa debe dejar de
                                                tratar por completo los datos
                                                del Titular de datos personales.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <b>Parcial:</b>
                                            </span>
                                        </font>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Sobre ciertas finalidades
                                                consentidas caso en el cual la
                                                fundación deberá suspender
                                                parcialmente el tratamiento de
                                                los datos del titular. Se
                                                mantienen entonces otros fines
                                                del tratamiento que el
                                                Responsable, de conformidad con
                                                la autorización otorgada, puede
                                                llevar a cabo y con los que el
                                                titular está de acuerdo.{' '}
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El derecho de revocatoria no es un derecho
                                    absoluto y la fundación como responsable del
                                    tratamiento de datos personales, puede negar
                                    o limitar el ejercicio del mismo cuando:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                El titular de los datos tenga el
                                                deber legal o contractual de
                                                permanecer en la base de datos.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{ marginLeft: '0.5in', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                La revocatoria de la
                                                autorización del tratamiento
                                                obstaculice actuaciones
                                                judiciales o administrativas
                                                vinculadas a obligaciones
                                                fiscales, la investigación y
                                                persecución de delitos o la
                                                actualización de sanciones
                                                administrativas.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.5in',
                            textIndent: '-0.2in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Los datos sean necesarios para
                                                proteger los intereses
                                                jurídicamente tutelados del
                                                titular; para realizar una
                                                acción en función del interés
                                                público, o para cumplir con una
                                                obligación legalmente adquirida
                                                por el titular.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.5in',
                            textIndent: '-0.2in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p align="justify" style={{ marginBottom: '0in' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Los datos sean datos de
                                                naturaleza pública y
                                                correspondan a los registros
                                                públicos, los cuales tienen como
                                                finalidad su publicidad.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.5in',
                            textIndent: '-0.2in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>5.8</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        <span>Derecho a </span>
                                    </b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        <span>
                                            presentar quejas ante la autoridad
                                            competente{' '}
                                        </span>
                                    </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El Titular de datos personales, tiene el
                                    derecho en todo momento a presentar ante la
                                    Superintendencia de Industria y Comercio
                                    quejas por infracciones a la ley y las demás
                                    normas que la modifiquen, adicionen o
                                    complementen.{' '}
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>
                                        5.9 Deberes como Responsable del
                                        Tratamiento.
                                    </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    cumplirá con los siguientes deberes:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a">
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Garantizar al Titular, en todo
                                                tiempo, el pleno y efectivo
                                                ejercicio del derecho de hábeas
                                                data;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.5in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={2}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Solicitar y conservar copia de
                                                la respectiva autorización
                                                otorgada por el Titular;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={3}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Informar debidamente al Titular
                                                sobre la finalidad de la
                                                recolección y los derechos que
                                                le asisten por virtud de la
                                                autorización otorgada;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={4}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Conservar la información bajo
                                                las condiciones de seguridad
                                                necesarias para impedir su
                                                adulteración, pérdida, consulta,
                                                uso o acceso no autorizado o
                                                fraudulento;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={5}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Garantizar que la información
                                                que se suministre al Encargado
                                                del Tratamiento sea veraz,
                                                completa, exacta, actualizada,
                                                comprobable y comprensible;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={6}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Actualizar la información,
                                                comunicando de forma oportuna al
                                                Encargado del Tratamiento, todas
                                                las novedades respecto de los
                                                datos que previamente le haya
                                                suministrado y adoptar las demás
                                                medidas necesarias para que la
                                                información suministrada a este
                                                se mantenga actualizada;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={7}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Rectificar la información cuando
                                                sea incorrecta y comunicar lo
                                                pertinente al Encargado del
                                                Tratamiento;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={8}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Suministrar al Encargado del
                                                Tratamiento, según el caso,
                                                únicamente datos cuyo
                                                Tratamiento esté previamente
                                                autorizado;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={9}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Exigir al Encargado del
                                                Tratamiento en todo momento, el
                                                respeto a las condiciones de
                                                seguridad y privacidad de la
                                                información del Titular;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={10}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Tramitar las consultas y
                                                reclamos;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={11}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Adoptar un manual interno de
                                                procedimientos para garantizar
                                                el adecuado cumplimiento para la
                                                atención de consultas y
                                                reclamos;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={12}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Informar al Encargado del
                                                Tratamiento cuando determinada
                                                información se encuentra en
                                                discusión por parte del Titular,
                                                una vez se haya presentado la
                                                reclamación y no haya finalizado
                                                el trámite respectivo;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={13}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Informar a solicitud del Titular
                                                sobre el uso dado a sus datos;
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={14}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Informar a la autoridad de
                                                protección de datos cuando se
                                                presenten violaciones a los
                                                códigos de seguridad y existan
                                                riesgos en la administración de
                                                la información de los Titulares.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={15}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                Cumplir las instrucciones y
                                                requerimientos que imparta la
                                                Superintendencia de Industria y
                                                Comercio.
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '10pt' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            <b>5.10</b>
                                        </span>
                                    </font>
                                </font>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            <b>
                                                <span style={{}}></span>
                                            </b>
                                        </span>
                                    </font>
                                </font>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            <b>
                                                <span style={{}}>
                                                    Deberes Encargado del
                                                    Tratamiento
                                                </span>
                                            </b>
                                        </span>
                                    </font>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '10pt' }}>
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            <span>
                                                Los Encargados del Tratamiento
                                                deberán cumplir los siguientes
                                                deberes:
                                            </span>
                                        </span>
                                    </font>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a">
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Garantizar al Titular, en
                                                    todo tiempo, el pleno y
                                                    efectivo ejercicio del
                                                    derecho de hábeas data;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        align="justify"
                        style={{
                            marginLeft: '0.5in',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={2}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Conservar la información
                                                    bajo las condiciones de
                                                    seguridad necesarias para
                                                    impedir su adulteración,
                                                    pérdida, consulta, uso o
                                                    acceso no autorizado o
                                                    fraudulento;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={3}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Realizar oportunamente la
                                                    actualización, rectificación
                                                    o supresión de los datos{' '}
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={4}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Actualizar la información
                                                    reportada por los
                                                    Responsables del Tratamiento
                                                    dentro de los cinco (5) días
                                                    hábiles contados a partir de
                                                    su recibo;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={5}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Tramitar las consultas y los
                                                    reclamos;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={6}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Adoptar un manual interno de
                                                    procedimientos para la
                                                    atención de consultas y
                                                    reclamos por parte de los
                                                    Titulares;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={7}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Registrar en la base de
                                                    datos la leyenda "reclamo en
                                                    trámite"{' '}
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={8}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Insertar en la base de datos
                                                    la leyenda "información en
                                                    discusión judicial" una vez
                                                    notificado por parte de la
                                                    autoridad competente sobre
                                                    procesos judiciales
                                                    relacionados con la calidad
                                                    del dato personal;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={9}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Abstenerse de circular
                                                    información que esté siendo
                                                    controvertida por el Titular
                                                    y cuyo bloqueo haya sido
                                                    ordenado por la
                                                    Superintendencia de
                                                    Industria y Comercio;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={10}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Permitir el acceso a la
                                                    información únicamente a las
                                                    personas que pueden tener
                                                    acceso a ella;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={11}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Informar a la
                                                    Superintendencia de
                                                    Industria y Comercio cuando
                                                    se presenten violaciones a
                                                    los códigos de seguridad y
                                                    existan riesgos en la
                                                    administración de la
                                                    información de los
                                                    Titulares;
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <ol type="a" start={12}>
                        <li>
                            <p
                                align="justify"
                                style={{
                                    marginBottom: '0in',
                                }}
                            >
                                <a name="_Toc369548926" />
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <font>
                                            <span lang="es-ES-u-co-trad">
                                                <span style={{}}>
                                                    Cumplir las instrucciones y
                                                    requerimientos que imparta
                                                    la Superintendencia de
                                                    Industria y Comercio.
                                                </span>
                                            </span>
                                        </font>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ol>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                            borderTop: 'none',
                            borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            paddingTop: '0in',
                            paddingBottom: '0.01in',
                            paddingLeft: '0in',
                            paddingRight: '0in',
                        }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{
                            lineHeight: '115%',
                            marginBottom: '0in',
                            borderTop: 'none',
                            borderBottom: '1.50pt solid #000000',
                            borderLeft: 'none',
                            borderRight: 'none',
                            paddingTop: '0in',
                            paddingBottom: '0.01in',
                            paddingLeft: '0in',
                            paddingRight: '0in',
                        }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>6.</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>MODIFICACIONES A LAS POLÍTICAS </b>
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    se reserva el derecho de modificar la
                                    política de Protección de Datos Personales
                                    en cualquier momento. Toda modificación se
                                    comunicará de forma oportuna a los Titulares
                                    de los datos a través de los medios
                                    habituales de contacto y/o a través de su{' '}
                                </span>
                            </font>
                        </font>
                        <font color="#000000">
                            <font>
                                <font size={2} style={{ fontSize: '11pt' }}>
                                    <span lang="es-ES-u-co-trad">
                                        página web
                                        https://www.fundacioniwoka.org
                                    </span>
                                </font>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span>
                                        En caso de haber cambios sustanciales en
                                        el contenido de las políticas del
                                        Tratamiento, referidos a la
                                        identificación del Responsable y a la
                                        finalidad del Tratamiento de los datos
                                        personales, los cuales afecten el
                                        contenido de la autorización, el
                                        Responsable del Tratamiento comunicará
                                        estos cambios y obtendrá del Titular una
                                        nueva autorización cuando el cambio se
                                        refiera a la finalidad del Tratamiento.
                                    </span>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <span>&nbsp;</span>
                                </span>
                            </font>
                        </font>
                    </p>
                    <h1
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <br />
                    </h1>
                    <h1
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    7. EL REGISTRO NACIONAL DE BASES DE DATOS{' '}
                                </span>
                            </font>
                        </font>
                    </h1>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '100%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    <b>IWOKA</b>
                                </span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad"></span>
                            </font>
                        </font>
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    como responsable del tratamiento de datos
                                    personales y según lo establecido por la
                                    Ley, no se encuentra obligada a efectuar el
                                    registro de sus bases de datos en el RNBD.
                                    No obstante, lo anterior, en caso de cumplir
                                    con los requisitos exigidos se dará
                                    aplicación a lo dispuesto en el Decreto 1074
                                    de 2015 y demás normas que lo modifiquen,
                                    deroguen o sustituyan.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h1
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548928" />
                        <a name="_Toc369548929" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    8. REFERENCIA A OTROS DOCUMENTOS
                                </span>
                            </font>
                        </font>
                    </h1>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    La presente política de protección de datos
                                    personales ha sido elaborada en concordancia
                                    con las siguientes normas:
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <ul>
                        <li>
                            <p
                                className="western"
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            Constitución Política de Colombia
                                        </span>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p
                                className="western"
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            Ley 1581 de 2012{' '}
                                        </span>
                                    </font>
                                </font>
                            </p>
                        </li>
                        <li>
                            <p
                                className="western"
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            Decreto 1377 de 2013{' '}
                                        </span>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <p
                                className="western"
                                align="justify"
                                style={{
                                    lineHeight: '115%',
                                    marginBottom: '0in',
                                }}
                            >
                                <font>
                                    <font size={2} style={{ fontSize: '11pt' }}>
                                        <span lang="es-ES-u-co-trad">
                                            Decreto Único 1074 de 2015
                                        </span>
                                    </font>
                                </font>
                            </p>
                        </li>
                    </ul>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <h1
                        lang="es-ES"
                        className="western"
                        align="justify"
                        style={{ marginTop: '0in' }}
                    >
                        <a name="_Toc369548927" />
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">9. VIGENCIA </span>
                            </font>
                        </font>
                    </h1>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <font>
                            <font size={2} style={{ fontSize: '11pt' }}>
                                <span lang="es-ES-u-co-trad">
                                    El término de vigencia de la presente
                                    política es igual al establecido para la
                                    duración de la fundación en los estatutos.
                                </span>
                            </font>
                        </font>
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        lang="es-ES-u-co-trad"
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                    <p
                        className="western"
                        align="justify"
                        style={{ lineHeight: '115%', marginBottom: '0in' }}
                    >
                        <br />
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DataPolicy;
