import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    Image,
} from '@react-pdf/renderer';

import LogoIwoka from '../../../../../../../assets/img/iwoka-logo-gray.png';
import Klogo from '../../../../../../../assets/img/iwoka-logo-print-k.png';
import FbLogo from '../../../../../../../assets/img/logo-facebook.png';
import IgLogo from '../../../../../../../assets/img/instagram-logo.png';
import MobileLogo from '../../../../../../../assets/img/mobile-logo.png';


const PrintFormat = ({ data = [] }) => {
    return (
        <React.Fragment>
            <Document>
                <Page size="A4">
                    <View style={{ position: 'relative' }}>
                        <View
                            style={{
                                position: 'absolute',
                                right: '46',
                                top: '43',
                            }}
                        >
                            <Image
                                src={LogoIwoka}
                                alt="random image"
                                style={{
                                    maxWidth: '144px',
                                    maxHeight: '52px',
                                }}
                            />
                        </View>
                        <View
                            style={{
                                position: 'relative',
                                left: '0',
                                top: '130',
                                margin: '0 46px',
                                backgroundColor: '#F8F9F8',
                                borderRadius: '30',
                                minHeight: '620',
                                fontSize: '11px',
                                fontWeight: '600',
                            }}
                        >
                            <View
                                style={{
                                    position: 'absolute',
                                    right: '-46',
                                    top: '70',
                                }}
                            >
                                <Image
                                    src={Klogo}
                                    alt="random image"
                                    style={{
                                        maxWidth: '247px',
                                        maxHeight: '642px',
                                    }}
                                />
                            </View>
                            <View
                                style={{
                                    backgroundColor: '#ECEDED',
                                    minHeight: '40',
                                    borderTopLeftRadius: '30px',
                                    borderTopRightRadius: '30px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: '0 15px',
                                    position: 'relative',
                                }}
                            >
                                <View
                                    style={{
                                        backgroundColor: '#DEE1E1',
                                        width: '1',
                                        height: '620',
                                        position: 'absolute',
                                        top: '0',
                                        left: '195',
                                    }}
                                ></View>
                                <View
                                    style={{
                                        backgroundColor: '#DEE1E1',
                                        width: '1',
                                        height: '620',
                                        position: 'absolute',
                                        top: '0',
                                        left: '253',
                                    }}
                                ></View>
                                <View
                                    style={{
                                        backgroundColor: '#DEE1E1',
                                        width: '1',
                                        height: '620',
                                        position: 'absolute',
                                        top: '0',
                                        left: '311',
                                    }}
                                ></View>
                                <View
                                    style={{
                                        backgroundColor: '#DEE1E1',
                                        width: '1',
                                        height: '620',
                                        position: 'absolute',
                                        top: '0',
                                        left: '371',
                                    }}
                                ></View>
                                <View
                                    style={{
                                        backgroundColor: '#DEE1E1',
                                        width: '1',
                                        height: '620',
                                        position: 'absolute',
                                        top: '0',
                                        left: '429',
                                    }}
                                ></View>
                                {/* Titles */}
                                <View
                                    style={{
                                        flex: 3.5,
                                        padding: '5',
                                        color: '#375784',
                                    }}
                                >
                                    <Text>Artículo</Text>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        padding: '5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#375784',
                                    }}
                                >
                                    <Text>Código</Text>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        padding: '5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#375784',
                                    }}
                                >
                                    <Text>Precio</Text>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        padding: '5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#375784',
                                    }}
                                >
                                    <Text>Stock</Text>
                                    <Text>actual</Text>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        padding: '5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#375784',
                                    }}
                                >
                                    <Text>Cantidad necesaria</Text>
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        padding: '5',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#375784',
                                    }}
                                >
                                    <Text>Faltantes</Text>
                                </View>
                            </View>
                            {data.map((product, index) => (
                                <View
                                    style={{
                                        minHeight: '30',
                                        borderBottom: '1.5px solid #ABAFB2',
                                        margin: '0 15px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                    key={index}
                                >
                                    <View
                                        style={{
                                            flex: 3.5,
                                            padding: '5',
                                            color: '#ef5543',
                                        }}
                                    >
                                        <Text>{product.product}</Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            padding: '5',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#5B5F63',
                                        }}
                                    >
                                        <Text>{product.product_code}</Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            padding: '5',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#5B5F63',
                                        }}
                                    >
                                        <Text>${product.cost}</Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            padding: '5',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#5B5F63',
                                        }}
                                    >
                                        <Text>{product.stock}</Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            padding: '5',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#5B5F63',
                                        }}
                                    >
                                        <Text>{product.total_quantity}</Text>
                                    </View>
                                    <View
                                        style={{
                                            flex: 1,
                                            padding: '5',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#ef5543',
                                        }}
                                    >
                                        <Text>
                                            {product.total_quantity -
                                                product.stock}
                                        </Text>
                                    </View>
                                </View>
                            ))}
                            {/* <Text>Section #2</Text> */}
                        </View>
                        {/* Footer */}
                        <View
                            style={{
                                position: 'absolute',
                                top: '800',
                                left: '0',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingRight: '46',
                                paddingLeft: '46',
                                fontSize: '12px',
                                color: '#375784',
                            }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>fundacioniwoka.org</Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    src={FbLogo}
                                    alt="random image"
                                    style={{
                                        maxWidth: '12.2px',
                                        maxHeight: '12.3px',
                                        marginRight: '4',
                                    }}
                                />
                                <Text>programaiwoka</Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    src={IgLogo}
                                    alt="random image"
                                    style={{
                                        maxWidth: '12.2px',
                                        maxHeight: '12.2px',
                                        marginRight: '4',
                                    }}
                                />
                                <Text>Programa iwoka</Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Image
                                    src={MobileLogo}
                                    alt="random image"
                                    style={{
                                        maxWidth: '8',
                                        maxHeight: '13px',
                                        marginRight: '4',
                                    }}
                                />
                                <Text>316 460 1084</Text>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </React.Fragment>
    );
};

export default PrintFormat;
