import React from "react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const Subtable = (row) => {
    return (
        <div className="border border-primary p-2 mt-2 mb-3 bg-blanco">
            <div>
                <h2 className="table-title-group mt-4 mt-md-0">
                    Lista de Acudientes
                </h2>
            </div>
            {!row.legal_guardians || row.legal_guardians.length === 0 ? (<p>No hay datos de acudientes assignados.</p>
            ) : (
                <BootstrapTable data={row.legal_guardians} remote={false}>
                    <TableHeaderColumn
                        width="20%"
                        dataField="id"
                        isKey
                    >
                        ID
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="20%"
                        dataField="first_name"
                    >
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="20%"
                        dataField="last_name"
                    >
                        Apellido
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="20%"
                        dataField="telephone"
                    >
                        Telefono
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="20%"
                        dataField="kinship"
                    >
                        Parentesco / relación
                    </TableHeaderColumn>
                </BootstrapTable>
            )}


        </div>
    );
};

export default Subtable;