import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';
import LoaderContext from '../context/LoaderContext';
import UserContext from '../context/UserContext';
import useUpdateEvents from './useUpdateEvents';
import useIsOffline from './useIsOffline';

export default function useAccount() {
    const { loader, setLoader } = useContext(LoaderContext);
    const { user, setUser } = useContext(UserContext);
    const { updateEvents } = useUpdateEvents();
    const { isOffline } = useIsOffline();
    const navigate = useNavigate();

    const login = (data = {}) => {
        setLoader(true);
        api.post('user/login', data)
            .then((response) => {
                navigate('/');
                setUser(response);
                isOffline(response);
            })
            .catch((response) => {
                let msj = 'Credenciales incorrectas, vuelva a intentar';
                if (response && response.detail) msj = response.detail;
                NotificationManager.error(msj, 'ERROR', 6000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const getMe = () => {
        return api
            .get('/user/me')
            .then((me) => {
                setUser(me);
                updateEvents();
                isOffline(me);
            })
            .catch(() => {})
            .finally(() => {});
    };

    const logOut = () => {
        setLoader(true);
        api.post('/user/logout')
            .then(() => {
                setUser({});
                navigate('/login');
            })
            .catch(() => {})
            .finally(() => {
                setLoader(false);
            });
    };

    const register = (data, onSuccess = undefined) => {
        setLoader(true);
        return api
            .post('user', data)
            .then(() => {
                if (onSuccess) onSuccess().then();
            })
            .catch((response) => {
                let msj = 'No se pudo realizar el registro';
                if (response && response.detail) msj = response.detail;
                NotificationManager.error(msj, 'ERROR', 6000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const pwdRecovery = (data) => {
        setLoader(true);
        return api
            .post('user/password_recovery', data)
            .then(() => {
                NotificationManager.success(
                    'Te enviamos un enlace para restablecer tu contraseña. Este sólo es válido durante 6 horas.',
                    'ÉXITO',
                    6000
                );
                navigate('/login');
            })
            .catch((response) => {
                let msj = 'Intente de nuevamente.';
                if (response && response.detail) msj = response.detail;
                NotificationManager.error(msj, 'ERROR', 6000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return { user, loader, login, getMe, logOut, register, pwdRecovery };
}
