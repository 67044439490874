import React from 'react';
import { Form, Field } from 'react-final-form';
import { required } from '../../../../../utility/validation';
import useCreate from '../../../../hooks/useCreate';
import { renderAsyncSelectField, renderSelectField } from '../../../Utils/renderField/renderField';

const ModalReuseHolidays = (props) => {
    const { setOpen, asyncOptions, setLoadTable, setReload, reload } = props;

    const { saveData: saveHolidays } = useCreate('holiday/reuse_holidays', '');

    const onSubmit = (formatData) => {
        saveHolidays(formatData);
        setReload(!reload)
        setOpen(false);
        setLoadTable(true)
    };

    var currentTime = new Date();
    var year1 = currentTime.getFullYear()

    let years = []

    for (let x = 0; x < 4; x++) {
        years.push(year1 + x)
    }

    const YEARS_OPTIONS = [
        { id: 1, year: years[0] },
        { id: 2, year: years[1] },
        { id: 3, year: years[2] },
        { id: 4, year: years[3] },
    ];


    return (
        <React.Fragment>
            <Form onSubmit={onSubmit}>
                {({ handleSubmit }) => {
                    return (
                        <form
                            name="loginForm"
                            className="form-validate mb-lg"
                            onSubmit={handleSubmit}
                        >   
                            <div className="container mt-2">
                                <div className="row">
                                    <div className="col-md">
                                        <label htmlFor="school">Año a reutilizar</label>
                                        <Field
                                            name="reuse_year"
                                            component={renderAsyncSelectField}
                                            modal={true}
                                            labelKey="year"
                                            valueKey="id"
                                            validate={required}
                                            loadOptions={asyncOptions}
                                            className="w-100"
                                            placeholder="Seleccione Año..."
                                        />
                                    </div>

                                    <div className="col-md">
                                        <label htmlFor="school">Nuevo año</label>

                                        <Field
                                            name="new_year"
                                            component={renderSelectField}
                                            modal={true}
                                            options={YEARS_OPTIONS}
                                            isSearchable={true}
                                            labelKey="year"
                                            valueKey="year"
                                            validate={required}
                                            className="w-100"
                                            placeholder="Seleccione Año..."
                                        />
                                    </div>

                                </div>
                            </div>

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between mt-4">
                                <button
                                    className="btn btn-secondary align-self-center w-5 mt-md-0 mt-2"
                                    onClick={() => setOpen(false)}
                                >
                                    Cancelar
                                </button>

                                <button
                                    type="submit"
                                    className="btn btn-primary align-self-center w-5"
                                >
                                    Guardar
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </React.Fragment>
    );
};

export default ModalReuseHolidays;
