import React, { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import LoaderContext from '../../../../context/LoaderContext';
import SessionForm from './SessionForm';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';
import { SwalWarning } from '../../../Utils/SwalAlerts';

const CreateUpdateSession = (props) => {
    const location = useLocation();
    const { loader } = useContext(LoaderContext);
    const { saveData } = useCreate('session', '/session');
    const { data, updateData, update } = useUpdate('session', '/session');

    const onSubmit = (dataForm) => {
                isUpdated ? (
            SwalWarning(
                'Advertencia',
                `El código generado será ${dataForm.code}, ¿Está de acuerdo?`
            ).then(value => {
                // console.log(value)
                if (!value.value) {
                    return 0
                } else {
                    const body = {
                        module: dataForm.module.id,
                        code: dataForm.code,
                        title: dataForm.title,
                        resume: dataForm.resume,
                        type_program: dataForm.type_program,
                        level: dataForm.level,
                    };
                    if (!update) saveData(body);
                    else updateData(body);
                }
            })
        ) : (
        SwalWarning(
            'Advertencia',
            `El código generado será ${dataForm.module.ext + '-' + dataForm.code}, ¿Está de acuerdo?`
        ).then(value => {
            // console.log(value)
            if (!value.value) {
                return 0
            } else {
                const body = {
                    module: dataForm.module.id,
                    code: dataForm.module.ext + '-' + dataForm.code,
                    title: dataForm.title,
                    resume: dataForm.resume,
                    type_program: dataForm.type_program,
                    level: dataForm.level,
                };
                if (!update) saveData(body);
                else updateData(body);
            }
        })
        );
    };

    const isViewed = location.pathname.includes('view');
    const isUpdated = location.pathname.includes('update');
    const isCreated = location.pathname.includes('create');

    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="pt-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <h1 className="title-2 cl-orange">
                            {`${
                                isUpdated
                                    ? 'Actualizar'
                                    : isCreated
                                    ? 'Registrar'
                                    : ''
                            }`}{' '}
                            Sesión
                        </h1>
                    </div>
                    <SessionForm
                        onSubmit={onSubmit}
                        initialValues={data}
                        view={isViewed}
                        created={isCreated}
                        updated={isUpdated}
                        goBackButton={() => (
                            <Link
                                to="/session"
                                className="btn btn-secondary align-self-center mt-4 m-md-0"
                            >
                                Regresar
                            </Link>
                        )}
                    />
                    <br />
                    <br />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default CreateUpdateSession;
