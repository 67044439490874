import React, { useContext, useState } from 'react';
import Modal from 'react-responsive-modal';
import LegalGuardianForm from './LegalGuardianForm';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import LoaderContext from '../../../../context/LoaderContext';

export default function GuardianModal(props) {
    const { openModal, onCloseModal, onSubmitModal, initialValues } = props;
    const { loader } = useContext(LoaderContext);

    const [firstValuesOnRender, setFirstValuesOnRender] = useState(null);

    const handleDataChange = (newData) => {
        if (!firstValuesOnRender) {
            setFirstValuesOnRender(newData);
        } 
    };

    return (
        <React.Fragment>
            {openModal && (
                <Modal open={openModal || false} onClose={onCloseModal}>
                    <LoadMask loading={loader} blur>
                        <LegalGuardianForm
                            onSubmit={async (data) => {
                                await onSubmitModal(data);
                                onCloseModal();
                            }}
                            isCreated={openModal}
                            initialValues={{ ...initialValues }}
                            closeOnOverlayClick={false}
                            focusTrapped={false}
                            onGoBack={onCloseModal}
                            handleDataChange={handleDataChange}
                        />
                    </LoadMask>
                </Modal>
            )}
        </React.Fragment>
    );
}
