import React from 'react';

export default function RemoveSvgIcon({
    width = 96,
    height = 93,
    onClick = () => {},
}) {
    return (
        <svg
            width={width}
            height={height}
            id="Capa_1"
            data-name="Capa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 700 700"
            onClick={onClick}
        >
            <defs>
                <style
                    dangerouslySetInnerHTML={{
                        __html: '.cls-1{fill:#ef5543;fill-rule:evenodd;}',
                    }}
                />
            </defs>
            <path
                className="cls-1"
                d="M63,539.26V484.72q0-5.84,2.92-8.76c36-53.57,110.05-86.68,171.41-101.29V354.22c-47.72-30.2-74-91.55-74-146.09C163.28,137,229.5,78.6,298.66,78.6,368.78,78.6,434,137,434,208.13c0,54.53-26.3,115.89-74,146.09v20.45c36,8.76,76,23.37,110.05,44.8-44.8,19.48-76,65.25-76,117.84,0,4.87,0,10.71,1,15.58H76.61A13.32,13.32,0,0,1,63,539.25ZM522.65,436A101.21,101.21,0,0,1,623.94,537.32c0,55.52-45.77,101.29-101.29,101.29A101.21,101.21,0,0,1,421.36,537.32C421.36,480.84,466.16,436,522.65,436Zm55.51,87.66V551h-112V523.69Z"
            />
        </svg>
    );
}
