import React, { useState, useEffect } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { useForm } from 'react-final-form';
import Modal from 'react-responsive-modal';
import Table from '../../../Utils/Grid';
import Search from '../../../Utils/Search';
import _ from 'lodash';
import { api } from 'api';
import './students_modal.css';
import AsyncSelectField from '../../../Utils/AsyncSelectInput';
import InputSelect from '../../../Utils/InputSelect';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import {
    TYPE_BENEFICIARY_OPTIONS,
    GRADE_YOUNG_OPTIONS,
    GRADE_KID_OPTIONS,
} from '../../../../../utility/constants';

const Checkbox = (props) => {
    const [checked, setChecked] = React.useState(false);

    React.useEffect(() => {
        const index = _.findIndex(
            props.selected,
            (item) => item.id === props.student.id
        );
        if (index !== -1) {
            setChecked(true);
        }
    }, [checked]);

    const toggleChange = () => {
        if (!checked) {
            props.addStudent(props.student);
            setChecked(true);
        } else {
            props.removeStudent(props.student);
            setChecked(false);
        }
    };
    return (
        <div className="">
            <label className="checkbox c-checkbox m-0">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={toggleChange}
                />
                <span className="fa fa-check"></span>
            </label>
        </div>
    );
};

export default function StudentsModal({ students, openModal, onCloseModal }) {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState(undefined);
    const [loader, setLoader] = useState(false);
    const [listStudents, setListStudents] = useState([]);
    const [selected, setSelected] = useState([]);
    const [count, setCount] = useState(0);
    const { asyncOptions: asyncSchools } = useAsyncOptions('school');
    const fatherForm = useForm();
    const [school, setSchool] = useState(null);
    const [type, setType] = useState(null);
    const { school: filterSchool } = fatherForm.getState().values;

    useEffect(() => {
        if (filterSchool && filterSchool.id) setSchool(filterSchool);
        getStudents(1, undefined, filterSchool);
    }, []);

    const selectStudent = (student) => {
        const copySelected = [...selected];
        const exists = _.find(copySelected, (item) => {
            return item.id === student.id;
        });
        if (exists === undefined) copySelected.push(student);
        setSelected(copySelected);
    };
    const removeStudent = (student) => {
        let copySelected = [...selected];
        copySelected = _.remove(copySelected, (item) => item.id !== student.id);
        setSelected(copySelected);
    };

    const addStudents = () => {
        fatherForm.change('beneficiaries', [...students, ...selected]);
        onCloseModal();
    };

    const getStudents = (
        page = 1,
        search = undefined,
        school = undefined,
        type = undefined
    ) => {
        const params = { page };
        if (search !== undefined && search !== null && search != '')
            params.search = search;
        if (school && school.id) params.school = school.id;
        if (type) params.type = type;
        if (students && students.length > 0) {
            params.ids_in = students.map((item) => item.id);
        }
        setLoader(true);
        api.get('beneficiary/no_active_group', params)
            .then((response) => {
                setListStudents(response.results);
                setCount(response.count);
                setPage(page);
            })
            .catch(() => {})
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={onCloseModal}
                center={true}
                closeOnOverlayClick={false}
                classNames={{
                    modal: 'student-modal',
                }}
            >
                <h3 className="font-size-3 font-color-secondary m-0">
                    Filtros
                </h3>
                <div className="d-flex flex-column flex-md-row mb-4 mt-2">
                    <div className="d-flex flex-column flex-1">
                        <AsyncSelectField
                            loadOptions={asyncSchools}
                            valueKey="id"
                            labelKey="name"
                            value={school}
                            isClearable={true}
                            isSearchable={true}
                            className="mr-md-3"
                            placeholder="Por colegio..."
                            disabled={
                                filterSchool && filterSchool.id ? true : false
                            }
                            onChange={(value) => {
                                setSchool(value);
                                getStudents(1, search, value, type);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column flex-1  mt-3 m-md-0">
                        <InputSelect
                            options={TYPE_BENEFICIARY_OPTIONS}
                            value={type}
                            isClearable={true}
                            isSearchable={true}
                            className="mr-md-3"
                            placeholder="Por tipo..."
                            onChange={(value) => {
                                setType(value);
                                getStudents(1, search, school, value);
                            }}
                        />
                    </div>
                    <div className="d-flex flex-column flex-2">
                        <Search
                            placeholder="Buscar por nombres y apellidos ..."
                            className="ml-md-3"
                            onSearch={(value) => {
                                setSearch(value);
                                getStudents(1, value, school, type);
                            }}
                        />
                    </div>
                </div>
                <Table
                    data={{ results: listStudents, count: count }}
                    page={page}
                    loading={loader}
                    onPageChange={(page) =>
                        getStudents(page, search, school, type)
                    }
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        dataAlign="center"
                        width="7rem"
                        dataFormat={(cell, row) => (
                            <Checkbox
                                student={row}
                                addStudent={selectStudent}
                                removeStudent={removeStudent}
                                selected={selected}
                            />
                        )}
                    >
                        ACCIONES
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10rem"
                        dataField="identification"
                        dataSort
                    >
                        Identificación
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10rem"
                        dataField="first_name"
                        dataSort
                    >
                        Nombres
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10rem"
                        dataField="last_name"
                        dataSort
                    >
                        Apellidos
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10rem"
                        dataField="school_grade"
                        dataFormat={(value) => {
                            const type = _.find(
                                [...GRADE_KID_OPTIONS, ...GRADE_YOUNG_OPTIONS],
                                {
                                    value: value,
                                }
                            );
                            if (type) return type.label;
                            else return '-----';
                        }}
                    >
                        Grado
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10rem"
                        dataField="school_hall"
                        dataFormat={(value) => {
                            if (value) return value;
                            else return '-----';
                        }}
                    >
                        Salon
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10rem"
                        dataField="type"
                        dataFormat={(value) => {
                            const type = _.find(TYPE_BENEFICIARY_OPTIONS, {
                                value: value,
                            });
                            if (type) return type.label;
                            else return '-----';
                        }}
                    >
                        Tipo de beneficiario
                    </TableHeaderColumn>
                </Table>
                <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-2">
                    <button
                        className="btn btn-secondary align-self-center mt-4 m-md-0"
                        onClick={() => onCloseModal()}
                    >
                        Cancelar
                    </button>

                    <button
                        className="btn btn-primary align-self-center mt-4 m-md-0"
                        onClick={() => {
                            addStudents();
                        }}
                    >
                        Guardar
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    );
}
