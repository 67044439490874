import React, { useState, useEffect, useContext } from 'react';
import Card from '../../../Utils/Card';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import UserContext from '../../../../context/UserContext';
import { SUPER_ADMIN, COORDINATOR } from '../../../../../utility/constants';
import { SwalWarning } from '../../../Utils/SwalAlerts';
import Upload from './Upload';
import { api } from 'api';
import './ads.css';

export default function Ads() {
    const [img, setImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext);

    useEffect(() => {
        setLoading(true);
        api.get('dashboard/get_ad')
            .then((response) => {
                const { file } = response;
                if (file) setImg(file);
            })
            .finally(() => setLoading(false));
    }, []);

    const uploadImg = async (file) => {
        const attachments = [];
        const url = 'dashboard/change_ad';
        if (file instanceof File) {
            attachments.push({ name: 'img', file: file });
        }
        setLoading(true);
        const { uploaded } = await api
            .postAttachments(url, {}, attachments)
            .finally(() => setLoading(false));
        if (uploaded) setImg(file);
    };

    const removeImg = async () => {
        const { value } = await SwalWarning(
            '¿Eliminar?',
            '¡No podrá revertir esta acción!'
        );
        if (!value) return;
        setLoading(true);
        const { removed } = await api
            .post('dashboard/remove_ad')
            .finally(() => setLoading(false));
        if (removed) setImg(null);
    };

    return (
        <React.Fragment>
            {([SUPER_ADMIN, COORDINATOR].includes(user.type) || img) && (
                <Card isPrimary className="m-2 card-1">
                    <h3 className="title mb-2">Anuncios</h3>
                    <LoadMask loading={loading}>
                        <Upload
                            onChange={uploadImg}
                            file={img}
                            onRemove={removeImg}
                            disabled={
                                ![SUPER_ADMIN, COORDINATOR].includes(user.type)
                            }
                        />
                    </LoadMask>
                </Card>
            )}
        </React.Fragment>
    );
}
