import { isEmpty, isFinite } from 'lodash';
import dayjs from 'dayjs';

export default function useFormatFilters() {
    const formatFilters = (filters) => {
        const _filters = {};
        for (let [key, value] of Object.entries(filters)) {
            if (!isEmpty(value) || isFinite(value)) {
                if (typeof value === 'object' && 'id' in value) {
                    _filters[key] = value.id;
                } else if (dayjs.isDayjs(value) && dayjs(value).isValid()) {
                    _filters[key] = dayjs(value).format('YYYY-MM-DD');
                } else {
                    _filters[key] = value;
                }
            }
        }
        return _filters;
    };

    return { formatFilters };
}
