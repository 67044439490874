import React, { useEffect, useState,useContext } from 'react';
import Table from '../../../Utils/Grid';
import { Actions } from '../../../Utils/Grid/StandardActions';
import {
    textWithTooltip,
    RenderText,
} from '../../../Utils/renderField/renderReadField';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { paginate } from '../../../../../utility/utils';
import useDayjsUtils from '../../../../hooks/useDayjsUtils';
import {
    GRADUATION,
    CULMINATION,
    CLOSING,
    LEVEL_TRAINING_PLAN,
} from '../../../../../utility/constants';
import _ from 'lodash';
import './training_group.css';
import UserContext from '../../../../context/UserContext';

function trClassFormat(row) {
    if ([GRADUATION, CULMINATION,CLOSING].includes(row.type_session))
        return 'tr-special';
}



export default function TableSessions({ sessions, view, removeSession, updateSession,rescheduleSessions }) {
    const { toTimeTz, toDateTz,changeTimeZone,addDaysToDate } = useDayjsUtils();
    const [page, setPage] = useState(1);
    const { user }= useContext(UserContext);
    const _sessions = sessions.filter((session) => {
        if (session['is_save'] === false) return true;
        return false;
    });
    
    return (
        <React.Fragment>
            <Table
                page={page}
                onPageChange={setPage}
                data={{
                    results: paginate(_sessions, page),
                    count: _sessions.length,
                }}
                trClassName={trClassFormat}
            >
                {!view && (
                    <TableHeaderColumn
                        dataField="code"
                        dataAlign="center"
                        width="8rem"
                        dataFormat={(cell, row) => {
                            const datenow = changeTimeZone(addDaysToDate(new Date(),-30), 'America/Bogota');
                            const daterow = changeTimeZone(new Date(row.start), 'America/Bogota');
                            if (row && !!row.its_over && daterow<=datenow) return;
                            return (
                                <Actions
                                    id={cell}
                                    erase_session_group={(id) => removeSession(id)}
                                    type={user.type}
                                />
                            );
                        }}
                    >
                        Eliminar
                    </TableHeaderColumn>
                )}
                <TableHeaderColumn isKey dataField="code" width="10rem">
                    Código
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="title"
                    width="15rem"
                    dataFormat={(value, row) => {
                        let _value = value;
                        const level = _.find(LEVEL_TRAINING_PLAN, {
                            value: row.level_session,
                        });
                        if (level) {
                            _value = `${value} (${level.label})`;
                        }
                        return <RenderText value={_value} />;
                    }}
                >
                    Titulo
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort
                    dataField="start"
                    width="10rem"
                    dataFormat={(value) => toDateTz(value)}
                >
                    Fecha
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="start"
                    width="10rem"
                    dataFormat={(value) => toTimeTz(value)}
                >
                    Hora inicio
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="end"
                    width="10rem"
                    dataFormat={(value) => toTimeTz(value)}
                >
                    Hora fin
                </TableHeaderColumn>
                {!view && (
                    <TableHeaderColumn
                        dataField="code"
                        dataAlign="center"
                        width="8rem"
                       
                        dataFormat={(cell, row) => {
                            //const datenow = changeTimeZone(addDaysToDate(new Date(),-30), 'America/Bogota');
                            //const daterow = changeTimeZone(new Date(row.start), 'America/Bogota');
                            const daterow = changeTimeZone(addDaysToDate(new Date(row.start),-30), 'America/Bogota');
                            if (row && !!row.its_over /*&& daterow<=datenow*/) return;
                            return (
                                    <Actions
                                    id={cell}
                                    row={row}
                                    month_ago={daterow}
                                    sessions={sessions}
                                    update_session={(code,data) => updateSession(code,data)}
                                    />
                            );
                        }}
                    >
                        Editar
                    </TableHeaderColumn>
                )}
            </Table>
        </React.Fragment>
    );
}
