import { useEffect } from 'react';
import { api } from 'api';

const registerServiceWorker = async () => {
    const swRegistration = await navigator.serviceWorker.register('/sw.js');
    return swRegistration;
};

const requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission();
    if (permission !== 'granted') {
        throw new Error('Permission not granted for Notification');
    }
};

const activeServices = async () => {
    if ('serviceWorker' in navigator) {
        const serviceWorker = await registerServiceWorker();
    } else {
        // console.log('serviceWorker not available.');
    }
    if ('PushManager' in window) {
        const permission = await requestNotificationPermission();
    } else {
        // console.log('pushManager not available.');
    }
};

export default function useServiceWorker() {
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', async () => {
                try {
                    await activeServices();
                } catch (e) {}
            });
        }
    }, []);
}
