import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import LoaderContext from '../../../../context/LoaderContext';
import SessionForm from '../../Session/CreateUpdate/SessionForm';
import { useForm } from 'react-final-form';
import useCreate from '../../../../hooks/useCreate';

export default function SessionModal({ openModal, onCloseModal }) {
    const { loader } = useContext(LoaderContext);
    const { data,saveData } = useCreate('session');
    const fatherForm = useForm();

    const onSubmit = async (formData) => {
            const body = {
            module: formData.module.id,
            code: formData.code,
            title: formData.title,
            resume: formData.resume,
            type_program: formData.type_program,
            level: formData.level,
        };
        const sessionSaved = await saveData(body);
        if (sessionSaved) {
            fatherForm.change('session', sessionSaved);
            onCloseModal();
        }
    };
    return (
        <React.Fragment>
            {openModal && (
                <Modal
                    open={openModal}
                    onClose={onCloseModal}
                    center={true}
                    focusTrapped={false}
                    closeOnOverlayClick={false}
                >
                    <LoadMask loading={loader} blur>
                        <SessionForm
                            onSubmit={onSubmit}
                            initialValues={data}
                            goBackButton={() => (
                                <button
                                    type="button"
                                    onClick={onCloseModal}
                                    className="btn btn-secondary align-self-center"
                                >
                                    Regresar
                                </button>
                            )}
                        />
                    </LoadMask>
                </Modal>
            )}
        </React.Fragment>
    );
}
