import React from 'react';
import Table from '../../../../Utils/Grid';
import { standardActions } from '../../../../Utils/Grid/StandardActions';
import TitleUnderline from '../../../../Utils/TitleUnderline';

const data = {
    count: 3,
    next: null,
    previous: null,
    results: [],
};

const DetailTable = ({ kit, setKit, isViewed, entryData }) => {
    // set kit to results
    data.results = kit;

    return (
        <React.Fragment>
            <TitleUnderline
                title={
                    isViewed
                        ? `Detalle del ${entryData.name}`
                        : 'Detalle del kit'
                }
                className="mt-4"
            />
            <Table
                className="mt-2"
                data={data}
                // onPageChange={(page) => getData(page)}
                // page={page}
                // loading={loader}
            >
                {!isViewed && (
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width="5rem"
                        dataFormat={standardActions({
                            erase: (id) => {
                                // delete the selected kit, filter and return a new array without the product
                                const changedKits = kit.filter(
                                    (kit) => kit.id !== id
                                );
                                setKit(changedKits);
                            },
                        })}
                    ></TableHeaderColumn>
                )}
                <TableHeaderColumn
                    className="mt-5"
                    width="12rem"
                    isKey={isViewed}
                    dataField="description"
                >
                    Artículo
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="code">
                    Código
                </TableHeaderColumn>
                <TableHeaderColumn width="7rem" dataField="quantity">
                    Cantidad
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="category">
                    Categoría
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default DetailTable;
