import React, { useContext, useEffect } from 'react';
import TypeTransferContext from '../../../../../context/TypeTransferContext';

import './TabButton.css';

const TabButtons = () => {
    const { typeTransfer, setTypeTransfer } = useContext(TypeTransferContext);
    const handleClick = (e) => {
        const { name } = e.target;
        switch (name) {
            case 'product_kit':
                setTypeTransfer(1);
                break;
            case 'kit_product':
                setTypeTransfer(2);
                break;
            default:
                break;
        }
    };
    return (
        <React.Fragment>
            <div className="tab-container pt-2">
                <button
                    className={`tab-item ${typeTransfer === 1 && 'active'}`}
                    onClick={handleClick}
                    name="product_kit"
                    type="button"
                    // autoFocus={entry.active ? true : false}
                >
                    Convertir <label>Artículo</label> a <label>Kit</label>
                </button>

                <button
                    className={`tab-item ${typeTransfer === 2 && 'active'}`}
                    onClick={handleClick}
                    name="kit_product"
                    type="button"
                    // autoFocus={issue.active ? true : false}
                >
                    Convertir <label>Kit</label> a <label>Artículo</label>
                </button>
            </div>
        </React.Fragment>
    );
};

export default TabButtons;
