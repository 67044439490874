import React, { useContext, useState } from 'react';
import { Field } from 'react-final-form';
import {
    composeValidators,
    date,
    minValue,
    required,
} from '../../../../../../utility/validation';
import useAsyncOptions from '../../../../../hooks/useAsyncOptions';
import {
    renderAsyncSelectField,
    renderDatePicker,
    renderField,
} from '../../../../Utils/renderField/renderField';
import {
    SCHOOL_COORDINATOR,
    SCHOOL_TRAINER,
} from '../../../../../../utility/constants';

import RadioButton from '../../Utils/RadioButton';
import UserContext from '../../../../../context/UserContext';

import MovmentContext from '../../../../../context/MovmentContext';

const RenderField = ({ refresh, getInventory }) => {
    return (
        <React.Fragment>
            {refresh && (
                <Field
                    name="product"
                    label="Seleccione Producto"
                    component={renderAsyncSelectField}
                    isSearchable={true}
                    isClearable={true}
                    loadOptions={getInventory}
                    valueKey="id"
                    labelKey="description"
                    labelKey2="sub_location"
                    type="text"
                    validate={required}
                    className="w-100"
                />
            )}
            {!refresh && (
                <Field
                    name="product"
                    label="Seleccione Producto"
                    component={renderAsyncSelectField}
                    isSearchable={true}
                    isClearable={true}
                    loadOptions={getInventory}
                    valueKey="id"
                    labelKey="description"
                    labelKey2="sub_location"
                    type="text"
                    validate={required}
                    className="w-100"
                />
            )}
        </React.Fragment>
    );
};

const BodyForm = ({ inputEditabel }) => {
    // filter to the api inventory (location)
    const [location, setLocation] = useState(1);
    // Refresh select
    const [refresh, setRefresh] = useState(true);

    // radio button state (movment)
    // 1 product
    // 2 school kit
    const { movment, setMovment } = useContext(MovmentContext);
    const { user } = useContext(UserContext);
    const isSchoolStaff = [SCHOOL_COORDINATOR, SCHOOL_TRAINER].includes(
        user.type
    );

    const { asyncOptions: getInventory } = useAsyncOptions('inventory', {
        location: location,
        show_by: 'product',
    });

    const { asyncOptions: getSchoolKit } = useAsyncOptions('school_kit');
    const { asyncOptions: getLocations } = useAsyncOptions('location');

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-md-row">
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="location">Ubicación salida</label>
                    <Field
                        name="location"
                        label="Seleccione Ubicación"
                        component={renderAsyncSelectField}
                        isSearchable={true}
                        isClearable={true}
                        loadOptions={getLocations}
                        valueKey="id"
                        labelKey="name"
                        labelKey2=""
                        type="text"
                        validate={required}
                        disabled={inputEditabel}
                        parse={(value) => {
                            setLocation(value.id);
                            setRefresh(!refresh);
                            return value;
                        }}
                        className="w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="date">Fecha de la salida</label>
                    <Field
                        name="date"
                        label="Fecha de la entrada"
                        component={renderDatePicker}
                        type="text"
                        validate={composeValidators(required, date)}
                        disabled={true}
                        className="form-control w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1"></div>
                <div className="form-group has-feedback m-2 flex-1"></div>
            </div>
            <div className="d-flex flex-column flex-md-row mt-md-4">
                <div className="form-group has-feedback m-2 flex-1">
                    <RadioButton
                        options={
                            isSchoolStaff
                                ? ['Artículo']
                                : ['Artículo', 'Kit escolar']
                        }
                        value={movment}
                        setValue={setMovment}
                        className="mt-2"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="product">
                        Seleccione {movment === 1 ? 'Artículo' : 'Kit escolar'}
                    </label>
                    {movment === 1 && (
                        <RenderField
                            refresh={refresh}
                            getInventory={getInventory}
                        />
                    )}
                    {movment === 2 && (
                        <Field
                            name="product"
                            label="Seleccione Producto"
                            component={renderAsyncSelectField}
                            isSearchable={true}
                            isClearable={true}
                            loadOptions={getSchoolKit}
                            valueKey="id"
                            labelKey="name"
                            type="text"
                            validate={required}
                            className="w-100"
                        />
                    )}
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="quantity">Cantidad</label>
                    <Field
                        name="quantity"
                        component={renderField}
                        placeholder="Ingrese la cantidad del producto"
                        type="number"
                        className="form-control w-100"
                        validate={composeValidators(required, minValue(1))}
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1 d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary mt-4"
                        onClick={() => {}}
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BodyForm;
