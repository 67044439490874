import React, { useRef, useState, useEffect, useContext } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import { useLocation } from 'react-router-dom';
import ScrollableInkTabBar from 'rc-tabs/lib/TabBar';
import { Link } from 'react-router-dom';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';
import GroupForm from './GroupFrom';
import _ from 'lodash';
import { SwalWarning } from '../../../Utils/SwalAlerts';
import usePost from '../../../../hooks/usePost';
import LoaderContext from '../../../../context/LoaderContext';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { SwalError } from '../../../Utils/SwalAlerts';
import dayjs from 'dayjs';
import TableSessions from './TableSessions';
import HistorySessionTable from './HistorySessionTable';
import { CULMINATION, GRADUATION,CLOSING } from '../../../../../utility/constants';
import { NotificationManager } from 'react-notifications';
import { DialogBeforeUnLoad } from '../../../Utils/DialogBeforeUnLoad';
import { useNavigatingAway } from '../../../../hooks/useNavigatingAway';
import { areObjectsEqual } from '../../../Utils/Funcs';

export default function Group() {
    const location = useLocation();
    const isViewed = location.pathname.includes('view');
    const isUpdated = location.pathname.includes('update');
    const isCreated = location.pathname.includes('create');
    const buttonSubmitRef = useRef(null);
    const [sessions, setSessions] = useState([]);
    const { loader } = useContext(LoaderContext);
    const { saveData } = useCreate('training_group', '/training_group');
    const { data, update, updateData, getData, id } = useUpdate(
        'training_group',
        '/training_group',
        undefined,
        true,
        false,
        true
    );
    const { sendPost } = usePost();

    useEffect(() => {
        if (data && data.sessions) {
            setSessions(data.sessions);
            setFirstValuesOnRender(data);
        }
    }, [data]);

    const onGenerateSession = async (dataForm) => {
        const body = { ...dataForm };
        if (body.training_plan) body.training_plan = body.training_plan.id;
        if (id) body.training_group = id;
        body.start_day = dayjs(body.start_day).format('YYYY-MM-DD');
        body.end_time = dayjs(body.end_time).format('HH:mm');
        body.start_time = dayjs(body.start_time).format('HH:mm');
        body.sessions =
            data.sessions && data.sessions.length > 0 ? sessions : [];

        if (body.sessions.length > 0) {
            const { value } = await SwalWarning(
                'Plan ya cargado.',
                '¿Desea continuar con la generación de sesiones?'
                //  ', las nuevas sesiones se agregaran al final de las actuales.'
            );
            if (!value) return;
        }

        const _sessions = await sendPost(
            'training_group/generate_events',
            body
        );
        if (_sessions) setSessions(_sessions);
        return 'clear';
    };

    function tConvert(time) {
        time = time
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            time = time.slice(1);
            time[5] = +time[0] < 12 ? ':AM' : ':PM';
            time[0] = +time[0] % 12 || 12;
        }

        return time[5];
    }

    const onAddSession = async (dataForm) => {
        const body = {};
        const session = { ...dataForm };
        if (session && session.id) session.id = session.id;
        session.start_day = dayjs(session.start_day).format('YYYY-MM-DD');
        session.end_time = dayjs(session.end_time).format('HH:mm');
        session.start_time = dayjs(session.start_time).format('HH:mm');
        const events = Array.from([...sessions] || []);
        let exists_date = false;
        const has_culmination = _.find(sessions, (item) =>
            [CULMINATION, GRADUATION,CLOSING].includes(item.type_session) && item.is_save === false
        );       
        if (has_culmination && !!session.type_session) {
            NotificationManager.error(
                'Ya existe una sesión de culminación o graduación activa.',
                'Error',
                5000
            );
            return 'cancel';
        }       

        let code_event = '';
        let event_over=false;
        events.forEach(async (event) => {
            const event_day = dayjs(event.start).format('YYYY-MM-DD');
            if (event_day === session.start_day) {
                exists_date = true;
                code_event = event.code;
                event_over=event.its_over;                   
            } 
        });           
        if (!!exists_date) {
                const result = await SwalWarning(
                    'Fecha ocupada',
                    'En la fecha seleccionada ya se agregó anteriormente otra sesión. \n\n¿Desea correr la/las sesiones a la próxima fecha disponible? De lo contrario, permanecerán las dos en la misma fecha.',
                    'Si, deseo correrla.',
                    'No'
                );
                if (result.dismiss === 'close') return 'cancel';
                body.run_days = result.value;
        } 

        if (body.run_days === true && event_over==true) {
            NotificationManager.error(
                'Existe una sesión que ya ha concluido con esa fecha. No se puede correr las fechas.',
                'Error',
                5000
            );
            return 'cancel';
        } 

        body.session = { ...session };
        body.events = [...events];
        let _sessions = await sendPost('training_group/add_event', body);
        if (_sessions === undefined) return 'cancel';

        let data_edit_session = {
            fecha: session.start_day.replace(/-/g, '/'),
            fecha_action: false,
            final: session.end_time + tConvert(session.end_time),
            finish_action: false,
            inicio: session.start_time + tConvert(session.start_time),
            reschedule: true,
            start_action: false,
        };
        if (body.run_days === true) {
            let index_add_obj = _sessions.findIndex(
                (item) => item.code === body.session.session.code
            );
            let index_del_obj_clavo = _sessions.findIndex(
                (item) => item.code === code_event
            );

            let reset_sessiones = [];

            for (let i = 0; i < _sessions.length; i++) {
                if (i == index_del_obj_clavo) {
                    reset_sessiones.push(_sessions[index_add_obj]);
                }
                if (i !== index_add_obj) {
                    reset_sessiones.push(_sessions[i]);
                }
            }

            _sessions = await sendPost('training_group/update_event_item', {
                sessions: reset_sessiones,
                session: body.session.session.code,
                data: data_edit_session,
                is_add: true,
            });
        }

        if (_sessions) setSessions(_sessions);
    };

    const removeSession = async (code) => {
        const body = { sessions, remove: code };
        const _sessions = await sendPost('training_group/remove_event', body);
        if (_sessions) setSessions(_sessions);
    };

    const updateSession = async (code, data) => {
        const body = {
            sessions,
            session: code,
            data,
        };
        const _sessions = await sendPost(
            'training_group/update_event_item',
            body
        );
        if (_sessions) setSessions(_sessions);
    };

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        body.sessions = sessions;
        if (body.group) body.group = body.group.id;
        if (body.monitor) body.monitor = body.monitor.id;
        if (body.individual_mentor)
            body.individual_mentor = body.individual_mentor.id;
        if (body.mentor) body.mentor = body.mentor.id;
        const has_culmination = _.find(sessions, (item) =>
            [CULMINATION, GRADUATION,CLOSING].includes(item.type_session)
        );
        if (has_culmination === undefined && sessions.length > 0) {
            SwalError(
                'Error',
                'El grupo de formación no tiene una session de culminación o graduación.'
            );
            return;
        }
        if (!update) {
            if (body.sessions.length <= 0) {
                SwalError(
                    'Error',
                    'El grupo de formación debe tener al menos una sesión para que se pueda guardar.'
                );
                return;
            }
            saveData(body);
        } else updateData(body);
    };

    const [firstValuesOnRender, setFirstValuesOnRender] = useState(null);

    const [isChanged, setIsChangedData] = useState(false);

    const [canShowDialogBeforeUnLoad, setCanShowDialogBeforeUnLoad] = useState(
        (isUpdated || isCreated) && isChanged
    );

    const handleDataChange = (newData, delete_sessions) => {
        if (!firstValuesOnRender) {
            if (newData && newData.sessions && newData.sessions.length > 0) {
                setFirstValuesOnRender(newData);
            }
        } else {
            const VerifyIfDataChanged = (newData, firstValuesOnRender) => {
                const isDataChanged = !areObjectsEqual(
                    firstValuesOnRender,
                    newData
                );

                return isDataChanged;
            };
            let res;
            if (delete_sessions) {
                // It is done this way since the form does not update the values ​​of the sessions
                let newDataCpy = { ...newData };
                delete newDataCpy.sessions;
                let copy_sessions = [...sessions];
                newDataCpy.sessions = copy_sessions;
                res = VerifyIfDataChanged(newDataCpy, firstValuesOnRender);
            } else {
                res = VerifyIfDataChanged(newData, firstValuesOnRender);
            }
            setIsChangedData(res);
            setCanShowDialogBeforeUnLoad(res);
        }
    };

    const [showDialogBeforeUnLoad, confirmNavigation, cancelNavigation] =
        useNavigatingAway(canShowDialogBeforeUnLoad);

    useEffect(() => {}, [canShowDialogBeforeUnLoad, isChanged]);

    useEffect(() => {
        // for when session data changes
        if (firstValuesOnRender) {
            if (isUpdated || isCreated) {
                let dataCopyFirstValuesOnRender = { ...firstValuesOnRender };
                delete dataCopyFirstValuesOnRender.sessions;
                let copy_sessions = [...sessions];
                dataCopyFirstValuesOnRender.sessions = copy_sessions;
                handleDataChange(dataCopyFirstValuesOnRender);
            }
        }
    }, [sessions]);

    return (
        <React.Fragment>
            <DialogBeforeUnLoad
                showDialog={showDialogBeforeUnLoad}
                setShowDialog={setCanShowDialogBeforeUnLoad}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
            <div className="pt-5">
                <h1 className="title-2 cl-orange m-2">
                    {`${
                        isUpdated ? 'Actualizar' : isCreated ? 'Registrar' : ''
                    }`}{' '}
                    Grupo de formación
                </h1>
            </div>
            <LoadMask loading={loader} blur>
                <GroupForm
                    buttonSubmitRef={buttonSubmitRef}
                    view={isViewed}
                    initialValues={data}
                    onSubmit={onSubmit}
                    update={update}
                    onAddSession={onAddSession}
                    onGenerateSession={onGenerateSession}
                    sessions={sessions}
                    handleDataChange={handleDataChange}
                />
                <div className="rc-group mt-5">
                    {id ? (
                        <Tabs
                            defaultActiveKey={'ONE'}
                            tabBarPosition="top"
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                            animated={true}
                        >
                            <TabPane tab="Sesiones pendientes" key="ONE">
                                <div className="my-4">
                                    <TableSessions
                                        sessions={sessions}
                                        removeSession={removeSession}
                                        updateSession={updateSession}
                                        view={isViewed}
                                    />
                                </div>
                            </TabPane>
                            <TabPane tab="Historial de sesiones" key="TWO">
                                <div className="my-4">
                                    <HistorySessionTable
                                        id={id}
                                        sessions={sessions}
                                        view={isViewed}
                                        updateSession={updateSession}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    ) : (
                        <TableSessions
                            sessions={sessions}
                            removeSession={removeSession}
                            view={isViewed}
                        />
                    )}
                    <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                        <Link
                            to="/training_group"
                            className="btn btn-secondary align-self-center mt-4 m-md-0"
                        >
                            Regresar
                        </Link>
                        {!isViewed && (
                            <button
                                type="button"
                                className="btn btn-primary align-self-center mt-1"
                                disabled={isViewed}
                                onClick={() => {
                                    buttonSubmitRef.current.click();
                                }}
                            >
                                Guardar
                            </button>
                        )}
                    </div>
                    <br />
                </div>
            </LoadMask>
        </React.Fragment>
    );
}
