import React from 'react';
import {
    renderSwitchClass,
    renderFieldCheck,
} from '../../../Utils/renderField/renderField';
import { Field } from 'react-final-form';
import { useEffect } from 'react';
import './createUpdate.css';
import { useHasChanged, usePrevious} from '../../../../hooks/useHasChanged';

/**
 * @param {Object} valuesComponent
 * @param {Object} valuesComponent.values
 * @param {Boolean} valuesComponent.is_diligence
 * @param {String} valuesComponent.label
 * @param {String} valuesComponent.nameField
 * @returns {JSX.Element}
 *
 * @example
 * <FieldSwitch
 *    valuesComponent={{
 *      values: values,
 *      is_diligence: is_diligence,
 *      label: '¿Tiene diligencia?',
 *      nameField: "name_field"
 *      }}
 * />
 */
function FieldSwitch({ valuesComponent }) {
    const { values, is_diligence, label, nameField } = valuesComponent;

    return (
        <div className="form-group d-flex">
            <label htmlFor={nameField} className="mr-5 w-50">
                {label}
            </label>
            <Field
                name={nameField}
                component={renderSwitchClass}
                label={!!values[nameField] ? 'Si Autorizo' : 'No Autorizo'}
                type="text"
                width={50}
                height={22}
                classNameCustom="d-flex align-items-center justify-content-end w-50"
                disabled={!is_diligence}
            />
        </div>
    );
}


function DiligenceAuthorization({ values, form, isCreated, initialValues}) {

    let hasValuesChanged = useHasChanged(values.diligence_authorization);

    //treatment_personal y special_authorization cambiaran dependiendo de la diligencia
    useEffect(() => {
        // console.log('hasValuesChanged', hasValuesChanged);
        if (hasValuesChanged) {
            form.change('treatment_personal', values.diligence_authorization);
            form.change('special_authorization', values.diligence_authorization);
        }
    }, [values.diligence_authorization]);

    // Si es para actualizar datos pasaremos los valores iniciales
    useEffect(() => {
        if (!isCreated) {
            form.change('treatment_personal', initialValues.treatment_personal);
            form.change('special_authorization', initialValues.special_authorization);
        }
    }, []);

    return (
        <div className="d-flex flex-column  m-2 width-switch">
            <div className="form-group d-flex">
                <label
                    htmlFor="diligence_authorization"
                    className="mr-5 title-color"
                >
                    ¿Diligenció Autorización?
                </label>
                <Field
                    name="diligence_authorization"
                    component={renderFieldCheck}
                />
            </div>
            <FieldSwitch
                valuesComponent={{
                    values: values,
                    is_diligence: values.diligence_authorization,
                    label: 'Autorización de Tratamiento de Datos Personales',
                    nameField: 'treatment_personal',
                }}
            />
            <FieldSwitch
                valuesComponent={{
                    values: values,
                    is_diligence: values.diligence_authorization,
                    label: ' Autorización Especial',
                    nameField: 'special_authorization',
                }}
            />
        </div>
    );
}

export { DiligenceAuthorization };
