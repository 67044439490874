function areObjectsEqual(obj1, obj2) {
    const is_Debug = false; // Set to true to log errors

    // Not comparing these properties
    const next_params = [
        'modified',
        'created',
        // Not comparing these properties for bugs
        'photo',
        'img_authorization',
    ]

    // Check if both objects are of the same type
    if (typeof obj1 !== typeof obj2) {
        if (is_Debug) {
            console.log('Objects are not of the same type:', obj1, obj2);
        }
        return false;
    }

    // Check if they are null
    if (obj1 === null && obj2 === null) {
        return true;
    }

    // Check if they are primitives
    if (typeof obj1 !== 'object' && typeof obj2 !== 'object') {
        if (obj1 !== obj2) {
            if (is_Debug) {
                console.log('Primitives are not equal:', obj1, obj2);
            }
            return false;
        }
        return true;
    }

    // Check if they are objects
    if (typeof obj1 === 'object' && typeof obj2 === 'object') {

        // Compare if is undefined
        if (!(obj1 && obj2)){
            if (is_Debug) {
                console.log('Objects are undefined:', obj1, obj2);
            }
            return false;
        }

        // Get the object properties
        const propsObj1 = Object.getOwnPropertyNames(obj1);
        const propsObj2 = Object.getOwnPropertyNames(obj2);

        // Check if the number of properties is the same
        if (propsObj1.length !== propsObj2.length) {
            if (is_Debug) {
                console.log(
                    'Objects have different number of properties:',
                    obj1,
                    obj2
                );
            }
            return false;
        }

        // Check if properties and their values are equal
        for (let i = 0; i < propsObj1.length; i++) {
            const propName = propsObj1[i];

            // Check if properties have the same name
            if (!propsObj2.includes(propName)) {
                if (is_Debug) {
                    console.log(
                        'Objects have different properties:',
                        obj1,
                        obj2
                    );
                }
                return false;
            }

            // Check if property is not in the list of properties to ignore
            if (next_params.includes(propName)) {
                continue;
            }

            // Check if property values are deeply equal
            if (!areObjectsEqual(obj1[propName], obj2[propName])) {
                if (is_Debug) {
                    console.log(
                        'Property values are not equal:',
                        'obj1',
                        propName,
                        obj1[propName],
                        'obj2',
                        propName,
                        obj2[propName]
                    );
                }
                return false;
            }
        }

        // If all properties are equal
        return true;
    }

    // If none of the above conditions are met, the objects are not equal

    if (is_Debug) {
        console.log('Objects are not equal:', obj1, obj2);
    }
    return false;
}

export { areObjectsEqual };
