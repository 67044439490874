import React from 'react';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import { renderAsyncSelectField } from '../../../Utils/renderField/renderField';
import { required } from '../../../../../utility/validation';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';

const SessionForm = (props) => {
    const { onSubmit, view, sessions } = props;
    const { asyncOptions } = useAsyncOptions('session');
    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit, form, valid }) => {
                const exclude_ids = Array.from(sessions || []).map(
                    (item) => item.id
                );
                return (
                    <form
                        name="SessionForm"
                        className="form-validate mb-lg"
                        onSubmit={async (event) => {
                            await handleSubmit(event);
                            if (valid) form.restart();
                        }}
                    >
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback px-2 flex-1">
                                <label htmlFor="code">Sesión</label>
                                <Field
                                    key={`${exclude_ids.length}-session`}
                                    name="session"
                                    component={renderAsyncSelectField}
                                    isClearable={true}
                                    loadOptions={(search) =>
                                        asyncOptions(search, {
                                            exclude_ids: exclude_ids,
                                        })
                                    }
                                    isSearchable={true}
                                    valueKey="id"
                                    labelKey="code"
                                    labelKey2="title"
                                    type="text"
                                    validate={required}
                                    disabled={view}
                                />
                            </div>
                            <div className="d-flex flex-column  m-2 mx-md-5 pl-2 flex-1 ">
                                <label className="m-2 p-1" />
                                <div className="d-flex justify-content-center justify-content-md-start">
                                    <button
                                        type="submit"
                                        className="btn btn-secondary align-self-center align-self-md-auto mt-1"
                                        disabled={view}
                                    >
                                        Agregar sesión
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default SessionForm;
