import React, { Suspense, useMemo, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Product from './Product';
import Entry from './Entry';
import InventoryIssue from './InventoryIssue';
import Transfers from './Transfers';
import KitConversion from './KitConversion';
import SchoolKit from './SchoolKit';
import Location from './Location';
import Supplier from './Supplier';

import MovmentContext from '../../../context/MovmentContext';
import InventoryContext from '../../../context/InventoryContext';
import TypeTransferContext from '../../../context/TypeTransferContext';
import ProtectedInventory from '../../../../ProtectedInventory';

// const ProductList = React.lazy(() => import('./Product/List'));
const ProductKitList = React.lazy(() => import('./ProductKitList/List'));
const EntryList = React.lazy(() => import('./Entry/List'));
const InventoryIssueList = React.lazy(() => import('./InventoryIssue/List'));
const InventoryList = React.lazy(() => import('./Inventory/List'));
const TransfersList = React.lazy(() => import('./Transfers/List'));
const KitConversionList = React.lazy(() => import('./KitConversion/List'));

export default function InventoryRoute() {
    const [movment, setMovment] = useState(1);
    const radioButton = useMemo(() => ({ movment, setMovment }), [movment]);

    const [show, setShow] = useState(1);
    const showInventory = useMemo(() => ({ show, setShow }), [show]);

    // * you control if you want to make a transfer of product or a transfer of product to kit
    // radio button state (kit transfer or product transfer) type transfer
    // 1 product
    // 2 school kit
    const [typeTransfer, setTypeTransfer] = useState(1);
    const typeTransferInventory = useMemo(
        () => ({ typeTransfer, setTypeTransfer }),
        [typeTransfer]
    );
    return (
        <React.Fragment>
            <Routes>
                <Route
                    path=""
                    element={
                        <Suspense fallback={null}>
                            <InventoryList />
                        </Suspense>
                    }
                />
                {/* <Route
                    path="/articles"
                    element={
                        <Suspense fallback={null}>
                            <ProductList />
                        </Suspense>
                    }
                /> */}
                <Route
                    path="/transfers"
                    element={
                        <Suspense fallback={null}>
                            <TransfersList />
                        </Suspense>
                    }
                />
                <Route
                    path="/entry"
                    element={
                        <Suspense fallback={null}>
                            <EntryList />
                        </Suspense>
                    }
                />
                <Route
                    path="/inventory_issue"
                    element={
                        <Suspense fallback={null}>
                            <InventoryIssueList />
                        </Suspense>
                    }
                />
                {/* Inventory - Product / kit */}
                <Route
                    path="/product_kit"
                    element={
                        <Suspense fallback={null}>
                            <InventoryContext.Provider value={showInventory}>
                                <ProtectedInventory>
                                    <ProductKitList />
                                </ProtectedInventory>
                            </InventoryContext.Provider>
                        </Suspense>
                    }
                />
                {/* Product / kit - Product */}
                <Route
                    path="/product/create"
                    element={<Product.CreateUpdate />}
                />

                <Route
                    path="/product/:id/update"
                    element={<Product.CreateUpdate />}
                />

                <Route
                    path="/product/:id/view"
                    element={<Product.CreateUpdate />}
                />

                {/* Product / kit - School kit */}
                <Route
                    path="/school_kit/create"
                    element={
                        <SchoolKit.CreateUpdate path_back="/inventory/product_kit" />
                    }
                />
                <Route
                    path="/school_kit/:id/update"
                    element={
                        <SchoolKit.CreateUpdate path_back="/inventory/product_kit" />
                    }
                />
                <Route
                    path="/school_kit/:id/view"
                    element={
                        <SchoolKit.CreateUpdate path_back="/inventory/product_kit" />
                    }
                />

                {/* Inventory - Entry */}
                <Route
                    path="/entry/create"
                    element={
                        <MovmentContext.Provider value={radioButton}>
                            <Entry.CreateUpdate path_back="/inventory/entry" />
                        </MovmentContext.Provider>
                    }
                />

                <Route
                    path="/entry/:id/view"
                    element={
                        <Entry.CreateUpdate path_back="/inventory/entry" />
                    }
                />

                {/* Inventory - InventoryIssue */}
                <Route
                    path="/inventory_issue/create"
                    element={
                        <MovmentContext.Provider value={radioButton}>
                            <InventoryIssue.CreateUpdate path_back="/inventory/inventory_issue" />
                        </MovmentContext.Provider>
                    }
                />

                <Route
                    path="/inventory_issue/:id/view"
                    element={
                        <InventoryIssue.CreateUpdate path_back="/inventory/inventory_issue" />
                    }
                />

                {/* <Route
                    path="/inventory_issue/:id/update"
                    element={
                        <InventoryIssue.CreateUpdate path_back="/inventory/inventory_issue" />
                    }
                /> */}

                {/* Transfers */}
                <Route
                    path="/transfers/create"
                    element={
                        <MovmentContext.Provider value={radioButton}>
                            <TypeTransferContext.Provider
                                value={typeTransferInventory}
                            >
                                <Transfers.CreateUpdate path_back="/inventory/transfers" />
                            </TypeTransferContext.Provider>
                        </MovmentContext.Provider>
                    }
                />

                <Route
                    path="/transfers/:id/view"
                    element={
                        <MovmentContext.Provider value={radioButton}>
                            <Transfers.CreateUpdate path_back="/inventory/transfers" />
                        </MovmentContext.Provider>
                    }
                />
                {/* Kit Conversion */}
                <Route
                    path="/kit_conversion"
                    element={
                        <Suspense fallback={null}>
                            <KitConversionList />
                        </Suspense>
                    }
                />
                <Route
                    path="/kit_conversion/create"
                    element={
                        <MovmentContext.Provider value={radioButton}>
                            <TypeTransferContext.Provider
                                value={typeTransferInventory}
                            >
                                <KitConversion.CreateUpdate path_back="/inventory/kit_conversion" />
                            </TypeTransferContext.Provider>
                        </MovmentContext.Provider>
                    }
                />
                <Route
                    path="/kit_conversion/:id/view"
                    element={
                        <KitConversion.CreateUpdate path_back="/inventory/kit_conversion" />
                    }
                />

                {/* Loaction */}
                <Route path="/location" element={<Location.List />} />
                <Route
                    path="/location/create"
                    element={<Location.CreateUpdate />}
                />

                <Route
                    path="/location/:id/update"
                    element={<Location.CreateUpdate />}
                />

                <Route
                    path="/location/:id/view"
                    element={<Location.CreateUpdate />}
                />

                {/* Supplier */}
                <Route path="/supplier" element={<Supplier.List />} />
                <Route
                    path="/supplier/create"
                    element={<Supplier.CreateUpdate />}
                />

                <Route
                    path="/supplier/:id/update"
                    element={<Supplier.CreateUpdate />}
                />

                <Route
                    path="/supplier/:id/view"
                    element={<Supplier.CreateUpdate />}
                />
            </Routes>
        </React.Fragment>
    );
}
