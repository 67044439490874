import React from 'react';

export default function GraduationSvgIcon({
    width = 88,
    height = 88,
    color = '#ef5543',
}) {
    return (
        <svg
            width={width}
            height={height}
            id="Capa_1"
            data-name="Capa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 700 700"
            fill={color}
        >
            <path d="M210.58,465c4.07-4.07,7.12-10.18,7.12-15.26a22.39,22.39,0,1,0-44.78,0c0,6.1,2,11.19,7.13,15.26,3,3.06,4.07,6.11,3,10.18l-21.37,116h66.15l-21.37-116A15.54,15.54,0,0,1,210.58,465Z" />
            <path d="M184.12,405.94V345.89l-19.34-5.09c-12.22,22.39-19.34,45.8-22.39,71.24-1,9.16,3.05,19.34,11.19,24.42,4.07-15.26,16.29-26.45,30.54-30.52Z" />
            <path d="M534.21,339.78,524,342.84,355.1,391.69c-1,0-3.06,1-4.07,1h-6.11L206.51,353V407c19.34,5.09,33.58,22.39,33.58,42.74A46.25,46.25,0,0,1,235,470.05c41.73,15.26,77.35,25.44,109.91,31.54a14.09,14.09,0,0,0,9.16,0c57-10.18,118.06-30.53,188.27-62.08,10.18-5.09,17.3-15.26,15.27-27.48-3.05-25.43-11.19-48.84-23.41-72.25Z" />
            <path d="M371.37,276.68c0-3.05-8.14-10.18-21.37-10.18s-21.38,6.11-21.38,10.18c0,3.06,8.14,10.18,21.38,10.18S371.37,279.74,371.37,276.68Z" />
            <path d="M191.24,321.46l115-43.76v-1c0-18.32,19.34-32.57,43.76-32.57s43.77,14.25,43.77,32.57S374.43,309.25,350,309.25c-13.23,0-25.44-4.07-32.56-11.2l-94.65,35.62L350,370.31l187.25-53.94,138.41-39.69L350,183.05,24.34,276.68l163.85,46.81c1-1,2-2,3.05-2Z" />
        </svg>
    );
}
