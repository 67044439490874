import React from 'react';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import {
    renderAsyncSelectField,
    renderDatePicker,
    renderTimePicker,
} from '../../../Utils/renderField/renderField';
import { required } from '../../../../../utility/validation';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import useIsOffline from '../../../../hooks/useIsOffline';
import { CULMINATION, GRADUATION,CLOSING } from '../../../../../utility/constants';
import { NotificationManager } from 'react-notifications';
import { SwalWarning } from '../../../Utils/SwalAlerts';
import dayjs from 'dayjs';
import { api } from 'api';

const GenerateSessionForm = ({
    onSubmit,
    view,
    start_day,
    dataFormGroup,
    sessions,
}) => {
    const { asyncOptions } = useAsyncOptions('training_plan');
    const { isOffline } = useIsOffline();

    const validate = (values) => {
        const errors = {};
        if (!!values.start_time && !!values.end_time) {
            const start_time = dayjs(values.start_time);
            const end_time = dayjs(values.end_time);
            if (end_time.isBefore(start_time) || end_time.isSame(start_time)) {
                errors.end_time = 'Hora de fin debe ser mayor a hora de inicio';
            }
        }
        
        // if (start_day !== undefined && !!values.start_day) {
        //     const min_start_day = dayjs(start_day).startOf('day');
        //     const new_start_day = dayjs(values.start_day).startOf('day');
        //     if (min_start_day.isAfter(new_start_day)) {
        //         errors.start_day = `La fecha debe ser major a ${min_start_day.format(
        //             'DD/MM/YYYY'
        //         )}`;
        //     }
        // }

        if (!dataFormGroup.group) {
            errors.training_plan =
                'Debe seleccionar un grupo de beneficiarios.';
        }
        return errors;
    };

    const onGenerate = async (dataForm) => {
        // Validate if exists a culmination session
        try {

            const lastSession = _.find(sessions, (item) =>
                [CULMINATION, GRADUATION,CLOSING].includes(item.type_session)
            );
            
            // Si el plan de formacion es en un dia diferente al actual, si deja agregarlo
            if (sessions[0]) {

                const firstSession = sessions[0]
                const date_session = new Date(firstSession.start);
                const day = date_session.getDay();

                const date_session_new = new Date(dataForm.start_day);
                const day_new = date_session_new.getDay();

                if (day_new && day_new == day) {
                    if (lastSession && !lastSession.its_over) {
                        NotificationManager.error(
                            'No puedes agregar otro plan de formación el mismo dia si el actual no se ha finalizado.',
                            'ERROR',
                            5000
                        );
                        return 'clear';
                    }
                }
            }

            let matchDataPost = {
                plan_id: dataForm.training_plan.id,
                group_id: dataFormGroup.group.id,
            };
            const matchUrl = `group_beneficiaries/${dataFormGroup.group.id}/match_training_plan`;
            const updateUrl = `group_beneficiaries/${dataFormGroup.group.id}/update_type_level`;
            const respOne = await api.post(matchUrl, matchDataPost);
            isOffline(respOne);
            const { not_match_level, not_match_type, msg, is_change_level, grade} = respOne;

            const data = { ...dataForm };
            data.group_id = dataFormGroup.group.id;

            if (not_match_type || not_match_level) {
                const { value } = await SwalWarning('Alerta', msg);
                if (value) {

                    if (is_change_level) {
                        const { value } = await SwalWarning('Alerta', `¿Desea cambiar todos los beneficiarios del grupo al ${grade} º grado ?`);
                        matchDataPost = {
                            ...matchDataPost,
                            is_change_grade: value,
                        };
                        const respTwo = await api.post(updateUrl, matchDataPost);
                        isOffline(respTwo);
                        return onSubmit(data);
                    }

                    const respTwo = await api.post(updateUrl, matchDataPost);
                    isOffline(respTwo);
                    return onSubmit(data);
                }

            } else {
                return onSubmit(data);
            }
        } catch (e) {
            let message = 'Error interno.';
            if (e && e.detail) message = e.detail;
            else if (_.isArray(e)) message = e;
            NotificationManager.error(message, 'ERROR', 6000);
        }
    };

    return (
        <Form
            onSubmit={onGenerate}
            validate={validate}
            initialValues={{ start_day: start_day || null }}
        >
            {({ handleSubmit, form }) => {
                return (
                    <div
                        name="GenerateSessionForm"
                        className="form-validate mb-lg"
                    >
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="training_plan">
                                    Plan de formación / Nivel
                                </label>
                                <Field
                                    name="training_plan"
                                    component={renderAsyncSelectField}
                                    isSearchable={true}
                                    loadOptions={asyncOptions}
                                    type="text"
                                    labelKey="name"
                                    valueKey="id"
                                    validate={required}
                                    className="w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-5 flex-1">
                                <label htmlFor="start_day">
                                    Inicio clases grupales
                                </label>
                                <Field
                                    name="start_day"
                                    component={renderDatePicker}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="start_time">Hora Inicio</label>
                                <Field
                                    name="start_time"
                                    component={renderTimePicker}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                    minuteStep={30}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-5 flex-1">
                                <label htmlFor="end_time">Hora Finaliza</label>
                                <Field
                                    name="end_time"
                                    component={renderTimePicker}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                    minuteStep={30}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row p-2 flex-1 justify-content-end">
                            <button
                                type="button"
                                onClick={async (event) => {
                                    const result = await handleSubmit(event);
                                    if (result == 'clear') form.restart();
                                }}
                                className="btn btn-secondary align-self-center mt-1"
                                disabled={view}
                            >
                                Generar sesiones
                            </button>
                        </div>
                    </div>
                );
            }}
        </Form>
    );
};

export default GenerateSessionForm;
