import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { EditFilled } from '@ant-design/icons';
import UserContext from '../../../context/UserContext';
import OfflineContext from '../../../context/OfflineContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    COORDINATOR,
} from '../../../../utility/constants';
import homeIcon from '../../../../../assets/img/home.png';
import schoolIcon from '../../../../../assets/img/school.png';
import beneficiaryIcon from '../../../../../assets/img/beneficiary.png';
import inventoryIcon from '../../../../../assets/img/inventory.png';
import calendarIcon from '../../../../../assets/img/calendar.png';
import UserIcon from '../../../../../assets/img/user.png';
import ReportingIcon from '../../../../../assets/img/reporting.png';

import './sidebar.css';

const { SubMenu } = Menu;

const rootSubmenuKeys = ['beneficiary', 'calendar', 'inventory', 'reporting'];

function MenuItem({
    itemIcon,
    itemTitle,
    itemTo,
    className,
    onClick,
    userType,
    notAllowTo = [],
}) {
    if (userType && notAllowTo.includes(userType)) return <div />;
    return (
        <li className={`nav-item my-2`} key={itemTitle} onClick={onClick}>
            <NavLink
                to={itemTo || '/'}
                end
                className={({ isActive }) =>
                    `nav-link ${className} ${isActive ? 'active' : ''}`
                }
            >
                <div className="item-icon-wrapper">
                    {itemIcon && <img src={itemIcon} className="mb-1" />}
                </div>
                <span className="pl-1 align-self-center">{itemTitle}</span>
            </NavLink>
        </li>
    );
}

export default function MenuSidebar({ navToggle }) {
    const { user } = useContext(UserContext);
    const { offline } = useContext(OfflineContext);
    const [openKeys, setOpenKeys] = useState([]);

    const conditions_calendar = ['calendar', 'calendar/holiday']
    const validate_calendar = conditions_calendar.some(x => window.location.hash.includes(x))

    const conditions_groups = ['module', 'session', 'training_plan', 'group_beneficiaries', 'training_group']
    const validate_groups = conditions_groups.some(x => window.location.hash.includes(x))

    const conditions_inventory = ['inventory', 'inventory/product_kit', 'inventory/entry', 'inventory/inventory_issue', 'inventory/transfers', 'inventory/kit_conversion', 'inventory/location', 'inventory/supplier']
    const validate_inventory = conditions_inventory.some(x => window.location.hash.includes(x))

    const conditions_reporting = ['reporting/kpi-assistance', 'reporting/assistance', 'reporting']
    const validate_reporting = conditions_reporting.some(x => window.location.hash.includes(x))

    useEffect(() => {
        if (validate_calendar){
            setOpenKeys(['calendar'])
        }

        if (validate_groups){
            setOpenKeys(['beneficiary'])
        }

        if (validate_inventory){
            setOpenKeys(['inventory'])
        }

        if (validate_reporting){
            setOpenKeys(['reporting'])
        }
    }, []);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    if (user.type == INVITED) {
        return (
            <React.Fragment>
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                >
                    <MenuItem className='onlyMenu' itemIcon={homeIcon} itemTitle="Home" itemTo="/" />
                </Menu>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {offline ? (
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                >
                    <SubMenu
                        key="calendar"
                        icon={<img src={calendarIcon} width="1.2rem" />}
                        title="Calendario"
                    >
                        <MenuItem
                            className='menu-item'
                            itemTitle="Agenda"
                            itemTo="/calendar"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[INVITED, ASSISTANT]}
                        />
                    </SubMenu>
                    <MenuItem
                        className='onlyMenu'
                        itemIcon={beneficiaryIcon}
                        itemTitle="Beneficiarios"
                        itemTo="/beneficiary"
                        onClick={navToggle}
                    />
                </Menu>
            ) : (
                <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                >
                    <MenuItem
                        itemIcon={homeIcon}
                        itemTitle="Home"
                        itemTo="/"
                        onClick={navToggle}
                        className='onlyMenu'
                    />
                    <SubMenu
                        key="calendar"
                        icon={<img src={calendarIcon} width="1.2rem" />}
                        title="Calendario"
                    >
                        <MenuItem
                            itemTitle="Agenda"
                            itemTo="/calendar"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[INVITED]}
                        />

                        <MenuItem
                            itemTitle="Feriados"
                            itemTo="/calendar/holiday"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[INVITED]}
                        />
                    </SubMenu>
                    <MenuItem
                        itemIcon={beneficiaryIcon}
                        itemTitle="Beneficiarios"
                        itemTo="/beneficiary"
                        onClick={navToggle}
                        className='onlyMenu'
                    />
                    <SubMenu
                        key="beneficiary"
                        icon={<EditFilled />}
                        title="Grupos / Formación"
                    >
                        <MenuItem
                            itemTitle="Módulos"
                            itemTo="/module"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[
                                INVITED
                            ]}
                        />
                        <MenuItem
                            itemTitle="Sesiones"
                            itemTo="/session"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[
                                INVITED
                            ]}
                        />
                        <MenuItem
                            itemTitle="Planes de Formación"
                            itemTo="/training_plan"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[
                                INVITED,
                            ]}
                        />
                        <MenuItem
                            itemTitle="Grupo beneficiarios"
                            itemTo="/group_beneficiaries"
                            className="menu-item"
                            onClick={navToggle}
                        />
                        <MenuItem
                            className="menu-item"
                            itemTitle="Grupos de Formación"
                            itemTo="/training_group"
                            onClick={navToggle}
                        />
                    </SubMenu>
                    <MenuItem
                        itemIcon={schoolIcon}
                        itemTitle="Colegios"
                        className='onlyMenu'
                        itemTo="/school"
                        onClick={navToggle}
                        userType={user.type}
                        notAllowTo={[INVITED]}
                    />
                    <SubMenu
                        key="inventory"
                        icon={<img src={inventoryIcon} width="1.2rem" />}
                        title="Inventario"
                    >
                        <MenuItem
                            itemTitle="Resumen"
                            itemTo="/inventory"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[
                                SCHOOL_TRAINER,
                                INVITED,
                            ]}
                        />
                        <MenuItem
                            itemTitle="Artículos / Kits"
                            onClick={navToggle}
                            className="menu-item"
                            itemTo="/inventory/product_kit"
                            userType={user.type}
                            notAllowTo={[
                                SCHOOL_COORDINATOR,
                                SCHOOL_TRAINER,
                                INVITED,
                            ]}
                        />
                        <MenuItem
                            itemTitle="Entradas"
                            itemTo="/inventory/entry"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[INVITED]}
                        />
                        <MenuItem
                            itemTitle="Salidas"
                            itemTo="/inventory/inventory_issue"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[INVITED]}
                        />
                        <MenuItem
                            itemTitle="Traslados"
                            itemTo="/inventory/transfers"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[INVITED]}
                        />
                        <MenuItem
                            itemTitle="Conversión de kits"
                            itemTo="/inventory/kit_conversion"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[ SCHOOL_COORDINATOR,
                                SCHOOL_TRAINER,
                                INVITED]}
                        />
                        <MenuItem
                            itemTitle="Ubicaciones"
                            itemTo="/inventory/location"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[INVITED]}
                        />
                        <MenuItem
                            itemTitle="Proveedores / donantes"
                            itemTo="/inventory/supplier"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[
                                SCHOOL_COORDINATOR,
                                SCHOOL_TRAINER,
                                INVITED,
                            ]}
                        />
                    </SubMenu>
                    <MenuItem
                        itemIcon={UserIcon}
                        itemTitle="Usuarios"
                        className='onlyMenu'
                        itemTo="/user"
                        onClick={navToggle}
                        userType={user.type}
                        notAllowTo={[
                            INVITED,
                            TRAINER,
                            SCHOOL_TRAINER,
                            ASSISTANT,
                            COORDINATOR,
                        ]}
                    />
                    <SubMenu
                        key="reporting"
                        icon={<img src={ReportingIcon} width="1.2rem" />}
                        title="Reportes"
                    >
                        <MenuItem
                            itemTitle="KPIs Asistencia"
                            itemTo="/reporting/kpi-assistance"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[INVITED]}
                        />
                        <MenuItem
                            itemTitle="Asistencia"
                            itemTo="/reporting/assistance"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[
                                INVITED,
                            ]}
                        />
                        <MenuItem
                            itemTitle="Módulos"
                            itemTo="/reporting"
                            className="menu-item"
                            onClick={navToggle}
                            userType={user.type}
                            notAllowTo={[
                                INVITED,
                                SCHOOL_COORDINATOR,
                                SCHOOL_TRAINER
                            ]}
                        />
                    </SubMenu>
                </Menu>
            )}
        </React.Fragment>
    );
}
