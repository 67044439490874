import { useContext } from 'react';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import useIsOffline from './useIsOffline';
import { api } from 'api';
import LoaderContext from '../context/LoaderContext';
import _ from 'lodash';

const usePost = (path_module, initial_params = {}) => {
    const { setLoader } = useContext(LoaderContext);
    const navigate = useNavigate();
    const { isOffline } = useIsOffline();

    const sendPost = async (name_api, body) => {
        setLoader(true);
        let res = undefined;
        try {
            const _params = { ...initial_params };
            res = await api.post(name_api, body, _params);
            NotificationManager.success('Petición exitosa', 'ÉXITO', 6000);
            isOffline(res);
            if (path_module) navigate(path_module);
            return res;
        } catch (e) {
            let message = 'Error interno.';
            if (e && e.detail) message = e.detail;
            else if (_.isArray(e)) message = e;
            NotificationManager.error(message, 'ERROR', 6000);
        } finally {
            setLoader(false);
            return res;
        }
    };

    return { sendPost };
};

export default usePost;
