import React from 'react';
import { Field, Form } from 'react-final-form';
import { renderField } from '../../Utils/renderField';
import {
    composeValidators,
    required,
    minLength,
    password,
} from '../../../../utility/validation';

const validates = (values) => {
    const errors = {};
    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Contraseña no coincide';
    }
    return errors;
};

const ChangePasswordForm = (props) => {
    const { onSubmit } = props;
    return (
        <Form validate={validates} onSubmit={onSubmit}>
            {({ handleSubmit, submitting, pristine }) => (
                <form
                    name="ChangePasswordForm"
                    className="form-validate mb-lg m-4"
                    onSubmit={handleSubmit}
                >
                    <div className="form-group has-feedback m-2 flex-1">
                        <Field
                            name="password"
                            label="Contraseña"
                            component={renderField}
                            type="password"
                            placeholder="Nueva contraseña"
                            validate={composeValidators(
                                required,
                                minLength(8),
                                password
                            )}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group has-feedback m-2 flex-1">
                        <Field
                            name="confirmPassword"
                            label="Confirmar Contraseña"
                            component={renderField}
                            type="password"
                            validate={required}
                            placeholder="Confirmar Contraseña"
                            className="form-control"
                        />
                    </div>

                    <div className="buttons-box mt-4">
                        <button
                            type="submit"
                            className="btn btn-primary align-self-center w-100"
                            disabled={submitting || pristine}
                        >
                            Cambiar contraseña
                        </button>
                    </div>
                </form>
            )}
        </Form>
    );
};

export default ChangePasswordForm;
