import React, { useContext, useState } from 'react';
import Table from '../../../Utils/Grid';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import Search from '../../../Utils/Search';
import LoaderContext from '../../../../context/LoaderContext';
import useList from '../../../../hooks/useList';
import useDelete from '../../../../hooks/useDelete';
import UserContext from '../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN,
    COORDINATOR,
} from '../../../../../utility/constants'

export default function GroupStudents() {
    const { loader } = useContext(LoaderContext);
    const { data, getData, page } = useList('group_beneficiaries');
    const [search, setSearch] = useState(null);
    const { deleteData } = useDelete('group_beneficiaries');
    const { user }= useContext(UserContext);

    return (
        <React.Fragment>
            <div className="pt-5" />
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <h1 className="title-text">Grupo Beneficiarios</h1>
                { (user.type === SUPER_ADMIN || user.type === COORDINATOR) &&(
                <Link
                    to="/group_beneficiaries/create"
                    className="btn btn-primary"
                >
                    Registrar Grupo Beneficiarios
                </Link>
                )}
            </div>
            <Search
                onSearch={(value) => {
                    getData(1, {
                        search: value,
                    }).then();
                    setSearch(value);
                }}
                placeholder="Buscar por nombre, código y colegio ..."
            />
            <Table
                className="mt-5"
                data={data}
                loading={loader}
                onPageChange={(page) => getData(page, { search: search })}
                page={page}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/group_beneficiaries',
                        update: '/group_beneficiaries',
                        erase: (id) => deleteData(id, getData),
                        type:user.type,
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn dataField="name" width="10rem">
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn dataField="code" width="10rem">
                    Código
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="school"
                    dataFormat={(value) =>
                        value && value.name ? value.name : ''
                    }
                    width="10rem"
                >
                    Colegio
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
}
