import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Form } from 'react-final-form';

import BodyForm from './BodyForm';
import DetailTable from './DetailTable/DetailTable';
import dayjs from 'dayjs';
import _ from 'lodash';

const EntryForm = ({
    entryData,
    onSubmit,
    update,
    path_back,
    isViewed,
    entries,
    setEntries,
    onClick,
}) => {
    // variable to handle allowing to edit supplier, date and location fields if there is already a product added
    const inputEditable = entries.length >= 1;
    if (isViewed) {
        const { details } = entryData;
        if (details) {
            // so that it runs only once
            useEffect(() => {
                // data for table
                details.map((entry) => {
                    entry.code = entry.product.code;
                    entry.description = entry.product.description;
                    entry.category = entry.product.category;
                    entry.name = entryData.name;
                    entry.school_kit = entry.school_kit;
                    entry.school_kit_name = entry.school_kit
                        ? entry.school_kit.name
                        : '';
                });
                // Assign the kit data to the table
                const current_entry = entryData.details;
                setEntries(...entries, current_entry);
            }, []);
        }
    }
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={entryData}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                render={({ handleSubmit, submitting, form, values }) => {
                    if (!window.setFormValue)
                        window.setFormValue = form.mutators.setValue;
                    if (_.get(values, 'date', undefined) === undefined) {
                        form.change('date', dayjs());
                    }
                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            {!isViewed && (
                                <BodyForm
                                    inputEditable={inputEditable}
                                    values={values}
                                />
                            )}
                            <DetailTable
                                entries={entries}
                                isViewed={isViewed}
                                entryData={entryData}
                                setEntries={setEntries}
                            />

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                                <Link
                                    to={path_back}
                                    className="btn btn-secondary mt-4 mt-md-0"
                                >
                                    Regresar
                                </Link>
                                {!isViewed && (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={submitting}
                                        onClick={onClick}
                                    >
                                        {update ? 'Actualizar' : 'Guardar'}
                                    </button>
                                )}
                            </div>
                        </form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default EntryForm;
