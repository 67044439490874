import { useEffect, useState, useContext } from 'react';

import { api } from 'api';
import { INITIAL_DATA } from '../../utility/constants';
import { NotificationManager } from 'react-notifications';
import LoaderContext from '../context/LoaderContext';
import OfflineContext from '../context/OfflineContext';
import _ from 'lodash';

export const useReportingList = (name_api, body = {}) => {
    const [data, setData] = useState(INITIAL_DATA);
    const [page, setPage] = useState(1);
    const { setLoader } = useContext(LoaderContext);
    const { offline } = useContext(OfflineContext);

    const getData = async (page = 1, params = {}, showNotification = true) => {
        if (!name_api) {
            return;
        }
        setLoader(true);
        const _params = { ...body, ...params };
        // _params.page = page;
        let _data = {};
        try {
            _data = await api.post(`${name_api}/?page=${page}&`, _params);
            setData(_data);
            setPage(page);
            if (_.get(_data, 'results.length', 0) > 0) {
                if (page === 1 && showNotification) {
                    NotificationManager.success(
                        'Reporte Generado',
                        'ÉXITO',
                        6000
                    );
                }
                setTimeout(() => {
                    const scrollDiv = document.getElementById('report-table');
                    if (scrollDiv) {
                        window.scrollTo({
                            top: scrollDiv.offsetTop + 75,
                            behavior: 'smooth',
                        });
                    }
                }, 100);
            } else {
                NotificationManager.warning(
                    'No se encontraron datos con el filtro aplicado',
                    'ATENCIÓN',
                    6000
                );
            }
        } catch (e) {
            let msj = 'No se pudo obtener los registros';
            if (e && e.detail) msj = e.detail;
            else if (_.isArray(e)) msj = e;
            NotificationManager.error(msj, 'ERROR', 6000);
        } finally {
            setLoader(false);
        }
        return _data;
    };

    useEffect(() => {
        if (!offline) getData(1, body);
    }, []);

    return { data, page, getData, setData, INITIAL_DATA };
};

export default useReportingList;
