import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { api } from 'api';
import { useContext } from 'react';
import LoaderContext from '../context/LoaderContext';
import _ from 'lodash';

export const useDelete = (name_api, path_module = undefined) => {
    const navigate = useNavigate();
    const { setLoader } = useContext(LoaderContext);

    const deleteData = async (id, getData = undefined) => {
        setLoader(true);
        try {
            await api.erase(`${name_api}/${id}`);
            NotificationManager.success('Registro eliminado.', 'ÉXITO', 6000);
            if (path_module) navigate(path_module);
            if (getData) await getData();
        } catch (e) {
            let msj = 'No se pudo eliminar el registro.';
            if (e && e.detail) msj = e.detail;
            else if (_.isArray(e)) msj = e;
            NotificationManager.error(msj, 'ERROR', 6000);
        } finally {
            setLoader(false);
        }
    };

    return { deleteData };
};

export default useDelete;
