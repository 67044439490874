import React from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
    renderField,
    renderDatePicker,
    renderSelectField,
    renderTextArea,
} from '../../../Utils/renderField/renderField';
import {
    composeValidators,
    required,
    maxLength,
    date,
} from '../../../../../utility/validation';
import {
    COUNTRY_OPTIONS,
    WORKDAY_OPTIONS,
    STATUS_SCHOOL_OPTIONS,
    SIZE_SCHOOL_OPTIONS,
    ACTIVE,
} from '../../../../../utility/constants';

const INITIAL_VALUES = {
    country: 'Colombia',
    status: ACTIVE,
};

const SchoolForm = (props) => {
    const { onSubmit, initialValues, view } = props;

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{ ...INITIAL_VALUES, ...initialValues }}
        >
            {({ handleSubmit, submitting }) => {
                return (
                    <form
                        name="loginForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="nit">NIT</label>
                                <Field
                                    name="nit"
                                    label="NIT"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(50)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="name">Nombres</label>
                                <Field
                                    name="name"
                                    label="Nombres"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="code">Código del colegio</label>
                                <Field
                                    name="code"
                                    label="Código del colegio"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(15)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="telephone">Teléfono</label>
                                <Field
                                    name="telephone"
                                    label="Teléfono"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(15)}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="web_site">Sitio web</label>
                                <Field
                                    name="web_site"
                                    label="Sito web"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(50)}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="country">País</label>
                                <Field
                                    name="country"
                                    label="País"
                                    component={renderSelectField}
                                    options={COUNTRY_OPTIONS}
                                    defaultInputValue={_.find(COUNTRY_OPTIONS, {
                                        value: 'Colombia',
                                    })}
                                    type="text"
                                    validate={required}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="department">Departamento</label>
                                <Field
                                    name="department"
                                    component={renderField}
                                    label="Departamentos"
                                    validate={composeValidators(
                                        required,
                                        maxLength(75)
                                    )}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="city">Municipio</label>
                                <Field
                                    name="city"
                                    label="Municipio"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(100)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="address">Dirección</label>
                                <Field
                                    name="address"
                                    label="Dirección"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(200)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="status_school">
                                    Estado del colegio
                                </label>
                                <Field
                                    name="status"
                                    label="Estado del colegio"
                                    component={renderSelectField}
                                    options={STATUS_SCHOOL_OPTIONS}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="workday">Jornada</label>
                                <Field
                                    name="workday"
                                    label="Jornada"
                                    component={renderSelectField}
                                    options={WORKDAY_OPTIONS}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(50)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="range_size">
                                    Tamaño de colegio
                                </label>
                                <Field
                                    name="range_size"
                                    label="Tamaño de colegio"
                                    component={renderSelectField}
                                    options={SIZE_SCHOOL_OPTIONS}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(50)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="start_date">Fecha inicio</label>
                                <Field
                                    name="start_date"
                                    label="Fecha de inicio"
                                    component={renderDatePicker}
                                    type="text"
                                    validate={composeValidators(required, date)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="end_date">Fecha fin</label>
                                <Field
                                    name="end_date"
                                    label="Fecha fin"
                                    component={renderDatePicker}
                                    type="text"
                                    validate={date}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="d-none d-md-flex m-2 mx-lg-4 flex-1" />
                        </div>
                        {/* <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="w-100 form-group has-feedback m-2 flex-1 session">
                                <label htmlFor="end_date">Observaciones</label>
                                <Field
                                    name="observations"
                                    component={renderTextArea}
                                    rows={12}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div> */}
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/school"
                                className="btn btn-secondary align-self-center mt-4 m-md-0"
                            >
                                Regresar
                            </Link>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary align-self-center"
                                    disabled={submitting}
                                >
                                    Guardar
                                </button>
                            )}
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default SchoolForm;
