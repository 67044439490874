import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SessionFrom from './SessionForm';
import DndTable from '../DndTable';
import { SwalError } from '../../../Utils/SwalAlerts';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';
import TrainingPlanForm from './TrainingPlanForm';
import './training_plan.css';

export default function TrainingPlan() {
    const location = useLocation();
    const [sessions, setSessions] = useState([]);
    const { saveData } = useCreate('training_plan', '/training_plan');
    const submitRef = useRef(null);
    const { data, updateData, update } = useUpdate(
        'training_plan',
        '/training_plan'
    );

    useEffect(() => {
        if (data && data.sessions) {
            setSessions(data.sessions);
        }
    }, [data]);

    const onAddSession = (dataForm) => {
        const { session } = dataForm;
        const codeExists = sessions.find(
            (item) => item['code'] === session['code']
        );
        if (codeExists) {
            SwalError('Error', 'El código de sesión ya existe.');
        } else {
            setSessions((items) => [...items, session]);
        }
    };

    const onSubmit = (dataForm) => {
        if (sessions.length <= 0) {
            SwalError(
                'Error',
                'El plan de formación debe tener al menos una sesión para que se pueda guardar.'
            );
        } else {
            const body = { ...dataForm };
            body.sessions = sessions;
            if (!update) saveData(body);
            else updateData(body);
        }
    };
    const isViewed = location.pathname.includes('view');
    const isUpdated = location.pathname.includes('update');
    const isCreated = location.pathname.includes('create');

    return (
        <React.Fragment>
            <div className="pt-5">
                <h1 className="title-2 cl-orange m-2">
                    {`${
                        isUpdated ? 'Actualizar' : isCreated ? 'Registrar' : ''
                    }`}{' '}
                    Plan de formación
                </h1>
                <TrainingPlanForm
                    submitRef={submitRef}
                    initialValues={data}
                    onSubmit={onSubmit}
                />
                <SessionFrom
                    onSubmit={onAddSession}
                    view={isViewed}
                    sessions={sessions}
                />
                <br />
                <div className="mt-4">
                    <h2 className="table-title mt-4 mt-md-0">
                        Lista de sesiones
                    </h2>
                </div>

                <DndTable
                    className="mt-5"
                    rows={sessions}
                    setRows={(rows) => setSessions(rows)}
                />
                <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                    <Link
                        to="/training_plan"
                        className="btn btn-secondary align-self-center mt-4 m-md-0"
                    >
                        Regresar
                    </Link>
                    {!isViewed && (
                        <button
                            type="button"
                            className="btn btn-primary align-self-center"
                            onClick={() => submitRef.current.click()}
                        >
                            Guardar
                        </button>
                    )}
                </div>
                <br />
                <div id="draggable-portal" />
            </div>
        </React.Fragment>
    );
}
