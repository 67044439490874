import React from 'react';
import MenuSidebar from './MenuSidebar';
import './sidebar.css';

export default function SideBar(props) {
    const { toggleOpen, navToggle, closedToggle } = props;
    
    return (
        <aside
            className={`main-sidebar px-0 col-12 col-md-3 col-lg-2 bg-orange ${
                toggleOpen ? 'open' : ''
            }`}
            style={{ zIndex: '20 !important' }}
        >
            <nav className="main-navbar align-items-stretch flex-md-nowrap pb-5 mb-3 mt-5 pt-1">
                <a href="#" className="w-100 mr-0 bg-inherit">
                    <div className="d-table m-auto">
                        <img
                            id="main-logo"
                            className="d-inline-block align-top mr-1"
                            src={require('assets/img/logo-iwoka.png')}
                            alt="Logo"
                        />
                    </div>
                </a>
                <a
                    className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
                    onClick={closedToggle}
                >
                    <i className="material-icons"></i>
                </a>
            </nav>
            <br />
            <MenuSidebar navToggle={navToggle} />
            <img
                className="iwoka-logo"
                src={require('assets/img/iwoka-logo-sidebar.svg')}
            />
        </aside>
    );
}
