import React, { useContext, useState } from 'react';
import Table from '../../../Utils/Grid';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import Search from '../../../Utils/Search';
import LoaderContext from '../../../../context/LoaderContext';
import useList from '../../../../hooks/useList';
import useDelete from '../../../../hooks/useDelete';
import UserContext from '../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN,
    COORDINATOR
} from '../../../../../utility/constants'

export default function TrainingGroup() {
    const { loader } = useContext(LoaderContext);
    const { data, getData, page } = useList('training_group');
    const [search, setSearch] = useState(null);
    const { deleteData } = useDelete('training_group');
    const { user }= useContext(UserContext);

    return (
        <React.Fragment>
            <div className="pt-5" />
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <h1 className="title-text">Grupo de Formación</h1>
                { (user.type === SUPER_ADMIN || user.type === COORDINATOR) &&(
                <Link to="/training_group/create" className="btn btn-primary">
                    Registrar Grupo
                </Link>
                )}
            </div>
            <Search
                onSearch={(value) => {
                    getData(1, {
                        search: value,
                    }).then();
                    setSearch(value);
                }}
                placeholder="Buscar por nombre ..."
            />
            <Table
                className="mt-5"
                data={data}
                loading={loader}
                onPageChange={(page) => getData(page, { search: search })}
                page={page}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/training_group',
                        update_traing_group: '/training_group',
                        erase: (id) => deleteData(id, getData),
                        type:user.type,
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn dataField="name" width="12rem">
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="group"
                    width="12rem"
                    dataFormat={(value) => value.name}
                >
                    Grupo beneficiarios
                </TableHeaderColumn>
                <TableHeaderColumn dataField="school" width="10rem">
                    Colegio
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="mentor"
                    width="12rem"
                    dataFormat={(value) =>
                        `${value.first_name} ${value.last_name}`
                    }
                >
                    Mentor Grupal
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                    dataField="individual_mentor"
                    width="12rem"
                    dataFormat={(value) =>
                        `${value.first_name} ${value.last_name}`
                    }
                >
                    Mentor Individual
                </TableHeaderColumn> */}
                <TableHeaderColumn
                    dataField="monitor"
                    width="12rem"
                    dataFormat={(value) =>
                        value ? (`${value.first_name} ${value.last_name}`) : ''
                    }
                >
                    Monitor
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
}
