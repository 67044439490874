import React from 'react';
import { Field, Form } from 'react-final-form';
import { renderField } from '../../Utils/renderField';
import { Link } from 'react-router-dom';

const validate = (values) => {
    const errors = {};
    if (!values.username) {
        errors.username = 'Campo requerido';
    }
    if (!values.password) {
        errors.password = 'Campo requerido';
    }

    return errors;
};

const LoginForm = (props) => {
    const { onSubmit } = props;
    return (
        <Form validate={validate} onSubmit={onSubmit}>
            {({ handleSubmit, submitting, pristine }) => (
                <form
                    name="loginForm"
                    className="form-validate mb-lg m-4"
                    onSubmit={handleSubmit}
                >
                    <div className="form-group has-feedback">
                        <Field
                            name="username"
                            label="Usuario"
                            placeholder="ID"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group has-feedback">
                        <Field
                            name="password"
                            label="Contraseña"
                            placeholder="Contraseña"
                            component={renderField}
                            type="password"
                            className="form-control"
                        />
                    </div>
                    <span className="password-recovery">
                        <Link to="/password-recovery">
                            Olvidé mi contraseña
                        </Link>
                    </span>
                    <div className="buttons-box mt-4">
                        <button
                            type="submit"
                            className="btn btn-primary align-self-center w-100"
                            disabled={submitting || pristine}
                        >
                            Iniciar Sesión
                        </button>
                    </div>
                </form>
            )}
        </Form>
    );
};

export default LoginForm;
