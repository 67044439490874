export const MALE = 0;
export const FEMALE = 1;
export const OTHER = 2;
export const INACTIVE = 'inactivo';
export const ACTIVE = 'activo';
export const IMPLEMENTATION = 'implementación';
export const MONITORING = 'monitoreo';
export const TRANSFER = 'transferencia';
export const DESERTED = 'desertado';
export const GRADUATED = 'egresado';
export const NOT_ENTER = 'no ingreso';
export const ABANDONMENT= 'abandono';
export const GRADUATED_THEORETICAL='egresado teorico'
export const GRADUATED_LEVELING='egresado nivelacion'
export const GRADUATED_PRACTICE='egresado practico'
// users
export const SUPER_ADMIN = 1;
export const COORDINATOR = 2;
export const TRAINER = 3;
export const ASSISTANT = 4;
export const SCHOOL_COORDINATOR = 5;
export const SCHOOL_TRAINER = 6;
export const INVITED = 7;
export const YOUNG_BENEFICIARY = 1;
export const LEGAL_GUARDIAN = 2;
export const EDUCATIVE = 3;
export const KID_BENEFICIARY = 4;
export const VOLUNTEER = 5;
export const GROUP_MENTORING = 0;
export const INDIVIDUAL_MENTORING = 1;
export const PERSONAL_EVENT = 2;
export const SENA_EVENT = 3;
export const USER_ACTIVE = 1;
export const USER_PENDING = 2;
export const USER_INACTIVE = 3;
export const GENDER_OPTIONS = [
    { value: MALE, label: 'Hombre' },
    { value: FEMALE, label: 'Mujer' },
];
export const LEVEL_1 = 1;
export const LEVEL_2 = 2;
export const LEVEL_3 = 3;
export const LEVEL_4 = 4;
export const LEVEL_5 = 5;
export const LEVEL_6 = 6;
export const LEVEL_7 = 7;
export const LEVEL_8 = 8;
export const LEVEL_9 = 9;
export const LEVEL_10 = 10;
export const LEVEL_11 = 11;
export const LEVEL_12 = 12;
export const LEVEL_13 = 13;
export const CULMINATION = 1;
export const GRADUATION = 2;
export const CLOSING = 3;


export const THEORETICAL ='teorico';
export const PRACTICE = 'practico';
export const LEVELING= 'nivelacion';

export const STUDY_LEVEL_OPTIONS = [
    { value: 'Bachiller', label: 'Bachiller' },
    { value: 'Estudiante', label: 'Estudiante' },
    { value: 'Técnico', label: 'Técnico' },
    { value: 'Profesional', label: 'Profesional' },
    { value: 'Especialista', label: 'Especialista' },
    { value: 'Magister', label: 'Magister' },
    { value: 'Doctorado', label: 'Doctorado' },
];

export const WORK_POSITION_OPTIONS = [
    { value: 'Director', label: 'Director' },
    { value: 'Coordinador del programa', label: 'Coordinador del programa' },
    {
        value: 'Coordinador administrativo',
        label: 'Coordinador administrativo',
    },
    { value: 'Mentor', label: 'Mentor' },
    { value: 'Monitor', label: 'Monitor' },
    { value: 'Auxiliar', label: 'Auxiliar' },
    { value: 'Practicante', label: 'Practicante' },
    { value: 'Voluntario', label: 'Voluntario' },
];

export const FUNCTION_IWOKA_OPTIONS = [
    { value: 'Coordinador', label: 'Coordinador' },
    { value: 'Mentor', label: 'Mentor' },
    { value: 'Monitor', label: 'Monitor' },
];

export const COUNTRY_OPTIONS = [{ value: 'Colombia', label: 'Colombia' }];

export const WORKDAY_OPTIONS = [
    { value: 'am', label: 'Jornada Mañana' },
    { value: 'pm', label: 'Jornada Tarde' },
    { value: 'unica', label: 'Jornada Única' },
    { value: 'doble jornada', label: 'Doble Jornada' },
];

export const STATUS_SCHOOL_OPTIONS = [
    { value: IMPLEMENTATION, label: 'Implementación' },
    { value: INACTIVE, label: 'Inactivo' },
    { value: MONITORING, label: 'Monitoreo' },
    { value: TRANSFER, label: 'Transferencia' },
];

export const SIZE_SCHOOL_OPTIONS = [
    { value: '300-500', label: '300 - 500' },
    { value: '501-750', label: '501 - 750' },
    { value: '751-1000', label: '751 - 1000' },
    { value: '1001-1500', label: '1001 - 1500' },
    { value: '1501-2000', label: '1501 - 2000' },
    { value: '2001-2500', label: '2001 - 2500' },
    { value: '2501-3000', label: '2501 - 3000' },
    { value: '3001-3500', label: '3001 - 3500' },
    { value: '3501-4000', label: '3501 - 4000' },
    { value: '4001-4500', label: '4001 - 4500' },
    { value: '4501-5000', label: '4501 - 5000' },
];

export const TYPE_OPERATIONAL_USER_OPTIONS = [
    { value: SUPER_ADMIN, label: 'Super usuario' },
    { value: COORDINATOR, label: 'Líder Iwoka' },
    { value: TRAINER, label: 'Formador Iwoka' },
    { value: ASSISTANT, label: 'Auxiliar Iwoka' },
    { value: SCHOOL_COORDINATOR, label: 'Coordinador colegio' },
    { value: SCHOOL_TRAINER, label: 'Formador Colegio' },
    // { value: INVITED, label: 'Invitado' },
];

export const CATEGORY_OPTIONS = [
    { value: 'Cartillas', label: 'Cartillas' },
    { value: 'Papelería', label: 'Papelería' },
    { value: 'Fiesta', label: 'Fiesta' },
    { value: 'Marca Iwoka', label: 'Marca Iwoka' },
    { value: 'Electrónicos', label: 'Electrónicos' },
    { value: 'Muebles', label: 'Muebles' },
    { value: 'Metodología', label: 'Metodología' },
];

export const LOCATION_OPTIONS = [
    { value: 'Enviagado', label: 'Enviagado' },
    { value: 'Santa Fe', label: 'Santa Fe' },
    { value: 'Tolima', label: 'Tolima' },
    { value: 'Huila', label: 'Huila' },
];

export const TYPE_SUPPLIER_OPTIONS = [
    { value: 'proveedor', label: 'Proveedor' },
    { value: 'donante', label: 'Donante' },
];

export const UPS_OPTIONS = [
    { value: 'Eps Sura', label: 'Eps Sura' },
    { value: 'Asmet Salud', label: 'Asmet Salud' },
    { value: 'Medimás', label: 'Medimás' },
    {
        value: 'S.O.S-Servicio Occidental De Salud',
        label: 'S.O.S-Servicio Occidental De Salud',
    },
    { value: 'Salud Total', label: 'Salud Total' },
    { value: 'Nueva EPS', label: 'Nueva EPS' },
    { value: 'Eps Sánitas', label: 'Eps Sánitas' },
    { value: 'Medplús', label: 'Medplús' },
];

export const RETIREMENT_OPTIONS = [
    { value: 'Colpensiones', label: 'Colpensiones' },
    { value: 'Porvenir', label: 'Porvenir' },
    { value: 'Protección', label: 'Protección' },
    { value: 'Colfondos', label: 'Colfondos' },
    { value: 'Other', label: 'Otros' },
];

export const SEVERANCE_FOUND_OPTIONS = [
    { value: 'Porvenir', label: 'Porvenir' },
    { value: 'Protección', label: 'Protección' },
    { value: 'Colfondos', label: 'Colfondos' },
    { value: 'Fondo Nacional del Ahorro', label: 'Fondo Nacional del Ahorro' },
    { value: 'Other', label: 'Otros' },
];

export const WORK_AREA_OPTIONS = [
    { value: 'Dirección', label: 'Dirección' },
    { value: 'Operación', label: 'Operación' },
    { value: 'Administración', label: 'Administración' },
];
export const WORK_AREA_OPTIONS_SCHOOL = [
    { value: 'directivo', label: 'Directivo' },
    { value: 'administrativo', label: 'Administrativo' },
    { value: 'académico', label: 'Académico' },
];

export const SCHOOL_WORK_POSITIONS_OPTIONS = [
    { value: 'Docente Primaria', label: 'Docente Primaria' },
    { value: 'Docente Bachiller', label: 'Docente Bachiller' },
    { value: 'Administrativo', label: 'Administrativo' },
    { value: 'Servicio de Apoyo', label: 'Servicio de Apoyo' },
];

export const STATUS_BENEFICIARY_OPTIONS = [
    { value: ACTIVE, label: 'Activo' },
    { value: DESERTED, label: 'Desertado' },
    { value: NOT_ENTER, label: 'No ingresó' },
    { value: GRADUATED, label: 'Egresado' },
    { value: INACTIVE, label: 'Inactivo' },
    { value: GRADUATED_THEORETICAL, label: 'Egresado Teórico' },
];

export const STATUS_BENEFICIARY_OPTIONS_LEVELING = [
    { value: ACTIVE, label: 'Activo' },
    { value: DESERTED, label: 'Desertado' },
    { value: NOT_ENTER, label: 'No ingresó' },
    { value: GRADUATED, label: 'Egresado' },
    { value: INACTIVE, label: 'Inactivo' },
    { value: GRADUATED_LEVELING, label: 'Egresado Nivelación' },
];

export const STATUS_BENEFICIARY_PRACTICE_OPTIONS = [
    { value: ACTIVE, label: 'Activo' },
    { value: ABANDONMENT, label: 'Abandono' },
    { value: NOT_ENTER, label: 'No ingresó' },
    { value: GRADUATED, label: 'Egresado' },
    { value: INACTIVE, label: 'Inactivo' },
    { value: GRADUATED_PRACTICE, label: 'Egresado Práctico' },
];

export const LEVEL_BENEFICIARY_OPTIONS = [
    { value: LEVEL_4, label: 'Nivel I' },
    { value: LEVEL_5, label: 'Nivel II' },
    { value: LEVEL_6, label: 'Nivel III' },
];

export const LEVEL_BENEFICIARY_EDUCATIVE_OPTIONS = [
    { value: LEVEL_7, label: 'CI Nivel I' },
    { value: LEVEL_8, label: 'CI Nivel II' },
    { value: LEVEL_9, label: 'CI acompañamiento I' },
    { value: LEVEL_10, label: 'CI acompañamiento II' },
    { value: LEVEL_11, label: 'CI Actualización' },
];

export const LEVEL_BENEFICIARY_LEGAL_GUARDIAN_OPTIONS = [
    { value: LEVEL_12, label: 'Acudientes de niños' },
    { value: LEVEL_13, label: 'Acudientes de jóvenes' },
];

export const LEVEL_TRAINING_PLAN = [
    { value: LEVEL_1, label: '1º - Niños' },
    { value: LEVEL_2, label: '2º - Niños' },
    { value: LEVEL_3, label: '3º - Niños' },
    { value: LEVEL_4, label: 'Nivel I - Jóvenes' },
    { value: LEVEL_5, label: 'Nivel II - Jóvenes' },
    { value: LEVEL_6, label: 'Nivel III - Jóvenes' },
    { value: LEVEL_7, label: 'CI Nivel I' },
    { value: LEVEL_8, label: 'CI Nivel II' },
    { value: LEVEL_9, label: 'CI acompañamiento I' },
    { value: LEVEL_10, label: 'CI acompañamiento II' },
    { value: LEVEL_11, label: 'CI Actualización' },
    { value: LEVEL_12, label: 'Acudientes de niños' },
    { value: LEVEL_13, label: 'Acudientes de jóvenes' },
];

export const LEVEL_BENEFICIARY = [
    { value: LEVEL_1, label: '1º' },
    { value: LEVEL_2, label: '2º' },
    { value: LEVEL_3, label: '3º' },
    { value: LEVEL_4, label: 'Nivel I' },
    { value: LEVEL_5, label: 'Nivel II' },
    { value: LEVEL_6, label: 'Nivel III' },
];

export const SPECIAL_BONDING = [
    { value: 'nivelacion', label: 'Nivelación' },
    { value: 'servicio social', label: 'Servicio social' },
];

export const SCHOOL_HALL_OPTIONS = [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
    { value: 'd', label: 'd' },
    { value: 'e', label: 'e' },
    { value: 'f', label: 'f' },
    { value: 'g', label: 'g' },
    { value: 'h', label: 'h' },
];

export const GRADE_YOUNG_OPTIONS = [
    { value: 9, label: '9º' },
    { value: 10, label: '10º' },
    { value: 11, label: '11º' },
];

export const GRADE_KID_OPTIONS = [
    { value: LEVEL_1, label: '1º' },
    { value: LEVEL_2, label: '2º' },
    { value: LEVEL_3, label: '3º' },
];

export const RISK_BENEFICIARY_OPTIONS = [
    { value: 'violación', label: 'Violación' },
    { value: 'micro-tráfico', label: 'Micro-tráfico' },
    { value: 'prostitución', label: 'Prostitución' },
    { value: 'drogadicción', label: 'Drogadicción' },
    { value: 'alcoholismo', label: 'Alcoholismo' },
    { value: 'web-cam', label: 'Web-cam' },
    { value: 'violencia', label: 'Violencia' },
    { value: 'pandillas', label: 'Pandillas' },
    { value: 'enfermedad', label: 'Enfermedad' },
    { value: 'hambre', label: 'Hambre' },
    { value: 'higiene', label: 'Higiene' },
];

export const TYPE_DEVICE_OPTIONS = [
    { value: 'celular', label: 'Celular' },
    { value: 'computador', label: 'Computador' },
    { value: 'tablet', label: 'Tablet' },
    { value: 'celular, computadora, tablet', label: 'Todos los anteriores' },
    { value: 'otro', label: 'Otro' },
];

export const TYPE_NETWORK_OPTIONS = [
    { value: 'wifi', label: 'Wifi' },
    { value: 'datos wp / fb', label: 'Datos wp / fb' },
    { value: 'datos navegación', label: 'Datos navegación' },
];

export const ONE_TIME = 0;
export const EVERY_DAY = 1;
export const EVERY_WEEK = 2;
export const EVERY_MONTH = 3;

export const TYPE_EVENT_OPTIONS = [
    { value: ONE_TIME, label: 'No repetir' },
    { value: EVERY_DAY, label: 'Cada dia' },
    { value: EVERY_WEEK, label: 'Cada semana' },
    { value: EVERY_MONTH, label: 'Cada mes' },
];

export const TYPE_REPORTING_MODULES = [
    { value: 'CALENDAR', label: 'Calendario' },
    { value: 'BENEFICIARY', label: 'Beneficiarios' },
    { value: 'OPERATIONAL_USERS', label: 'Usuarios operativos' },
    { value: 'SESSION', label: 'Sesiones' },
    { value: 'BENEFICIARY_GROUPS', label: 'Grupo de beneficiarios' },
    { value: 'FORMATION_PLANS', label: 'Planes de formación' },
    { value: 'TRAINING_GROUPS', label: 'Grupos de formación' },
    { value: 'SCHOOLS', label: 'Colegios' },
    { value: 'INVENTORY', label: 'Inventario' },
];

export const TYPE_REPORTING_MODULES_SCHOOL = [
    { value: 'CALENDAR', label: 'Calendario' },
    { value: 'BENEFICIARY', label: 'Beneficiarios' },
    { value: 'SESSION', label: 'Sesiones' },
    { value: 'BENEFICIARY_GROUPS', label: 'Grupo de beneficiarios' },
    { value: 'FORMATION_PLANS', label: 'Planes de formación' },
    { value: 'TRAINING_GROUPS', label: 'Grupos de formación' },
    { value: 'SCHOOLS', label: 'Colegios' },
    { value: 'INVENTORY', label: 'Inventario' },
];

export const USER_STATUS_OPTIONS = [
    { value: USER_ACTIVE, label: 'Activo' },
    { value: USER_PENDING, label: 'Por activar' },
    { value: USER_INACTIVE, label: 'Inactivo' },
];

export const INITIAL_DATA = {
    results: [],
    count: 0,
};

export const TYPE_BENEFICIARY_OPTIONS = [
    { value: YOUNG_BENEFICIARY, label: 'Joven' },
    { value: KID_BENEFICIARY, label: 'Niño' },
    { value: LEGAL_GUARDIAN, label: 'Acudiente' },
    { value: EDUCATIVE, label: 'Docente' },
    { value: VOLUNTEER, label: 'Voluntario/a' },
];
export const SCHOOL_USER_TYPES = [SCHOOL_COORDINATOR, SCHOOL_TRAINER];
export const IWOKA_USER_TYPES = [SUPER_ADMIN, COORDINATOR, TRAINER, ASSISTANT];

export const CONNECTION_TYPE = [
    { value: 'VIRTUAL', label: 'Virtual' },
    { value: 'PRESENCIAL', label: 'Presencial' },
    { value: 'ADICIONAL', label: 'Adicional' },
    { value: 'CANCELADA', label: 'Cancelada' },
];

export const DESERTED_OPTIONS = [
    { value: 'Cambio de colegio', label: 'Cambio de colegio' },
    { value: 'Cambio de ciudad', label: 'Cambio de ciudad' },
    { value: 'Cambio de jornada', label: 'Cambio de jornada' },
    { value: 'Desertó del sistema educativo', label: 'Desertó del sistema educativo' },
];

export const DESERTED_OPTIONS_PRACTICE = [
    { value: 'Cuidado de familiar', label: 'Cuidado de familiar' },
    {
        value: 'Dificultad transporte/conexión',
        label: 'Dificultad transporte/conexión',
    },
    { value: 'Mal académicamente', label: 'Mal académicamente' },
    { value: 'No le gusta el trabajo con niños', label: 'no le gusta el trabajo con niños' },
    { value: 'No le gusta la formación de iwoka', label: 'No le gusta la formación de iwoka' },
    { value: 'Cruce con otra actividad del colegio', label: 'Cruce con otra actividad del colegio' },
    { value: 'Los papás no lo dejan', label: 'Los papás no lo dejan' },
];

export const DESERTED_OPTIONS_LEVELING = [
    { value: 'Cambio de colegio', label: 'Cambio de colegio' },
    { value: 'Cambio de ciudad', label: 'Cambio de ciudad' },
    { value: 'Cambio de jornada', label: 'Cambio de jornada' },
    { value: 'Desertó del sistema educativo', label: 'Desertó del sistema educativo' },
];

export const YES = 1;
export const HALF_SESSION = 2;
export const DOBLE_SESSION = 3;
export const NO_REASON = 4;
export const ASYNCHRONOUS = 5;
export const DOMESTIC_CALAMITY = 6;
export const PUNISHMENT = 7;
export const MEDICAL_APPOINTMENT = 8;
export const WEATHER_CONDITION = 9;
export const CONNECTION_DIFFICULTY = 10;
export const MOBILITY_DIFFICULTY = 11;
export const FAMILY_COMMISSION = 12;
export const DISEASE = 13;
export const STUDY = 14;
export const EXCUSE = 15;
export const NOT_lIKE_CHILDREN = 16;
export const WORKED = 17;

export const TYPE_ASSISTANCE_OPTIONS = [
    { value: YES, label: 'Si' },
    { value: NO_REASON, label: 'No da razón' },
    { value: DOMESTIC_CALAMITY, label: 'Problemas de salud' },
    { value: PUNISHMENT, label: 'Dificultad de conexión' },
    { value: MEDICAL_APPOINTMENT, label: 'Encargo familiar' },
    { value: CONNECTION_DIFFICULTY, label: 'Carga académica' },
    { value: MOBILITY_DIFFICULTY, label: 'Trabajo' },
    { value: FAMILY_COMMISSION, label: 'Cruce con otra actividad del colegio' },
    { value: ASYNCHRONOUS, label: 'Asincrónica' },
];

export const PROGRAM_OPTIONS=[
    {value: THEORETICAL,label:'Teórico'},
    {value: PRACTICE,label:'Práctico'},
    {value: LEVELING,label:'Nivelación'},
 ]