import React, { useContext, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { api } from 'api';
import { NotificationManager } from 'react-notifications';
import {
    renderAsyncSelectField,
    renderField,
} from '../../../Utils/renderField/renderField';
import {
    required,
    composeValidators,
    maxLength,
} from '../../../../../utility/validation';
import { DateTime } from 'luxon';
import UserContext from '../../../../context/UserContext';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import { PERSONAL_EVENT } from '../../../../../utility/constants';

const EventPersonalForm = (props) => {
    const { user } = useContext(UserContext);
    const { onSubmit, initialValues } = props;
    const { asyncOptions: asyncUser } = useAsyncOptions('user', {
        exclude_id: user.id,
    });

    const getEventDate = (start, end) => {
        const date = DateTime.fromJSDate(start).toLocaleString(
            DateTime.DATE_FULL
        );
        const hourStart = DateTime.fromJSDate(start)
            .setLocale('en')
            .toLocaleString(DateTime.TIME_SIMPLE);

        const hourEnd = DateTime.fromJSDate(end)
            .setLocale('en')
            .toLocaleString(DateTime.TIME_SIMPLE);
        return `${date}   ${hourStart} - ${hourEnd}`;
    };

    return (
        <Form
            initialValues={{ type: PERSONAL_EVENT, ...initialValues }}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, submitting, pristine, values }) => {
                return (
                    <form
                        name="loginForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        <div className="m-2 my-4">
                            <p className="m-0 font-size-4 mb-3">
                                Horario {getEventDate(values.start, values.end)}
                            </p>
                        </div>
                        <div className="form-group has-feedback m-2">
                            <label htmlFor="title">Titulo</label>
                            <Field
                                name="title"
                                label="identification"
                                placeholder="Añade un titulo"
                                component={renderField}
                                type="text"
                                validate={composeValidators(
                                    required,
                                    maxLength(150)
                                )}
                            />
                        </div>
                        <div className="form-group has-feedback m-2 my-3 flex-1">
                            <label htmlFor="description">Descripción</label>
                            <Field
                                name="description"
                                placeholder="Añade descripción"
                                component={renderField}
                                type="text"
                                validate={required}
                                className="form-control"
                            />
                        </div>

                        <div className="form-group has-feedback m-2 my-3 flex-1">
                            <label htmlFor="participants">Participantes</label>
                            <Field
                                name="users"
                                component={renderAsyncSelectField}
                                modal={true}
                                loadOptions={asyncUser}
                                placeholder="Añade participantes"
                                isSearchable={true}
                                isMulti={true}
                                labelKey="first_name"
                                labelKey2="last_name"
                                valueKey="id"
                                type="text"
                                className="w-100"
                            />
                        </div>

                        <div className="d-flex my-4 justify-content-center">
                            <button
                                type="submit"
                                className="btn btn-primary m-1 align-self-center"
                                disabled={submitting}
                            >
                                Guardar
                            </button>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default EventPersonalForm;
