import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Title.css';

const uniqueId = () =>
    Date.now().toString(36) + Math.random().toString(36).substring(2);

const TitleUnderline = ({
    title,
    color = '#ef5543',
    className = '',
    idTitle = 'title-' + uniqueId(),
    blue,
    calculationByCharacters = false,
}) => {
    const loadTitle = () => {
        const element = document.querySelector(`#${idTitle}`);
        if (element) {
            element.style.removeProperty('--ww', 0);
            const characters = element.textContent.length * 11;
            const width_add = 30;
            let element_width = element.offsetWidth + width_add;
            if (calculationByCharacters) {
                element_width = parseInt(characters) + parseInt(width_add);
            }
            element.style.setProperty('--ww', `${element_width}px`);
            element.style.setProperty('--color', color);
            if (!!blue) element.classList.add('blue');
        }
    };
    useEffect(() => {
        window.onload = () => {
            loadTitle();
        };
        loadTitle();
    }, []);
    return (
        <h1 className={`title-underline ${className}`} id={idTitle}>
            {title}
        </h1>
    );
};
TitleUnderline.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
    calculationByCharacters: PropTypes.bool,
};

export default TitleUnderline;
