import React, { useRef } from 'react';
import { Radio } from 'antd';

import './RadioButton.css';

const RadioButton = ({
    options = [],
    value = 1,
    setValue,
    disabled = false,
    className = '',
    alignRow = false,
    setValue2 = '',
    onChange = '',
}) => {
    if (!onChange) {
        onChange = (e) => {
            if (e.target.value) {
                setValue(parseInt(e.target.value));

                // set empty, only used in inventory issue or transfer
                if (window.setFormTransfer) {
                    window.setFormTransfer('product', '');
                }
                if (window.setFormIssue) {
                    window.setFormIssue('product', '');
                }

                // if the setValue2 exists, only used in transfers
                if (setValue2) {
                    // Set the value to the other select input of location in inventory
                    setValue2(parseInt(e.target.value));
                    // empty the select inputs, when changing the option of the radio button
                    window.setFormTransfer('location_destination', '');
                    window.setFormTransfer('location', '');
                }
            }
        };
    }
    return (
        <div>
            <div className={`d-flex ${className}`}>
                {options.map((option, i) => (
                    <div
                        key={i}
                        className={`radio-main ${
                            alignRow !== false
                                ? 'flex-row align-items-start'
                                : ''
                        }`}
                        onClick={onChange}
                    >
                        <label htmlFor={`radio${i}`}>{option}</label>
                        <label className="radio-container">
                            {' '}
                            <input
                                type="radio"
                                defaultChecked={i + 1 == value ? 'checked' : ''}
                                name="radio"
                                value={i + 1}
                                id={`radio${i}`}
                            />
                            <span className="checkmark" />
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RadioButton;
