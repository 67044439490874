import { api } from 'api';

export default function useUpdateEvents() {
    const updateEvents = async () => {
        if ('serviceWorker' in navigator) {
            await navigator.serviceWorker.ready.then(async () => {
                await new Promise((r) => setTimeout(r, 5000));
                await api.get('offline/sync');
                const events = api.get('offline/events');
                const schools = api.get('offline/schools');
                const youths = api.get('offline/youths');
                Promise.all([events, schools, youths]);
            });
        }
    };
    return { updateEvents };
}
