import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import {
    composeValidators,
    date,
    minValue,
    required,
} from '../../../../../../utility/validation';
import useAsyncOptions from '../../../../../hooks/useAsyncOptions';
import {
    renderAsyncSelectField,
    renderDatePicker,
    renderField,
} from '../../../../Utils/renderField/renderField';
import _ from 'lodash';

// Modal
import Supplier from '../../Supplier';
import Location from '../../Location';
const BodyForm = ({ inputEditable, values }) => {
    const { asyncOptions: getProducts } = useAsyncOptions('product');
    const { asyncOptions: getSuppliers } = useAsyncOptions('supplier');
    const { asyncOptions: getLocations } = useAsyncOptions('location');

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-md-row">
                <div className="form-group d-flex has-feedback m-2 flex-1">
                    <div className="flex-1">
                        <label htmlFor="supplier">Proveedor o Donante</label>
                        <Field
                            name="supplier"
                            label="Seleccione Proveedor o Donante"
                            component={renderAsyncSelectField}
                            isSearchable={true}
                            isClearable={true}
                            loadOptions={getSuppliers}
                            valueKey="id"
                            labelKey="name"
                            labelKey2=""
                            type="text"
                            validate={required}
                            disabled={inputEditable}
                            className="w-100"
                        />
                    </div>
                    <Supplier.ModalForm />
                </div>
                <div className="form-group d-flex has-feedback m-2 flex-1">
                    <div className="flex-1">
                        <label htmlFor="location">Ubicación</label>
                        <Field
                            name="location"
                            label="Seleccione Ubicación"
                            component={renderAsyncSelectField}
                            isSearchable={true}
                            isClearable={true}
                            loadOptions={getLocations}
                            valueKey="id"
                            labelKey="name"
                            labelKey2=""
                            type="text"
                            validate={required}
                            disabled={inputEditable}
                            className="w-100"
                        />
                    </div>
                    <Location.ModalForm />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="date">Fecha de la entrada</label>
                    <Field
                        name="date"
                        label="Fecha de la entrada"
                        component={renderDatePicker}
                        type="text"
                        validate={composeValidators(required, date)}
                        disabled={true}
                        className="form-control w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1"></div>
            </div>
            <div className="d-flex flex-column flex-md-row mt-md-4">
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="product">Seleccione Artículo</label>
                    <Field
                        name="product"
                        label="Seleccione Producto"
                        component={renderAsyncSelectField}
                        isSearchable={true}
                        isClearable={true}
                        loadOptions={getProducts}
                        valueKey="id"
                        labelKey="description"
                        labelKey2="code"
                        type="text"
                        validate={required}
                        className="w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="quantity">Cantidad</label>
                    <Field
                        name="quantity"
                        component={renderField}
                        placeholder="Ingrese la cantidad del producto"
                        type="number"
                        className="form-control w-100"
                        validate={composeValidators(required, minValue(1))}
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    {_.get(values, 'supplier.type', undefined) ===
                        'proveedor' && (
                        <div>
                            <label htmlFor="price">Precio unitario</label>
                            <Field
                                name="price"
                                component={renderField}
                                placeholder="Ingrese el precio del producto"
                                type="number"
                                className="form-control w-100"
                                validate={composeValidators(
                                    required,
                                    minValue(1)
                                )}
                            />
                        </div>
                    )}
                </div>
                <div className="form-group has-feedback m-2 flex-1 d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary mt-4"
                        onClick={() => {}}
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BodyForm;
