import React from 'react';
import ModalForm from '../../../Utils/Modal';
import Body from './Body';

const SelectInventoryProduct = ({
    openModal,
    setOpenModal,
    dataModal,
    radio,
    setRadio,
    onClick,
}) => {
    return (
        <React.Fragment>
            <ModalForm
                title={'Seleccione ubicación para autorellenar'}
                Body={
                    <Body
                        dataModal={dataModal}
                        radio={radio}
                        setRadio={setRadio}
                        onCloseModal={() => setOpenModal(false)}
                        onClick={onClick}
                    />
                }
                openModal={openModal}
                setOpenModal={setOpenModal}
                onCloseModal={() => setOpenModal(false)}
                formData={[]}
                onSubmit={() => {}}
            />
        </React.Fragment>
    );
};

export default SelectInventoryProduct;
