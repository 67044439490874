import React, { useRef, useState } from 'react';

import CheckboxFilters from './CheckboxFilters';
import Filters from './Filters';

const FilterInputs = ({
    setData,
    reportData,
    today,
    initial_data,
    getData,
    setReportData,
}) => {
    const [filterFields, setFilterFields] = useState([]);

    const btnReset = useRef();

    const onSubmit = async (dataForm) => {
        const body = { ...dataForm };
        setReportData({ ...reportData, fields: body });
        await getData(1, { ...reportData, fields: body });
    };
    return (
        <React.Fragment>
            <div className="form-group" style={{ marginBottom: '-20px' }}>
                <label>Filtros</label>
            </div>
            <Filters
                today={today}
                btnReset={btnReset}
                reportData={reportData}
                initial_data={initial_data}
                setData={setData}
                setFilterFields={setFilterFields}
                setReportData={setReportData}
            />

            <CheckboxFilters
                filterFields={filterFields}
                btnReset={btnReset}
                onSubmit={onSubmit}
            />
        </React.Fragment>
    );
};

export default FilterInputs;
