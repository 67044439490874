import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HashRouter as Router } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import LoaderContext from './common/context/LoaderContext';
import MissingProductsContext from './common/context/MissingProductsContext';
import OfflineContext from './common/context/OfflineContext';
import useServiceWorker from './common/hooks/useServiceWorker';
import UserContext from './common/context/UserContext';
import EventFiltersContext from './common/context/EventFiltersContext';
import { NotificationManager } from 'react-notifications';
import _ from 'lodash';

export default function Root(props) {
    const [loader, setLoader] = useState(false);
    const loaderValue = useMemo(() => ({ loader, setLoader }), [loader]);
    const serviceWorker = useServiceWorker();

    const [eventFilters, setEventFilters] = useState({ mentoring_group: true });
    const filterValue = useMemo(
        () => ({ eventFilters, setEventFilters }),
        [eventFilters]
    );

    const [missingProducts, setMissingProducts] = useState([]);
    const missing = useMemo(
        () => ({ missingProducts, setMissingProducts }),
        [missingProducts]
    );

    const [offline, setOffline] = useState(false);
    const offlineValue = useMemo(() => ({ offline, setOffline }), [offline]);

    const [user, setUser] = useState({});
    const userValue = useMemo(() => ({ user, setUser }), [user]);

    const settingOffline = (offline) => {
        setOffline((s) => {
            if (_.isEqual(s, offline)) return offline;
            if (offline) {
                NotificationManager.info(
                    'Modo offline se ha activado, las funciones a realizar estarán limitadas.',
                    '',
                    5000
                );
            } else {
                NotificationManager.info(
                    'Estas en linea de nuevo, se habilitaran todas las funciones habituales.',
                    '',
                    5000
                );
            }
            return offline;
        });
    };

    const addListeners = () => {
        window.addEventListener('online', () => {
            settingOffline(false);
        });
        window.addEventListener('offline', () => {
            settingOffline(true);
        });
    };
    const removeListeners = () => {
        window.addEventListener('online', () => {
            settingOffline(false);
        });
        window.addEventListener('offline', () => {
            settingOffline(true);
        });
    };
    useEffect(() => {
        addListeners();
        return removeListeners();
    }, []);

    function content() {
        return <Router>{props.routes}</Router>;
    }

    return (
        <OfflineContext.Provider value={offlineValue}>
            <LoaderContext.Provider value={loaderValue}>
                <UserContext.Provider value={userValue}>
                    <MissingProductsContext.Provider value={missing}>
                        <EventFiltersContext.Provider value={filterValue}>
                            {content()}
                        </EventFiltersContext.Provider>
                    </MissingProductsContext.Provider>
                </UserContext.Provider>
            </LoaderContext.Provider>
        </OfflineContext.Provider>
    );
}

Root.propTypes = {
    routes: PropTypes.element.isRequired,
};
