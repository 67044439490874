import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import eraseIcon from '../../../../../assets/img/delete.png';
import viewIcon from '../../../../../assets/img/view.png';
import updateIcon from './../../../../../assets/img/update.png';
import { lowerCase } from 'lodash';
import useDayjsUtils, { DEFAULT_TIMEZONE } from '../../../hooks/useDayjsUtils';
import { COORDINATOR, CULMINATION, GRADUATION , CLOSING} from '../../../../utility/constants';
import { NotificationManager } from 'react-notifications';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN
} from '../../../../utility/constants'
import dayjs from 'dayjs';

class Actions extends Component {
    constructor(props) {
        super(props);
        const { toTimeTz, toDateTz, changeTimeZone, addDaysToDate } =
            useDayjsUtils();
        this.convertiHora = toTimeTz;
        this.cambiarZonaHoraria = changeTimeZone;
        this.addDay = addDaysToDate;
        this.getDate = toDateTz;
    }

    verifySession(session) {
        const [day, month, year] = session.start_day.split('/');
        const fecha = session.fecha_action
            ? `${year}-${month}-${day}`
            : session.start_day;
        session.start_day = dayjs(fecha).format('YYYY-MM-DD');
        const events = Array.from([...this.props.sessions] || []);
        let exists_date = false;
        const has_culmination = _.find(this.props.sessions, (item) =>
            [CULMINATION, GRADUATION,CLOSING].includes(item.type_session)
        );

        if (has_culmination && !!session.type_session) {
            NotificationManager.error(
                'Ya existe una sesión de culminación o graduación activa.',
                'Error',
                5000
            );
            return false;
        }

        events.forEach((event) => {
            const event_day = dayjs(event.start).format('YYYY-MM-DD');
            if (event_day === session.start_day) {
                exists_date = true;
            }
        });

        if (!!exists_date) {
            NotificationManager.error(
                'En la fecha seleccionada ya se agregó anteriormente otra sesión. Se sugiere correr las fechas',
                'Advertencia Fecha ocupada',
                5000
            );
            return true;
        }
        return true;
    }

    validateHour(h, m) {
        return h > 0 && h <= 12 && m >= 0 && m <= 59;
    }

    formatHour(h, fm) {
        if (lowerCase(fm) == 'am') {
            switch (h) {
                case '12':
                    return '00';
                default:
                    return h;
            }
        } else {
            switch (h) {
                case '01':
                    return '13';
                case '02':
                    return '14';
                case '03':
                    return '15';
                case '04':
                    return '16';
                case '05':
                    return '17';
                case '06':
                    return '18';
                case '07':
                    return '19';
                case '08':
                    return '20';
                case '09':
                    return '21';
                case '10':
                    return '22';
                case '11':
                    return '23';
                default:
                    return h;
            }
        }
    }

    update = (id) => {
        return () => {
            // Data
            let reschedule = false;
            let fecha_sumit;
            let fecha_action = true;
            let start_submit;
            let start_action = true;
            let finish_submit;
            let finish_action = true;

            const reagenda = () => {
                const { reagendacion: text } = Swal.fire({
                    title: 'Reagendar fechas posteriores',
                    html: `
                   <dl>
                    <dt>¿Desea reagendar también las fechas posteriores?</dt>
                    <dd>Se realizara un ajuste para fechas de feriado.</dd>
                   </dl>
                   `,
                    confirmButtonText: 'SI',
                    cancelButtonText: 'NO',
                    showCancelButton: true,
                }).then((response) => {
                    if (response.isConfirmed) {
                        reschedule = true;
                        const data = {
                            fecha: fecha_sumit,
                            fecha_action: fecha_action,
                            inicio: start_submit,
                            start_action: start_action,
                            final: finish_submit,
                            finish_action: finish_action,
                            reschedule: reschedule,
                        };
                        this.props.update_session(id, data);
                    } else {
                        const isValid =
                            fecha_action &&
                            this.verifySession({
                                start_day: fecha_sumit,
                                start_time: start_submit,
                                end_time: finish_submit,
                                fecha_action,
                            });

                        if (isValid) {
                            const data = {
                                fecha: fecha_sumit,
                                fecha_action: fecha_action,
                                inicio: start_submit,
                                start_action: start_action,
                                final: finish_submit,
                                finish_action: finish_action,
                                reschedule: reschedule,
                            };
                            this.props.update_session(id, data);
                        } else if (start_action || finish_action) {
                            const data = {
                                fecha: fecha_sumit,
                                fecha_action: false,
                                inicio: start_submit,
                                start_action: start_action,
                                final: finish_submit,
                                finish_action: finish_action,
                                reschedule: reschedule,
                            };
                            this.props.update_session(id, data);
                        } else {
                            const data = {
                                fecha: fecha_sumit,
                                fecha_action: false,
                                inicio: start_submit,
                                start_action: false,
                                final: finish_submit,
                                finish_action: false,
                                reschedule: false,
                            };
                            this.props.update_session(id, data);
                        }
                    }
                });
            };

            // Hora Final
            const hourFinal = (id) => {
                const { datestart: text } = Swal.fire({
                    title: 'Editar Hora Final',
                    input: 'text',
                    inputPlaceholder: 'HH:MM FM',
                    inputValidator: (datestart) => {
                        if (datestart) {
                            if (
                                !/^[0-9]{2}[:][0-9]{2}[\s][A a P p][M m]$/i.test(
                                    datestart
                                ) &&
                                datestart.length !== 8
                            ) {
                                return 'Formato de Hora Invalido';
                            }
                            datestart.replace(/[\s]/g, ':');
                            // Fecha inicio
                            const [day, month, year] = fecha_sumit.split('/');
                            // Hora Inicio
                            const [hour, minute, format1] =
                                start_submit.split(':');
                            // Wrap fecha inicio
                            const datetest = new Date(
                                +year,
                                +month - 1,
                                +day,
                                +this.formatHour(hour, format1),
                                +minute
                            );
                            // Hora Final
                            const [hour2, minute2, formato] =
                                datestart.split(':');
                            // Wrap fecha final
                            const datetest2 = new Date(
                                +year,
                                +month - 1,
                                +day,
                                +this.formatHour(hour2, formato),
                                +minute2
                            );

                            if (
                                !this.validateHour(
                                    parseInt(hour2),
                                    parseInt(minute2)
                                )
                            )
                                return 'Hora Invalida';

                            if (datetest2 < datetest)
                                return 'La hora no puede ser menor a la de inicio';
                        } else {
                            return 'Debes Ingresar una Hora de Inicio';
                        }
                    },
                    showCancelButton: true,
                })
                    .then((response) => {
                        if (response.isConfirmed) {
                            const [hour, minute, format] = response.value
                                .replace(/[\s]/g, ':')
                                .split(':');
                            const h = this.formatHour(hour, format);
                            finish_submit = `${h}:${minute}:${format}`;
                        } else {
                            finish_action = false;
                            finish_submit = this.convertiHora(
                                this.props.row.end
                            ).replace(/[\s]/g, ':');
                            const [hour, minute, format] =
                                finish_submit.split(':');
                            const h = this.formatHour(hour, format);
                            finish_submit = `${h}:${minute}:${format}`;
                        }
                    })
                    .finally(() => {
                        reagenda();
                    });
            };

            const hourStart = (id) => {
                const { datestart: text } = Swal.fire({
                    title: 'Editar Hora Inicio',
                    input: 'text',
                    inputPlaceholder: 'HH:MM FM',
                    inputValidator: (datestart) => {
                        if (datestart) {
                            if (
                                !/^[0-9]{2}[:][0-9]{2}[\s][A a P p][M m]$/i.test(
                                    datestart
                                ) &&
                                datestart.length !== 8
                            )
                                return 'Formato de Hora Invalido';
                            datestart.replace(/[\s]/g, ':');
                            const [hour, minute, formato] =
                                datestart.split(':');
                            if (
                                !this.validateHour(
                                    parseInt(hour),
                                    parseInt(minute)
                                )
                            )
                                return 'Hora Invalida';
                        } else {
                            return 'Debes Ingresar una Hora de Inicio';
                        }
                    },
                    showCancelButton: true,
                })
                    .then((response) => {
                        if (response.isConfirmed) {
                            const [hour, minute, format] = response.value
                                .replace(/[\s]/g, ':')
                                .split(':');
                            const h = this.formatHour(hour, format);
                            start_submit = `${h}:${minute}:${format}`;
                        } else {
                            start_action = false;
                            start_submit = this.convertiHora(
                                this.props.row.start
                            ).replace(/[\s]/g, ':');
                            const [hour, minute, format] =
                                start_submit.split(':');
                            const h = this.formatHour(hour, format);
                            start_submit = `${h}:${minute}:${format}`;
                        }
                    })
                    .finally(() => {
                        hourFinal(id);
                    });
            };

            const dateStart = (id) => {
                const { datestart: text } = Swal.fire({
                    title: 'Editar Fecha Inicio',
                    input: 'text',
                    inputPlaceholder: 'DD-MM-YYYY',
                    inputValidator: (datestart) => {
                        if (datestart) {
                            const regex =
                                /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[-](?:0?[1-9]|1[0-2])|(?:29|30)[-](?:0?[13-9]|1[0-2])|31[-](?:0?[13578]|1[02]))[-](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[-]0?2[-](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
                            const validacion = regex.test(datestart);
                            if (!(validacion && datestart.length == 10))
                                return 'Formato de Fecha Invalido.';

                            const [day, month, year] = datestart.split('-');
                            const daterow = this.cambiarZonaHoraria(
                                new Date(+year, +month - 1, +day),
                                'America/Bogota'
                            );
                            const datenow = this.cambiarZonaHoraria(
                                this.addDay(new Date(), -30),
                                'America/Bogota'
                            );
                            daterow.setHours(0, 0, 0, 0);
                            datenow.setHours(0, 0, 0, 0);
                            // if (daterow < datenow)
                            //     return 'No puedes reagendar más de un mes atrás.';
                        } else {
                            return 'Debes ingresar la fecha de inicio.';
                        }
                    },
                    showCancelButton: true,
                })
                    .then((response) => {
                        if (response.isConfirmed)
                            fecha_sumit = response.value.replace(/[-]/g, '/');
                        else {
                            const fechacurrent = this.cambiarZonaHoraria(
                                new Date(this.props.row.start),
                                'America/Bogota'
                            );
                            fecha_action = false;
                            fecha_sumit = fechacurrent
                                .toISOString()
                                .split('T')[0]
                                .replace(/[-]/g, '/')
                                .toString();
                        }
                    })
                    .finally(() => {
                        hourStart(id);
                    });
            };

            // Iniciar promesas en cadena
            dateStart(id);
        };
    };

    erase = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?' + id,
                text: '¡No podrá revertir esta acción!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                allowEnterKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.erase(id);
                }
            });
        };
    };

    erase_holiday = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?' + id,
                text: '¡No podrá revertir esta acción!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                allowEnterKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.erase_holiday(id);
                }
            });
        };
    };

    erase_session = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?' + id,
                text: '¡No podrá revertir esta acción!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                allowEnterKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.erase_session(id);
                }
            });
        };
    };

    erase_session_group = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?' + id,
                text: '¡No podrá revertir esta acción!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                allowEnterKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.erase_session_group(id);
                }
            });
        };
    };

    erase_module = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?' + id,
                text: '¡No podrá revertir esta acción!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                allowEnterKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.erase_module(id);
                }
            });
        };
    };

    erase_inventary = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?' + id,
                text: '¡No podrá revertir esta acción!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                allowEnterKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.erase_inventary(id);
                }
            });
        };
    };

    deleteKit = (id) => {
        return () => {
            Swal.fire({
                title: '',
                text: 'Si elimina el kit, se eliminará del inventario, y los articulos de dicho kit, regresarán a ser articulos del inventario.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.deleteKit(id);
                }
            });
        };
    };

    annul = (id, row) => {
        return () => {
            Swal.fire({
                icon: 'warning',
                title: 'Anular Registro',
                text: 'No podrá revertir esta acción',
                type: 'warning',
                input: 'textarea',
                inputPlaceholder: 'Describa el motivo de la anulación',
                inputValidator: (value) => {
                    return !value && 'Debes detallar el motivo de la anulación';
                },
                cancelButtonText: 'Cancelar',
                reverseButtons: true,
                inputAttributes: {
                    autocapitalize: 'off',
                },
                showCancelButton: true,
                confirmButtonText: 'Continuar',
            }).then((result) => {
                // // console.log(result);
                if (result.value) {
                    if (row.is_annulled) {
                        Swal.fire(
                            'Error',
                            'No puedes anular un registro ya anulado',
                            'error'
                        );
                    } else {
                        this.props.annul(id, result.value);
                    }
                }
            });
        };
    };

    eraseHistorySession = (row) => {
        return () => {
            const { id, start, end, code, title } = row;
            const date = dayjs(start)
                .tz(DEFAULT_TIMEZONE)
                .format('DD-MM-YYYY hh:mm A');

            Swal.fire({
                title: '¿Está seguro de que desea eliminar el registro de asistencia de esta sesión?',
                html: `<strong>Codigo:</strong> ${code} <br> <strong>Nombre sesión</strong> ${title} <br> <strong>Fecha:</strong> ${date}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                allowEnterKey: false,
            }).then((result) => {
                if (result.value) {
                    this.props.eraseHistorySession(row.id);
                }
            });
        };
    };

    render() {
        const {
            id,
            row,
            view,
            update,
            update_beneficiary,
            update_session,
            update_session_list,
            update_holiday,
            update_training,
            update_product,
            update_traing_group,
            update_module,
            update_school,
            update_inventary,
            erase,
            erase_session_group,
            erase_session,
            erase_holiday,
            erase_module,
            eraseHistorySession,
            erase_inventary,
            deleteKit,
            annul,
            type,
        } = this.props;

        return (
            <div className="d-flex justify-content-center">
                {view !== undefined && (
                    <Link to={`${view}/${id}/view`} className="px-2">
                        <i className="material-icons">
                            <img src={viewIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {update !== undefined && (
                    <Link
                        className="text-warning"
                        to={`${update}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {update_beneficiary !== undefined && type !== ASSISTANT && (
                    <Link
                        className="text-warning"
                        to={`${update_beneficiary}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {(update_product !== undefined && (type == SUPER_ADMIN || type == ASSISTANT)) && (
                    <Link
                        className="text-warning"
                        to={`${update_product}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {(update_holiday !== undefined && (type == SUPER_ADMIN || type == ASSISTANT || type == COORDINATOR))&&(
                    <Link
                        className="text-warning"
                        to={`${update_holiday}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {(update_module !== undefined && (type === SUPER_ADMIN || type === COORDINATOR)) && (
                    <Link
                        className="text-warning"
                        to={`${update_module}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {update_session !== undefined && (
                    <a
                        className="text-warning"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.update(id)}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </a>
                )}
                {(update_session_list !== undefined && (type === SUPER_ADMIN || type === COORDINATOR)) && (
                    <Link
                        className="text-warning"
                        to={`${update_session_list}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {(update_training !== undefined && (type === SUPER_ADMIN || type === COORDINATOR)) &&  (
                    <Link
                        className="text-warning"
                        to={`${update_training}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {update_traing_group !== undefined && type !== ASSISTANT  &&  (
                    <Link
                        className="text-warning"
                        to={`${update_traing_group}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {(update_school !== undefined && (type !== ASSISTANT && type !== SCHOOL_TRAINER)) &&  (
                    <Link
                        className="text-warning"
                        to={`${update_school}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {(update_inventary !== undefined && (type == SUPER_ADMIN || type == ASSISTANT)) &&  (
                    <Link
                        className="text-warning"
                        to={`${update_inventary}/${id}/update`}
                    >
                        <i className="material-icons">
                            <img src={updateIcon} className="icon" />
                        </i>
                    </Link>
                )}
                {erase !== undefined && type == SUPER_ADMIN && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.erase(id)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}
                {(erase_holiday !== undefined && (type == SUPER_ADMIN || type == ASSISTANT)) &&  (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.erase_holiday(id)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}
                {erase_session_group !== undefined && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.erase_session_group(id)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}
                {(erase_session !== undefined && (type === SUPER_ADMIN || type === COORDINATOR)) && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.erase_session(id)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}
                {(erase_module !== undefined && (type === SUPER_ADMIN || type === COORDINATOR)) && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.erase_module(id)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}
                {(erase_inventary !== undefined && (type === SUPER_ADMIN || type === ASSISTANT)) && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.erase_inventary(id)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}

                {eraseHistorySession !== undefined && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.eraseHistorySession(id)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}

                {(deleteKit && (type === SUPER_ADMIN || type === ASSISTANT)) && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.deleteKit(id)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}
                {(annul && (type === SUPER_ADMIN || type === ASSISTANT)) && (
                    <a
                        className="px-2"
                        style={{ cursor: 'pointer', color: '#c4183c' }}
                        onClick={this.annul(id, row)}
                    >
                        <i className="material-icons">
                            <img src={eraseIcon} className="icon" />
                        </i>
                    </a>
                )}
            </div>
        );
    }
}
Actions.propTypes = {};

function standardActions(actions) {
    return (cell, row) => {
        return <Actions id={cell} row={row} {...actions} />;
    };
}

export { Actions, standardActions };
