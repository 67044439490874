import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = (props) => {
    const [checked, setChecked] = React.useState(false);

    const toggleChange = () => {
        if (!checked) {
            props.onChange(true);
            setChecked(true);
        } else {
            props.onChange(false);
            setChecked(false);
        }
    };
    return (
        <React.Fragment>
            <div className="d-flex flex-row p-1 mt-2">
                <label className="checkbox c-checkbox">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={toggleChange}
                    />
                    <span className="fa fa-check"></span>
                </label>
                <div className="policy-text">
                    <p></p>
                </div>
                <div className="d-flex flex-row">
                    <span className="text-2">
                        Acepto las{' '}
                        <Link
                            to="/data-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            condiciones del servicio y las políticas de
                            privacidad{' '}
                        </Link>
                        de Iwoka.
                    </span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TermsAndConditions;
