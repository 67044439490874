import React, { useContext, useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import TitleUnderLine from '../../../Utils/TitleUnderline';
import {
    renderAsyncSelectField,
    renderDatePicker,
    renderField,
    renderPhotoUploader,
    renderSelectField,
    renderFileUploader,
    renderFieldCheck,
    renderSwitch,
    renderNumber,
} from '../../../Utils/renderField/renderField';
import {
    required,
    composeValidators,
    maxLength,
    date,
    email,
    isPositiveNum,
} from '../../../../../utility/validation';
import {
    ACTIVE,
    COUNTRY_OPTIONS,
    GENDER_OPTIONS,
    STATUS_BENEFICIARY_OPTIONS,
    STATUS_BENEFICIARY_OPTIONS_LEVELING,
    STATUS_BENEFICIARY_PRACTICE_OPTIONS,
    WORKDAY_OPTIONS,
    DESERTED,
    LEVEL_BENEFICIARY_OPTIONS,
    RISK_BENEFICIARY_OPTIONS,
    TYPE_DEVICE_OPTIONS,
    TYPE_NETWORK_OPTIONS,
    LEGAL_GUARDIAN,
    YOUNG_BENEFICIARY,
    SCHOOL_HALL_OPTIONS,
    GRADE_YOUNG_OPTIONS,
    SPECIAL_BONDING,
    ABANDONMENT,
} from '../../../../../utility/constants';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import PlusIcon from '../../../../../../assets/img/plus.png';
import OfflineContext from '../../../../context/OfflineContext';
import GuardianModal from './LegalGuardianModal';
import { DiligenceAuthorization } from './DiligenceAuthorization';

const otherValidations = (values) => {
    const errors = {};
    if (values.has_device && !values.type_device) {
        errors.type_device = required(values.type_device);
    }

    return errors;
};

const DEFAULT_VALUES = {
    famof: false,
    can_use_img: false,
    parents_together: false,
    has_device: false,
    status: ACTIVE,
    country: 'Colombia',
};

export default function YoungForm(props) {
    const { onSubmit, view, initialValues, onSubmitModal, isCreated } = props;
    const [modal, setModal] = useState(false);
    const [validator, setValidator] = useState(0);
    const [family, setFamily] = useState({
        mother: initialValues.n_mothers,
        father: initialValues.n_fathers,
        stepmothers: initialValues.n_stepmothers,
        stepfathers: initialValues.n_stepfathers,
        grandmothers: initialValues.n_grandmothers,
        grandparents: initialValues.n_grandparents,
    });
    const type_beneficiary = initialValues.type || YOUNG_BENEFICIARY;
    const { offline } = useContext(OfflineContext);
    const exclude_id = initialValues.id ? [initialValues.id] : [];
    const { asyncOptions: asyncSchool } = useAsyncOptions('school');

    const { asyncOptions: asyncLegalG } = useAsyncOptions('beneficiary', {
        type: LEGAL_GUARDIAN,
        exclude_ids: exclude_id,
    });

    /// The code from the line 81 to 90 is logical for when it is offline
    const [schools, setSchools] = useState([]);
    useEffect(() => {
        asyncSchool(undefined).then((options) => {
            setSchools(options);
        });
    }, []);

    return (
        <Form
            initialValues={{ ...DEFAULT_VALUES, ...initialValues }}
            validate={otherValidations}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, submitting, values, form }) => {
                props.handleDataChange(values);
                let school = '';
                if (values.school && values.school.id) {
                    school = values.school.id;
                }
                window.setLegalGuardians = (value) => {
                    let legal_guardians = [];
                    if (values.legal_guardians) {
                        legal_guardians = [...values.legal_guardians];
                    }
                    legal_guardians.push(value);
                    form.change('legal_guardians', legal_guardians);
                };

                return (
                    <form
                        name="beneficiaryForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        {validator > 0 &&
                            ((family.mother >= 1 && family.father >= 1) ||
                                (family.mother >= 1 && family.stepfathers >= 1) ||
                                (family.father >= 1 && family.stepmothers >= 1) ||
                                (family.grandmothers >= 1 &&
                                    family.grandparents >= 1) ||
                                (family.stepfathers >= 1 && family.stepmothers >= 1)
                                ? form.change('famof', true)
                                : form.change('famof', false))}
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="photo">Foto</label>
                                <Field
                                    name="photo"
                                    component={renderPhotoUploader}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="can_use_img">
                                    Autorización de uso de imagen y tratamiento
                                    de datos personales
                                </label>
                                <Field
                                    name="img_authorization"
                                    component={renderFileUploader}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                                <br />
                                <Field
                                    name="can_use_img"
                                    component={renderSwitch}
                                    label={
                                        !!values.can_use_img
                                            ? 'Si Autorizo'
                                            : 'No Autorizo'
                                    }
                                    type="text"
                                    width={50}
                                    height={22}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        {YOUNG_BENEFICIARY == type_beneficiary && (
                            <div className="d-flex flex-column flex-md-row">
                                <DiligenceAuthorization values={values} form={form} isCreated={isCreated} initialValues={initialValues}/>
                            </div>
                        )}
                        {!isCreated && (
                            <div className="d-flex flex-column flex-md-row mt-md-4">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="status">
                                        Estado Teórico
                                    </label>
                                    <Field
                                        name="status"
                                        component={renderSelectField}
                                        options={STATUS_BENEFICIARY_OPTIONS}
                                        isSearchable={false}
                                        validate={required}
                                        className="form-control w-100"
                                        disabled={true}
                                    />
                                </div>
                                {values.status === DESERTED ? (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                        <label htmlFor="desertion_reason">
                                            Razón de deserción
                                        </label>
                                        <Field
                                            name="desertion_reason"
                                            component={renderField}
                                            type="text"
                                            className="form-control w-100"
                                            disabled={true}
                                        />
                                        {values.last_desertion && (
                                            <span className="mt-2 ml-2 font-color-primary">
                                                {' '}
                                                El beneficiario fue desertado el{' '}
                                                {values.last_desertion}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1"></div>
                                )}
                            </div>
                        )}

                        {!isCreated && (
                            <div className="d-flex flex-column flex-md-row mt-md-4">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="status_practice">
                                        Estado práctico
                                    </label>
                                    <Field
                                        name="status_practice"
                                        component={renderSelectField}
                                        options={STATUS_BENEFICIARY_PRACTICE_OPTIONS}
                                        isSearchable={false}
                                        validate={required}
                                        className="form-control w-100"
                                        disabled={true}
                                    />
                                </div>
                                {values.status_practice === ABANDONMENT ? (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                        <label htmlFor="desertion_reason_practice">
                                            Razón de Abandono
                                        </label>
                                        <Field
                                            name="desertion_reason_practice"
                                            component={renderField}
                                            type="text"
                                            className="form-control w-100"
                                            disabled={true}
                                        />
                                        {values.last_desertion_practice && (
                                            <span className="mt-2 ml-2 font-color-primary">
                                                {' '}
                                                El beneficiario abandonó el{' '}
                                                {values.last_desertion_practice}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1"></div>
                                )}
                            </div>
                        )}

                        {!isCreated && (
                            <div className="d-flex flex-column flex-md-row mt-md-4">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="status_leveling">
                                        Estado Nivelación
                                    </label>
                                    <Field
                                        name="status_leveling"
                                        component={renderSelectField}
                                        options={STATUS_BENEFICIARY_OPTIONS_LEVELING}
                                        isSearchable={false}
                                        validate={required}
                                        className="form-control w-100"
                                        disabled={true}
                                    />
                                </div>
                                {values.status_leveling === DESERTED ? (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                        <label htmlFor="desertion_reason_leveling">
                                            Razón de Deserción
                                        </label>
                                        <Field
                                            name="desertion_reason_leveling"
                                            component={renderField}
                                            type="text"
                                            className="form-control w-100"
                                            disabled={true}
                                        />
                                        {values.last_desertion_leveling && (
                                            <span className="mt-2 ml-2 font-color-primary">
                                                {' '}
                                                El beneficiario desertó el{' '}
                                                {values.last_desertion_leveling}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1"></div>
                                )}
                            </div>
                        )}

                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="identification">ID</label>
                                <Field
                                    name="identification"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="d-none d-md-flex m-2 mx-md-5 flex-1" />
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="first_name">Nombres</label>
                                <Field
                                    name="first_name"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="last_name">Apellidos</label>
                                <Field
                                    name="last_name"
                                    label="Apellidos"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="birthday">
                                    Fecha nacimiento
                                </label>
                                <Field
                                    name="birthday"
                                    component={renderDatePicker}
                                    validate={composeValidators(required, date)}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="gender">Sexo</label>
                                <Field
                                    name="gender"
                                    component={renderSelectField}
                                    options={GENDER_OPTIONS}
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="email">
                                    Correo Electrónico
                                </label>
                                <Field
                                    name="email"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(email)}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="cellphone">Celular</label>
                                <Field
                                    name="cellphone"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(15)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="instagram">Instagram</label>
                                <Field
                                    name="instagram"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(200)}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="country">País</label>
                                <Field
                                    name="country"
                                    component={renderSelectField}
                                    options={COUNTRY_OPTIONS}
                                    validate={required}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="department">Departamento</label>
                                <Field
                                    name="department"
                                    component={renderField}
                                    validate={maxLength(75)}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="city">Municipio</label>
                                <Field
                                    name="city"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(100)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="address">Dirección</label>
                                <Field
                                    name="address"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(200)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="school">Colegio</label>
                                {offline ? (
                                    <Field
                                        name="school"
                                        component={renderSelectField}
                                        isSearchable={true}
                                        withAsync={true}
                                        labelKey="name"
                                        valueKey="id"
                                        options={schools}
                                        className="w-100"
                                        disabled={view}
                                    />
                                ) : (
                                    <Field
                                        name="school"
                                        component={renderAsyncSelectField}
                                        isSearchable={true}
                                        labelKey="name"
                                        valueKey="id"
                                        loadOptions={asyncSchool}
                                        validate={required}
                                        className="w-100"
                                        disabled={view}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="workday">Jornada</label>
                                <Field
                                    name="workday"
                                    component={renderSelectField}
                                    options={WORKDAY_OPTIONS}
                                    validate={composeValidators(
                                        required,
                                        maxLength(50)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="school_grade">Grado</label>
                                <Field
                                    name="school_grade"
                                    component={renderSelectField}
                                    options={GRADE_YOUNG_OPTIONS}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(25)
                                    )}
                                    className="form-control w-100"
                                    disabled={initialValues.status_group}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="school_hall">Salón</label>
                                <Field
                                    name="school_hall"
                                    component={renderSelectField}
                                    options={SCHOOL_HALL_OPTIONS}
                                    validate={required}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="level">Nivel</label>
                                <Field
                                    name="level"
                                    component={renderSelectField}
                                    options={LEVEL_BENEFICIARY_OPTIONS}
                                    className="form-control w-100"
                                    disabled={initialValues.status_group}
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2  flex-1">
                                <label htmlFor="social_project">
                                    ¿Debe Proyecto Social?
                                </label>
                                <Field
                                    name="social_project"
                                    component={renderSelectField}
                                    validate={required}
                                    options={[
                                        { value: true, label: 'Si' },
                                        { value: false, label: 'No' },
                                    ]}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="d-none d-md-flex m-2 mx-md-5 flex-1" />
                        </div>

                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="type_network">
                                    Tipo de conexión?
                                </label>
                                <Field
                                    name="type_network"
                                    component={renderSelectField}
                                    options={TYPE_NETWORK_OPTIONS}
                                    type="text"
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="legal_guardians">
                                    Acudiente
                                </label>
                                <div className="d-flex">
                                    <Field
                                        key={school + 'L'}
                                        name="legal_guardians"
                                        component={renderAsyncSelectField}
                                        loadOptions={async (search) =>
                                            await asyncLegalG(search, {
                                                school: school,
                                            })
                                        }
                                        isMulti={true}
                                        isSearchable={true}
                                        labelKey="first_name"
                                        labelKey2="last_name"
                                        valueKey="id"
                                        type="text"
                                        className="w-100"
                                        disabled={view}
                                    />
                                    {!offline && (
                                        <div className="d-flex " width="30px">
                                            <button
                                                className="btn p-0 ml-2"
                                                type="button"
                                                onClick={() => setModal(true)}
                                                disabled={view}
                                            >
                                                <img
                                                    src={PlusIcon}
                                                    width="20px"
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="parents_together">
                                    Padres viven juntos?
                                </label>
                                <Field
                                    name="parents_together"
                                    component={renderFieldCheck}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="risks">Riesgos</label>
                                <Field
                                    name="risks"
                                    component={renderSelectField}
                                    isMulti={true}
                                    options={RISK_BENEFICIARY_OPTIONS}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="has_device">
                                    Tiene dispositivo propio?
                                </label>
                                <Field
                                    name="has_device"
                                    component={renderFieldCheck}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="type_device">
                                    Tipo de dispositivo
                                </label>
                                <Field
                                    name="type_device"
                                    component={renderSelectField}
                                    options={TYPE_DEVICE_OPTIONS}
                                    className="form-control w-100"
                                    disabled={view || !values.has_device}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="famof">
                                    ¿Cumple características de FAMOF?
                                </label>
                                <Field
                                    name="famof"
                                    component={renderSelectField}
                                    options={[
                                        { value: true, label: 'Si' },
                                        { value: false, label: 'No' },
                                    ]}
                                    disabled={true}
                                    className="form-control w-100"
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="special_boding">
                                    Vinculación especial
                                </label>
                                <Field
                                    name="special_boding"
                                    component={renderSelectField}
                                    options={SPECIAL_BONDING}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        {values.famof == true && (
                            <div className="d-flex flex-column flex-md-row mt-md-4">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="how_long_together">
                                        ¿Hace cuánto viven juntos?
                                    </label>
                                    <Field
                                        name="how_long_together"
                                        component={renderNumber}
                                        validate={required}
                                        disabled={view}
                                        className="form-control w-100"
                                    />
                                </div>
                                <div className="d-none d-md-flex m-2 mx-md-5 flex-1"></div>
                            </div>
                        )}
                        <br />
                        <TitleUnderLine title="Conformación familiar" />
                        <div className="d-flex flex-md-column">
                            <div className="d-flex flex-column flex-md-row col-6 col-md-12 p-0">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_mothers">Mamá</label>
                                    <Field
                                        name="n_mothers"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                        parse={(value) => {
                                            setFamily({
                                                ...family,
                                                mother: value,
                                            });
                                            setValidator(validator + 1);

                                            return value;
                                        }}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_fathers">Papá</label>
                                    <Field
                                        name="n_fathers"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                        parse={(value) => {
                                            setFamily({
                                                ...family,
                                                father: value,
                                            });
                                            setValidator(validator + 1);

                                            return value;
                                        }}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_stepmothers">
                                        Madrastra
                                    </label>
                                    <Field
                                        name="n_stepmothers"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                        parse={(value) => {
                                            setFamily({
                                                ...family,
                                                stepmothers: value,
                                            });
                                            setValidator(validator + 1);

                                            return value;
                                        }}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_stepfathers">
                                        Padrastro
                                    </label>
                                    <Field
                                        name="n_stepfathers"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                        parse={(value) => {
                                            setFamily({
                                                ...family,
                                                stepfathers: value,
                                            });
                                            setValidator(validator + 1);

                                            return value;
                                        }}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_brothers_sisters">
                                        Hermanos/Hermanas
                                    </label>
                                    <Field
                                        name="n_brothers_sisters"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-md-row col-6 col-md-12 p-0">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_grandmothers">
                                        Abuela
                                    </label>
                                    <Field
                                        name="n_grandmothers"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                        parse={(value) => {
                                            setFamily({
                                                ...family,
                                                grandmothers: value,
                                            });
                                            setValidator(validator + 1);

                                            return value;
                                        }}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_grandparents">
                                        Abuelo
                                    </label>
                                    <Field
                                        name="n_grandparents"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                        parse={(value) => {
                                            setFamily({
                                                ...family,
                                                grandparents: value,
                                            });
                                            setValidator(validator + 1);

                                            return value;
                                        }}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_uncles">Tío/Tía</label>
                                    <Field
                                        name="n_uncles"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_others">Otros</label>
                                    <Field
                                        name="others"
                                        component={renderField}
                                        type="text"
                                        className="form-control w-100"
                                        disabled={view}
                                    />
                                </div>
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="n_others_number">
                                        Otros (Cantidad)
                                    </label>
                                    <Field
                                        name="n_others"
                                        component={renderField}
                                        validate={isPositiveNum}
                                        type="number"
                                        className="form-control w-100"
                                        disabled={view}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/beneficiary"
                                className="btn btn-secondary align-self-center mt-4 m-md-0"
                            >
                                Regresar
                            </Link>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary align-self-center"
                                    disabled={submitting}
                                >
                                    Guardar
                                </button>
                            )}
                        </div>
                        <GuardianModal
                            openModal={modal}
                            initialValues={{
                                type: LEGAL_GUARDIAN,
                                school: values.school,
                                country: values.country,
                                department: values.department,
                                city: values.city,
                                address: values.address,
                            }}
                            onCloseModal={() => setModal(false)}
                            onSubmitModal={onSubmitModal}
                        />
                    </form>
                );
            }}
        </Form>
    );
}
