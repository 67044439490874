import React, { useState, useEffect } from 'react';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import { DateTime, Settings } from 'luxon';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-responsive-modal/styles.css';
import InfoModal from './ModalInfo/InfoModal';
import { DEFAULT_TIMEZONE } from '../../../hooks/useDayjsUtils';
import _ from 'lodash';
import Tooltip from '@mui/material/Tooltip';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
import {
    GROUP_MENTORING,
    INDIVIDUAL_MENTORING,
    PERSONAL_EVENT,
    SENA_EVENT,
    YOUNG_BENEFICIARY,
    KID_BENEFICIARY,
    EDUCATIVE,
    LEGAL_GUARDIAN,
    VOLUNTEER,
} from '../../../../utility/constants';

import './calendar.css';
Settings.defaultLocale = 'es';
Settings.defaultZone = DEFAULT_TIMEZONE;

const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = '#' + event.hexColor;

    const training_group_type = _.get(event, 'training_group.type', undefined);

    var style = {
        backgroundColor: backgroundColor,
        borderRadius: '0px',
        color: 'white',
        border: '0px',
        display: 'block',
    };

    if (event.type == GROUP_MENTORING) {
        if (training_group_type === EDUCATIVE) {
            style.backgroundColor = '#c2ccd9';
        } else if (training_group_type === LEGAL_GUARDIAN) {
            style.backgroundColor = '#9aa9bf';
        } else if (training_group_type === KID_BENEFICIARY) {
            style.backgroundColor = '#a9aaad';
        } else {
            style.backgroundColor = '#ef6447';
        }
    } else if (event.type == INDIVIDUAL_MENTORING) {
        style.backgroundColor = '#f3977a';
    } else if (event.type == PERSONAL_EVENT) {
        style.backgroundColor = '#a2ce8c';
    } else if (event.type == SENA_EVENT) {
        style.backgroundColor = '#72bc56';
    }

    return {
        style: style,
    };
};

function customTooltip(event) {
    return `${event.title}${' - ' + _.get(event, 'training_group.name', '')}`;
}

const EventElement = ({ event }) => {
    let classChecks = ''
    // //EVENTO HEAD
    // classChecks += !!event.event_head && event.more_events && event.more_events.length > 0 ? ` checkEventHead` : '';
    // //TEST
    // classChecks += event.is_save ? ' chSave' : '';
    // classChecks += event.is_last_event_calendar_with_save ? ' chIsUltime' : '';
    
    let textTooltip = `${event.title}${' - ' + _.get(event, 'training_group.name', '')}`;
    if (event.is_last_event_registered) textTooltip += ' - ULTIMO EVENTO';
    if (event.is_save){
        textTooltip += event.is_last_event_registered ?  ' GUARDADO'  : ' - GUARDADO';
    }
    // if (event.more_events && event.more_events.length > 0) textTooltip += ' - EVENTO PADRE';    
    if (event.is_last_event_calendar_with_save) textTooltip += ' - ULTIMO EVENTO CALENDARIO';

    return (
        <React.Fragment>
            <Tooltip title={textTooltip} arrow  placement="left-start">
                <div className={`InfoEvent ${classChecks}`}>
                    <div>
                        <p className="mb-1 ">{event.title}</p>
                        <p className="mb-1">
                            {_.get(event, 'training_group.name', '')}
                        </p>
                    </div>
                </div>
            </Tooltip>
        </React.Fragment>
    );
};

const MyCalendar = (props) => {
    const { setGetFilters } = props;
    const [getFilter, setGetFilter] = useState(false);
    const [eventDataModal, setDataEventModal] = useState({});

    useEffect(() => {
        if (getFilter) {
            setGetFilter(false);
            setGetFilters(true);
        }
    }, [getFilter]);

    return (
        <React.Fragment>
            <Calendar
                selectable
                localizer={luxonLocalizer(DateTime, { firstDayOfWeek: 7 })}
                startAccessor="start"
                endAccessor="end"
                min={new Date(2022, 5, 6, 7)}
                max={new Date(2022, 5, 6, 18)}
                style={{ height: 800 }}
                popup={false}
                onSelectEvent={(eventProps) => setDataEventModal(eventProps)}
                messages={{
                    date: 'Fecha',
                    time: 'Hora',
                    event: 'Evento',
                    allDay: 'Todo el Dia',
                    week: 'Semana',
                    work_week: 'Work Week',
                    day: 'Dia',
                    month: 'Mes',
                    previous: 'Anterior',
                    next: 'Siguiente',
                    yesterday: 'Ayer',
                    tomorrow: 'Mañana',
                    today: 'Hoy',
                    agenda: 'Agenda',
                    myView: 'myView',
                    showMore: (total) => `+${total} mas`,
                }}
                tooltipAccessor={() => null}
                
                eventPropGetter={eventStyleGetter}
                dayLayoutAlgorithm="no-overlap"
                views={{
                    month: true,
                    week: true,
                    day: true,
                    agenda: true,
                }}
                components={{
                    month: {
                        event: (eventProps) => (
                            <EventElement
                                setGetFilters={setGetFilter}
                                {...eventProps}
                            />
                        ),
                    },
                    week: {
                        event: (eventProps) => (
                            <EventElement
                                setGetFilters={setGetFilter}
                                {...eventProps}
                            />
                        ),
                    },
                    day: {
                        event: (eventProps) => (
                            <EventElement
                                setGetFilter={setGetFilters}
                                {...eventProps}
                            />
                        ),
                    },
                }}
                {...props}
            />

            <hr />
            {eventDataModal && eventDataModal.id && (
                <InfoModal
                    onCloseModal={() => setDataEventModal({})}
                    event={eventDataModal}
                    setGetFilters={setGetFilter}
                />
            )}
        </React.Fragment>
    );
};
export default MyCalendar;
