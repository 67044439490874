import React, { useContext, useState } from 'react';

import { Link } from 'react-router-dom';
import Table from '../../../../Utils/Grid';
import { useList } from '../../../../../hooks/useList';
import LoaderContext from '../../../../../context/LoaderContext';
import { standardActions } from '../../../../Utils/Grid/StandardActions';
import { dateFormatter } from '../../../../../../utility/formatFields';
import TitleUnderline from '../../../../Utils/TitleUnderline';
import FilterInputs from '../../Utils/FilterInputs';

import TransferLogo from '../../../../../../../assets/img/traslado-logo.svg';
import TitleIcon from '../../Utils/TitleIcon';
import UserContext from '../../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN
} from '../../../../../../utility/constants'

const ListProducts = () => {
    const [filters, setFilter] = useState({
        search: '',
        start: '',
        end: '',
    });
    const { loader } = useContext(LoaderContext);
    const { user }= useContext(UserContext);
    const { data, getData, page } = useList('transfer', {
        ordering: '-id',
        search: filters.search,
        start: filters.start,
        end: filters.end,
        type_transfer: 'transfer',
    });

    const format = (cell) => {
        try {
            return cell.name ? cell.name : cell;
        } catch (error) {
            return '---';
        }
    };
    return (
        <React.Fragment>
            <TitleIcon title="Traslados" image={TransferLogo} />
            <FilterInputs
                search_placeholder="Buscar por proveedor o ubicación"
                getData={getData}
                filters={filters}
                setFilter={setFilter}
            />
            <div className="d-flex flex-column flex-md-row justify-content-between mt-5">
                <TitleUnderline title="Detalle de Traslados" />
                { (user.type === SUPER_ADMIN || user.type === ASSISTANT) &&(
                <Link
                    to="/inventory/transfers/create"
                    className="btn btn-primary"
                >
                    Registar Traslado
                </Link>
                )}
            </div>
            <Table
                data={data}
                onPageChange={(page) => getData(page)}
                page={page}
                loading={loader}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/inventory/transfers',
                        // update: '/inventory/transfer',
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="date"
                    dataSort={true}
                    dataFormat={dateFormatter}
                >
                    Fecha
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="origin"
                    dataFormat={format}
                >
                    Ubicación sale
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="destination"
                    dataFormat={format}
                >
                    Ubicación entrada
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="user_movement"
                    dataFormat={format}
                    tdStyle={(_, data) => {
                        const { is_annulled, reason_annulment } = data;
                        return is_annulled
                            ? {
                                  textDecoration: 'line-through',
                                  opacity: 0.6,
                              }
                            : {};
                    }}
                >
                    Realizado por
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default ListProducts;
