import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Form } from 'react-final-form';

import BodyForm from './BodyForm';
import DetailTable from './DetailTable';

const EntryForm = ({
    entryData,
    onSubmit,
    update,
    path_back,
    kit,
    isViewed,
    isUpdate,
    setKit,
    onClick,
}) => {
    const editable_name = kit.length >= 1 ? true : false;
    if (isViewed || isUpdate) {
        const { school_kit } = entryData;
        if (school_kit) {
            // so that it runs only once
            useEffect(() => {
                // data for table
                school_kit.map((kit) => {
                    kit.code = kit.product.code;
                    kit.description = kit.product.description;
                    kit.category = kit.product.category;
                    kit.name = entryData.name;
                });
                // Assign the kit data to the table
                const current_kit = entryData.school_kit;
                setKit(...kit, current_kit);
            }, []);
        }
    }
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={entryData}
                render={({ handleSubmit, submitting }) => {
                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            {!isViewed && (
                                <BodyForm
                                    editable_name={editable_name}
                                    isUpdate={isUpdate}
                                    kit={kit}
                                />
                            )}
                            <DetailTable
                                kit={kit}
                                setKit={setKit}
                                isViewed={isViewed}
                                entryData={entryData}
                            />

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                                <Link
                                    to={path_back}
                                    className="btn btn-secondary mt-4 mt-md-0"
                                >
                                    Regresar
                                </Link>
                                {!isViewed && (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={submitting}
                                        onClick={onClick}
                                    >
                                        {update ? 'Actualizar' : 'Guardar'}
                                    </button>
                                )}
                            </div>
                        </form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default EntryForm;
