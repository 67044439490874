import React from 'react';
import {
    TableBeneficiary,
    TableBeneficiaryGroup,
    TableCalendar,
    TableInventory,
    TableOperationalUser,
    TableSchool,
    TableSession,
    TableTrainingGroup,
    TableTrainingPlan,
} from './FilteredTables';

const ShowTables = ({ reportData, data, loader, page, getData }) => {
    return (
        <React.Fragment>
            {reportData.module === 'calendar' && (
                <TableCalendar
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
            {reportData.module === 'beneficiary' && (
                <TableBeneficiary
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
            {reportData.module === 'operational_user' && (
                <TableOperationalUser
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
            {reportData.module === 'session' && (
                <TableSession
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
            {reportData.module === 'beneficiary_groups' && (
                <TableBeneficiaryGroup
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
            {reportData.module === 'training_plan' && (
                <TableTrainingPlan
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
            {reportData.module === 'training_group' && (
                <TableTrainingGroup
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
            {reportData.module === 'schools' && (
                <TableSchool
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
            {reportData.module === 'inventory' && (
                <TableInventory
                    data={data}
                    loader={loader}
                    page={page}
                    reportData={reportData}
                    getData={getData}
                />
            )}
        </React.Fragment>
    );
};

export default ShowTables;
