import React from 'react';

import './card.css';

const Card = (props) => {
    const { isPrimary, isSecondary, className } = props;
    const getClassName = () => {
        if (isPrimary) return 'card-primary';
        if (isSecondary) return 'card-secondary';
        return 'card-default';
    };
    return (
        <React.Fragment>
            <div className={`${className || ''} ${getClassName()}`}>
                {props.children}
            </div>
        </React.Fragment>
    );
};

export default Card;
