import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import dayjs from 'dayjs';
import _ from 'lodash';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';
import LoaderContext from '../../../../context/LoaderContext';
import InputSelect from '../../../Utils/InputSelect';
import YoungForm from './YoungForm';
import KidForm from './KidForm';
import LegalGuardianForm from './LegalGuardianForm';
import EducativeForm from './EducativeForm';
import { SwalWarning } from '../../../Utils/SwalAlerts';
import { DialogBeforeUnLoad } from '../../../Utils/DialogBeforeUnLoad';

import {
    TYPE_BENEFICIARY_OPTIONS,
    YOUNG_BENEFICIARY,
    EDUCATIVE,
    LEGAL_GUARDIAN,
    KID_BENEFICIARY,
    VOLUNTEER,
} from '../../../../../utility/constants';
import { useNavigatingAway } from '../../../../hooks/useNavigatingAway';
import { areObjectsEqual } from '../../../Utils/Funcs';

const CreateUpdateBeneficiary = () => {
    const [type, setType] = useState(YOUNG_BENEFICIARY);
    const { saveData } = useCreate('beneficiary', '/beneficiary');
    const { saveData: saveDataModal } = useCreate('beneficiary');
    const { data, updateData, update, setData, getData } = useUpdate(
        'beneficiary',// name api
        '/beneficiary', // pathname module
        { attachments: true }, // local_pathname
        true, // get_data = true,
        false, // navigate_to = false,
        true // reaload=false,
    );
    const { loader } = useContext(LoaderContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (data && data.type) {
            setType(data.type)
            handleDataChange(data);
        }
    }, [data]);

    const onSubmit = async (dataForm) => {
        const body = { ...dataForm };
        const attachments = [];
        if (body.birthday)
            body.birthday = dayjs(body.birthday).format('YYYY-MM-DD');
        if (body.school) body.school = body.school.id;
        if (body.godfather) body.godfather = body.godfather.id;
        if (body.legal_guardian) body.legal_guardian = body.legal_guardian.id;
        if (body.photo instanceof File) {
            attachments.push({ name: 'photo', file: body.photo });
        }
        if (body.img_authorization instanceof File) {
            attachments.push({
                name: 'img_authorization',
                file: body.img_authorization,
            });
        }
        if (body.risks && Array.from(body.risks).length > 0) {
            body.risks = Array.from(body.risks).join(',');
        } else {
            body.risks = '';
        }
        delete body.photo;
        delete body.img_authorization;
        setFirstValuesOnRender(null);
        if (!update) saveData(body, attachments);
        else if (
            update &&
            body.type !== data.type &&
            data.in_active_group === true
        ) {
            const { value } = await SwalWarning(
                '¿Cambiar de tipo al beneficiario?',
                'El beneficiario esta en un grupo de formación con sesiones pendientes de tomar asistencia, realizar este cambio puede afecta las estadísticas de los beneficiarios.  ¿Desea continuar?'
            );
            if (value) {
                setFirstValuesOnRender(null)
                await updateData(body, attachments)
            }
        } else {
            setFirstValuesOnRender(null);
            await updateData(body, attachments);
        }
    };

    const onSubmitModal = async (dataForm) => {
        const body = { ...dataForm };
        if (body.birthday) {
            body.birthday = dayjs(body.birthday).format('YYYY-MM-DD');
        }
        if (body.school) body.school = body.school.id;
        const legal_guardian = await saveDataModal(body);
        if (legal_guardian) {
            window.setLegalGuardians(legal_guardian);
        }
    };

    const isViewed = location.pathname.includes('view');
    const isUpdated = location.pathname.includes('update');
    const isCreated = location.pathname.includes('create');

    const [firstValuesOnRender, setFirstValuesOnRender] = useState(null);

    const [isChanged, setIsChangedData] = useState(false);

    const [canShowDialogBeforeUnLoad, setCanShowDialogBeforeUnLoad] = useState(
        (isUpdated || isCreated) && isChanged
    );

    const handleDataChange = (newData) => {
        if (!firstValuesOnRender) {
            setFirstValuesOnRender(newData);
        } else {
            const isDataChanged = !areObjectsEqual(
                firstValuesOnRender,
                newData
            );
            setIsChangedData(isDataChanged);
            setCanShowDialogBeforeUnLoad(isDataChanged);
        }
    };

    const [showDialogBeforeUnLoad, confirmNavigation, cancelNavigation] =
        useNavigatingAway(canShowDialogBeforeUnLoad);

    useEffect(() => {}, [canShowDialogBeforeUnLoad, isChanged]);

    return (
        <React.Fragment>
            <DialogBeforeUnLoad
                showDialog={showDialogBeforeUnLoad}
                setShowDialog={setCanShowDialogBeforeUnLoad}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
            <div className="pt-5">
                <h1 className="title-2 cl-orange m-0">
                    {`${
                        isUpdated ? 'Actualizar' : isCreated ? 'Registrar' : ''
                    }`}{' '}
                    Beneficiario
                </h1>
                <br />
                <br />
                <LoadMask loading={loader} blur>
                    <div className="d-flex">
                        <div className="form-group has-feedback m-2 flex-1">
                            <label>Tipo</label>
                            <InputSelect
                                onChange={setType}
                                options={TYPE_BENEFICIARY_OPTIONS}
                                value={type}
                                disabled={isViewed}
                            />
                        </div>
                        <div className="d-none d-md-flex flex-1" />
                        <div className="d-none d-md-flex flex-1" />
                    </div>
                    <br />
                    <hr />
                    {((data && data.id) || isCreated) && (
                        <React.Fragment>
                            {[YOUNG_BENEFICIARY, VOLUNTEER].includes(type) && (
                                <YoungForm
                                    onSubmit={onSubmit}
                                    onSubmitModal={onSubmitModal}
                                    initialValues={{ ...data, type: type }}
                                    view={isViewed}
                                    isCreated={isCreated}
                                    handleDataChange={handleDataChange}
                                />
                            )}
                            {type === KID_BENEFICIARY && (
                                <KidForm
                                    onSubmit={onSubmit}
                                    onSubmitModal={onSubmitModal}
                                    initialValues={{ ...data, type: type }}
                                    view={isViewed}
                                    isCreated={isCreated}
                                    handleDataChange={handleDataChange}
                                />
                            )}
                            {type === EDUCATIVE && (
                                <EducativeForm
                                    onSubmit={onSubmit}
                                    initialValues={{ ...data, type: type }}
                                    isCreated={isCreated}
                                    view={isViewed}
                                    onGoBack={() => navigate('/beneficiary')}
                                    handleDataChange={handleDataChange}
                                />
                            )}
                            {type == LEGAL_GUARDIAN && (
                                <LegalGuardianForm
                                    onSubmit={onSubmit}
                                    initialValues={{ ...data, type: type }}
                                    view={isViewed}
                                    isCreated={isCreated}
                                    onGoBack={() => navigate('/beneficiary')}
                                    handleDataChange={handleDataChange}
                                />
                            )}
                        </React.Fragment>
                    )}
                    <br />
                    <br />
                </LoadMask>
            </div>
        </React.Fragment>
    );
};

export default CreateUpdateBeneficiary;
