import React from 'react';

import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import {
    composeValidators,
    required,
    maxLength,
} from '../../../../../../utility/validation';

import {
    renderField,
    renderDatePicker,
} from '../../../../Utils/renderField/renderField';
import { CATEGORY_OPTIONS } from '../../../../../../utility/constants';

const HolidayForm = ({ productData, onSubmit, view, update }) => {
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={productData}
                render={({ handleSubmit, submitting }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 mr-lg-4 flex-1">
                                <label htmlFor="date">Selecionar fecha</label>
                                <Field
                                    name="date"
                                    component={renderDatePicker}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mr-lg-4 flex-1">
                                <label htmlFor="holiday">Dia Festivo</label>
                                <Field
                                    name="holiday"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    disabled={view}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/calendar/holiday"
                                className="btn btn-secondary mt-4 mt-md-0"
                            >
                                Regresar
                            </Link>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    Actualizar
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default HolidayForm;
