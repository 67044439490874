import React from 'react';
import { Field } from 'react-final-form';
import {
    renderAsyncSelectField,
    renderSelectField,
} from '../../../Utils/renderField/renderField';

import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import {
    YOUNG_BENEFICIARY,
    GRADE_KID_OPTIONS,
    GRADE_YOUNG_OPTIONS,
    USER_ACTIVE,
} from '../../../../../utility/constants';
import _ from 'lodash';

const FormFilter = (Props) => {
    const { values } = Props;

    const { asyncOptions: asyncSchool } = useAsyncOptions('school');
    const { asyncOptions: asyncUser } = useAsyncOptions('user', {
        status: USER_ACTIVE,
    });
    const { asyncOptions: asyncBeneficiary } = useAsyncOptions('beneficiary');
    // console.log(values.mentoring_individual_school);
    return (
        <React.Fragment>
            {values.mentoring_group && (
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-md">
                            <label htmlFor="school">Colegio</label>
                            <Field
                                name="mentoring_group_school"
                                component={renderAsyncSelectField}
                                modal={true}
                                isClearable={true}
                                isSearchable={true}
                                labelKey="name"
                                valueKey="id"
                                loadOptions={asyncSchool}
                                className="w-100"
                            />
                        </div>
                        <div className="col-md">
                            <label htmlFor="mentor">Mentor</label>
                            <Field
                                name="mentoring_group_mentor"
                                component={renderAsyncSelectField}
                                modal={true}
                                isClearable={true}
                                isSearchable={true}
                                labelKey="first_name"
                                labelKey2="last_name"
                                valueKey="id"
                                loadOptions={asyncUser}
                                className="w-100"
                            />
                        </div>
                        <div className="col-md">
                            <label htmlFor="beneficiary">Beneficiario</label>
                            <Field
                                key={
                                    'school' +
                                    _.get(
                                        values,
                                        'mentoring_group_school.id',
                                        ' --clear'
                                    )
                                }
                                name="mentoring_group_beneficiary"
                                component={renderAsyncSelectField}
                                modal={true}
                                isClearable={true}
                                isSearchable={true}
                                labelKey="first_name"
                                labelKey2="last_name"
                                valueKey="id"
                                loadOptions={(search) =>
                                    asyncBeneficiary(search, {
                                        type: YOUNG_BENEFICIARY,
                                        school: values.mentoring_group_school,
                                    })
                                }
                                className="w-100"
                            />
                        </div>
                    </div>
                </div>
            )}
            {values.mentoring_individual && (
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-md">
                            <label htmlFor="school">Colegio</label>
                            <Field
                                name="mentoring_individual_school"
                                component={renderAsyncSelectField}
                                modal={true}
                                isClearable={true}
                                isSearchable={true}
                                labelKey="name"
                                labelKey2="last_name"
                                valueKey="id"
                                loadOptions={asyncSchool}
                                className="w-100"
                            />
                        </div>
                        <div className="col-md">
                            <label htmlFor="mentor">Mentor</label>
                            <Field
                                name="mentoring_individual_mentor"
                                component={renderAsyncSelectField}
                                modal={true}
                                isClearable={true}
                                isSearchable={true}
                                labelKey="first_name"
                                labelKey2="last_name"
                                valueKey="id"
                                loadOptions={asyncUser}
                                className="w-100"
                            />
                        </div>
                        <div className="col-md">
                            <label htmlFor="beneficiary">Beneficiario</label>
                            <Field
                                key={
                                    'school' +
                                    _.get(
                                        values,
                                        'mentoring_individual_school.id',
                                        ' --clear'
                                    )
                                }
                                name="mentoring_individual_beneficiary"
                                component={renderAsyncSelectField}
                                modal={true}
                                isClearable={true}
                                isSearchable={true}
                                labelKey="first_name"
                                labelKey2="last_name"
                                valueKey="id"
                                loadOptions={(search) =>
                                    asyncBeneficiary(search, {
                                        type: YOUNG_BENEFICIARY,
                                        school: values.mentoring_individual_school,
                                    })
                                }
                                className="w-100"
                            />
                        </div>
                    </div>
                </div>
            )}

            {values.personal_event && (
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-md">
                            <label htmlFor="mentor">Participantes</label>
                            <Field
                                name="personal_event_mentor"
                                component={renderAsyncSelectField}
                                modal={true}
                                isClearable={true}
                                isSearchable={true}
                                labelKey="first_name"
                                labelKey2="last_name"
                                valueKey="id"
                                loadOptions={asyncUser}
                                className="w-100"
                            />
                        </div>
                        <div className="col-md"></div>
                    </div>
                </div>
            )}

            {values.sena && (
                <div className="container mt-2">
                    <div className="row">
                        <div className="col-md">
                            <label htmlFor="school">Colegio</label>
                            <Field
                                name="sena_school"
                                component={renderAsyncSelectField}
                                modal={true}
                                isClearable={true}
                                isSearchable={true}
                                labelKey="name"
                                valueKey="id"
                                loadOptions={asyncSchool}
                                className="w-100"
                            />
                        </div>
                        <div className="col-md">
                            <label htmlFor="grade">Grado</label>
                            <Field
                                name="sena_grade"
                                component={renderSelectField}
                                modal={true}
                                isClearable={true}
                                options={[
                                    ...GRADE_KID_OPTIONS,
                                    ...GRADE_YOUNG_OPTIONS,
                                ]}
                                type="text"
                                className="form-control w-100"
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default FormFilter;
