import React, { useContext } from 'react';
import ForgotPasswordFrom from './ForgotPasswordFrom';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import useAccount from '../../../hooks/useAccount';
import LoaderContext from '../../../context/LoaderContext';

import '../passwordRecovery.css';

const ForgotPassword = () => {
    const { loader } = useContext(LoaderContext);
    const { pwdRecovery } = useAccount();

    const onSubmit = (data) => {
        pwdRecovery(data);
    };
    return (
        <React.Fragment>
            <div className="background-pwd d-flex flex-column justify-content-center">
                <div className="pwd-wrapper">
                    <div className="card card-pwd col-10 col-md-6 col-lg-4 col-xl-3">
                        <div className="d-flex flex-column align-items-center pt-3 bienvenida">
                            <h1 className="text-center">
                                ¿Olvidaste tu contraseña?
                            </h1>
                        </div>
                        <span className="px-4">
                            No te preocupes resetear tu contraseña es fácil,
                            necesitamos que ingreses tu ID.
                        </span>

                        <LoadMask loading={loader} blur>
                            <ForgotPasswordFrom onSubmit={onSubmit} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;
