import React, { useMemo, useState, useContext, useRef, useEffect } from 'react';
import Table from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import LogoReporting from '../../../../../../assets/img/reporteria-logo.svg';
import { NotificationManager } from 'react-notifications';
import FilterFields from './FilterFields';
import { api } from 'api';
import { getFriendlyName } from './fields';
import { SwalError } from '../../../Utils/SwalAlerts';
import { SwalSuccess } from '../../../Utils/SwalAlerts';
import LoaderContext from '../../../../context/LoaderContext';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import MainFilters from './MainFilters';
import { isEmpty } from 'lodash';
import useFormatFilters from '../../../../hooks/useFormatFilters';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';

const DEFAULT_FILTER = { start: dayjs().startOf('year'), end: dayjs() };

export default function Assistance() {
    const { loader, setLoader } = useContext(LoaderContext);
    const [page, setPage] = useState(1);
    const [initialFilters, setInitialFilters] = useState({});
    const [fields, setFields] = useState([]);
    const [filtersReport, setFiltersReport] = useState({});
    const [report, setReport] = useState({ results: [], count: 0 });
    const [filters, setFilters] = useState(DEFAULT_FILTER);
    const btnGenerateRep = useRef();
    const { formatFilters } = useFormatFilters();

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const formatTextEmpty = (cell, row) => {
        if (!cell) return '---';
        return (
            <React.Fragment>
                <div
                    data-for={row.id + '-name_cell-'}
                    data-tip={`
                    ${cell}
                `}
                >
                    {cell}
                </div>
            </React.Fragment>
        );
    };

    const onSubmit = async (data) => {
        const fieldsIter = Object.entries(data);
        const _fields = [];
        for (let [key, value] of fieldsIter) {
            if (value === true) _fields.push(key);
        }
        const _filters = formatFilters(filters);
        if (_fields.length < 1) {
            SwalError(
                'Error',
                'Debe seleccionar al menos un campo para generar reporte.'
            );
            return;
        }
        const report = await getReport(1, _filters, _fields);
        if (report) {
            setFields(_fields);
            setFiltersReport(_filters);
        }
        if (report.results.length > 0) {
            NotificationManager.success('Reporte Generado', 'ÉXITO', 6000);
            setTimeout(() => {
                const scrollDiv = document.getElementById('report-table');
                if (scrollDiv) {
                    window.scrollTo({
                        top: scrollDiv.offsetTop + 75,
                        behavior: 'smooth',
                    });
                }
            }, 100);
        } else {
            NotificationManager.warning(
                'No se encontraron datos con el filtro aplicado',
                'ATENCIÓN',
                6000
            );
        }
    };

    const getReport = async (page = 1, filters, fields) => {
        let report = undefined;
        setLoader(true);
        try {
            report = await api.get('reporting/assistance', {
                page,
                ...filters,
                fields: fields,
            });
            if (report) {
                setReport(report);
                setPage(page);
            }
        } catch (e) {
            SwalError('Error', 'No se pudo generar reporte.');
        } finally {
            setLoader(false);
            return report;
        }
    };
    const onChangeAllCheck = (check, fields, values) => {
        const _values = { ...values };
        for (let field of fields) {
            _values[field.name] = check;
        }
        setInitialFilters(_values);
    };

    const tableColumns = useMemo(() => {
        const firstObj =
            report && report.results && report.results.length > 0 ? report.results[0] : [];
        const columns = [];
        for (let key of Object.keys(firstObj)) {
            columns.push({ key: key, name: getFriendlyName(key) });
        }
        return columns;
    }, [report]);

    const onDownloadReport = async() => {
        const downloadButton = document.getElementById('download-button');
        downloadButton.disabled = true;
        let reportUrl = 'reporting/assistance?';
        for (let [key, value] of Object.entries(filtersReport)) {
            if (value !== undefined && value !== null) {
                reportUrl += `${key}=${value}&`;
            }
        }
        if (fields) reportUrl += `fields=${fields.join(',')}&`;
        reportUrl += 'excel=true';
        setLoader(true);
        api.get(reportUrl)
        .catch((err) => {            
            SwalError(
                '¡Error al descargar!',
                'Ocurrió un error al descargar el archivo. Por favor intenté más tarde.'
            );
            setLoader(false);
        })
        .then((data) => {
            if (data) {                
                SwalSuccess('¡Descarga en proceso!',
                'La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo')
                setLoader(false);
                esperarDescarga(data.id);
                setLoader(false);
            }
        });
        
    };

    const esperarDescarga = (id) => {
        const downloadButton = document.getElementById('download-button');
        let intervalPromise;
        function listener() {
            api.get(`reporting/estado_descarga`, { id })
                .catch((err) => {
                    let msg =
                        "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                    if (err.estado) {
                        msg = error.observaciones;
                    }
                    clearInterval(intervalPromise);
                    SwalError(
                        '¡Error al descargar!',
                        msg
                    );
                    setLoader(false);
                })
                .then((resp) => {
                    if (resp.estado === 10) {
                        NotificationManager.info('Generando Documento', 'INFO', 2000);
                        setLoader(false)
                    } else 
                    if (resp.estado === 20) {
                        downloadButton.disabled = false;
                        clearInterval(intervalPromise);
                        let nombre = resp.archivo
                            ? resp.archivo.split("/media_root/archivos/")[1]
                            : "reporte-asistencia.xlsx";
                        const context = {
                            name: name,
                            url: resp.archivo,
                        };
                        descargaArchivo(context);
                        setLoader(false);
                        NotificationManager.success('Descarga realizada', 'ÉXITO', 6000);
                    }
                });
        }
    
        listener();
        intervalPromise = setInterval(listener, 10000);
    };

    const descargaArchivo = (context) =>  {
        let elem = document.createElement("a");
        elem.href = context.url;
        elem.download = context.name;
        elem.target = "hiddenIframe";
        elem.click();
        setLoader(false);
    };

    return (
        <React.Fragment>
            <div className="d-flex pt-5 mb-5">
                <img
                    src={LogoReporting}
                    alt="logo inventario"
                    width="55px"
                    height="55"
                />
                <h1 className="title-2 cl-orange mt-1 ml-3">
                    Reporte Asistencias
                </h1>
            </div>
            <LoadMask loading={loader}>
                <React.Fragment>
                    <MainFilters filters={filters} setFilters={setFilters} />
                    <FilterFields
                        onSubmit={onSubmit}
                        onChangeAllCheck={onChangeAllCheck}
                        initialValues={initialFilters}
                        btnGenerateRep={btnGenerateRep}
                    />
                    <div className="d-flex flex-column-reverse flex-md-row justify-content-between mt-1 mb-2">
                        {report.results.length > 0 ? (
                            <button
                                id='download-button'
                                onClick={onDownloadReport}
                                className="mt-4 mt-md-0"
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: '#375784',
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                }}
                            >
                                Descargar Excel
                            </button>
                        ) : (
                            <div />
                        )}
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                btnGenerateRep.current.click();
                            }}
                        >
                            Generar Reporte
                        </button>
                    </div>
                    <br />
                    {report.results.map((item) => (
                        <ReactTooltip
                            className={'detail-tooltip'}
                            id={item.id + '-name_cell-'}
                            key={item.id + '-tooltip-'}
                            resizeHide={true}
                            multiline={true}
                            insecure={true}
                            effect="float"
                            place="bottom"
                            type="dark"
                        />
                    ))}
                    {tableColumns && tableColumns.length > 0 && (
                        <Table
                            data={report}
                            id={'report-table'}
                            page={page}
                            onPageChange={(page) => {
                                getReport(page, filtersReport, fields);
                            }}
                        >
                            {tableColumns.map((column) => {
                                const isKey = column.key === 'id';
                                return (
                                    <TableHeaderColumn
                                        width={`${isKey ? '0px' : '12rem'}`}
                                        key={column.key}
                                        isKey={isKey}
                                        dataField={column.key}
                                        dataFormat={formatTextEmpty}
                                    >
                                        {column.name}
                                    </TableHeaderColumn>
                                );
                            })}
                        </Table>
                    )}
                </React.Fragment>
            </LoadMask>
        </React.Fragment>
    );
}
