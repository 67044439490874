import React from 'react';

import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import {
    composeValidators,
    required,
} from '../../../../../../utility/validation';
import {
    renderField,
    renderSelectField,
} from '../../../../Utils/renderField/renderField';
import { CATEGORY_OPTIONS } from '../../../../../../utility/constants';

const ProductForm = ({ productData, onSubmit, view, update }) => {
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={productData}
                render={({ handleSubmit, submitting }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="code">Codigo</label>
                                <Field
                                    name="code"
                                    component={renderField}
                                    placeholder="Ingrese el codigo del activo"
                                    type="text"
                                    className="form-control w-100"
                                    validate={composeValidators(required)}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="description">Descripcion</label>
                                <Field
                                    name="description"
                                    component={renderField}
                                    placeholder="Descripcion del activo"
                                    type="text"
                                    className="form-control w-100"
                                    validate={composeValidators(required)}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="category">Categoria</label>
                                <Field
                                    name="category"
                                    label="categoria"
                                    component={renderSelectField}
                                    options={CATEGORY_OPTIONS}
                                    type="text"
                                    className="form-control w-100"
                                    validate={composeValidators(required)}
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/inventory/product_kit"
                                className="btn btn-secondary mt-4 mt-md-0"
                            >
                                Regresar
                            </Link>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    {update ? 'Actualizar' : 'Guardar'}
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default ProductForm;
