import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import LoaderContext from '../../../../context/LoaderContext';
import dayjs from 'dayjs';
import SchoolForm from './SchoolForm';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';

export default function CreateUpdateSchool() {
    const { saveData } = useCreate('school', '/school');
    const { updateData, data, update } = useUpdate('school', '/school');
    const { loader } = useContext(LoaderContext);
    const location = useLocation();

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        body.start_date = dayjs(dataForm.start_date).format('YYYY-MM-DD');
        if (body.end_date)
            body.end_date = dayjs(dataForm.end_date).format('YYYY-MM-DD');
        if (!update) saveData(body);
        else updateData(body);
    };

    const isViewed = location.pathname.includes('view');
    const isUpdated = location.pathname.includes('update');
    const isCreated = location.pathname.includes('create');

    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="pt-5">
                    <h1 className="title-2 cl-orange">
                        {`${
                            isUpdated
                                ? 'Actualizar'
                                : isCreated
                                ? 'Registrar'
                                : ''
                        }`}{' '}
                        colegio
                    </h1>
                    <SchoolForm
                        onSubmit={onSubmit}
                        initialValues={data}
                        view={isViewed}
                    />
                    <br />
                    <br />
                </div>
            </LoadMask>
        </React.Fragment>
    );
}
