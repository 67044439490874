import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import {
    renderField,
    renderAsyncSelectField,
    renderTextArea,
    renderSelectField
} from '../../../Utils/renderField/renderField';
import {
    composeValidators,
    required,
    maxLength,
} from '../../../../../utility/validation';
import {
    LEVEL_TRAINING_PLAN,
} from '../../../../../utility/constants';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';

const algo = (value) => {
    // console.log("value:", value)
}

const SessionForm = (props) => {

    const { onSubmit, initialValues, view, updated, created, goBackButton, modules } = props;

    const { asyncOptions } = useAsyncOptions('module');

    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        setSelectedOption(initialValues.type_program)
    }, []);

    const handleOptionChange = (event) => {
        initialValues.type_program = ""
        const selectedValue = event.target.value;
        setSelectedOption((prevSelectedOption) => {
            // Si el checkbox seleccionado ya estaba seleccionado previamente, deselecciónalo.
            if (prevSelectedOption === selectedValue) {
                return null;
            } else {
                return selectedValue;
            }
        });
    }

    return (
        <Form onSubmit={(formData) => onSubmit({ ...formData, type_program: selectedOption })} initialValues={initialValues}>
            {({ handleSubmit, form, valid }) => {
                const select = Array.from(modules || []).map(
                    (item) => item.id
                );
                return (
                    <form
                        name="SessionForm"
                        className="form-validate mb-lg"
                        onSubmit={async (event) => {
                            await handleSubmit(event);
                            //if (valid) form.restart();
                        }}
                    >
                        <div className="d-flex flex-column flex-md-row  mt-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label>Título del módulo</label>
                                <Field
                                    key={select.id}
                                    name="module"
                                    component={renderAsyncSelectField}
                                    isClearable={true}
                                    loadOptions={(search) =>
                                        asyncOptions(search, {
                                            select: select,
                                        })
                                    }
                                    isSearchable={true}
                                    valueKey="id"
                                    labelKey="module"
                                    type="text"
                                    validate={required}
                                    disabled={view}
                                />
                            </div>
                            <div className="d-none d-md-flex m-2 flex-1" />


                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="code">Nivel</label>
                                <Field
                                    name="level"
                                    component={renderSelectField}
                                    options={LEVEL_TRAINING_PLAN}
                                    isClearable={true}
                                    isSearchable={true}
                                    type="text"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        
                        <div className="d-flex flex-column flex-md-row  mt-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label>Código</label>
                                <Field
                                    name="code"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1">
                                <label>Titulo de la sesión</label>
                                <Field
                                    name="title"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(200)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1">
                                <label>Tipo de Programa</label>
                                <div>
                                    <input
                                        type="checkbox"
                                        name="type_program"
                                        value="teorico"
                                        checked={initialValues.type_program == "teorico" ? true
                                            : selectedOption === "teorico"}
                                        onChange={handleOptionChange}
                                        disabled={view}
                                    />
                                    &nbsp;&nbsp;Teórico
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        name="type_program"
                                        value="practico"
                                        checked={initialValues.type_program == "practico" ? true
                                            : selectedOption === "practico"}
                                        onChange={handleOptionChange}
                                        disabled={view}
                                    />
                                    &nbsp;&nbsp;Práctico
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        name="type_program"
                                        value="nivelacion"
                                        checked={initialValues.type_program == "nivelacion" ? true
                                            : selectedOption === "nivelacion"}
                                        onChange={handleOptionChange}
                                        disabled={view}
                                    />
                                    &nbsp;&nbsp;Nivelación
                                </div>
                            </div>
                        </div>
                        <div className="w-100 d-flex flex-column flex-md-row  mt-4">
                            <div className="w-100 form-group has-feedback m-2 flex-2 session">
                                <label>Resumen de Sesión</label>
                                <Field
                                    name="resume"
                                    component={renderTextArea}
                                    label="resume"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                    rows={10}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            {goBackButton()}

                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary align-self-center mb-3 mb-md-0"
                                >
                                    {`${updated ? 'Actualizar' : 'Registrar'}`}
                                </button>
                            )}
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default SessionForm;
