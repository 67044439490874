import React from 'react';
import Table from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';

export default function GeneralStats({ stats, type_program }) {
    return (
        <React.Fragment>
            <Table data={{ results: [stats], count: 0 }}>
                <TableHeaderColumn
                    isKey
                    width="10rem"
                    dataField="joined_to_program"
                >
                    Ingresaron al programa
                </TableHeaderColumn>
                <TableHeaderColumn width="7rem" dataField="actives">
                    Activos
                </TableHeaderColumn>
                {type_program === 'practico' ? (
                    <TableHeaderColumn width="7rem" dataField="abandonos">
                        Abandonos
                    </TableHeaderColumn>
                ) :
                    <TableHeaderColumn width="7rem" dataField="deserters">
                        Desertores
                    </TableHeaderColumn>
                }
                <TableHeaderColumn width="7rem" dataField="no_ingresaron">
                    No Ingresaron
                </TableHeaderColumn>
                {type_program === 'practico' ? (
                <TableHeaderColumn dataField="alert_abandonos" width="10rem">
                    % Abandono
                </TableHeaderColumn>
                ) :
                <TableHeaderColumn dataField="alert" width="10rem">
                    % Deserción
                </TableHeaderColumn>
                }
                <TableHeaderColumn dataField="assistance" width="10rem">
                    % Asistencia
                </TableHeaderColumn>
                <TableHeaderColumn dataField="alert_assistance" width="10rem">
                    % Inasistencia
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
}
