import React from 'react';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import {
    renderField,
    renderDatePicker,
} from '../../../Utils/renderField/renderField';

import {
    composeValidators,
    required,
    maxLength,
} from '../../../../../utility/validation';

const HolidayForm = ({ onSubmit, view }) => {
    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit, form, errors, valid }) => {
                return (
                    <form
                        name="SessionForm"
                        className="form-validate mb-lg"
                        onSubmit={async (event) => {
                            await handleSubmit(event);
                            if (valid) form.restart();
                        }}
                    >
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 mr-lg-4 flex-1">
                                <label htmlFor="date">Selecionar fecha</label>
                                <Field
                                    name="date"
                                    component={renderDatePicker}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mr-lg-4 flex-1">
                                <label htmlFor="holiday">Dia Festivo</label>
                                <Field
                                    name="holiday"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    disabled={view}
                                    className="form-control"
                                />
                            </div>
                            <div className="d-flex flex-column p-2 flex-1">
                                <label className="m-2 p-1" />
                                <button
                                    type="submit"
                                    className="btn btn-secondary align-self-center mt-1"
                                >
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default HolidayForm;
