import React from 'react';
import PropTypes from 'prop-types';

const Search = (props) => {
    const { onSearch, className, placeholder } = props;
    let timeout = null;
    const onSearchAction = (e) => {
        clearTimeout(timeout);
        const value = e.target.value;
        timeout = setTimeout(() => {
            onSearch(value);
        }, 500);
    };

    return (
        <React.Fragment>
            <div className={`${className || ''}`}>
                <input
                    onKeyUp={onSearchAction}
                    className="form-control col-12 mt-3 mt-md-0 col-md-4 "
                    placeholder={placeholder || 'Buscar ...'}
                ></input>
            </div>
        </React.Fragment>
    );
};

Search.propTypes = {
    onSearch: PropTypes.func.isRequired,
};

export default Search;
