import React, { useState } from 'react';
import IwokaStaffForm from './IwokaStaffForm';
import SchoolStaffForm from './SchoolStaffForm';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import { useNavigate } from 'react-router-dom';
import './register.css';
import dayjs from 'dayjs';
import useAccount from '../../../hooks/useAccount';
import logoIwoka from '../../../../../assets/img/logo-iwoka.svg';
import { SwalSuccess } from '../../Utils/SwalAlerts';
import InputSelect from '../../Utils/InputSelect';

const Register = (props) => {
    const { register, loader } = useAccount();
    const [selectOptions, setSelectOptions] = useState(null);
    const navigate = useNavigate();

    async function onSuccess() {
        const x = await SwalSuccess(
            'Registro exitoso',
            'Se te enviará un correo cuando tu cuenta sea habilitada.'
        );
        navigate('/login');
    }

    const onSubmit = async (data) => {
        const body = { ...data };
        delete body.confirmPassword;
        body.birthday = dayjs(body.birthday).format('YYYY-MM-DD');
        body.admission_date = dayjs().format('YYYY-MM-DD');
        if (data.school) body.school = data.school.id;
        await register(body, onSuccess);
    };

    return (
        <div className="background-register p-2 p-md-5">
            <div className="container-register my-4 p-2 p-md-4 ">
                <div className="d-flex justify-content-between">
                    <h1 className="title-2 font-color-primary">Registrarte</h1>
                    <img src={logoIwoka} className="logo-iwoka" />
                </div>
                <label className="label">Tipo de cuenta</label>
                <InputSelect
                    value={selectOptions}
                    isClearable={true}
                    placeholder="Seleccione tipo cuenta"
                    onChange={(value) => setSelectOptions(value)}
                    options={[
                        { value: 1, label: 'Personal Iwoka' },
                        { value: 2, label: 'Personal Colegio' },
                    ]}
                />
                <br />
                <LoadMask loading={loader} blur>
                    {selectOptions === 1 && (
                        <IwokaStaffForm onSubmit={onSubmit} />
                    )}
                    {selectOptions === 2 && (
                        <SchoolStaffForm onSubmit={onSubmit} />
                    )}
                    {![1, 2].includes(selectOptions) && (
                        <React.Fragment>
                            <div className="d-flex justify-content-center align-content-center h-100 my-5">
                                <div className="d-none d-md-flex my-5" />
                                <h2 className="font-size-2 font-color-secondary text-center">
                                    Debe seleccionar el tipo de cuenta que desea
                                    <br />
                                    solicitar para ingresar al sistema Iwoka.
                                </h2>
                            </div>
                        </React.Fragment>
                    )}
                </LoadMask>
            </div>
        </div>
    );
};

export default Register;
