import React from 'react';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import {
    renderField,
    renderTextArea,
} from '../../../Utils/renderField/renderField';
import {
    composeValidators,
    required,
    maxLength,
} from '../../../../../utility/validation';

const ModuleForm = (props) => {
    const { onSubmit, initialValues, view, updated, created, goBackButton } =
        props;
    return (
        <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({ handleSubmit }) => {
                return (
                    <form
                        name="ModuleForm"
                        className="form-validate mb-lg"
                        onSubmit={ handleSubmit }
                    >
                        <div className="d-flex flex-column flex-md-row  mt-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label>Titulo del Módulo</label>
                                <Field
                                    name="module"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(200)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1">
                                <label>Código</label>
                                <Field
                                    name="ext"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="w-100 d-flex flex-column flex-md-row  mt-4">
                            <div className="w-100 form-group has-feedback m-2 flex-2 session">
                                <label>Descripción del Módulo</label>
                                <Field
                                    name="description"
                                    component={renderTextArea}
                                    label="description"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                    rows={10}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            {goBackButton()}

                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary align-self-center mb-3 mb-md-0"
                                >
                                    {`${updated ? 'Actualizar' : 'Registrar'}`}
                                </button>
                            )}
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default ModuleForm;
