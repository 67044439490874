import React , { useState, useContext, useEffect }from 'react';
import AsyncSelect from 'react-select/async';

const customStylesReactSelect = {
    option: (provided, state) => {
        return {
            ...provided,
            color: '#484848',
            padding: '0.5rem',
            backgroundColor: state.isFocused ? '#FBD0CB' : '#fff',
            ':active': {
                backgroundColor: '#ef5543',
                color: '#fff',
            },
        };
    },
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#f5f6f7' : '#f1f1f1',
        color: '#484848',
        borderRadius: '1rem',
        borderColor: state.isFocused ? '#FBD0CB' : provided.borderColor,
        border: 'unset',
        boxShadow: 'unset',
    }),
};

export default function AsyncSelectFieldAssistance({
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    loadOptions,
    placeholder,
    labelKey = 'label',
    labelKey2 = undefined,
    valueKey = 'value',
    invalid = undefined,
    onChange = () => {},
    value = null,
    className = undefined,
    customStyles = {},
    goodfather_relation=[],
    ...props
}) {
    console.log("🚀 ~ goodfather_relation:", goodfather_relation)

    const [optionsList, setOptionsList] = useState([]);

    const modifiedLoadOptions = async (inputValue) => {
        
        try {
            const options = await loadOptions(inputValue);
            setOptionsList(options); 

            const filteredGoodfathers = options.filter( option => !goodfather_relation.some(padrino => padrino.id === option.id) );
            const combinedArray = [...goodfather_relation, ...filteredGoodfathers];

            return combinedArray;
        } catch (error) {
            console.error("Error loading options:", error);
            return [];
        }
            
    };

    return (
        <React.Fragment>
            <div className={`${className || ''}`}>
                <AsyncSelect
                    styles={{ ...customStylesReactSelect, ...customStyles }}
                    isClearable={isClearable}
                    cacheOptions
                    className={`react-select-container  ${
                        invalid ? 'is-invalid' : ''
                    }`}
                    backspaceRemovesValue={false}
                    isSearchable={isSearchable || false}
                    defaultOptions
                    isMulti={isMulti || false}
                    loadOptions={modifiedLoadOptions}
                    placeholder={placeholder}
                    onChange={(e) => {
                        onChange(e ? e : null);
                    }}
                    getOptionValue={(option) => option[valueKey]}
                    getOptionLabel={(option) =>
                        `${option[labelKey]} ${option[labelKey2] || ''}`
                    }
                    value={value}
                    isDisabled={disabled}
                    {...props}
                />
            </div>
        </React.Fragment>
    );
}