import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import LoaderContext from '../../../../context/LoaderContext';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';
import useList from '../../../../hooks/useList';
import useDelete from '../../../../hooks/useDelete';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import HolidayForm from './HolidayForm';
import HolidayTable from './HolidayTable';
import UserContext from '../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN,
    COORDINATOR
} from '../../../../../utility/constants'

const Holiday = (props) => {
    const [year, setYear] = useState({ year: dayjs().year() });
    const { asyncOptions } = useAsyncOptions('holiday/years/years');

    const [loadTable, setLoadTable] = useState(false);
    const [reload, setReload] = useState(false);

    const { saveData } = useCreate('holiday', '/calendar/holiday');
    const { update } = useUpdate('holiday', '/holiday');

    const { data, page, getData } = useList('holiday', {
        year: year.year,
    });
    const { deleteData } = useDelete('holiday');
    const { loader } = useContext(LoaderContext);
    const { user }= useContext(UserContext);

    const onChangeYear = (e) => {
        if (e == null) {
            e = { year: dayjs().year() };
            setYear(null);
        } else {
            setYear(e);
        }

        getData(1, e);
    };

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        body.date = dayjs(body.date).format('YYYY-MM-DD');
        setReload(!reload)
        if (!update) {
            saveData(body).then((item) => {
                setLoadTable(true);
            });
        }
    };

    const syncWait = (ms) => {
        const end = Date.now() + ms;
        while (Date.now() < end) continue;
    };

    useEffect(() => {
        if (loadTable) {
            syncWait(500);
            getData(1, year);
            setLoadTable(false);
        }
    }, [loadTable]);

    return (
        <React.Fragment>
            <div className="pt-5">
                <div className="d-flex flex-column-reverse flex-md-row justify-content-between">
                    <h1 className="title-2 cl-orange">Calendario</h1>
                </div>
                { (user.type === SUPER_ADMIN || user.type === ASSISTANT || user.type == COORDINATOR) &&(
                <HolidayForm onSubmit={onSubmit} />
                )}
                <br />
            </div>
            <hr />
            <HolidayTable
                data={data}
                page={page}
                getData={getData}
                deleteData={deleteData}
                loader={loader}
                onChangeYear={onChangeYear}
                asyncOptions={asyncOptions}
                year={year}
                setLoadTable = {setLoadTable}
                reload={reload}
                setReload={setReload}
            />
        </React.Fragment>
    );
};

export default Holiday;
