import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAccount from './common/hooks/useAccount';

// maquetas base
import SiderBar from './common/components/layout/Sidebar/SideBar';
import Navbar from './common/components/layout/Navbar/Navbar';
import NotFound from './common/components/layout/NotFound/NotFound';

export default function PrivateRouteBase(props) {
    const [toggleOpen, setToggleOpen] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [showNavbar, setShowNavbar] = useState(true);
    const { user, getMe } = useAccount();
    const location = useLocation();
    const navigate = useNavigate();
    const url = window.location.href;

    const isAuthenticated = () => {
        if (user && !user.username) {
            getMe();
            return 'Verifying';
        } else {
            if (user.has_temp_pwd && !url.includes('change-password')) {
                navigate(`/change-password/${user.username}`);
            }
            return true;
        }
    };

    const havePermissions = () => {
        if (user.type === 1 || user.type === 2 || user.type === 3 || user.type === 4) {
            return 'Ok';
        }
    }

    useEffect(() => {
        if (url.includes('change-password')) {
            setShowNavbar(false);
            setShowSidebar(false);
        } else {
            setShowNavbar(true);
            setShowSidebar(true);
        }
    }, [location.pathname]);

    const isAuth = isAuthenticated();
    const havePermission = havePermissions();

    return (
        <React.Fragment>
            {isAuth === true && (
                <div>
                    {showSidebar ? (
                        <React.Fragment>
                            <main>
                                {havePermission ? (
                                    props.children
                                ) : (
                                    <NotFound />
                                )}
                            </main>
                        </React.Fragment>
                    ) : (
                        <div className="main-content-container">
                            {props.children}
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
