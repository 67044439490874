import React from 'react';
import AsyncSelect from 'react-select/async';

const customStylesReactSelect = {
    option: (provided, state) => {
        return {
            ...provided,
            color: '#484848',
            padding: '0.5rem',
            backgroundColor: state.isFocused ? '#FBD0CB' : '#fff',
            ':active': {
                backgroundColor: '#ef5543',
                color: '#fff',
            },
        };
    },
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#f5f6f7' : '#f1f1f1',
        color: '#484848',
        borderRadius: '1rem',
        borderColor: state.isFocused ? '#FBD0CB' : provided.borderColor,
        border: 'unset',
        boxShadow: 'unset',
    }),
};

export default function AsyncSelectField({
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    loadOptions,
    placeholder,
    labelKey = 'label',
    labelKey2 = undefined,
    valueKey = 'value',
    invalid = undefined,
    onChange = () => {},
    value = null,
    className = undefined,
    customStyles = {},
    ...props
}) {
    return (
        <React.Fragment>
            <div className={`${className || ''}`}>
                <AsyncSelect
                    styles={{ ...customStylesReactSelect, ...customStyles }}
                    isClearable={isClearable}
                    cacheOptions
                    className={`react-select-container  ${
                        invalid ? 'is-invalid' : ''
                    }`}
                    backspaceRemovesValue={false}
                    isSearchable={isSearchable || false}
                    defaultOptions
                    isMulti={isMulti || false}
                    loadOptions={loadOptions}
                    placeholder={placeholder}
                    onChange={(e) => {
                        onChange(e ? e : null);
                    }}
                    getOptionValue={(option) => option[valueKey]}
                    getOptionLabel={(option) =>
                        `${option[labelKey]} ${option[labelKey2] || ''}`
                    }
                    value={value}
                    isDisabled={disabled}
                    {...props}
                />
            </div>
        </React.Fragment>
    );
}
