import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GroupStudentForm from './GroupStudentForm';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';
import _ from 'lodash';
import './group_students.css';
import { useNavigatingAway } from '../../../../hooks/useNavigatingAway';
import { DialogBeforeUnLoad } from '../../../Utils/DialogBeforeUnLoad';
import { areObjectsEqual } from '../../../Utils/Funcs';
import { SwalSuccess } from '../../../Utils/SwalAlerts';

export default function GroupStudent() {
    const location = useLocation();
    const { saveData } = useCreate(
        'group_beneficiaries',
        '/group_beneficiaries'
    );
    const { data, updateData, update, id } = useUpdate(
        'group_beneficiaries',
        '/group_beneficiaries',
        '/group_beneficiaries',
        true,
        false,
        true
    );

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        if (body.school) body.school = body.school.id;
        setFirstValuesOnRender(null);
        if (!update) saveData(body);
        else updateData(body);
    };

    const isViewed = location.pathname.includes('view');
    const isUpdated = location.pathname.includes('update');
    const isCreated = location.pathname.includes('create');

    const [firstValuesOnRender, setFirstValuesOnRender] = useState(null);

    const [isChanged, setIsChangedData] = useState(false);

    const [canShowDialogBeforeUnLoad, setCanShowDialogBeforeUnLoad] = useState(
        (isUpdated || isCreated) && isChanged
    );

    const handleDataChange = (newData) => {
        if (!firstValuesOnRender) {
            if (newData && newData.beneficiaries && newData.beneficiaries.length > 0) {
                setFirstValuesOnRender(newData);
            }
        } else {
            const isDataChanged = !areObjectsEqual(
                firstValuesOnRender,
                newData
            );
            setIsChangedData(isDataChanged);
            setCanShowDialogBeforeUnLoad(isDataChanged);
        }        
    };

   const [showDialogBeforeUnLoad, confirmNavigation, cancelNavigation] =     
        useNavigatingAway(canShowDialogBeforeUnLoad);

    useEffect(() => {}, [canShowDialogBeforeUnLoad, isChanged]);


    const handleClick = async (event, id) => {
        event.preventDefault()

        const downloadButton = document.getElementById('download-button');
        downloadButton.disabled = true;
        // Show the "Downloading" alert using SweetAlert
        SwalSuccess('¡Descarga en proceso!',
        'La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo')


        try {
            // Start the download by fetching the resource
            const response = await fetch(`api/group_beneficiaries/${id}/generate_reporting_excel`);

            if (response.ok) {
                // If the response is successful, trigger the download
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style = 'display: none';
                a.href = url;
                a.download = 'report.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                downloadButton.disabled = false;
                // Show "Download Completed" alert
                NotificationManager.success('Descarga realizada', 'ÉXITO', 6000);
            } else {
                // If the response is not successful, show "Processing Document" alert
                const processingAlert = setInterval(() => {
                    NotificationManager.info('Generando Documento', 'INFO', 6000);
                }, 10000);

                // Check if the download is completed every 1 second
                const checkDownload = setInterval(async () => {
                    const checkResponse = await fetch(`api/group_beneficiaries/${id}/generate_reporting_excel`);
                    if (checkResponse.ok) {
                        // The download is completed, clear the intervals and show "Download Completed" alert
                        downloadButton.disabled = false;
                        clearInterval(processingAlert);
                        clearInterval(checkDownload);
                        NotificationManager.success('Descarga realizada', 'ÉXITO', 6000);
                    }
                }, 1000);
            }
        } catch (error) {
            // If there was an error, show "Processing Document" alert
            const processingAlert = setInterval(() => {
                NotificationManager.info('Generando Documento', 'INFO', 6000);
            }, 10000);

            // Check if the download is completed every 1 second
            const checkDownload = setInterval(async () => {
                const checkResponse = await fetch(`api/group_beneficiaries/${id}/generate_reporting_excel`);
                if (checkResponse.ok) {
                    // The download is completed, clear the intervals and show "Download Completed" alert
                    downloadButton.disabled = false;
                    clearInterval(processingAlert);
                    clearInterval(checkDownload);
                    NotificationManager.success('Descarga realizada', 'ÉXITO', 6000);
                }
            }, 1000);
        }
    }

    return (
        <React.Fragment>
            <DialogBeforeUnLoad
                showDialog={showDialogBeforeUnLoad}
                setShowDialog={setCanShowDialogBeforeUnLoad}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
            <div className="pt-5">
                <h1 className="title-2 cl-orange m-2">
                    {`${
                        isUpdated ? 'Actualizar' : isCreated ? 'Registrar' : ''
                    }`}{' '}
                    Grupo beneficiarios
                </h1>
                <GroupStudentForm
                    onSubmit={onSubmit}
                    groupId={id}
                    initialValues={{
                        status: true,
                        ...data,
                    }}
                    handleDataChange={handleDataChange}
                    view={isViewed}
                    isCreated ={isCreated}
                />
                <br />
         
                <button
                    id='download-button'
                    onClick={(event) => handleClick(event, id)}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#375784',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                    }}
                    className="mb-5"
                >
                    Descargar Excel
                </button>
            
            </div>
        </React.Fragment>
    );
}
