import React from 'react';
import NumberFormat from 'react-number-format';

export const RenderNumber = ({
    value,
    decimalScale,
    className,
    prefix = '',
    suffix = '',
}) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale || 0}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix={prefix}
            suffix={suffix}
            displayType="text"
        />
    );
};

export const RenderCurrency = ({ value, className }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={2}
            fixedDecimalScale
            value={value}
            thousandSeparator
            prefix="Q "
            displayType="text"
        />
    );
};

export const RenderDateTime = ({ value, className }) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>
                {fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}
            </span>
        );
    }
    return <span className={className}>{value}</span>;
};

export const ReadFields = {
    renderCurrency: RenderCurrency,
    renderNumber: RenderNumber,
};

export const RenderCheckBox = ({ value = '', className = '', label = '' }) => {
    return (
        <div
            className={`checkbox c-checkbox ${className || ''}`}
            style={{ cursor: 'unset' }}
        >
            <label className="">
                <input
                    type="checkbox"
                    checked={value ? value : false}
                    style={{ cursor: 'unset' }}
                    readOnly={true}
                />
                <span className="fa fa-check" />
                &nbsp;{label}
            </label>
        </div>
    );
};

export const RenderText = ({ value = '', className = '' }) => {
    return (
        <span title={value} className={className}>
            {value}
        </span>
    );
};

export function textWithTooltip(value) {
    return <RenderText value={value} />;
}
