import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import ModalFilter from './ModalFilter';

const MainFilters = (props) => {
    const { initialValues, setInitialValues, setGetFilters } = props;

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-md-row  mb-1">
                <div className="form-group has-feedback flex-1">
                    <button
                        className="btn btn-secondary btn-calendar-filter align-self-center"
                        onClick={onOpenModal}
                    >
                        Agregar filtros
                    </button>
                </div>
            </div>
            {open && (
                <Modal open={open} onClose={onCloseModal} center>
                    <div className="d-flex flex-column-reverse flex-md-row justify-content-between">
                        <h1 className="title-2 cl-orange">
                            Filtros sobre calendario
                        </h1>
                    </div>

                    <hr />
                    <ModalFilter
                        setOpen={setOpen}
                        initialValues={initialValues}
                        setInitialValues={setInitialValues}
                        setGetFilters={setGetFilters}
                    />
                    <hr />
                </Modal>
            )}
        </React.Fragment>
    );
};

export default MainFilters;
