import React from 'react';
import { Field, Form } from 'react-final-form';

import { renderSelectField } from '../../../../Utils/renderField/renderField';
import { required } from '../../../../../../utility/validation';
import {
    DESERTED,
    ACTIVE,
    NOT_ENTER,
    DESERTED_OPTIONS,
} from '../../../../../../utility/constants';

const STATUS_OPTIONS = [
    { value: ACTIVE, label: 'Activo' },
    { value: DESERTED, label: 'Desertado' },
    { value: NOT_ENTER, label: 'No ingresó' },
];

const DisabledBeneficiaryForm = ({ initialValues, onSubmit }) => {
    return (
        <Form initialValues={{ ...initialValues }} onSubmit={onSubmit}>
            {({ handleSubmit, submitting, values }) => {
                return (
                    <form
                        name="beneficiaryForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        <div className="d-flex flex-column flex-lg-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="status">
                                    Estado Teórico del usuario
                                </label>
                                <Field
                                    name="status"
                                    component={renderSelectField}
                                    options={STATUS_OPTIONS}
                                    isSearchable={false}
                                    validate={required}
                                    modal={true}
                                    className="form-control w-100"
                                />
                            </div>
                        </div>
                        {values.status === DESERTED && (
                            <div className="d-flex flex-column flex-lg-row">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="desertion_reason">
                                        Razón de deserción
                                    </label>
                                    <Field
                                        name="desertion_reason"
                                        component={renderSelectField}
                                        isSearchable={false}
                                        type="text"
                                        validate={required}
                                        modal={true}
                                        options={DESERTED_OPTIONS}
                                        className="form-control w-100"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="container bg-light mt-4">
                            <div className="col-md-12 text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary "
                                    disabled={submitting}
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
};

export default DisabledBeneficiaryForm;
