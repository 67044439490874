import { useContext } from 'react';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { api } from 'api';
import { INITIAL_DATA } from '../../utility/constants';
import LoaderContext from '../context/LoaderContext';
import _ from 'lodash';
import { SwalError } from '../components/Utils/SwalAlerts';
import MissingProductsContext from '../context/MissingProductsContext';
import useIsOffline from './useIsOffline';

// To display products without inventory stock, in a readable format
const formatErrors = (missing_products) => {
    return missing_products
        .map((product, index) => {
            return `
            <div ${index !== 0 ? 'style="margin-top: -70px !important;' : ''}">
                Artículo: <span style="font-weight: bold;">${
                    product.product
                }</span>, Serie: <span style="font-weight: bold;">${
                product.serial_number === 'NO_SERIAL'
                    ? '---'
                    : product.serial_number
            }</span>
                Stock Actual: <span style="font-weight: bold;">${
                    product.stock
                }</span>, Cantidad Sale: <span style="font-weight: bold; color: #ef5543;">${
                product.total_quantity
            }</span>
            </div> <hr>
        `;
        })
        .join('');
};

const useCreate = (name_api, path_module, initial_params = {}) => {
    const { setLoader } = useContext(LoaderContext);
    // context of missing products
    const { setMissingProducts } = useContext(MissingProductsContext);
    const { isOffline } = useIsOffline();
    const navigate = useNavigate();
    const data = INITIAL_DATA;

    const saveData = async (body, attachments = []) => {
        setLoader(true);
        let res;
        try {
            const _params = { ...initial_params };
            if (attachments.length > 0) {
                res = await api.postAttachments(
                    name_api,
                    body,
                    attachments,
                    _params
                );
            } else {
                res = await api.post(name_api, body, _params);
            }
            isOffline(res);
            NotificationManager.success('Registro exitoso', 'ÉXITO', 6000);
            if (path_module) {
                navigate(path_module);
            }
            return res;
        } catch (e) {
            let message = 'Error interno.';
            if (e && e.detail) message = e.detail;
            if (e && e.code) message = e.code[0];
            if (e && e.username) message = e.username[0];
            else if (_.isArray(e)) message = e;

            if (e && e.errors_inventory) {
                message = e.errors_inventory;
                // set the missing products (message contains missing products)
                setMissingProducts(message);
                const missing_products = formatErrors(message);

                // button to download the pdf, onclick simulate the click to the element with the id download-pdf
                const button_print = `<button style="color: #375784; text-decoration: underline; border-style: none; background-color: transparent;" onclick="document.getElementById('download-pdf').click();">Descargar PDF</button>`;

                SwalError(
                    'Productos sin Stock',
                    String(missing_products) + button_print
                );
            } else if (e && e.msg) {
                message = e.msg;
                SwalError('Conflicto', String(message));
            } else {
                NotificationManager.error(message, 'ERROR', 7000);
            }
        } finally {
            setLoader(false);
        }
        return res;
    };

    return { data, saveData };
};

export default useCreate;
