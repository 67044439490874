import React from 'react';
import { Field } from 'react-final-form';
import {
    composeValidators,
    minValue,
    required,
} from '../../../../../../utility/validation';
import useAsyncOptions from '../../../../../hooks/useAsyncOptions';
import {
    renderAsyncSelectField,
    renderField,
} from '../../../../Utils/renderField/renderField';

const BodyForm = ({ editable_name, isUpdate, kit }) => {
    const { asyncOptions: getProducts } = useAsyncOptions('product');

    // if it is update, activate the edition to the name field
    if (isUpdate) editable_name = false;

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-md-row mt-md-4">
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="name">Nombre del Kit</label>
                    <Field
                        name="name"
                        component={renderField}
                        placeholder="Ingrese nombre del kit"
                        type="text"
                        className="form-control w-100"
                        disabled={editable_name}
                        validate={required}
                        parse={(name) => {
                            // get the value of the input name, and if it changes, rename the others
                            kit.map((_kit) => (_kit.name = name));
                            return name;
                        }}
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1"></div>
                <div className="form-group has-feedback m-2 flex-1"></div>
                <div className="form-group has-feedback m-2 flex-1"></div>
            </div>
            <div className="d-flex flex-column flex-md-row mt-md-4">
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="product">Seleccione Artículo</label>
                    <Field
                        name="product"
                        label="Seleccione Producto"
                        component={renderAsyncSelectField}
                        isSearchable={true}
                        isClearable={true}
                        loadOptions={getProducts}
                        valueKey="id"
                        labelKey="description"
                        labelKey2="code"
                        type="text"
                        validate={required}
                        className="w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="quantity">Cantidad</label>
                    <Field
                        name="quantity"
                        component={renderField}
                        placeholder="Ingrese la cantidad del producto"
                        type="number"
                        className="form-control w-100"
                        validate={composeValidators(required, minValue(1))}
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1"></div>
                <div className="form-group has-feedback m-2 flex-1 d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary mt-4"
                        onClick={() => {}}
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BodyForm;
