import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ChangePasswordForm from './ChangePasswordForm';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import LoaderContext from '../../../context/LoaderContext';
import useAccount from '../../../hooks/useAccount';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';

import '../passwordRecovery.css';

const validateToken = async (username, token, history) => {
    return await api
        .post('user/validate_token', { username, token })
        .catch(() => {
            NotificationManager.error(
                'El enlace de recuperación de contraseña ha expirado.',
                'ERROR',
                6000
            );
            if (user && user.username) {
                localStorage.removeItem('token');
                navigate('/login'); 
            } else {
                navigate('/login'); 
            }
            history.push('/password-recovery');
        });
};

const updatePassword = async (endpoint, username, token, password) => {
    return await api.post(endpoint, {
        username,
        token,
        password,
    });
};

const ChangePassword = (props) => {
    const { user, getMe } = useAccount();
    const { loader, setLoader } = useContext(LoaderContext);
    const navigate = useNavigate();
    const { username, token } = useParams();

    useEffect(() => {
        // If me.username doesn`t exists is because the user is not authenticated
        if (user && !user.username)
            validateToken(username, token, history).then();
    }, []);

    useEffect(() => {
        if (user && user.username && !user.has_temp_pwd) {
            navigate('/');
        }
    }, [user]);

    const onSubmit = (data) => {
        const { password } = data;

        const endpoint =
            user && user.username
                ? 'user/update_password_auth'
                : 'user/update_password';
        setLoader(true);
        updatePassword(endpoint, username, token, password)
            .then(() => {
                NotificationManager.success(
                    'Cambio de contraseña exitoso.',
                    'ÉXITO',
                    6000
                );
                if (user && user.username) getMe().then();
                else navigate('/login');
            })
            .catch(() => {
                NotificationManager.error(
                    'El enlace de recuperación de contraseña ha expirado.',
                    'ERROR',
                    6000
                );
                navigate('/password-recovery');
            })
            .finally(() => {
                setLoader(false);
            });
    };
    return (
        <React.Fragment>
            <div className="background-pwd d-flex flex-column justify-content-center">
                <div className="pwd-wrapper">
                    <div className="card card-pwd col-10 col-md-6 col-lg-4 col-xl-3">
                        <div className="d-flex flex-column align-items-center pt-3 bienvenida">
                            <h1 className="text-center text-white">
                                Cambio de contraseña
                            </h1>
                        </div>
                        <span className="px-4 mx-1">
                            Ingresa tu nueva contraseña
                        </span>

                        <LoadMask loading={loader} blur>
                            <ChangePasswordForm onSubmit={onSubmit} />
                        </LoadMask>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ChangePassword;
