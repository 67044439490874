import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const localizedFormat = require('dayjs/plugin/localizedFormat');
import locale_es from 'dayjs/locale/es';

export const DEFAULT_TIMEZONE = 'America/Bogota';

export default function useDayjsUtils() {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(localizedFormat);

    const toTimeTz = (value) => {
        if (dayjs(value).isValid())
            return dayjs(value).tz(DEFAULT_TIMEZONE).format('hh:mm A');
        return '';
    };

    const toDateTz = (value) => {
        if (dayjs(value).isValid())
            return dayjs(value).tz(DEFAULT_TIMEZONE).format('DD-MM-YYYY');
        return '';
    };

    const toLocaleDateTimeTz = (value, format = 'DD-MM-YYYY') => {
        if (dayjs(value).isValid())
            return dayjs(value)
                .tz(DEFAULT_TIMEZONE)
                .locale(locale_es)
                .format(format);
        return '';
    };


    const  changeTimeZone = (date, timeZone) => {
        if (typeof date === 'string') {
          return new Date(
            new Date(date).toLocaleString('en-US', {
              timeZone,
            }),
          );
        }
      
        return new Date(
          date.toLocaleString('en-US', {
            timeZone,
          }),
        );
    }


    const addDaysToDate = (date, days) =>{
      var res = new Date(date);
      res.setDate(res.getDate() + days);
      return res;
    }

    return { toTimeTz, toDateTz, toLocaleDateTimeTz, changeTimeZone, addDaysToDate };
}
