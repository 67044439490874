import React, { useContext } from 'react';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import Modal from 'react-responsive-modal';
import './Modal.css';
import LoaderContext from '../../../../../context/LoaderContext';

const ModalForm = ({
    ComponentForm,
    Body,
    ComponentButton,
    styleButton = '',
    title,
    openModal,
    setOpenModal,
    onCloseModal,
    formData,
    onSubmit,
}) => {
    const { loader } = useContext(LoaderContext);
    return (
        <React.Fragment>
            {ComponentButton && (
                <ComponentButton
                    className={styleButton}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                />
            )}
            <div className="register-modal">
                <Modal
                    open={openModal}
                    onClose={onCloseModal}
                    focusTrapped={false}
                    center={true}
                    classNames={{
                        modal: 'customModal px-5',
                    }}
                >
                    <div className="pt-5">
                        <h1 className="title-2 cl-orange">{title}</h1>
                    </div>
                    <div className="body-modal">
                        <LoadMask loading={loader} blur>
                            {ComponentForm && (
                                <ComponentForm
                                    formData={formData}
                                    onSubmit={onSubmit}
                                    onCloseModal={onCloseModal}
                                />
                            )}
                            {Body && Body}
                        </LoadMask>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default ModalForm;
