import React from 'react';
import { Field } from 'react-final-form';
import {
    composeValidators,
    date,
    minValue,
    required,
} from '../../../../../../utility/validation';
import useAsyncOptions from '../../../../../hooks/useAsyncOptions';
import {
    renderAsyncSelectField,
    renderDatePicker,
    renderField,
} from '../../../../Utils/renderField/renderField';

import TabButton from '../../Utils/TabButton';

const BodyForm = ({ inputEditabel }) => {
    const { asyncOptions: getSchoolKit } = useAsyncOptions('school_kit');
    const { asyncOptions: getLocationsOrigin } = useAsyncOptions('location');

    return (
        <React.Fragment>
            <TabButton />
            <div className="d-flex flex-column flex-md-row pt-4">
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="location">Ubicacion</label>
                    <Field
                        name="location"
                        label="Seleccione Ubicacion"
                        component={renderAsyncSelectField}
                        isSearchable={true}
                        isClearable={true}
                        loadOptions={getLocationsOrigin}
                        valueKey="id"
                        labelKey="name"
                        labelKey2=""
                        type="text"
                        validate={required}
                        disabled={inputEditabel}
                        className="w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="date">Fecha de la conversión</label>
                    <Field
                        name="date"
                        label="Fecha de la conversión"
                        component={renderDatePicker}
                        type="text"
                        value={() => new Date()}
                        validate={composeValidators(required, date)}
                        disabled={true}
                        className="form-control w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1"></div>
                <div className="form-group has-feedback m-2 flex-1"></div>
            </div>
            <div className="d-flex flex-column flex-md-row mt-md-4">
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="product">Seleccione Kit escolar</label>
                    <Field
                        name="product"
                        label="Seleccione Producto"
                        component={renderAsyncSelectField}
                        isSearchable={true}
                        isClearable={true}
                        loadOptions={getSchoolKit}
                        valueKey="id"
                        labelKey="name"
                        type="text"
                        validate={required}
                        className="w-100"
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label htmlFor="quantity">Cantidad</label>
                    <Field
                        name="quantity"
                        component={renderField}
                        placeholder="Ingrese la cantidad del producto"
                        type="number"
                        className="form-control w-100"
                        validate={composeValidators(required, minValue(1))}
                    />
                </div>
                <div className="form-group has-feedback m-2 flex-1"></div>
                <div className="form-group has-feedback m-2 flex-1 d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary mt-4"
                        onClick={() => {}}
                    >
                        Agregar
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default BodyForm;
