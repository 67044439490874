import React, { useEffect, useMemo } from 'react';
import Table from '../../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import ReactTooltip from 'react-tooltip';
import { schoolFields } from '../FilterInputs/checkbox_filters';
import _ from 'lodash';

const TableSchool = ({ data, loader, page, reportData, getData }) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const columns = useMemo(() => {
        const firstRecord = _.get(data, 'results[0]', {});
        const _columns = [{ value: 'Id', name: 'id' }];
        schoolFields.forEach((column) => {
            if (Object.keys(firstRecord).includes(column.name)) {
                _columns.push(column);
            }
        });
        return _columns;
    }, [data.results]);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const formatTextEmpty = (cell, row) => {
        if (!cell) return '---';
        return (
            <React.Fragment>
                <div
                    data-for={row.id + '-name_cell-'}
                    data-tip={`
                    ${cell}
                `}
                >
                    {cell}
                </div>
            </React.Fragment>
        );
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    return (
        <React.Fragment>
            {_.get(data, 'results', []).map((item) => (
                <ReactTooltip
                    className={'detail-tooltip'}
                    id={item.id + '-name_cell-'}
                    key={item.id + '-tooltip-'}
                    resizeHide={true}
                    multiline={true}
                    insecure={true}
                    effect="float"
                    place="bottom"
                    type="dark"
                />
            ))}
            {_.get(data, 'results.length', 0) !== 0 && (
                <Table
                    className="mt-5"
                    data={data}
                    loading={loader}
                    onPageChange={(page) => getData(page, reportData, false)}
                    page={page}
                >
                    {columns.map((column) => (
                        <TableHeaderColumn
                            dataField={column.name}
                            width={`${column.name !== 'id' ? '12rem' : '0px'}`}
                            isKey={column.name == 'id'}
                            key={column.name}
                            dataFormat={formatTextEmpty}
                        >
                            {column.value}
                        </TableHeaderColumn>
                    ))}
                </Table>
            )}
        </React.Fragment>
    );
};

export default TableSchool;
