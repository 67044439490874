import React, { useState } from 'react';
import useCreate from '../../../../../hooks/useCreate';
import ButtonAdd from '../../Utils/ButtonAdd/ButtonAdd';
import ModalForm from '../../Utils/Modal';
import LocationForm from '../ModalForm/LocationForm';

const CreateUpdate = () => {
    const [openModal, setOpenModal] = useState(false);
    const { data, saveData } = useCreate('location', '');
    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        if (body.school) body.school = body.school.id;
        saveData(body).then((d) => {
            window.setFormValue('location', {
                id: d.id,
                name: d.name,
                school: d.school,
            });
        });
        setOpenModal(false);
    };
    return (
        <React.Fragment>
            <ModalForm
                ComponentForm={LocationForm}
                ComponentButton={ButtonAdd}
                title={'Registrar Ubicación'}
                openModal={openModal}
                setOpenModal={setOpenModal}
                onCloseModal={() => setOpenModal(false)}
                formData={data}
                onSubmit={onSubmit}
            />
        </React.Fragment>
    );
};

export default CreateUpdate;
