import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import InputSelect from '../../../Utils/InputSelect';
import dayjs from 'dayjs';
import _ from 'lodash';
import LoaderContext from '../../../../context/LoaderContext';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';
import useRestorePwd from '../../../../hooks/useRestorePwd';
import {
    TYPE_OPERATIONAL_USER_OPTIONS,
    INVITED,
    SCHOOL_USER_TYPES,
    IWOKA_USER_TYPES,
    TRAINER,
} from '../../../../../utility/constants';
import SchoolStaffForm from './SchoolStaffForm';
import IwokaStaffForm from './IwokaStaffForm';
import { SwalError, SwalWarning } from '../../../Utils/SwalAlerts';

const CreateUpdateOperationalUsers = () => {
    const [type, setType] = useState(TRAINER);
    const { saveData } = useCreate('user', '/user');
    const { data, updateData, update, id } = useUpdate('user', '/user');
    const { sendRestorePwd } = useRestorePwd();
    const { loader } = useContext(LoaderContext);
    const location = useLocation();

    useEffect(() => {
        if (data && data.type) {
            if (data.type === INVITED) {
                setType(null);
            } else {
                setType(data.type);
            }
        }
    }, [data]);

    const onRestorePwd = async () => {
        const { value } = await SwalWarning(
            '¿Restablecer contraseña?',
            '¡No podrá revertir esta acción!'
        );
        if (value === true) {
            await sendRestorePwd(`user/${id}/restore_pwd`);
        }
    };

    const onSubmit = (data) => {
        const body = { ...data };
        body.type = type;
        body.birthday = dayjs(data.birthday).format('YYYY-MM-DD');
        body.admission_date = dayjs(data.admission_date).format('YYYY-MM-DD');
        if (body.egress_date) {
            body.egress_date = dayjs(data.egress_date).format('YYYY-MM-DD');
        }
        if (body.immediate_boss) body.immediate_boss = body.immediate_boss.id;
        if (data.school) body.school = data.school.id;
        if (type == null || type == undefined) {
            SwalError(
                'Error',
                'Debe seleccionar el "tipo de usuario" de este registro para continuar.'
            );
        } else {
            if (!update) saveData(body);
            else updateData(body);
        }
    };

    const isViewed = location.pathname.includes('view');
    const isUpdated = location.pathname.includes('update');
    const isCreated = location.pathname.includes('create');

    return (
        <React.Fragment>
            <div className="py-5">
                <div className="d-flex justify-content-between flex-column flex-md-row">
                    <h1 className="title-2 cl-orange">
                        {`${
                            isUpdated
                                ? 'Actualizar'
                                : isCreated
                                ? 'Registrar'
                                : ''
                        }`}{' '}
                        Usuario
                    </h1>
                    {isUpdated && (
                        <div>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onRestorePwd}
                            >
                                Restablecer contraseña
                            </button>
                        </div>
                    )}
                </div>
                <br />
                <LoadMask loading={loader} blur>
                    <div className="d-flex flex-column flex-md-row">
                        <div className="form-group has-feedback m-2 flex-1">
                            <label>Tipo de usuario</label>
                            <InputSelect
                                onChange={setType}
                                options={TYPE_OPERATIONAL_USER_OPTIONS}
                                value={type}
                                disabled={isViewed}
                            />
                        </div>
                        {data.id && type === null ? (
                            <div className="d-flex justify-content-center align-items-center flex-2">
                                <p className="m-0 px-2 text-center">
                                    El usuario se ha registrado <br /> desde el
                                    formulario{' '}
                                    <b className="font-color-primary">
                                        "
                                        {data.edu_flag
                                            ? 'Personal Colegio'
                                            : 'Personal Iwoka'}
                                        "
                                    </b>
                                </p>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div className="d-none d-md-flex flex-1" />
                                <div className="d-none d-md-flex flex-1" />
                            </React.Fragment>
                        )}
                    </div>
                    <br />
                    {((data && data.id) || isCreated) && (
                        <React.Fragment>
                            {(SCHOOL_USER_TYPES.includes(type) ||
                                (type == null && data.edu_flag)) && (
                                <SchoolStaffForm
                                    onSubmit={onSubmit}
                                    initialValues={data}
                                    view={isViewed}
                                />
                            )}
                            {(IWOKA_USER_TYPES.includes(type) ||
                                (type == null && !data.edu_flag)) && (
                                <IwokaStaffForm
                                    onSubmit={onSubmit}
                                    initialValues={data}
                                    view={isViewed}
                                />
                            )}
                        </React.Fragment>
                    )}
                </LoadMask>
            </div>
        </React.Fragment>
    );
};

export default CreateUpdateOperationalUsers;
