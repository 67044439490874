import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Form } from 'react-final-form';

import BodyForm from './BodyForm';
import DetailTable from './DetailTable/DetailTable';
import dayjs from 'dayjs';

const ListConvertKit = ({
    transferData,
    onSubmit,
    update,
    path_back,
    isViewed,
    transfer,
    setTransfer,
    onClick,
}) => {
    // variable to handle allowing to edit supplier, date and location fields if there is already a product added
    const inputEditabel = transfer.length >= 1;
    if (isViewed) {
        const { details } = transferData;
        if (details) {
            // so that it runs only once
            useEffect(() => {
                // data for table
                details.map((transfer) => {
                    transfer.code = transfer.product.code;
                    transfer.description = transfer.product.description;
                    transfer.category = transfer.product.category;
                    transfer.name = transferData.name;
                    transfer.school_kit = transfer.school_kit;
                    transfer.school_kit_name = transfer.school_kit
                        ? transfer.school_kit.name
                        : '';
                });
                // Assign the kit data to the table
                const current_transfer = transferData.details;
                setTransfer(...transfer, current_transfer);
            }, []);
        }
    }
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={transferData}
                mutators={{
                    // expect (field, value) args from the mutator
                    setInput: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                render={({ handleSubmit, submitting, form, values }) => {
                    if (!window.setFormTransfer)
                        window.setFormTransfer = form.mutators.setInput;
                    if (_.get(values, 'date', undefined) === undefined) {
                        form.change('date', dayjs());
                    }

                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            {!isViewed && (
                                <BodyForm inputEditabel={inputEditabel} />
                            )}
                            <DetailTable
                                isViewed={isViewed}
                                transferData={transferData}
                                transfer={transfer}
                                setTransfer={setTransfer}
                            />

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                                <Link
                                    to={path_back}
                                    className="btn btn-secondary mt-4 mt-md-0"
                                >
                                    Regresar
                                </Link>
                                {!isViewed && (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={submitting}
                                        onClick={onClick}
                                    >
                                        {update ? 'Actualizar' : 'Guardar'}
                                    </button>
                                )}
                            </div>
                        </form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default ListConvertKit;
