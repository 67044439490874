import React, { useContext, useEffect, useState } from 'react';

import EntryForm from './EntryForm';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import useUpdate from '../../../../../hooks/useUpdate';
import useCreate from '../../../../../hooks/useCreate';
import LoaderContext from '../../../../../context/LoaderContext';
import MovmentContext from '../../../../../context/MovmentContext';
import { NotificationManager } from 'react-notifications';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import { api } from 'api';
import ModalAutofill from './ModalAutofill';
import Swal from 'sweetalert2';

import EntryLogo from '../../../../../../../assets/img/entrada-logo.svg';
import TitleIcon from '../../Utils/TitleIcon';

import './DetailTable.css';
import _ from 'lodash';

const CreateUpdate = ({ path_back }) => {
    const [modal, setModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [addedProduct, setAddedProduct] = useState([]);
    const [radio, setRadio] = useState(1);

    const location = useLocation();
    const isViewed = location.pathname.includes('view');
    // radio button state (movment)
    // 1 product
    // 2 school kit
    const { movment, setMovment } = useContext(MovmentContext);
    const isProduct = movment === 1;
    useEffect(() => {
        setMovment(1);
    }, []);

    const { data, title, update, updateData } = useUpdate('entries', path_back);

    const { saveData: saveEntry } = useCreate('entries', '/inventory/entry');

    const { loader, setLoader } = useContext(LoaderContext);

    const [entries, setEntries] = useState([]);

    const makeRandomId = () => {
        let a = new Date();
        let b = a.toISOString();
        let id = b.replace(/[-T:.Z]/g, '');
        return id;
    };

    const saveData = () => {
        if (entries.length < 1) {
            // no product added
            NotificationManager.error(
                'Agrega al menos 1 Artículo',
                'ERROR',
                6000
            );
        } else {
            // Formatting the data to a format acceptable to the entry api
            const details = []; /// array with the formatted products
            entries.map((product_entry) => {
                const {
                    product,
                    serie,
                    assigned_to,
                    observations,
                    location,
                    sub_location,
                    quantity,
                    price,
                } = product_entry;
                const isKit = product_entry.product.school_kit; // true false
                // If it is a kit, separate the products and format the data
                if (isKit) {
                    const kit_products = product_entry.product.school_kit;
                    const school_kit = product_entry.product.id;
                    kit_products.map((kit_product) => {
                        // Total products to enter
                        const total_quantity = quantity * kit_product.quantity;
                        details.push({
                            product: kit_product.product.id,
                            quantity: parseInt(total_quantity),
                            serial_number: serie,
                            assigned_to: assigned_to.id || '',
                            observations,
                            sub_location,
                            cost: parseFloat(price),
                            location: location.id,
                            school_kit,
                        });
                    });
                } else {
                    const school_kit = '';
                    // format the data
                    details.push({
                        product: product.id,
                        quantity: parseInt(quantity),
                        serial_number: serie,
                        assigned_to: assigned_to.id || '',
                        observations,
                        sub_location,
                        cost: parseFloat(price),
                        location: location.id,
                        school_kit,
                    });
                }
            });
            // Acceptable data format for the api
            const date_entry = dayjs(entries[0].date).format(
                'YYYY-MM-DD HH:mm:ss'
            );
            const supplier = entries[0].supplier.id;
            const destination = entries[0].location.id;
            const formatData = {
                type: 'entrada',
                date: date_entry,
                supplier: supplier,
                destination,
                details,
            };

            // send data to api
            Swal.fire({
                icon: 'warning',
                title: 'Atención',
                text: 'Al guardar la entrada, afectara a inventario',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    saveEntry(formatData);
                }
            });
        }
    };

    const onSubmit = async (dataForm, event) => {
        const body = { ...dataForm };
        event.reset({
            location: body.location,
            supplier: body.supplier,
            date: body.date,
            quantity: '1',
            price: '1',
        });
        const product_to_check = {
            product: body.product.id,
            location: body.location.id,
            serie: body.serie,
        };
        setLoader(true);
        let sub_location = '';
        try {
            const res = await api.post(
                'inventory/product_exists',
                product_to_check
            );

            if (movment === 1) {
                if (res.results.length > 0) {
                    if (res.results.length === 1) {
                        sub_location = res.results[0].sub_location || '';
                    } else {
                        setModal(true);
                        setDataModal(res);
                        setAddedProduct(body);
                        // body.sub_location = res.results[0].sub_location;
                    }
                } else {
                    sub_location = '';
                }
            }
        } catch (error) {
            NotificationManager.error(
                'No tienes permisos para esta accion',
                'ERROR',
                6000
            );
        } finally {
            setLoader(false);
            // // console.log('respuesta:', res);
        }
        if (!update) {
            if (isProduct) {
                body.description = body.product.description;
            } else {
                body.description = body.product.name;
            }
            body.supplier_name = body.supplier.name;
            body.location_name = body.location.name;
            body.serie = '';
            body.observations = '';
            body.assigned_to = '';
            if (_.get(body, 'supplier.type', undefined) === 'donante')
                body.price = 0;
            body.sub_location = sub_location;
            body.id = makeRandomId();
            setEntries([...entries, body]);
        } else {
            if (body.assigned_to) body.assigned_to = body.assigned_to.id;
            body.product = body.product.id;
            updateData(body);
        }
    };
    const modalAutofillOnClick = () => {
        const { id } = addedProduct;
        const { sub_location, serial_number } = dataModal.results[radio - 1];
        // Get input changes
        const changeData = (dataObj) => {
            const changedFormValues = {
                ...dataObj,
                sub_location: sub_location,
                serie: serial_number === 'NO_SERIAL' ? '' : serial_number,
            };
            return changedFormValues;
        };
        const changedEntry = entries.map((entry) => {
            return entry.id === id ? changeData(entry) : entry;
        });
        setEntries(changedEntry);
        setModal(false);
    };

    return (
        <React.Fragment>
            <ModalAutofill
                openModal={modal}
                setOpenModal={setModal}
                dataModal={dataModal}
                radio={radio}
                setRadio={setRadio}
                onClick={modalAutofillOnClick}
            />
            <LoadMask loading={loader} blur>
                <div>
                    <TitleIcon title="Entradas" image={EntryLogo} />
                    <EntryForm
                        entryData={data}
                        onSubmit={onSubmit}
                        update={update}
                        path_back={path_back}
                        isViewed={isViewed}
                        entries={entries}
                        setEntries={setEntries}
                        onClick={saveData}
                    />
                </div>
            </LoadMask>
            <br />
        </React.Fragment>
    );
};

export default CreateUpdate;
