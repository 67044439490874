import React, { useEffect, useState } from 'react';
import Table from '../../../../../Utils/Grid';
import { standardActions } from '../../../../../Utils/Grid/StandardActions';
import TitleUnderline from '../../../../../Utils/TitleUnderline';
import KitExpandTable from './KitExpandTable';
import TitleSubTitle from '../../../Utils/TitleSubTitle';
import dayjs from 'dayjs';

const data = {
    count: 3,
    next: null,
    previous: null,
    results: [],
};

const DetailTable = ({ isViewed, transferData, transfer, setTransfer }) => {
    const [transferViewed, setTransferViewed] = useState({
        date_transfer: '',
        origin: '',
        destination: '',
    });
    // Allow row expand
    const [openExpand, setOpenExpand] = useState(false);
    // set transfer to results
    data.results = transfer;

    if (isViewed) {
        useEffect(() => {
            if (transferData.date) {
                const date_transfer = dayjs(transferData.date).format(
                    'YYYY-MM-DD'
                );
                setTransferViewed({
                    date_transfer,
                    origin: transferData.origin.name,
                    destination: transferData.destination.name,
                });
            }
        }, [transferData]);
    }

    // Get input changes
    const changeData = (dataObj, e) => {
        const changedFormValues = {
            ...dataObj,
            [e.target.name]: e.target.value,
        };
        return changedFormValues;
    };

    // Set input changes to transfer
    const handleChange = (id, e) => {
        const changedTransfer = transfer.map((transfer) => {
            return transfer.id === id ? changeData(transfer, e) : transfer;
        });
        setTransfer(changedTransfer);
    };

    // Allow row expand
    const options = {
        onRowClick: (row, i) => {
            // if the row product was clicked
            if (i === 1) {
                // Expand row
                setOpenExpand(true);
            } else {
                setOpenExpand(false);
            }
        },
    };
    const format = (cell) => {
        if (cell === 'NO_SERIAL' || cell === '' || cell === undefined) {
            return '---';
        }
        return cell;
    };

    const formatText = (cell) => {
        if (cell === '' || !cell) {
            return '---';
        }
        return cell;
    };
    return (
        <React.Fragment>
            {isViewed && (
                <div className="d-flex flex-md-row flex-column flex-wrap mb-3">
                    <TitleSubTitle
                        title="Ubicación Sale"
                        subTitle={transferViewed.origin}
                    />
                    <TitleSubTitle
                        title="Ubicación entra"
                        subTitle={transferViewed.destination}
                    />
                    <TitleSubTitle
                        title="Fecha de traslado"
                        subTitle={transferViewed.date_transfer}
                    />
                </div>
            )}
            <TitleUnderline title="Detalle de traslado" className="mt-4" />
            <Table
                className="mt-2"
                data={data}
                expandableRow={(row) => {
                    // if it is a school kit, show expand row
                    if (row.product.school_kit && openExpand) return true;
                    return false;
                }}
                expandComponent={(row) => {
                    // formatted data for table and display table
                    const school_kit = row.product.school_kit;
                    const products = school_kit.map((kit) => {
                        const { quantity, product } = kit;
                        const { code, description, category } = product;
                        const total_quantity = quantity * row.quantity;
                        return {
                            code,
                            description,
                            category,
                            quantity,
                            total_quantity,
                        };
                    });

                    // Return the table of products, with the formatted data
                    return <KitExpandTable products={products} />;
                }}
                options={options}
                // onPageChange={(page) => getData(page)}
                // page={page}
                // loading={loader}
            >
                {!isViewed && (
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width="5rem"
                        dataFormat={standardActions({
                            erase: (id) => {
                                const changedTransfer = transfer.filter(
                                    (transfer) => transfer.id !== id
                                );
                                setTransfer(changedTransfer);
                            },
                        })}
                    ></TableHeaderColumn>
                )}
                <TableHeaderColumn
                    className="mt-5"
                    width="12rem"
                    dataField="description"
                    isKey={isViewed}
                >
                    Artículo
                </TableHeaderColumn>
                <TableHeaderColumn width="7rem" dataField="quantity">
                    Cantidad
                </TableHeaderColumn>
                {/* <TableHeaderColumn width="10rem" dataField="location_name">
                    Ubicación origen
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="location_destination_name"
                >
                    Ubicación salida
                </TableHeaderColumn> */}
                <TableHeaderColumn
                    width="10rem"
                    dataField="serial_number"
                    dataFormat={format}
                >
                    Serie
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="observations"
                    dataFormat={(value, data) => {
                        const { id } = data;
                        if (!isViewed) {
                            return (
                                <input
                                    className="form-control w-100"
                                    placeholder="Ingrese Observacion"
                                    name="observations"
                                    onChange={(e) => handleChange(id, e)}
                                    value={value}
                                    onClick={() => setOpenExpand(false)}
                                />
                            );
                        }
                        return value ? value : '---';
                    }}
                >
                    Observacion
                </TableHeaderColumn>
                {isViewed && (
                    <TableHeaderColumn
                        width="7rem"
                        dataField="school_kit_name"
                        dataFormat={formatText}
                    >
                        Kit escolar
                    </TableHeaderColumn>
                )}
            </Table>
        </React.Fragment>
    );
};

export default DetailTable;
