import { useContext } from 'react';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { SwalSuccess } from '../components/Utils/SwalAlerts';

import { api } from 'api';
import LoaderContext from '../context/LoaderContext';
import _ from 'lodash';

const useRestorePwd = (initial_params = {}) => {
    const { setLoader } = useContext(LoaderContext);

    const sendRestorePwd = async (name_api, body = {}) => {
        setLoader(true);
        let res = undefined;
        try {
            const _params = { ...initial_params };
            res = await api.post(name_api, body, _params);
            SwalSuccess(
                'Cambio de contraseña',
                'Se le ha envidado al usuario, en su correo institucional una contraseña temporal para que pueda ingresar al sistema'
            );
            return res;
        } catch (e) {
            let message = 'Error interno.';
            if (e && e.detail) message = e.detail;
            else if (_.isArray(e)) message = e;
            NotificationManager.error(message, 'ERROR', 6000);
        } finally {
            setLoader(false);
            return res;
        }
    };

    return { sendRestorePwd };
};

export default useRestorePwd;
