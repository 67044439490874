import React, { useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from 'Utils/LoadMask/LoadMask';
import logoIwoka from '../../../../../assets/img/logo-iwoka.png';
import useAccount from '../../../hooks/useAccount';
import LoaderContext from '../../../context/LoaderContext';

const Login = () => {
    const { login, user } = useAccount();
    const { loader } = useContext(LoaderContext);
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="background-login d-flex flex-column justify-content-center">
                <div className="login-wrapper">
                    <div className="card card-login col-10 col-md-6 col-lg-4 col-xl-3">
                        <div className="d-flex flex-column align-items-center pt-3 bienvenida">
                            <h1 className="text-center">Bienvenidos</h1>
                        </div>
                        <div className="d-flex flex-column align-items-center pt-3 bienvenida mb-1">
                            <img src={logoIwoka} />
                        </div>
                        <LoadMask loading={loader} blur>
                            <LoginForm onSubmit={login} />
                        </LoadMask>
                        <hr className="bg-white m-2 mx-4" />
                        <div className="d-flex">
                            <Link to="/register" className="register-link px-4">
                                Crea tu cuenta
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
