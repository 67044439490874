import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/TabBar';
import GenerateSessionForm from './GenerateSessionForm';
import SessionForm from './SessionForm';
import _ from 'lodash';
import {
    renderAsyncSelectField,
    renderSelectField,
    renderField,
} from '../../../Utils/renderField/renderField';
import {
    composeValidators,
    maxLength,
    required,
} from '../../../../../utility/validation';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';

import {
    EDUCATIVE,
    YOUNG_BENEFICIARY,
    KID_BENEFICIARY,
    LEGAL_GUARDIAN,
    USER_ACTIVE,
} from '../../../../../utility/constants';

export const TYPE_BENEFICIARY_OPTIONS = [
    { value: YOUNG_BENEFICIARY, label: 'Jóvenes' },
    { value: KID_BENEFICIARY, label: 'Niños' },
    { value: LEGAL_GUARDIAN, label: 'Acudientes' },
    { value: EDUCATIVE, label: 'Docentes' },
];

const GroupForm = ({
    onSubmit,
    view,
    buttonSubmitRef,
    initialValues,
    update,
    onGenerateSession,
    onAddSession,
    sessions,
    handleDataChange,
}) => {
    const { asyncOptions: asyncMentors } = useAsyncOptions('user', {
        status: USER_ACTIVE,
    });
    const { asyncOptions: asyncMonitors } = useAsyncOptions('user', {
        status: USER_ACTIVE,
    });
    const { asyncOptions: asyncGroup } = useAsyncOptions('group_beneficiaries');
    const [typeGroup, setTypeGroup] = useState(null);

    return (
        <Form onSubmit={onSubmit} initialValues={{ ...initialValues }}>
            {({ handleSubmit, values, form }) => {
                handleDataChange(values, true);
                return (
                    <form
                        name="GroupForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="group">
                                    Grupo Beneficiarios
                                </label>
                                <Field
                                    name="group"
                                    component={renderAsyncSelectField}
                                    loadOptions={asyncGroup}
                                    isSearchable={true}
                                    type="text"
                                    valueKey="id"
                                    labelKey="name"
                                    validate={required}
                                    className="w-100"
                                    disabled={view}
                                    parse={(value) => {
                                        if (value && value.name) {
                                            form.change('name', value.name);
                                        }
                                        return value;
                                    }}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-5 flex-1">
                                <label htmlFor="group">Nombre</label>
                                <Field
                                    name="name"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(200)
                                    )}
                                    className="w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-5 flex-1">
                                <label htmlFor="group">Tipo de grupo</label>
                                <Field
                                    name="type"
                                    component={renderSelectField}
                                    type="text"
                                    validate={required}
                                    options={TYPE_BENEFICIARY_OPTIONS}
                                    className="w-100"
                                    disabled={view}
                                    parse={(value) => {
                                        setTypeGroup(value);
                                        return value;
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="start_day">Mentor Grupal</label>
                                <Field
                                    name="mentor"
                                    component={renderAsyncSelectField}
                                    loadOptions={asyncMentors}
                                    isSearchable={true}
                                    type="text"
                                    valueKey="id"
                                    labelKey="first_name"
                                    labelKey2="last_name"
                                    validate={required}
                                    className="w-100"
                                    disabled={view}
                                />
                            </div>
                            {typeGroup === 1 || typeGroup === 4 ? (
                                <React.Fragment>
                                    <div className="form-group has-feedback m-2 mx-lg-5 flex-1">
                                        <label htmlFor="monitor">Monitor</label>
                                        <Field
                                            name="monitor"
                                            component={renderAsyncSelectField}
                                            loadOptions={asyncMonitors}
                                            isSearchable={true}
                                            type="text"
                                            valueKey="id"
                                            labelKey="first_name"
                                            labelKey2="last_name"
                                            validate={required}
                                            className="w-100"
                                            disabled={view}
                                        />
                                    </div>
                                    <div className="d-none d-md-flex m-2 mx-lg-5 flex-1"></div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="d-none d-md-flex m-2 mx-lg-5 flex-1"></div>
                                    <div className="d-none d-md-flex m-2 mx-lg-5 flex-1"></div>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="rc-group mt-5">
                            <Tabs
                                defaultActiveKey={update ? 'TWO' : 'ONE'}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                                animated={true}
                            >
                                <TabPane
                                    tab="Cargar plan de Formación"
                                    key="ONE"
                                >
                                    <div className="my-4">
                                        <GenerateSessionForm
                                            start_day={
                                                values && values.start_day
                                                    ? values.start_day
                                                    : undefined
                                            }
                                            view={view}
                                            sessions={sessions}
                                            dataFormGroup={values}
                                            onSubmit={onGenerateSession}
                                        />
                                    </div>
                                </TabPane>
                                <TabPane
                                    tab="Cargar una sesión nueva"
                                    key="TWO"
                                >
                                    <div className="my-4">
                                        <SessionForm
                                            sessions={sessions}
                                            dataFormGroup={values}
                                            onSubmit={onAddSession}
                                            view={view}
                                        />
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                        <button
                            ref={buttonSubmitRef}
                            type="submit"
                            className="d-none"
                        />
                    </form>
                );
            }}
        </Form>
    );
};

export default GroupForm;
