import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import FormFilter from './FormFilter';

const ModalFilter = (props) => {
    const { initialValues, setInitialValues, setOpen, setGetFilters } = props;
    const [localState, setLocalState] = useState({});

    // useEffect(() => () => console.log('unmount'), []);

    useEffect(() => {
        setLocalState({
            mentoring_group: initialValues.mentoring_group,
            mentoring_individual: initialValues.mentoring_individual,
            personal_event: initialValues.personal_event,
            sena: initialValues.sena,
        });
    }, [initialValues]);

    const CheckBoxFilter = (Props) => {
        const label = Props.label;
        const checkName = Props.checkName;
        //const checked = Props.checked;

        const change = () => {
            if (checkName == 'mentoring_group') {
                setLocalState({
                    mentoring_group: true,
                    mentoring_individual: false,
                    personal_event: false,
                    sena: false,
                });
            }
            if (checkName == 'mentoring_individual') {
                setLocalState({
                    mentoring_group: false,
                    mentoring_individual: true,
                    personal_event: false,
                    sena: false,
                });
            }
            if (checkName == 'personal_event') {
                setLocalState({
                    mentoring_group: false,
                    mentoring_individual: false,
                    personal_event: true,
                    sena: false,
                });
            }
            if (checkName == 'sena') {
                setLocalState({
                    mentoring_group: false,
                    mentoring_individual: false,
                    personal_event: false,
                    sena: true,
                });
            }
        };

        return (
            <React.Fragment>
                <div className="checkbox c-checkbox">
                    <label className="needsclick">
                        <input
                            key={checkName}
                            type="checkbox"
                            onChange={change}
                            checked={
                                localState
                                    ? localState[checkName]
                                    : initialValues[checkName]
                            }
                        />
                        <span className="fa fa-check mr-2" />
                        {label}
                    </label>
                </div>
            </React.Fragment>
        );
    };

    const onSubmit = (dataFilters) => {
        let dataForm = { ...dataFilters };
        let data = {};

        if (dataFilters.mentoring_group) {
            data = {
                mentoring_group: true,
                mentoring_group_school: dataForm.mentoring_group_school,
                mentoring_group_mentor: dataForm.mentoring_group_mentor,
                mentoring_group_beneficiary:
                    dataForm.mentoring_group_beneficiary,
            };
        }

        if (dataFilters.mentoring_individual) {
            data = {
                mentoring_individual: true,
                mentoring_individual_school:
                    dataForm.mentoring_individual_school,
                mentoring_individual_mentor:
                    dataForm.mentoring_individual_mentor,
                mentoring_individual_beneficiary:
                    dataForm.mentoring_individual_beneficiary,
            };
        }

        if (dataFilters.personal_event) {
            data = {
                personal_event: true,
                personal_event_mentor: dataForm.personal_event_mentor,
            };
        }

        if (dataFilters.sena) {
            data = { sena: true };
            if (dataForm.sena_school) {
                data.sena_school = dataForm.sena_school;
            }
            if (dataForm.sena_grade) {
                data.sena_grade = dataForm.sena_grade;
            }
        }

        data = { ...data };
        setInitialValues(data);
        setGetFilters(true);
        setOpen(false);
    };

    return (
        <React.Fragment>
            <div className="container mt-2">
                <div className="row">
                    <div className="col-md">
                        <CheckBoxFilter
                            label={'Mentoria Grupal'}
                            checkName={'mentoring_group'}
                        />
                    </div>
                    <div className="col-md">
                        <CheckBoxFilter
                            label={'Mentoria Individual'}
                            checkName={'mentoring_individual'}
                        />
                    </div>
                    <div className="col-md">
                        <CheckBoxFilter
                            label={'Evento Personalizado'}
                            checkName={'personal_event'}
                        />
                    </div>
                    <div className="col-md">
                        <CheckBoxFilter label={'Extracurricular'} checkName={'sena'} />
                    </div>
                </div>
            </div>

            <Form initialValues={{ ...initialValues }} onSubmit={onSubmit}>
                {({ handleSubmit, pristine, values, form }) => {
                    if (localState && localState.mentoring_group == true) {
                        form.change('mentoring_group', true);
                        form.change('mentoring_individual', false);
                        form.change('personal_event', false);
                        form.change('sena', false);
                    }

                    if (localState && localState.mentoring_individual == true) {
                        form.change('mentoring_group', false);
                        form.change('mentoring_individual', true);
                        form.change('personal_event', false);
                        form.change('sena', false);
                    }

                    if (localState && localState.personal_event == true) {
                        form.change('mentoring_group', false);
                        form.change('mentoring_individual', false);
                        form.change('personal_event', true);
                        form.change('sena', false);
                    }

                    if (localState && localState.sena == true) {
                        form.change('mentoring_group', false);
                        form.change('mentoring_individual', false);
                        form.change('personal_event', false);
                        form.change('sena', true);
                    }

                    return (
                        <form
                            name="loginForm"
                            className="form-validate mb-lg"
                            onSubmit={handleSubmit}
                        >
                            <FormFilter values={values} />

                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between mt-4">
                                <Link
                                    to="/calendar"
                                    className="btn btn-secondary align-self-center mt-4 m-md-0"
                                >
                                    Regresar
                                </Link>

                                <button
                                    type="submit"
                                    className="btn btn-primary align-self-center"
                                >
                                    Aplicar filtros
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </React.Fragment>
    );
};

export default ModalFilter;
