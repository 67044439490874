import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import {
    renderAsyncSelectField,
    renderDatePicker,
    renderTimePicker,
    renderSelectField,
    renderSelectField2
} from '../../../Utils/renderField/renderField';
import { required } from '../../../../../utility/validation';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import PlusIcon from '../../../../../../assets/img/plus.png';
import SessionModal from './SessionModal';
import { CULMINATION, GRADUATION,CLOSING } from '../../../../../utility/constants';
import dayjs from 'dayjs';
import useUpdate from '../../../../hooks/useUpdate';
import useList from '../../../../hooks/useList';
const SessionForm = ({ onSubmit, view, sessions, dataFormGroup }) => {
    const { asyncOptions } = useAsyncOptions('session');
    const [sessionModal, setSessionModal] = useState(false);
    const { id } = useUpdate(
        'training_group',
        '/training_group'
    );
    let union_exclude = sessions
    if(id){
        const { data } = useList(
            `training_group/${id}/historial_sessions`
        );
        union_exclude = [...sessions,...data.results]
    }
  
    // Filtrar los elementos que no contienen "NS" en el campo "code"
    const filtered_exclude = union_exclude.filter(item => !item.code.includes("NS") && item.status_session !== "CANCELADA" && item.active !== false);

    // Crear una lista de session_ids a excluir
    const exclude_ids = filtered_exclude.map(item => item.session); 

    
    const onAddSession = (dataForm) => {
        const _dataForm = { ...dataForm };
        _dataForm.group_id = dataFormGroup.group.id;
        return onSubmit(_dataForm);
    };

    const validate = (values) => {
        const errors = {};
        if (!!values.start_time && !!values.end_time) {
            const start_time = dayjs(values.start_time);
            const end_time = dayjs(values.end_time);
            if (end_time.isBefore(start_time) || end_time.isSame(start_time)) {
                errors.end_time = 'Hora de fin debe ser mayor a hora de inicio';
            }
        }
        if (!dataFormGroup.group) {
            errors.session = 'Debe seleccionar un grupo de beneficiarios.';
        }
        return errors;
    };

    return (
        <Form onSubmit={onAddSession} validate={validate}>
            {({ handleSubmit, form, valid, values }) => {
                return (
                    <div name="SessionForm" className="form-validate mb-lg">
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="code">Sesión</label>
                                <div className="d-flex">
                                    <Field
                                        key={`${exclude_ids.length}-session`}
                                        name="session"
                                        component={renderAsyncSelectField}
                                        isClearable={true}
                                        isSearchable={true}
                                        loadOptions={(search) =>
                                            asyncOptions(search, {
                                                exclude_ids: exclude_ids,
                                            })
                                        }
                                        valueKey="id"
                                        labelKey="title"
                                        type="text"
                                        validate={required}
                                        className="w-100"
                                        disabled={view}
                                        placeholder="Buscar por titulo del modulo, código o sesión"
                                    />
                                    <div className="d-flex " width="30px">
                                        <button
                                            className="btn p-0 ml-2"
                                            type="button"
                                            disabled={view}
                                            onClick={() =>
                                                setSessionModal(true)
                                            }
                                        >
                                            <img src={PlusIcon} width="20px" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-5 flex-1">
                                <label htmlFor="title">Fecha</label>
                                <Field
                                    name="start_day"
                                    component={renderDatePicker}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="start_time">Hora Inicio</label>
                                <Field
                                    name="start_time"
                                    component={renderTimePicker}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                    minuteStep={30}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-5 flex-1">
                                <label htmlFor="end_time">Hora Finaliza</label>
                                <Field
                                    name="end_time"
                                    component={renderTimePicker}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                    minuteStep={30}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="end_time">
                                    Tipo Sesión (Opcional)
                                </label>
                                <Field
                                    name="type_session"
                                    component={renderSelectField2}
                                    options={[
                                        {
                                            value: CULMINATION,
                                            label: 'Culminación',
                                        },
                                        {
                                            value: GRADUATION,
                                            label: 'Graduación',
                                        },
                                        {
                                            value: CLOSING,
                                            label: 'Cierre',
                                        },
                                    ]}
                                    isClearable={true}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row p-2 flex-1 justify-content-end">
                            <button
                                type="button"
                                onClick={async (event) => {
                                    const result = await handleSubmit(event);
                                    if (valid && result != 'cancel')
                                        form.restart();
                                }}
                                className="btn btn-secondary align-self-center mt-1"
                                disabled={view}
                            >
                                Agregar sesión
                            </button>
                        </div>
                        <SessionModal
                            openModal={sessionModal}
                            onCloseModal={() => setSessionModal(false)}
                        />
                    </div>
                );
            }}
        </Form>
    );
};

export default SessionForm;
