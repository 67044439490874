import React from 'react';
import { Field, Form } from 'react-final-form';

import MultiUploadFile from './File/MultiUploadFile';
import { renderTextArea } from '../../../Utils/renderField/renderField';
import { GROUP_MENTORING } from '../../../../../utility/constants';
import _ from 'lodash';

const Minute = (props) => {
    const {
        onSubmit,
        initialValues,
        setSteps,
        id,
        files,
        onChangeFile,
        onRemoveFile,
        onRemoveOldFile,
        oldFiles,
    } = props;

    return (
        <React.Fragment>
            <Form onSubmit={onSubmit} initialValues={initialValues}>
                {({ handleSubmit, values }) => {
                    return (
                        <form
                            name="MinuteForm"
                            className="form-validate mb-lg minute"
                            onSubmit={handleSubmit}
                        >
                            <div className=" form-group has-feedback m-2">
                                <label>Desarrollo de sesión</label>
                                <Field
                                    name="summary"
                                    component={renderTextArea}
                                    rows={5}
                                    className="form-control"
                                />
                            </div>
                            {values && values.type === GROUP_MENTORING && (
                                <div className=" form-group has-feedback m-2 mt-4 ">
                                    <label>
                                        Observaciones a la metodología.
                                    </label>
                                    <Field
                                        name="observations"
                                        component={renderTextArea}
                                        rows={4}
                                        className="form-control"
                                    />
                                </div>
                            )}
                            <div className=" form-group has-feedback m-2 mt-4">
                                <label>Comentarios</label>
                                <Field
                                    name="comments"
                                    component={renderTextArea}
                                    rows={4}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1  mt-4">
                                <label>Documentos o fotos</label>
                                <MultiUploadFile
                                    id={id}
                                    files={files}
                                    onChangeFile={onChangeFile}
                                    onRemoveFile={onRemoveFile}
                                    onRemoveOldFile={onRemoveOldFile}
                                    data={oldFiles}
                                    item={values}
                                />
                            </div>
                            <hr />
                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                                <button
                                    className="btn btn-secondary align-self-center mt-4 m-md-0"
                                    onClick={() => {
                                        setSteps(1);
                                    }}
                                >
                                    Regresar
                                </button>
                                <button
                                    className="btn btn-primary align-self-center"
                                    type="submit"
                                >
                                    {`${
                                        _.get(values, 'is_save', false) == true
                                            ? 'Actualizar'
                                            : 'Registrar'
                                    }`}
                                    &nbsp;Asistencia
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
            <br />
        </React.Fragment>
    );
};

export default Minute;
