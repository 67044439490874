import { useEffect, useState, useContext } from 'react';
import { NotificationManager } from 'react-notifications';
import { useNavigate, useParams } from 'react-router-dom';
import useIsOffline from './useIsOffline';
import { api } from 'api';
import LoaderContext from '../context/LoaderContext';
import _ from 'lodash';
import { SwalError, SwalSuccess } from '../components/Utils/SwalAlerts';
import MissingProductsContext from '../context/MissingProductsContext';
import { formatErrors } from './useCreate';

const useUpdate = (
    name_api,
    path_module = '',
    local_pathname = '',
    get_data = true,
    navigate_to = false,
    reaload=false,
) => {
    const [data, setData] = useState({});
    const { setLoader } = useContext(LoaderContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const { isOffline } = useIsOffline();
    const title = id ? 'Actualizar' : 'Ingresar';
    const update = id ? true : false;
    const { setMissingProducts } = useContext(MissingProductsContext);

    const getData = async (id) => {
        setLoader(true);
        try {
            const data = await api.get(`${name_api}/${id}`);
            setData(data);
            isOffline(data);
        } catch (e) {
            let msj = 'No se pudo obtener el registro';
            if (e && e.detail) msj = e.detail;
            else if (_.isArray(e)) msj = e;
            NotificationManager.error(msj, 'ERROR', 6000);
        } finally {
            setLoader(false);
        }
    };

    const updateData = async (
        body,
        attachments = [],
        custom_id = null,
        extra_api_name,
        _getData = null
    ) => {
        setLoader(true);
        let res;
        try {
            const id_data = custom_id ? custom_id : id;
            let endpoint = `${name_api}/${id_data}`;

            if (extra_api_name) {
                endpoint = `${endpoint}/${extra_api_name}`;
            }

            if (attachments.length > 0) {
                res = await api.putAttachments(endpoint, body, attachments);
            } else res = await api.put(endpoint, body);
            // NotificationManager.success(
            //     'Registro actualizado exitoso',
            //     'ÉXITO',
            //     6000
            // );
            if (_getData) _getData();
            if (path_module && navigate_to) navigate(path_module);
            reaload && getData(id);
            isOffline(res);
            SwalSuccess('Guardado', '');
        } catch (e) {
            let message = 'Error interno.';
            if (e && e.detail) message = e.detail;
            if (e && e.username) message = e.username[0];
            else if (_.isArray(e)) message = e;

            if (e && e.errors_inventory) {
                message = e.errors_inventory;
                // set the missing products (message contains missing products)
                setMissingProducts(message);
                const missing_products = formatErrors(message);

                SwalError('Productos sin Stock', String(missing_products));
            } else {
                NotificationManager.error(message, 'ERROR', 7000);
            }
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (id && get_data) getData(id);
    }, [id]);

    return { data, title, id, update, updateData, setData, getData };
};

export default useUpdate;
