import React from 'react';
import { createPortal } from 'react-dom';
import { Draggable } from 'react-beautiful-dnd';
import eraseIcon from '../../../../../../assets/img/delete.png';
import Swal from 'sweetalert2';
import './dndTable.css';

export default function DndRow({ onRemoveRow, item, index }) {
    const draggablePortal = document.getElementById('draggable-portal');

    const optionalPortal = (isDragging, element) => {
        if (isDragging) {
            return createPortal(element, draggablePortal);
        }
        return element;
    };

    const onErase = () => {
        Swal.fire({
            title: '¿Eliminar?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, eliminar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                onRemoveRow(item);
            }
        });
    };

    return (
        <Draggable draggableId={`${item.code}`} index={index}>
            {(provided, snapshot) =>
                optionalPortal(
                    snapshot.isDragging,
                    <div
                        className={`dnd-row ${snapshot.isDragging && 'select'}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                    >
                        <div className="dnd-row-content" align="center">
                            <i
                                style={{ cursor: 'pointer' }}
                                className="material-icons"
                            >
                                <img
                                    className="icon"
                                    src={eraseIcon}
                                    onClick={onErase}
                                />
                            </i>
                        </div>
                        <div className="dnd-row-content">
                            <span>{item.code}</span>
                        </div>
                        <div className="dnd-row-content">
                            <span>{item.title}</span>
                        </div>
                    </div>
                )
            }
        </Draggable>
    );
}
