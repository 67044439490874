import React, { useState } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/TabBar';
import { Field, Form } from 'react-final-form';
import { renderFieldCheck } from '../../../Utils/renderField/renderField';
import './assistance.css';
import {
    eventFields,
    groupBeneficiariesFields,
    beneficiaryFields,
    trainingGroupFields,
} from './fields';

const CheckboxField = ({ label = '', onChange = () => {} }) => {
    const [check, setCheck] = useState(false);
    return (
        <div className="checkbox c-checkbox">
            <label className="needsclick">
                <input
                    type="checkbox"
                    onChange={(e) => {
                        setCheck(!check);
                        onChange(!check);
                    }}
                    value={check}
                />
                <span className="fa fa-check mr-2" />
                {label}
            </label>
        </div>
    );
};

export default function FilterFields({
    onSubmit,
    initialValues,
    onChangeAllCheck,
    btnGenerateRep,
}) {
    return (
        <React.Fragment>
            <div className="rc-assistance mt-5">
                <Form
                    initialValues={{ ...initialValues }}
                    onSubmit={onSubmit}
                    render={({ handleSubmit, submitting, values }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            <Tabs
                                defaultActiveKey={'ONE'}
                                tabBarPosition="top"
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                                animated={true}
                            >
                                <TabPane tab="Evento" key="ONE">
                                    <div className="d-flex justify-content-end form-group my-4">
                                        <CheckboxField
                                            label="Seleccionar todos"
                                            onChange={(checkAll) => {
                                                onChangeAllCheck(
                                                    checkAll,
                                                    eventFields,
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex flex-row flex-wrap justify-content-start pl-3 mt-4">
                                        {eventFields.map((field, i) => (
                                            <div
                                                className="form-group d-flex mr-5"
                                                style={{
                                                    width: '135px',
                                                }}
                                                key={i}
                                            >
                                                <Field
                                                    name={field.name}
                                                    component={renderFieldCheck}
                                                    className="mt-1"
                                                />
                                                <label
                                                    htmlFor="checkbox"
                                                    title={field.value}
                                                    style={{
                                                        color: '#5F5F5F',
                                                        marginRight: '10px',
                                                        maxWidth: '100px',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {field.value}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </TabPane>
                                <TabPane tab="Grupo de formación" key="TWO">
                                    <div className="d-flex justify-content-end form-group my-4">
                                        <CheckboxField
                                            label="Seleccionar todos"
                                            onChange={(checkAll) => {
                                                onChangeAllCheck(
                                                    checkAll,
                                                    trainingGroupFields,
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex flex-row flex-wrap justify-content-start pl-3 mt-4">
                                        {trainingGroupFields.map((field, i) => (
                                            <div
                                                className="form-group d-flex mr-5"
                                                style={{
                                                    width: '135px',
                                                }}
                                                key={i}
                                            >
                                                <Field
                                                    name={field.name}
                                                    component={renderFieldCheck}
                                                    className="mt-1"
                                                />
                                                <label
                                                    htmlFor="checkbox"
                                                    title={field.value}
                                                    style={{
                                                        color: '#5F5F5F',
                                                        marginRight: '10px',
                                                        maxWidth: '100px',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {field.value}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </TabPane>
                                <TabPane
                                    tab="Grupo de beneficiarios"
                                    key="TREE"
                                >
                                    <div className="d-flex justify-content-end form-group my-4">
                                        <CheckboxField
                                            label="Seleccionar todos"
                                            onChange={(checkAll) => {
                                                onChangeAllCheck(
                                                    checkAll,
                                                    groupBeneficiariesFields,
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex flex-row flex-wrap justify-content-start pl-3 mt-4">
                                        {groupBeneficiariesFields.map(
                                            (field, i) => (
                                                <div
                                                    className="form-group d-flex mr-5"
                                                    style={{
                                                        width: '135px',
                                                    }}
                                                    key={i}
                                                >
                                                    <Field
                                                        name={field.name}
                                                        component={
                                                            renderFieldCheck
                                                        }
                                                        className="mt-1"
                                                    />
                                                    <label
                                                        htmlFor="checkbox"
                                                        title={field.value}
                                                        style={{
                                                            color: '#5F5F5F',
                                                            marginRight: '10px',
                                                            maxWidth: '100px',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                        }}
                                                    >
                                                        {field.value}
                                                    </label>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </TabPane>
                                <TabPane tab="Beneficiario" key="FOUR">
                                    <div className="d-flex justify-content-end form-group my-4">
                                        <CheckboxField
                                            label="Seleccionar todos"
                                            onChange={(checkAll) => {
                                                onChangeAllCheck(
                                                    checkAll,
                                                    beneficiaryFields,
                                                    values
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex flex-row flex-wrap justify-content-start pl-3 mt-4">
                                        {beneficiaryFields.map((field, i) => (
                                            <div
                                                className="form-group d-flex mr-5"
                                                style={{
                                                    minWidth: '130px',
                                                    maxWidth: '130px',
                                                }}
                                                key={i}
                                            >
                                                <Field
                                                    name={field.name}
                                                    component={renderFieldCheck}
                                                    className="mt-1"
                                                />
                                                <label
                                                    htmlFor="checkbox"
                                                    title={field.value}
                                                    style={{
                                                        color: '#5F5F5F',
                                                        marginRight: '10px',
                                                        maxWidth: '100px',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {field.value}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </TabPane>
                            </Tabs>
                            <button
                                ref={btnGenerateRep}
                                className="d-none"
                            ></button>
                        </form>
                    )}
                />
            </div>
        </React.Fragment>
    );
}
