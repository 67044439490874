import React, { useContext } from 'react';
import AsyncSelectInput from '../../Utils/AsyncSelectInput';
import useAsyncOptions from '../../../hooks/useAsyncOptions';
import UserContext from '../../../context/UserContext';
import DatePicker from '../../Utils/DayPicker';
import locale_es from 'antd/es/date-picker/locale/es_ES';
import _ from 'lodash';
import {
    SUPER_ADMIN,
    COORDINATOR,
    TRAINER,
    ASSISTANT,
} from '../../../../utility/constants';

export default function MainFilters({ filters, setFilters }) {
    const { asyncOptions: schoolOptions } = useAsyncOptions('school');
    const { user } = useContext(UserContext);

    return (
        <React.Fragment>
            <div className="form-group d-flex flex-column flex-md-row flex-wrap has-feedback m-2 flex-1">
                {[SUPER_ADMIN, COORDINATOR, TRAINER, ASSISTANT].includes(
                    user.type
                ) && (
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>
                            Colegio{' '}
                            <span className="font-size-4 font-color-primary">
                                (Beneficiario)
                            </span>
                        </label>
                        <AsyncSelectInput
                            valueKey="id"
                            labelKey="name"
                            isClearable
                            isSearchable
                            isMulti={true}
                            onChange={(school) => {
                                setFilters((f) => ({
                                    ...f,
                                    school: school,
                                }));
                            }}
                            loadOptions={schoolOptions}
                            value={filters.school}
                        />
                    </div>
                )}
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Año</label>
                    <DatePicker
                        onChange={(value) =>
                            setFilters((f) => ({
                                ...f,
                                year: value,
                            }))
                        }
                        locale={locale_es}
                        value={filters.year}
                        format="YYYY"
                        picker="year"
                        placeholder="Año"
                        className="form-control w-100"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
