import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import LoaderContext from '../../../../../context/LoaderContext';
import useCreate from '../../../../../hooks/useCreate';
import useUpdate from '../../../../../hooks/useUpdate';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import ProductForm from './ProductForm';

const CreateUpdate = () => {
    const location = useLocation();
    const { loader } = useContext(LoaderContext);
    const { data, title, update, updateData } = useUpdate(
        'product',
        '/inventory/product_kit'
    );
    const isViewed = location.pathname.includes('view');
    const { saveData } = useCreate('product', '/inventory/product_kit');
    const onSubmit = (dataForm) => {
        const selectedCategory = dataForm.category; // Obtén la categoría seleccionada
        const acronyms = {
            // Mapea las categorías a sus acrónimos correspondientes
            'Cartillas': 'CAR',
            'Papelería': 'PAP',
            'Fiesta': 'FIE',
            'Marca Iwoka': 'MI',
            'Electrónicos': 'ELE',
            'Muebles': 'MUE',
            'Metodología': 'MET',
        };
        
        // Obtén el acrónimo correspondiente a la categoría seleccionada
        const selectedAcronym = acronyms[selectedCategory];
    
        // Agrega el acrónimo al código y actualiza el body
        const updatedCode = `${selectedAcronym}-${dataForm.code}`;
        const updatedBody = {
            ...dataForm,
            code: updatedCode,
        };
        
        const product_id = updatedBody.id;
        updatedBody.product = product_id;
        
        if (!update) saveData(updatedBody);
        else updateData(updatedBody, product_id);
    };
    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="py-5">
                    <h1 className="title-2 cl-orange">
                        {/*isViewed ? 'Ver' : title*/} Artículo
                    </h1>
                    <ProductForm
                        productData={data}
                        onSubmit={onSubmit}
                        view={isViewed}
                        update={update}
                    />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default CreateUpdate;
