import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import LoaderContext from '../../../../../context/LoaderContext';
import useCreate from '../../../../../hooks/useCreate';
import useUpdate from '../../../../../hooks/useUpdate';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import SupplierForm from './SupplierForm';

const CreateUpdate = () => {
    const location = useLocation();
    const { loader } = useContext(LoaderContext);
    const { data, title, update, updateData } = useUpdate(
        'supplier',
        '/inventory/supplier'
    );
    const isViewed = location.pathname.includes('view');
    const { saveData } = useCreate('supplier', '/inventory/supplier');
    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        const id = body.id;
        if (!update) saveData(body);
        else updateData(body, id);
    };
    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="py-5">
                    <h1 className="title-2 cl-orange">
                        {/*isViewed ? 'Ver' : title*/} Proveedor
                    </h1>
                    <SupplierForm
                        supplierData={data}
                        onSubmit={onSubmit}
                        view={isViewed}
                        update={update}
                    />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default CreateUpdate;
