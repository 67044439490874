import React, { useState } from 'react';
import useCreate from '../../../../../hooks/useCreate';
import ButtonAdd from '../../Utils/ButtonAdd/ButtonAdd';
import ModalForm from '../../Utils/Modal';
import SupplierForm from '../ModalForm/SupplierForm';
import { useForm } from 'react-final-form';

const CreateUpdate = () => {
    const [openModal, setOpenModal] = useState(false);
    const { data, saveData } = useCreate('supplier', '');
    const fatherForm = useForm();

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        setOpenModal(false);
        saveData(body).then((d) => {
            fatherForm.change('supplier', {
                id: d.id,
                name: d.name,
                last_name: d.last_name,
                type: d.type,
            });
        });
    };
    return (
        <React.Fragment>
            <ModalForm
                ComponentForm={SupplierForm}
                ComponentButton={ButtonAdd}
                title={'Registrar Proveedor'}
                openModal={openModal}
                setOpenModal={setOpenModal}
                onCloseModal={() => setOpenModal(false)}
                formData={data}
                onSubmit={onSubmit}
            />
        </React.Fragment>
    );
};

export default CreateUpdate;
