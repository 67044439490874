import React, { useContext, useState } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { Link } from 'react-router-dom';
import Table from '../../../Utils/Grid';
import Search from '../../../Utils/Search';
import useList from '../../../../hooks/useList';
import useDelete from '../../../../hooks/useDelete';
import LoaderContext from '../../../../context/LoaderContext';
import UserContext from '../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN,
    COORDINATOR
} from '../../../../../utility/constants'

const Modules = (props) => {
    const { data, page, getData } = useList('module');
    const { deleteData } = useDelete('module');
    const [search, setSearch] = useState(null);
    const { loader } = useContext(LoaderContext);
    const { user }= useContext(UserContext);
    return (
        <React.Fragment>
            <div className="pt-5" />
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <h1 className="title-text">Módulos</h1>
                { (user.type === SUPER_ADMIN || user.type === COORDINATOR) &&(
                <Link to="/module/create" className="btn btn-primary">
                    Registrar Módulo
                </Link>
                )}
            </div>
            <Search
                onSearch={(value) => {
                    getData(1, {
                        search: value,
                    }).then();
                    setSearch(value);
                }}
                placeholder="Buscar por código y módulo ..."
            />
            <Table
                className="mt-5"
                data={data}
                loading={loader}
                onPageChange={(page) => getData(page, { search: search })}
                page={page}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/module',
                        update_module: '/module',
                        erase_module: (id) => deleteData(id, getData),
                        type:user.type,
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn dataField="module" width="10rem">
                    Título del módulo
                </TableHeaderColumn>
                <TableHeaderColumn dataField="ext" width="5rem">
                    Código
                </TableHeaderColumn>
                <TableHeaderColumn dataField="description" width="10rem">
                    Descripción
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default Modules;
