import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './stylesDialogBeforeUnLoad.css';

export const DialogBeforeUnLoad = ({
    showDialog,
    setShowDialog,
    cancelNavigation,
    confirmNavigation,
}) => {
    const handleDialogClose = () => {
        setShowDialog(false);
    };
    return (
        <Dialog
            fullWidth
            open={showDialog}
            onClose={handleDialogClose}
            className="DialogBeforeUnLoad"
        >
            <ErrorOutlineIcon />
            <DialogTitle>¿Desea salir de la página?</DialogTitle>
            <DialogContent>
                <Typography>Los cambios realizados no se guardarán</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cancelNavigation}>
                    No
                </Button>
                <Button variant="contained" onClick={confirmNavigation}>
                    Si
                </Button>
            </DialogActions>
        </Dialog>
    );
};
