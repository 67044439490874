import React, { useContext } from 'react';
import {
    TYPE_REPORTING_MODULES,
    TYPE_REPORTING_MODULES_SCHOOL,
} from '../../../../../../utility/constants';
import { customStylesReactSelect } from '../../../../Utils/renderField/renderField';
import { checkbox_filters } from './checkbox_filters';
import Select from 'react-select';
import DatePicker from '../../../../Utils/DayPicker';
import locale_es from 'antd/es/date-picker/locale/es_ES';
import UserContext from '../../../../../context/UserContext';
import {
    SUPER_ADMIN,
    COORDINATOR,
    TRAINER,
    ASSISTANT,
} from '../../../../../../utility/constants';

import dayjs from 'dayjs';

const Filters = ({
    today,
    btnReset,
    reportData,
    initial_data,
    setData,
    setFilterFields,
    setReportData,
}) => {
    const { user } = useContext(UserContext);
    const onChangeModule = (e) => {
        const {
            calendar,
            beneficiary,
            schools,
            beneficiary_groups,
            operational_user,
            session,
            training_plan,
            training_group,
            inventory,
        } = checkbox_filters;

        const { value: module } = e;
        btnReset.current.click();
        switch (module) {
            case 'CALENDAR':
                // // console.log('calendario seleccionado');
                setFilterFields(calendar);
                setReportData({
                    ...reportData,
                    module: 'calendar',
                    api: 'reporting/calendar',
                });
                setData(initial_data);
                break;
            case 'BENEFICIARY':
                setFilterFields(beneficiary);
                setReportData({
                    ...reportData,
                    module: 'beneficiary',
                    api: 'reporting/beneficiary',
                });
                setData(initial_data);
                break;
            case 'OPERATIONAL_USERS':
                setFilterFields(operational_user);
                setReportData({
                    ...reportData,
                    module: 'operational_user',
                    api: 'reporting/operational_user',
                });
                setData(initial_data);
                break;
            case 'SCHOOLS':
                setFilterFields(schools);
                setReportData({
                    ...reportData,
                    module: 'schools',
                    api: 'reporting/schools',
                });
                setData(initial_data);
                break;
            case 'BENEFICIARY_GROUPS':
                setFilterFields(beneficiary_groups);
                setReportData({
                    ...reportData,
                    module: 'beneficiary_groups',
                    api: 'reporting/beneficiary_groups',
                });
                setData(initial_data);
                break;
            case 'SESSION':
                setFilterFields(session);
                setReportData({
                    ...reportData,
                    module: 'session',
                    api: 'reporting/session',
                });
                setData(initial_data);
                break;
            case 'FORMATION_PLANS':
                setFilterFields(training_plan);
                setReportData({
                    ...reportData,
                    module: 'training_plan',
                    api: 'reporting/training_plan',
                });
                setData(initial_data);
                break;
            case 'TRAINING_GROUPS':
                setFilterFields(training_group);
                setReportData({
                    ...reportData,
                    module: 'training_group',
                    api: 'reporting/training_group',
                });
                setData(initial_data);
                break;
            case 'INVENTORY':
                setFilterFields(inventory);
                setReportData({
                    ...reportData,
                    module: 'inventory',
                    api: 'reporting/inventory',
                });
                setData(initial_data);
                break;
            default:
                setFilterFields([]);
                setReportData({});
                setData(initial_data);
                break;
        }
    };
    const onChangeStart = (e) => {
        if (e) {
            const start_date = dayjs(e).format('YYYY-MM-DD');
            setReportData({ ...reportData, start_date });
        } else {
            setReportData({ ...reportData, start_date: '' });
        }
    };
    const onChangeEnd = (e) => {
        if (e) {
            const end_date = dayjs(e)
                .endOf('date')
                .format('YYYY-MM-DD HH:mm:ss');
            setReportData({ ...reportData, end_date });
        } else {
            setReportData({ ...reportData, end_date: '' });
        }
    };

    const isIwooka = [COORDINATOR, TRAINER, ASSISTANT, SUPER_ADMIN].includes(
        user.type
    );

    return (
        <React.Fragment>
            <hr />
            <div className="d-flex flex-column flex-md-row form-group">
                <div className="form-group d-flex flex-column flex-md-row has-feedback m-2 flex-1">
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Módulo</label>
                        <Select
                            styles={customStylesReactSelect}
                            backspaceRemovesValue={false}
                            classNamePrefix="react-select"
                            isSearchable={true}
                            options={
                                isIwooka
                                    ? TYPE_REPORTING_MODULES
                                    : TYPE_REPORTING_MODULES_SCHOOL
                            }
                            placeholder="Filtrar por módulo"
                            onChange={onChangeModule}
                        />
                    </div>
                    <div className="form-group has-feedback m-2 flex-1"></div>
                </div>
            </div>
            <div className="form-group d-flex flex-column flex-md-row flex-wrap has-feedback m-2 flex-1">
                <div className="form-group d-flex flex-column flex-md-row flex-wrap has-feedback m-2 flex-1">
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Fecha Inicio</label>
                        <DatePicker
                            onChange={onChangeStart}
                            locale={locale_es}
                            defaultValue={reportData.start_date || null}
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            className="form-control w-100"
                        />
                    </div>
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Fecha Fin</label>
                        <DatePicker
                            onChange={onChangeEnd}
                            locale={locale_es}
                            defaultValue={reportData.end_date || null}
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            className="form-control w-100"
                        />
                    </div>
                    <div className="form-group has-feedback m-2 flex-1"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Filters;
