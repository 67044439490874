import React, { useContext, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Views } from 'react-big-calendar';
import MainCalendar from './Calendar';
import Holiday from './Holidays/Holiday';
import OfflineContext from '../../../context/OfflineContext';
import EventsOffline from './EventsOffline';
import Assistance from './Assistance';
import CreateUpdate from './Holidays/CreateUpdate';

export default function Calendar() {
    const { offline } = useContext(OfflineContext);
    const [view, setView] = useState(Views.WEEK);
    return (
        <Routes>
            {offline ? (
                <Route path="/" element={<EventsOffline />} />
            ) : (
                <Route
                    path="/"
                    element={<MainCalendar view={view} setView={setView} />}
                />
            )}
            <Route path="holiday" element={<Holiday />} />
            <Route path=":id/assistance" element={<Assistance />} />
            <Route path=":id/update" element={<CreateUpdate />} />
        </Routes>
    );
}
