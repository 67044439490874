import React from 'react';

export default function CulminationSvgIcon({
    width = 88,
    height = 88,
    color = '#ef5543',
}) {
    return (
        <svg
            width={width}
            height={height}
            id="Capa_1"
            data-name="Capa 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 700 700"
            fill={color}
        >
            <path d="M53.47,478.05h70.84V617.26H53.47Z" />
            <path d="M171.68,391.88h70.84V617.26H171.68Z" />
            <path d="M290,310.55h70.83V617.26H290Z" />
            <path d="M408.2,228H479V617.26H408.2Z" />
            <path d="M646.53,175.75l-84.88-93-85,93h49.83V617.27h70.74V175.75Z" />
        </svg>
    );
}
