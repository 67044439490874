import React, { useState, useContext } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import useAccount from '../../../hooks/useAccount';
const defaultAvatar = require('assets/img/avatar-placeholder.png');
import './Navbar.css';
import OfflineSvgIcon from './OfflineSvg';
import OfflineContext from '../../../context/OfflineContext';

function Offline() {
    const { offline } = useContext(OfflineContext);
    return (
        <React.Fragment>
            {offline && (
                <div className="d-flex align-items-center ml-2">
                    <OfflineSvgIcon width={50} height={40} />
                    <p className="m-0 offline-text">Offline</p>
                </div>
            )}
        </React.Fragment>
    );
}

export default function Navbar(props) {
    const [toggle, setToggle] = useState(false);
    const { user, logOut } = useAccount();
    const { navToggle } = props;

    return (
        <nav className="align-items-stretch flex-md-nowrap p-0 navbar">
            <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
                <Offline />
                <div className="ml-3 input-group input-group-seamless" />
            </div>
            <ul className="flex-row navbar-nav">
                <Dropdown isOpen={toggle} toggle={() => setToggle(!toggle)}>
                    <DropdownToggle
                        color="light"
                        // caret
                        className="nav-item-dropdown bg-light-gray border-0 d-flex align-items-center flex-row-reverse"
                    >
                        <img
                            className="user-avatar rounded-circle ml-md-3"
                            src={
                                user.profile && user.profile.avatar
                                    ? user.profile.avatar
                                    : defaultAvatar
                            }
                            alt="User Avatar"
                        />
                        <span className="d-none d-md-inline-block">
                            {user.first_name}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem divider />
                        <Link
                            tabIndex="0"
                            className="text-danger"
                            onClick={logOut}
                            to={false}
                        >
                            <DropdownItem>
                                <i className="material-icons text-danger"></i>
                                Salir
                            </DropdownItem>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </ul>
            <div className="d-flex d-md-none">
                <Offline />
            </div>
            <nav className="nav">
                <a
                    className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none text-center"
                    onClick={navToggle}
                >
                    <i className="material-icons"></i>
                </a>
            </nav>
        </nav>
    );
}
