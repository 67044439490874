import React, { useContext, useState } from 'react';
import LoaderContext from '../../../../context/LoaderContext';
import FilterInputs from './FilterInputs/FilterInputs';
import ShowTables from './ShowTables';
import useReportingList from '../../../../hooks/useReportingList';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import { SwalError, SwalSuccess } from '../../../Utils/SwalAlerts';
import LogoReporting from '../../../../../../assets/img/reporteria-logo.svg';
import dayjs from 'dayjs';

// import { PDFDownloadLink } from '@react-pdf/renderer';
// import PrintFormat from './PrintFormat/PrintFormat';

const day = new Date().getDate();
const month = new Date().getMonth() + 1;
const year = new Date().getFullYear();
const today = `${year}-${month}-${day}`;

const INITIAL_REPORT_DATA = {
    module: '',
    api: '',
    start_date: dayjs().startOf('year'),
    end_date: dayjs().endOf('date'),
    fields: [],
};
export default function ListReporting() {
    const { loader } = useContext(LoaderContext);
    const [reportData, setReportData] = useState(INITIAL_REPORT_DATA);
    const { data, page, getData, setData, INITIAL_DATA } = useReportingList(
        reportData.api
    );

    const handleClick = async () => {
        const downloadButton = document.getElementById('download-button');
        downloadButton.disabled = true;

        // Show the "Downloading" alert using SweetAlert
        SwalSuccess('¡Descarga en proceso!',  
        'La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo')

        
        try {
            // Start the download by fetching the resource
            //const response = await fetch(`api/reporting/generate_reporting_excel/${reportData}`);
            const response = await fetch('api/reporting/generate_reporting_excel/', {
                method: 'POST', // Use POST method to send data
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reportData),
            });

            if (response.ok) {
                // If the response is successful, trigger the download
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style = 'display: none';
                a.href = url;
                a.download = 'report.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                downloadButton.disabled = false;
                // Show "Download Completed" alert
                NotificationManager.success('Descarga realizada', 'ÉXITO', 6000);
            } else {
                // If the response is not successful, show "Processing Document" alert
                const processingAlert = setInterval(() => {
                    NotificationManager.info('Generando Documento', 'INFO', 6000);
                }, 10000);

                // Check if the download is completed every 1 second
                const checkDownload = setInterval(async () => {
                    const checkResponse = await fetch('api/reporting/generate_reporting_excel');
                    if (checkResponse.ok) {
                        // The download is completed, clear the intervals and show "Download Completed" alert
                        downloadButton.disabled = false;
                        clearInterval(processingAlert);
                        clearInterval(checkDownload);
                        NotificationManager.success('Descarga realizada', 'ÉXITO', 6000);
                    }
                }, 1000);
            }
        } catch (error) {
            // If there was an error, show "Processing Document" alert
            const processingAlert = setInterval(() => {
                NotificationManager.info('Generando Documento', 'INFO', 6000);
            }, 10000);

            // Check if the download is completed every 1 second
            const checkDownload = setInterval(async () => {
                const checkResponse = await fetch('api/reporting/generate_reporting_excel');
                if (checkResponse.ok) {
                    // The download is completed, clear the intervals and show "Download Completed" alert
                    downloadButton.disabled = false;
                    clearInterval(processingAlert);
                    clearInterval(checkDownload);
                    NotificationManager.success('Descarga realizada', 'ÉXITO', 6000);
                }
            }, 1000);
        }
    };


    return (
        <React.Fragment>
            <div className="d-flex pt-5 mb-5">
                <img
                    src={LogoReporting}
                    alt="logo inventario"
                    width="55px"
                    height="55"
                />
                <h1 className="title-2 cl-orange mt-1 ml-3">Reportería</h1>
            </div>

            <FilterInputs
                setData={setData}
                reportData={reportData}
                today={today}
                initial_data={INITIAL_DATA}
                getData={getData}
                setReportData={setReportData}
            />
            <div id="report-table" />
            <ShowTables
                reportData={reportData}
                data={data}
                loader={loader}
                page={page}
                getData={getData}
            />

{_.get(data, 'results.length', 0) > 0 && (
                <button
                    id='download-button'
                    onClick={handleClick}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#375784',
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                    }}
                    className="mb-5"
                >
                    Descargar Excel
                </button>
)}
        </React.Fragment>
    );
}
