import React, { useContext, useState } from 'react';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { api } from 'api';
import Swal from 'sweetalert2';
import { NotificationManager } from 'react-notifications';
import LoaderContext from '../../../../context/LoaderContext';
import UserContext from '../../../../context/UserContext';
import EventPersonalForm from '../../../App/Calendar/EventModal/EventPersonalForm';
import LoadMask from '../../LoadMask/LoadMask';
import useDayjsUtils, {
    DEFAULT_TIMEZONE,
} from '../../../../hooks/useDayjsUtils';
import { DateTime, Settings } from 'luxon';
import {
    GROUP_MENTORING,
    INDIVIDUAL_MENTORING,
    PERSONAL_EVENT,
    SENA_EVENT,
    YOUNG_BENEFICIARY,
    KID_BENEFICIARY,
    EDUCATIVE,
    LEGAL_GUARDIAN,
    VOLUNTEER,
} from '../../../../../utility/constants';
import _ from 'lodash';
import '../calendar.css';
Settings.defaultLocale = 'es';
Settings.defaultZone = DEFAULT_TIMEZONE;

export default function InfoModal(props) {
        const [editEvent, setEditEvent] = useState(false);
    const { setLoader, loader } = useContext(LoaderContext);
    const { user } = useContext(UserContext);
    const { toLocaleDateTimeTz } = useDayjsUtils();
    const { setGetFilters } = props;
    const { onCloseModal } = props;

    let type = props.event.type;
    let backgroundColor = 'transparent';
    const training_group_type = _.get(
        props.event,
        'training_group.type',
        undefined
    );

    if (type == GROUP_MENTORING) {
        if (training_group_type === EDUCATIVE) {
            backgroundColor = '#c2ccd9';
        } else if (training_group_type === LEGAL_GUARDIAN) {
            backgroundColor = '#9aa9bf';
        } else if (training_group_type === KID_BENEFICIARY) {
            backgroundColor = '#a9aaad';
        } else {
            backgroundColor = '#ef6447';
        }
    } else if (type == INDIVIDUAL_MENTORING) {
        backgroundColor = '#f3977a';
    } else if (type == PERSONAL_EVENT) {
        backgroundColor = '#a2ce8c';
    } else if (type == SENA_EVENT) {
        backgroundColor = '#72bc56';
    }

    const onOpenModal = () => {
        setEditEvent(false);
    };

    const updateData = async (body, id) => {
        setLoader(true);
        try {
            const endpoint = `event/${id}`;
            await api.put(endpoint, body);
            NotificationManager.success(
                'Evento actualizado exitoso',
                'ÉXITO',
                6000
            );
            onCloseModal();
            //if (path_module) navigate(path_module);
        } catch (e) {
            let message = 'Error interno.';
            if (e && e.detail) message = e.detail;
            else if (_.isArray(e)) message = e;
            NotificationManager.error(message, 'ERROR', 6000);
        } finally {
            setLoader(false);
            setGetFilters(true);
            onOpenModal();
        }
    };

    const deleteData = async (id) => {
        setLoader(true);
        try {
            const endpoint = `event/${id}/delete_event`;
            await api.get(endpoint);
            NotificationManager.success(
                'Evento eliminado exitoso',
                'ÉXITO',
                6000
            );
            onCloseModal();
            //if (path_module) navigate(path_module);
        } catch (e) {
            let message = 'Error interno.';
            if (e && e.detail) message = e.detail;
            else if (_.isArray(e)) message = e;
            NotificationManager.error(message, 'ERROR', 6000);
        } finally {
            setLoader(false);
            setGetFilters(true);
            onOpenModal();
        }
    };

    const updateEvent = (eventData) => {
        const newEvent = { ...eventData };
        if (newEvent.start) DateTime.fromJSDate(eventData.start).toISO();
        if (newEvent.end) DateTime.fromJSDate(eventData.end).toISO();
        if (newEvent.users)
            newEvent.users = newEvent.users.map((user) => user.id);
        if (newEvent.create_by) newEvent.create_by = newEvent.create_by.id;

        updateData(newEvent, props.event.id);
    };

    const unassignData = async (id) => {
        setLoader(true);
        try {
            const endpoint = `event/${id}/unassign_event`;
            await api.get(endpoint);
            NotificationManager.success('Designación exitosa', 'ÉXITO', 6000);
            onCloseModal();
            //if (path_module) navigate(path_module);
        } catch (e) {
            let message = 'Error interno.';
            if (e && e.detail) message = e.detail;
            else if (_.isArray(e)) message = e;
            NotificationManager.error(message, 'ERROR', 6000);
        } finally {
            setLoader(false);
            setGetFilters(true);
            onOpenModal();
        }
    };

    const deleteEvent = () => {
        deleteData(props.event.id);
    };

    const unassign = () => {
        unassignData(props.event.id);
    };

    return (
        <Modal
            open={true}
            onClose={onCloseModal}
            classNames={{
                root: 'InfoEventModal-root',
                modal: 'InfoEventModal',
            }}
            center={true}
        >
            <LoadMask loading={loader}>
                {!editEvent && (
                    <React.Fragment>
                        <div>
                            <div className="d-flex flex-column-reverse flex-md-row justify-content-between">
                                <div className="align-self-center mt-4 m-md-0">
                                    <div
                                        className="square"
                                        style={{
                                            backgroundColor: backgroundColor,
                                        }}
                                    ></div>
                                    <div className="text-info-modal">
                                        <h5>&nbsp;&nbsp;{props.event.title}</h5>
                                    </div>
                                    {type == PERSONAL_EVENT &&
                                        props.event.create_by &&
                                        props.event.create_by.id == user.id && (
                                            <React.Fragment>
                                                <span className="ml-3">
                                                    <i
                                                        onClick={() => {
                                                            setEditEvent(true);
                                                        }}
                                                        className="fa fa-pencil fa-lg"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>

                                                <span
                                                    className="ml-2"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            icon: 'warning',
                                                            title: 'Atención',
                                                            text: '¿Esta seguro que desea eliminar el evento?',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText:
                                                                'Continuar',
                                                            cancelButtonText:
                                                                'Cancelar',
                                                            reverseButtons: true,
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                deleteEvent();
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-trash-o fa-lg"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                            </React.Fragment>
                                        )}
                                    {type == PERSONAL_EVENT &&
                                        props.event.users &&
                                        props.event.create_by &&
                                        props.event.create_by.id != user.id &&
                                        props.event.users.some(
                                            (e) => e.id === user.id
                                        ) && (
                                            <React.Fragment>
                                                <span
                                                    className="ml-2"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            icon: 'warning',
                                                            title: 'Atención',
                                                            text: '¿Esta seguro que desea desasignarse del evento?',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText:
                                                                'Continuar',
                                                            cancelButtonText:
                                                                'Cancelar',
                                                            reverseButtons: true,
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                unassign();
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-calendar-times-o"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                            </React.Fragment>
                                        )}

                                    {type == INDIVIDUAL_MENTORING &&
                                        props.event.create_by &&
                                        props.event.create_by.id == user.id && (
                                            <React.Fragment>
                                                <span
                                                    className="ml-2"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            icon: 'warning',
                                                            title: 'Atención',
                                                            text: '¿Esta seguro que desea eliminar el evento?',
                                                            icon: 'warning',
                                                            showCancelButton: true,
                                                            confirmButtonText:
                                                                'Continuar',
                                                            cancelButtonText:
                                                                'Cancelar',
                                                            reverseButtons: true,
                                                        }).then((result) => {
                                                            if (result.value) {
                                                                deleteEvent();
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <i
                                                        className="fa fa-trash-o fa-lg"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                            </React.Fragment>
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="mb-2">
                            {toLocaleDateTimeTz(props.event.start, 'll')}
                            &nbsp;
                            {toLocaleDateTimeTz(
                                props.event.start,
                                'h:mm A'
                            )} - {toLocaleDateTimeTz(props.event.end, 'h:mm A')}
                        </div>
                        {type == GROUP_MENTORING && (
                            <React.Fragment>
                                <div>
                                    <p className="m-0 font-weight-bolder">
                                        Grupo de formación:{' '}
                                    </p>
                                    {_.get(
                                        props,
                                        'event.training_group.name',
                                        ''
                                    )}
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bolder">
                                        Grupo de beneficiarios:{' '}
                                    </p>
                                    {_.get(
                                        props,
                                        'event.training_group.group__name',
                                        ''
                                    )}
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bolder">
                                        Mentor:{' '}
                                    </p>
                                    {_.get(
                                        props,
                                        'event.training_group.mentor__first_name',
                                        ''
                                    )}
                                    &nbsp;
                                    {_.get(
                                        props,
                                        'event.training_group.mentor__last_name',
                                        ''
                                    )}
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bolder">
                                        Monitor:{' '}
                                    </p>
                                    {_.get(
                                        props,
                                        'event.training_group.monitor__first_name',
                                        ''
                                    )}
                                    &nbsp;
                                    {_.get(
                                        props,
                                        'event.training_group.monitor__last_name',
                                        ''
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                        {type == INDIVIDUAL_MENTORING && (
                            <React.Fragment>
                                {props.event.individual_mentor_text && (
                                    <div>
                                        <p className="m-0 font-weight-bolder">
                                            Mentor Individual:{' '}
                                        </p>
                                        {props.event.individual_mentor_text}
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        {[GROUP_MENTORING, INDIVIDUAL_MENTORING].includes(
                            type
                        ) && (
                            <React.Fragment>
                                <div>
                                    <p className="m-0 font-weight-bolder">
                                        Descripción:{' '}
                                    </p>
                                    {props.event.summary}
                                </div>
                            </React.Fragment>
                        )}
                        {type == SENA_EVENT && (
                            <React.Fragment>
                                <div>
                                    <p className="m-0 font-weight-bolder">
                                        Colegio:{' '}
                                    </p>
                                    {props.event.school_name}
                                </div>
                                <div>
                                    <p className="m-0 font-weight-bolder">
                                        Grado:{' '}
                                    </p>
                                    {props.event.grade}
                                </div>
                            </React.Fragment>
                        )}
                        {(type == SENA_EVENT || type == PERSONAL_EVENT) && (
                            <div>
                                <p className="m-0 font-weight-bolder">
                                    Descripción:{' '}
                                </p>
                                {props.event.description}
                            </div>
                        )}
                        {type == PERSONAL_EVENT && (
                            <React.Fragment>
                                <p className="m-0 font-weight-bolder">
                                    Participantes:{' '}
                                </p>
                                <ol>
                                    {props.event.users.map((user) => (
                                        <li key={user.id}>{user.first_name}</li>
                                    ))}
                                </ol>
                            </React.Fragment>
                        )}
                        <hr />
                        {props.event.create_by && (
                            <div>
                                <p className="m-0 font-weight-bolder">
                                    Creado por:{' '}
                                </p>
                                {props.event.create_by.first_name}&nbsp;
                                {props.event.create_by.last_name}
                            </div>
                        )}
                        {type == GROUP_MENTORING && (
                            <div className="text-center">
                                <Link
                                    to={
                                        '/calendar/' +
                                        props.event.id +
                                        '/assistance/'
                                    }
                                    className="btn btn-primary align-self-center mt-4 m-md-0"
                                >
                                    {`${
                                        !props.event.is_save
                                            ? 'Registrar'
                                            : props.event.is_save
                                            ? 'Actualizar'
                                            : ''
                                    }`}
                                    &nbsp;Asistencia
                                </Link>
                            </div>
                        )}
                        {type == INDIVIDUAL_MENTORING && (
                            <div className="text-center">
                                <Link
                                    to={
                                        '/calendar/' +
                                        props.event.id +
                                        '/assistance/'
                                    }
                                    className="btn btn-primary align-self-center mt-4 m-md-0"
                                >
                                    {`${
                                        !props.event.is_save
                                            ? 'Registrar'
                                            : props.event.is_save
                                            ? 'Actualizar'
                                            : ''
                                    }`}
                                    &nbsp;Asistencia
                                </Link>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {editEvent && (
                    <React.Fragment>
                        <div className="pt-3 event-custom-modal event-custom-overlay">
                            <span className="d-none d-md-inline-block">
                                Editar evento personalizado
                            </span>
                            <hr />
                            <EventPersonalForm
                                onSubmit={updateEvent}
                                initialValues={{ ...props.event }}
                            />
                        </div>
                    </React.Fragment>
                )}
            </LoadMask>
        </Modal>
    );
}
