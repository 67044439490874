import React, { useContext } from 'react';
import FileUploader from '../../../../Utils/FileUploader';
import '../Assistance.css';
import OfflineContext from '../../../../../context/OfflineContext';

const MultiUploadFile = (props) => {
    const {
        id,
        files,
        onChangeFile,
        onRemoveFile,
        onRemoveOldFile,
        data,
        item,
    } = props;
    const { offline } = useContext(OfflineContext);

    return (
        <React.Fragment>
            <FileUploader onChange={onChangeFile} />
            <br />
            {data.length > 0 && (
                <div className="row">
                    {data.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className="col-12 col-md-6 col-lg-3 col-sm-12 main-container-g mt-3"
                            >
                                {offline ? (
                                    <div className="btn-old-files mr-3 multi-file-box-a  text-truncate">
                                        {item.name}
                                    </div>
                                ) : (
                                    <a
                                        href={'media/' + item.archivo}
                                        download={item.name}
                                        type="button"
                                        className="btn-old-files mr-3 multi-file-box-a  text-truncate"
                                    >
                                        <img
                                            className="mr-2"
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAADsUlEQVR4nO3cTWhcVRjG8f+ZDMVACYoUxCYFQVE0wYWYSeNCTd240VZMR+NCQUGwQtyItZkpg8YiuNTudCHWj8ko6EJcCMmmMbmJgSySUihiKZlSu5D6gTHk5h4XQWxrapzMzHnvNM9vOXPved85D/frzDAgIiIiIiLbjbNuYDO5/KivZ/+oXEj1Z8xYN7DdKQBjCsCYAjCmAIwpAGMKQERExEiq10lAa0HSZArAmAIwpgCMKQBjCsCYAhARETGS6nUS0FqQNJkCMKYAjCkAYwrAmAIwpgCMKQBjCsCYAjCmAIwpAGMKwJgCMKYAjAVbK88dfOs9nD8Uql5dvDsejY28HKJUsCNgj79jGM/Xoeptmefb9ourr4QqF/TbotwzpQ4XZyc9dIesW4PTKyvx3vkvS5dCFQz+dV3v4Ju3kXGRg12ha2/iZ9p8X/RJ8UzIosEvwjOV4o8u4QkPK6Fr/4dVvHsy9OQDtIUuCFA9NX6uq2ffErDfov6/OPdSVB75wqK0SQAASwvj8533DOzE0W/Vwzr/TlQuvG1V3fQ5ILo7fg34yrCFb/Ykd75uWN/+d0EPDZZ2LmeyJ4F7A5deJBv3Rx+Xfg1c9wrmAQA88FTp1thnZ4DdgUpeiJNsbq5y+FygeteUiqWIyc9K50mSx4E/ApT7EziQhsmHlAQAEFWOznl4Dqjrp4ib8A5eiMqF6SbWqInZXdBGqovjpzq7BzLAg80Y3zn/xnS5+G4zxt6qVFwDruRdX370hMcNNXjYz6OxIwfBNfMIq1lqTkH/cP6G9rXngcadJpybi5dXn03b5EMqj4B19w+WbslksjNAV30jufOZxPdOVQrVhjTWYCk8AtbNVkoXksQ9BvxexzDLCWv70zr5kOIAAGYrI/N4nwfWtrB74r0fmi0fnW10X42UqrugjVQXJ8509exbAR6pZT/vOTwzVvygSW01TOoDAFhaGJ/s7BnYDdz3f7Z3jg+jcuHVJrfVEKk+BV0u7th1CJjYdEPHyZt+6Xix+R01RmrvgjbSe+DYzW5HMg3cfo1NzrbtiHPffVS6GLKverRUAAB7nz52V5IkU8CNV7312xqu//vyyIJFX1vVMqegv019euQ0+DwQX/bymscPtdrkQ4tchK9WXZz4oav74UvgHgXwMDxTLp6w7mvbyeVHj/flR9+37qMeWesG6tH+Uzxs3YOIiIi0qJqfhOv9/57rXa3/T9RyT8LXGwVgTAEYUwDGFIAxBWBMAYiIiIiIiAT2F+LI89mAkeGXAAAAAElFTkSuQmCC"
                                        ></img>
                                        {item.name}
                                    </a>
                                )}
                                <div className="d-flex align-items-center btn-delete multi-file-box-b">
                                    <img
                                        width="20px"
                                        height="20px"
                                        onClick={() => onRemoveOldFile(item)}
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAD2UlEQVR4nO2cvW4TQRRGz9gvYPMj8TxxHoF1g0CiSaCAAA2hcwc0JCEFPwURlNk8QoLyLrQo2QZFSCSXguwoMbLi9czs3lHuKe2dnfV3PPbMrH3BMAzDMAzDMAzDMAzDMAzDMAwjACmKvoDr+joWRcDJZNJL2UeycKQo+hXHO4KcDMuDRw4kVV8pEHDV3dGmIHeGP8/uucPDPyn6SSKgDh/kPoDA55wk1OHjeAogIrupJEQXMB2+fzwTCdPh+8cTSYgqYFb4/nnlEmaF759PICGagKvC98cplXBV+P64yBKiCJg3fH+8Mgnzhu+PjygheIolRdGv3NHXecMHcLBSjZe2NExRm4YP4JwbV7f6O1IU/dD+gwW4sjx1Ij8aNxT35LgYfexSwiLh+7Y9flGWZ6HXEO3FV8XSa8GtN23X1cdRUPiOT8Pdg8cxrjnquy8XCVrChwTDX7sETeFDos9frRK0hQ8JvwC1SdAYPkSYBc1iUH5/JeLeNm3nYCX27Ehr+NDCFPDo7vIb5+Rl03axRoLm8KGlOXhXErSHDy0ugtqWkEP40PIqtC0JuYQPHWwDpJaQU/jQ0T5MKgm5hQ8dboTFlpBj+JBwHXAVN/b21xdeJ4xHHy6uE3INHxTsxy88Es6DA8g1fFAgAMIkuDN+5xo+KBEAi0tYBC3hgyIB0I4ETeGDMgGQVoK28EGhAEgjQWP4oFQAxJWgNXxQLEDAVcVoA1gLOo/i8EGxAAiXoD186HAlPDei+00SitoXF7K9cOk8ykeBSgGxwvfnUyxBnYDY4fvzKpWgSkCq8P35FUpQIyB1+L4fZRJUCGgrfN+fIgmdT0NDb6YAW03bOWF1+qZOV3R6ATHuZAEsuljTMBLy/HPEVHAhK+auJXQiIMU93FzvC7cuIGVQOUpoVUAbAeUmoTUBbQaTk4RWBHQRSC4SkgvoMogcJCRdiHUdgAMZ7B08Q3jfuK2wWhWjjdSLtXT1ghS9+wK3OrYG5cHzVCMhyQjQFD6EjQRgrRovv0s1EuLXC1IWfqxrw7nNwe7+C9V/U9Ucvu9HmYRr8VfQ//pTJCFOvaCMwvf9KpEQLCDH8H3/CiSEz4KKokePm43bOdlWsBf/b3bkZLt5a7lNUYTXWwo9AdC8ZJmCGyEXaToSYpYsi/clPG/RPmXh18wrIXbRvmgLMVeWpwOGD3F8m3WM1vBhvsWa6rKVNedF/L4gPLj0uOLwLzJrJGRRuLVmWkIu4ddMS8iqdHFNLUHgJKfwa2oJqYt3J0Umk56G394sioCLURvUMAzDMAzDMAzDMAzDMAzDMIzrzV8oKKEiSHXKAQAAAABJRU5ErkJggg=="
                                    ></img>{' '}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            <br />
            {files.length > 0 && (
                <div className="row">
                    {files.map((item, index) => (
                        <div
                            key={index}
                            className="col-12 col-md-6 col-lg-3 col-sm-12 main-container-g  mt-3"
                        >
                            <div className="btn-old-files mr-3 multi-file-box-a  text-truncate">
                                {item.name}
                            </div>

                            <div className="d-flex align-items-center btn-delete  multi-file-box-b">
                                <img
                                    width="20px"
                                    height="20px"
                                    onClick={() => {
                                        onRemoveFile(item);
                                    }}
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAD2UlEQVR4nO2cvW4TQRRGz9gvYPMj8TxxHoF1g0CiSaCAAA2hcwc0JCEFPwURlNk8QoLyLrQo2QZFSCSXguwoMbLi9czs3lHuKe2dnfV3PPbMrH3BMAzDMAzDMAzDMAzDMAzDMAwjACmKvoDr+joWRcDJZNJL2UeycKQo+hXHO4KcDMuDRw4kVV8pEHDV3dGmIHeGP8/uucPDPyn6SSKgDh/kPoDA55wk1OHjeAogIrupJEQXMB2+fzwTCdPh+8cTSYgqYFb4/nnlEmaF759PICGagKvC98cplXBV+P64yBKiCJg3fH+8Mgnzhu+PjygheIolRdGv3NHXecMHcLBSjZe2NExRm4YP4JwbV7f6O1IU/dD+gwW4sjx1Ij8aNxT35LgYfexSwiLh+7Y9flGWZ6HXEO3FV8XSa8GtN23X1cdRUPiOT8Pdg8cxrjnquy8XCVrChwTDX7sETeFDos9frRK0hQ8JvwC1SdAYPkSYBc1iUH5/JeLeNm3nYCX27Ehr+NDCFPDo7vIb5+Rl03axRoLm8KGlOXhXErSHDy0ugtqWkEP40PIqtC0JuYQPHWwDpJaQU/jQ0T5MKgm5hQ8dboTFlpBj+JBwHXAVN/b21xdeJ4xHHy6uE3INHxTsxy88Es6DA8g1fFAgAMIkuDN+5xo+KBEAi0tYBC3hgyIB0I4ETeGDMgGQVoK28EGhAEgjQWP4oFQAxJWgNXxQLEDAVcVoA1gLOo/i8EGxAAiXoD186HAlPDei+00SitoXF7K9cOk8ykeBSgGxwvfnUyxBnYDY4fvzKpWgSkCq8P35FUpQIyB1+L4fZRJUCGgrfN+fIgmdT0NDb6YAW03bOWF1+qZOV3R6ATHuZAEsuljTMBLy/HPEVHAhK+auJXQiIMU93FzvC7cuIGVQOUpoVUAbAeUmoTUBbQaTk4RWBHQRSC4SkgvoMogcJCRdiHUdgAMZ7B08Q3jfuK2wWhWjjdSLtXT1ghS9+wK3OrYG5cHzVCMhyQjQFD6EjQRgrRovv0s1EuLXC1IWfqxrw7nNwe7+C9V/U9Ucvu9HmYRr8VfQ//pTJCFOvaCMwvf9KpEQLCDH8H3/CiSEz4KKokePm43bOdlWsBf/b3bkZLt5a7lNUYTXWwo9AdC8ZJmCGyEXaToSYpYsi/clPG/RPmXh18wrIXbRvmgLMVeWpwOGD3F8m3WM1vBhvsWa6rKVNedF/L4gPLj0uOLwLzJrJGRRuLVmWkIu4ddMS8iqdHFNLUHgJKfwa2oJqYt3J0Umk56G394sioCLURvUMAzDMAzDMAzDMAzDMAzDMIzrzV8oKKEiSHXKAQAAAABJRU5ErkJggg=="
                                ></img>{' '}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <br />
        </React.Fragment>
    );
};

export default MultiUploadFile;
