import React, { useContext, useState } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { Link } from 'react-router-dom';
import LoaderContext from '../../../../context/LoaderContext';
import Table from '../../../Utils/Grid';
import Search from '../../../Utils/Search';
import useList from '../../../../hooks/useList';
import useDelete from '../../../../hooks/useDelete';
import UserContext from '../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN,
    COORDINATOR
} from '../../../../../utility/constants'

const Schools = () => {
    const { loader } = useContext(LoaderContext);
    const { data, getData, page } = useList('school');
    const [search, setSearch] = useState(null);
    const { deleteData } = useDelete('school');
    const { user }= useContext(UserContext);


    return (
        <React.Fragment>
            <div className="pt-5" />
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <h1 className="title-text">Colegios</h1>
                { user.type == SUPER_ADMIN &&(
                <Link to="/school/create" className="btn btn-primary">
                    Registrar Colegio
                </Link>
                )}
            </div>
            <Search
                onSearch={(value) => {
                    getData(1, {
                        search: value,
                    }).then();
                    setSearch(value);
                }}
                placeholder="Buscar por nit y nombre ..."
            />
            <Table
                className="mt-5"
                data={data}
                loading={loader}
                onPageChange={(page) => getData(page, { search: search })}
                page={page}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/school',
                        update_school: '/school',
                        erase: (id) => deleteData(id, getData),
                        type:user.type,
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn dataField="nit" width="10rem">
                    NIT
                </TableHeaderColumn>
                <TableHeaderColumn dataField="name" width="10rem">
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn dataField="code" width="10rem">
                    Código del colegio
                </TableHeaderColumn>

                <TableHeaderColumn dataField="telephone" width="10rem">
                    Teléfono
                </TableHeaderColumn>
                <TableHeaderColumn dataField="status" width="10rem">
                    Estado
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default Schools;
