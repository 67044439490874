import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../../Utils/Grid';
import { standardActions } from '../../../../Utils/Grid/StandardActions';
import { useList } from '../../../../../hooks/useList';
import LoaderContext from '../../../../../context/LoaderContext';
import TitleUnderline from '../../../../Utils/TitleUnderline';
import _ from 'lodash';

import Search from '../../../../Utils/Search';

const LocationList = () => {
    const [filters, setFilter] = useState({
        search: '',
    });
    const { loader } = useContext(LoaderContext);
    const { data, getData, page } = useList('location', {
        ordering: '-id',
        search: filters.search,
    });
    const format = (cell) => {
        try {
            return cell.name ? cell.name : cell;
        } catch (error) {
            return '---';
        }
    };
    return (
        <React.Fragment>
            <h1 className="title-text pt-5">Ubicaciones</h1>
            <Search
                onSearch={(value) => {
                    getData(1, { search: value }).then();
                    setFilter({ ...filters, search: value }); //so that the filters remain fixed, until they are removed
                }}
                placeholder="Buscar por Nombre o colegio"
            />
            <div className="d-flex flex-column flex-md-row justify-content-between mt-5">
                <TitleUnderline title="Listado de ubicaciones" />
                <Link
                    to="/inventory/location/create"
                    className="btn btn-primary"
                >
                    Registrar Ubicación
                </Link>
            </div>
            <Table
                className="mt-5"
                data={data}
                onPageChange={(page) => getData(page)}
                page={page}
                loading={loader}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/inventory/location',
                        update: '/inventory/location',
                        // erase: async (id) => {
                        //     await deleteData(id);
                        //     getData();
                        // },
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="name"
                    dataFormat={format}
                >
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="school"
                    dataFormat={format}
                >
                    Colegio
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default LocationList;
