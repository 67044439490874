import React, { useContext, useState } from 'react';

import { Link } from 'react-router-dom';
import Table from '../../../../Utils/Grid';
import { dateFormatter } from '../../../../../../utility/formatFields';
import { useList } from '../../../../../hooks/useList';
import LoaderContext from '../../../../../context/LoaderContext';
import TitleUnderline from '../../../../Utils/TitleUnderline';
import { standardActions } from '../../../../Utils/Grid/StandardActions';
import FilterInputs from '../../Utils/FilterInputs';
import _ from "lodash"

import { api } from 'api';
import { NotificationManager } from 'react-notifications';

import IssueLogo from '../../../../../../../assets/img/salida-logo.svg';
import TitleIcon from '../../Utils/TitleIcon';
import UserContext from '../../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN,
    COORDINATOR,
} from '../../../../../../utility/constants'

const InventoryIssueList = () => {
    const { user }= useContext(UserContext);
    const [filters, setFilter] = useState({
        search: '',
        start: '',
        end: '',
        is_annulled: '',
    });

    const { loader } = useContext(LoaderContext);
    const { data, getData, page } = useList('inventory_issue', {
        ordering: '-id',
        search: filters.search,
        start: filters.start,
        end: filters.end,
        is_annulled: filters.is_annulled,
    });

    const format = (cell) => {
        try {
            return cell.name ? cell.name : cell;
        } catch (error) {
            return '---';
        }
    };
    return (
        <React.Fragment>
            <TitleIcon title="Salidas" image={IssueLogo} />
            <FilterInputs
                search_placeholder="Buscar por ubicación"
                show_filter_by={true}
                getData={getData}
                filters={filters}
                setFilter={setFilter}
            />
            <div className="d-flex flex-column flex-md-row justify-content-between mt-5">
                <TitleUnderline title="Listado de salidas" />
                { (user.type === SUPER_ADMIN || user.type === ASSISTANT || user.type === SCHOOL_COORDINATOR) &&(
                <Link
                    to="/inventory/inventory_issue/create"
                    className="btn btn-primary"
                >
                    Registrar Salida
                </Link>
                )}
            </div>
            <Table
                data={data}
                onPageChange={(page) => getData(page)}
                page={page}
                loading={loader}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/inventory/inventory_issue',
                        annul: async (id, reason) => {
                            try {
                                await api.post('inventory_issue/annul', {
                                    reason,
                                    id,
                                });
                                getData();
                                NotificationManager.success(
                                    'Registro exitoso',
                                    'ÉXITO',
                                    6000
                                );
                            } catch (e) {
                                let message = 'Error interno.';
                                if (e && e.detail) message = e.detail;
                                else if (_.isArray(e)) message = e;
                                NotificationManager.error(
                                    message,
                                    'ERROR',
                                    6000
                                );
                            }
                        },
                        type:user.type,
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="date"
                    dataSort={true}
                    dataFormat={dateFormatter}
                    tdStyle={(_, data) => {
                        const { is_annulled, reason_annulment } = data;
                        return is_annulled
                            ? {
                                  textDecoration: 'line-through',
                                  opacity: 0.6,
                              }
                            : {};
                    }}
                >
                    Fecha de la salida
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="origin"
                    dataFormat={format}
                    tdStyle={(_, data) => {
                        const { is_annulled, reason_annulment } = data;
                        return is_annulled
                            ? {
                                  textDecoration: 'line-through',
                                  opacity: 0.6,
                              }
                            : {};
                    }}
                >
                    Salió de la ubicación
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="user_movement"
                    dataFormat={format}
                    tdStyle={(_, data) => {
                        const { is_annulled, reason_annulment } = data;
                        return is_annulled
                            ? {
                                  textDecoration: 'line-through',
                                  opacity: 0.6,
                              }
                            : {};
                    }}
                >
                    Realizado por
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="reason_annulment"
                    dataFormat={format}
                >
                    Razón de anulación
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default InventoryIssueList;
