import React, { useEffect, useState } from 'react';
import Card from '../../Utils/Card';
import LoadMask from '../../Utils/LoadMask/LoadMask';
import LogoHome from '../../../../../assets/img/logo-home.svg';
import Ads from './Ads./Ads';
import './Dashboard.css';
import { api } from 'api';
import _ from 'lodash';
import MainFilters from './MainFilters';
import dayjs from 'dayjs';
import YouthImg from '../../../../../assets/img/img-youth.svg';
import KidImg from '../../../../../assets/img/kids.svg';
import useIsOffline from '../../../hooks/useIsOffline';
import HandsImg from '../../../../../assets/img/hands.svg';

const DEFAULT_FILTER = { year: dayjs() };

const Dashboard = (props) => {
    const [total, setTotal] = useState({});
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(DEFAULT_FILTER);
    const { isOffline } = useIsOffline();

    useEffect(() => {
        setLoading(true);
        api.get('dashboard/total')
            .then((response) => {
                isOffline(response);
                setTotal(response);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setLoading(true);
        const params = {};
        const school = _.get(filters, 'school', []);
        const year = _.get(filters, 'year', null);

        if (school.length > 0) params.school = school.map((i) => i.id);
        if (year !== null && dayjs(year).isValid())
            params.year = dayjs(year).year();
        api.get('dashboard/data', params)
            .then((response) => {
                isOffline(response);
                setData(response);
            })
            .finally(() => setLoading(false));
    }, [filters]);

    const data_youth = Array.from(data.data_youth || []);
    const data_kids = Array.from(data.data_kids || []);

    return (
        <React.Fragment>
            <div className="d-flex pt-5 mb-2 align-items-center">
                <img src={LogoHome} alt="logo home" width="55px" height="55" />
                <div className="d-flex flex-column justify-content-center pt-3">
                    <h1 className="title-2 cl-orange ml-3 m-0 p-0">
                        Dashboard
                    </h1>
                    <h3
                        className="title-3 cl-dark-blue ml-3"
                        style={{ marginTop: '-20px' }}
                    >
                        Datos generales
                    </h3>
                </div>
            </div>
            <Ads />
            <MainFilters filters={filters} setFilters={setFilters} />
            <LoadMask loading={loading}>
                <div className="d-flex flex-column flex-lg-row">
                    <Card isSecondary className="m-2 flex-1 is-relative">
                        <div style={{ overflow: 'auto', width: '85%' }}>
                            <table className="table-second">
                                <thead>
                                    <tr>
                                        <th scope="col">Jóvenes</th>
                                        <th scope="col">Nivel 1</th>
                                        <th scope="col">Nivel 2</th>
                                        <th scope="col">Nivel 3</th>
                                    </tr>
                                </thead>
                                {data_youth.length > 0 && (
                                    <tbody>
                                        {data_youth.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="first">
                                                        {_.get(
                                                            item,
                                                            'name',
                                                            ''
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'level-1',
                                                            0
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'level-2',
                                                            0
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'level-3',
                                                            0
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {data_youth.length === 0 && (
                            <div className="d-flex justify-content-center">
                                <span className="my-4 historic-text">
                                    No hay datos
                                </span>
                            </div>
                        )}
                        <div className="d-flex ml-2 mt-2 align-items-center">
                            <span className="font-color-secondary font-weight-bolder my-4">
                                Beneficiarios Históricos
                            </span>
                            <span className="historic-text ml-2">
                                {_.get(data, 'historic_youth', 0)}
                            </span>
                        </div>
                        <img className="img-youth" src={YouthImg} />
                    </Card>
                    <Card isSecondary className="m-2 flex-1 is-relative">
                        <div style={{ overflow: 'auto', width: '85%' }}>
                            <table className="table-second">
                                <thead>
                                    <tr>
                                        <th scope="col">Niños</th>
                                        <th scope="col">1º</th>
                                        <th scope="col">2º</th>
                                        <th scope="col">3º</th>
                                    </tr>
                                </thead>
                                {data_kids.length > 0 && (
                                    <tbody>
                                        {data_kids.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="first">
                                                        {_.get(
                                                            item,
                                                            'name',
                                                            ''
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'level-1',
                                                            0
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'level-2',
                                                            0
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'level-3',
                                                            0
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                )}
                            </table>
                        </div>
                        {data_kids.length === 0 && (
                            <div className="d-flex justify-content-center">
                                <span className="my-4 historic-text">
                                    No hay datos
                                </span>
                            </div>
                        )}
                        <div className="d-flex ml-2 mt-2 align-items-center">
                            <span className="font-color-secondary font-weight-bolder my-4">
                                Beneficiarios Históricos
                            </span>
                            <span className="historic-text ml-2">
                                {_.get(data, 'historic_kids', 0)}
                            </span>
                        </div>
                        <img className="img-kids" src={KidImg} />
                    </Card>
                </div>
                <div className="d-flex flex-column flex-md-row ">
                    <Card isSecondary className="m-2 flex-1 is-relative">
                        <div
                            style={{ overflow: 'auto', width: '85%' }}
                            className="mb-4 mb-md-0"
                        >
                            <table className="table-second">
                                <thead>
                                    <tr>
                                        <th scope="col">Otros Beneficiarios</th>
                                        <th scope="col">Activos</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(data.data_others || []).map(
                                        (item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="first">
                                                        {_.get(
                                                            item,
                                                            'name',
                                                            ''
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'count',
                                                            0
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <img className="img-hands" src={HandsImg} />
                    </Card>
                    <Card isPrimary className="m-2 flex-1">
                        <div style={{ overflow: 'auto' }}>
                            <table className="total-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Total Iwoka</th>
                                        <th scope="col">Activos</th>
                                        <th scope="col">Histórico</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(total || []).map(
                                        (item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="first">
                                                        {_.get(
                                                            item,
                                                            'name',
                                                            ''
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'active',
                                                            0
                                                        )}
                                                    </td>
                                                    <td>
                                                        {_.get(
                                                            item,
                                                            'historic',
                                                            0
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default Dashboard;
