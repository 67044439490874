import React from 'react';
import DatePicker from '../../../../Utils/DayPicker';
import dayjs from 'dayjs';
import Search from '../../../../Utils/Search';
import AsyncSelect from 'react-select/async';
import { customStylesReactSelect } from '../../../../Utils/renderField/renderField';
import locale_es from 'antd/es/date-picker/locale/es_ES';

const FilterInputs = ({
    module_title = '',
    search_placeholder,
    show_filter_by = false,
    getData,
    filters,
    setFilter,
}) => {
    const onChangeStart = (e) => {
        if (e) {
            const date_start = dayjs(e).format('YYYY-MM-DD');
            setFilter({ ...filters, start: date_start });
            getData(1, { start: date_start }).then();
        } else {
            setFilter({ ...filters, start: '' });
            getData(1, { start: '' }).then();
        }
    };
    const onChangeEnd = (e) => {
        if (e) {
            const date_end = dayjs(e).format('YYYY-MM-DD HH:mm:ss');
            setFilter({ ...filters, end: date_end });
            getData(1, { end: date_end }).then();
        } else {
            setFilter({ ...filters, end: '' });
            getData(1, { end: '' }).then();
        }
    };
    const onChangeShowBy = (e) => {
        if (e) {
            setFilter({ ...filters, is_annulled: e });
            if (e.show_by === 'Activos') {
                getData(1, { is_annulled: 0 }).then();
            } else if (e.show_by === 'Cancelados') {
                getData(1, { is_annulled: 1 }).then();
            }
        } else {
            setFilter({ ...filters, is_annulled: '' });
            getData(1, { is_annulled: '' }).then();
        }
    };
    return (
        <React.Fragment>
            {module_title && (
                <h1 className="title-2 cl-orange pt-5 mb-5">{module_title}</h1>
            )}
            <div
                className={`d-flex flex-column ${
                    !show_filter_by && 'flex-md-row'
                }`}
            >
                <div className="form-group d-flex has-feedback m-2 flex-1">
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Busqueda</label>
                        <Search
                            onSearch={(value) => {
                                getData(1, { search: value }).then();
                                setFilter({ ...filters, search: value });
                            }}
                            placeholder={search_placeholder}
                        />
                    </div>
                    {/* Show or not this filter input */}
                    {show_filter_by && (
                        <div className="form-group has-feedback m-2 flex-1">
                            <label>Mostrar por</label>
                            <AsyncSelect
                                styles={customStylesReactSelect}
                                isSearchable={true}
                                isClearable={true}
                                defaultOptions
                                loadOptions={async () => [
                                    {
                                        show_by: 'Activos',
                                    },
                                    {
                                        show_by: 'Cancelados',
                                    },
                                ]}
                                placeholder="Cancelados o Activos"
                                onChange={onChangeShowBy}
                                value={filters.is_annulled}
                                valueKey="show_by"
                                labelKey="show_by"
                                labelKey2=""
                                getOptionValue={(option) => option['show_by']}
                                getOptionLabel={(option) =>
                                    `${option['show_by']} ${option[''] || ''}`
                                }
                                onClick={() => setOpenExpand(false)}
                            />
                        </div>
                    )}
                    {show_filter_by && (
                        <div className="form-group has-feedback m-2 flex-1"></div>
                    )}
                </div>
                <div className="form-group d-flex flex-column flex-md-row flex-wrap has-feedback m-2 flex-1">
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Fecha Inicio</label>
                        <DatePicker
                            onChange={onChangeStart}
                            locale={locale_es}
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            className="form-control w-100"
                        />
                    </div>
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Fecha Fin</label>
                        <DatePicker
                            onChange={onChangeEnd}
                            locale={locale_es}
                            format="DD/MM/YYYY"
                            placeholder="DD/MM/YYYY"
                            className="form-control w-100"
                        />
                    </div>
                    {show_filter_by && (
                        <div className="form-group has-feedback m-2 flex-1"></div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default FilterInputs;
