import React from 'react';

export default function OfflineSvgIcon({
    width = 96,
    height = 93,
    onClick = () => {},
}) {
    return (
        <svg
            id="Capa_1"
            data-name="Capa 1"
            width={width}
            height={height}
            fill="rgb(225, 50, 85)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 700 700"
        >
            <path d="M575,338.65a170.5,170.5,0,0,0,2.33-27.53A163.34,163.34,0,0,0,265,244.5a105,105,0,0,0-128.34,79.33,128.33,128.33,0,0,0,55.65,244H495.64A128.33,128.33,0,0,0,575,338.65Zm-79.34,205.8H192.3a105,105,0,0,1-41-201.72,11.63,11.63,0,0,0,7-9A81.67,81.67,0,0,1,267.67,269.7a11.13,11.13,0,0,0,15.05-6.65A140,140,0,0,1,554,311.12a145.94,145.94,0,0,1-3.26,30,11.69,11.69,0,0,0,4.66,11.67,105,105,0,0,1-59.73,191.68Zm-140-128.34v-140a11.67,11.67,0,0,1,23.34,0v140a11.67,11.67,0,1,1-23.34,0Zm11.67,35h0a35,35,0,1,0,24.75,10.26,35,35,0,0,0-24.75-10.26Zm0,46.67h0a11.68,11.68,0,1,1,8.25-3.42,11.67,11.67,0,0,1-8.25,3.42Z" />
        </svg>
    );
}
