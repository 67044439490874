import { useContext } from 'react';
import OfflineContext from '../common/context/OfflineContext';
import _ from 'lodash';

export function paginate(array, page_number) {
    return array.slice((page_number - 1) * 10, page_number * 10);
}

export function isOffline(res) {
    const { offline, setOffline } = useContext(OfflineContext);
    const _res = _.cloneDeep(res);
    if (_res && _res.offline == true) {
        if (!_.isEqual(offline, true)) setOffline(true);
    } else {
        if (!_.isEqual(offline, false)) setOffline(false);
    }
}
