import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import {
    email,
    required,
    maxLength,
} from '../../../../../../utility/validation';
import {
    renderField,
    renderSelectField,
} from '../../../../Utils/renderField/renderField';
import { TYPE_SUPPLIER_OPTIONS } from '../../../../../../utility/constants';

const SupplierForm = ({ supplierData, onSubmit, view, update }) => {
    const [typeSupplier, setTypeSupplier] = useState('proveedor');

    // If the supplierData has a type property (editing or viewing), set the state to the type
    useEffect(() => {
        if (supplierData.type) {
            setTypeSupplier(supplierData.type);
        }
    }, [supplierData]);
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={supplierData}
                render={({ handleSubmit, submitting }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="type">Tipo de Proveedor</label>
                                <Field
                                    name="type"
                                    label="Tipo de Proveedor"
                                    component={renderSelectField}
                                    options={TYPE_SUPPLIER_OPTIONS}
                                    type="text"
                                    className="form-control w-100"
                                    validate={required}
                                    defaultValue={'proveedor'}
                                    parse={(value) => {
                                        setTypeSupplier(value);
                                        return value;
                                    }}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1"></div>
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="name">
                                    Nombre del{' '}
                                    {typeSupplier === 'proveedor'
                                        ? 'Proveedor'
                                        : 'Donante'}
                                </label>
                                <Field
                                    name="name"
                                    component={renderField}
                                    placeholder="Ingrese nombre"
                                    type="text"
                                    className="form-control w-100"
                                    validate={required}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1">
                                <React.Fragment>
                                    <label htmlFor="last_name">
                                        Correo Electrónico
                                    </label>
                                    <Field
                                        name="email"
                                        component={renderField}
                                        placeholder="Ingrese correo"
                                        type="text"
                                        className="form-control w-100"
                                        validate={email}
                                        disabled={view}
                                    />
                                </React.Fragment>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="name">Teléfono</label>
                                <Field
                                    name="cellphone"
                                    component={renderField}
                                    type="number"
                                    placeholder="Ingrese teléfono"
                                    validate={maxLength(15)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="d-none d-md-flex m-2 flex-1" />
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/inventory/supplier"
                                className="btn btn-secondary mt-4 mt-md-0"
                            >
                                Regresar
                            </Link>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    {update ? 'Actualizar' : 'Guardar'}
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default SupplierForm;
