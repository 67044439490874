import dayjs from 'dayjs';
import _ from 'lodash';

const isArrayEmpty = (value) => _.isArray(value) && value.length === 0;

const isEmpty = (value) =>
    value === undefined ||
    value === null ||
    value === '' ||
    isArrayEmpty(value);
function join(rules) {
    return (value, data) =>
        rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];
}

export function email(value) {
    // Let's not start a debate on email regex! This one is quite standard
    if (
        !isEmpty(value) &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
        return 'Dirección de correo electrónico no válida';
    }

    return null;
}

export function phone(value) {
    // Let's not start a debate on phone regex! This one is the best I can find, the best way to
    // do it correctly is utilizing a third party verification, but for our use case, it is
    // just overkill
    if (
        !isEmpty(value) &&
        !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]*$/g.test(value)
    ) {
        return 'Teléfono invalido';
    }

    return null;
}

export function required(value) {
    if (isEmpty(value)) {
        return '*Campo obligatorio';
    }

    return null;
}

export function minLength(min) {
    return (value) => {
        if (!isEmpty(value) && value.length < min) {
            return `Debe tener al menos ${min} caracteres`;
        }

        return null;
    };
}

export function maxLength(max) {
    return (value) => {
        if (!isEmpty(value) && value.length > max) {
            return `No debe tener mas de ${max} caracteres`;
        }

        return null;
    };
}

export function integer(value) {
    if (!Number.isInteger(Number(value))) {
        return 'Debe ser numérico';
    }

    return null;
}

export function oneOf(enumeration) {
    return (value) => {
        if (!enumeration.indexOf(value)) {
            return `Must be one of: ${enumeration.join(', ')}`;
        }

        return null;
    };
}

export function match(field) {
    return (value, data) => {
        if (data) {
            if (value !== data[field]) {
                return 'No coinciden';
            }
        }

        return null;
    };
}

export function createValidator(rules) {
    return (data = {}) => {
        const errors = {};
        Object.keys(rules).forEach((key) => {
            // concat enables both functions and arrays of functions
            const rule = join([].concat(rules[key]));
            const error = rule(data[key], data);
            if (error) {
                errors[key] = error;
            }
        });
        return errors;
    };
}

export function password(value) {
    const regularExpression =
        /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!regularExpression.test(value)) {
        return 'La contraseña debe contener al menos un número, un carácter especial y letras';
    }
    if (/iwoka/i.test(value)) {
        return 'La contraseña no debe contener la palabra iwoka';
    }
    return null;
}

export const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce(
            (error, validator) => error || validator(value),
            undefined
        );

export function date(value) {
    if (!isEmpty(value) && !dayjs(value).isValid()) {
        return 'Fecha invalida';
    }
    return null;
}

export function isOlder(value) {
    if (!isEmpty(value)) {
        const now = dayjs();
        const limitDate = dayjs().year(now.year() - 18);
        if (dayjs(value).isValid() && dayjs(value) > limitDate) {
            return 'Debe ser mayor de 18 años.';
        }
    }
    return null;
}

export function isPositiveNum(value) {
    if (!isEmpty(value) && parseInt(value) < 0) {
        return `El valor debe ser positivo.`;
    }

    return null;
}

export const minValue = (min) => (value) =>
    isNaN(value) || value >= min ? undefined : `Debe ser mayor que ${min}`;
