import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DndRow from './DndRow';

const reOrdenar = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export default function DndTable({ rows, setRows, className }) {
    const ListRows = React.memo(function Row({ listRows, onRemoveRow }) {
        return listRows.map((item, index) => (
            <DndRow
                item={item}
                onRemoveRow={onRemoveRow}
                index={index}
                key={item.code}
            />
        ));
    });

    const onDragEnd = (result) => {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }
        const orderRows = reOrdenar(
            rows,
            result.source.index,
            result.destination.index
        );
        setRows(orderRows);
    };
    const onRemoveRow = (row) => {
        let copyRow = [...rows];
        copyRow = copyRow.filter((item) => item['code'] !== row.code);

        setRows(copyRow);
    };

    return (
        <React.Fragment>
            <div className={`dnd-table mt-1 ${className || ''}`}>
                <div className="header">
                    <div
                        className="font-color-primary dnd-header-content"
                        style={{ textAlign: 'center' }}
                    >
                        Eliminar
                    </div>
                    <div className="dnd-header-content">Código</div>
                    <div className="dnd-header-content">Titulo de sesión</div>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="dndTable">
                        {(provided) => (
                            <div
                                className="col-12 p-0"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <ListRows
                                    listRows={rows}
                                    onRemoveRow={onRemoveRow}
                                />
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </React.Fragment>
    );
}
