import React from 'react';

import './TitleSubTitle.css';

const TitleSubTitle = ({ title, subTitle }) => {
    return (
        <div className="ml-3 card-container">
            <div className="card-item">
                <h2 className="title-2 cl-dark-blue">{title}</h2>
            </div>
            <div className="card-item">
                <h2 className="title-2">{subTitle}</h2>
            </div>
        </div>
    );
};

export default TitleSubTitle;
