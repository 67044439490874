import React, { useState, useEffect } from 'react';
import Table from '../../Utils/Grid';
import { Link } from 'react-router-dom';
import { TableHeaderColumn } from 'react-bootstrap-table';
import useDayjsUtils from '../../../hooks/useDayjsUtils';
import OfflineSvgIcon from './SvgIcons/OfflineSvgIcon';
import AssistanceSvgIcon from './SvgIcons/AssistanceSvgIcon';
import useEvents from '../../../hooks/useEvents';
import { paginate } from '../../../../utility/utils';
import {
    RenderText,
    RenderCheckBox,
} from '../../Utils/renderField/renderReadField';

import {
    GROUP_MENTORING,
    INDIVIDUAL_MENTORING,
    PERSONAL_EVENT,
    SENA_EVENT,
} from '../../../../utility/constants';
import './events_offline.css';

function getEventStyle(row, rowIndex) {
    switch (row.type) {
        case GROUP_MENTORING:
            return 'group-mentoring-event';
        case INDIVIDUAL_MENTORING:
            return 'individual-mentoring-event';
        case PERSONAL_EVENT:
            return 'personal-event';
        case SENA_EVENT:
            return 'sena-event';
        default:
            return '';
    }
}

export default function EventsOffline() {
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const { toDateTz, toTimeTz } = useDayjsUtils();
    const { getEvents, loader } = useEvents();
    useEffect(() => {
        getEvents().then((items) => setEvents(items));
    }, []);

    return (
        <React.Fragment>
            <div className="pt-5" />
            <div className="d-flex flex-column flex-md-row justify-content-start align-items-center mb-md-4">
                <OfflineSvgIcon width="3rem" height="3rem" />
                <h1 className="title-2 font-color-primary m-0 ml-3">
                    Listado de eventos
                </h1>
            </div>
            <div style={{ maxWidth: '100%', overflowX: 'hidden' }}>
                <Table
                    loading={loader}
                    page={page}
                    onPageChange={setPage}
                    data={{
                        results: paginate(events, page),
                        count: events.length || 0,
                    }}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="id"
                        width="5rem"
                        dataFormat={(value, row) => {
                            if (
                                [
                                    GROUP_MENTORING,
                                    INDIVIDUAL_MENTORING,
                                ].includes(row.type)
                            ) {
                                return (
                                    <Link to={`/calendar/${value}/assistance`}>
                                        <AssistanceSvgIcon
                                            width="1.8rem"
                                            height="1.6rem"
                                        />
                                    </Link>
                                );
                            }
                            return '';
                        }}
                    >
                        Asistencia
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="title"
                        width="10rem"
                        dataFormat={(value, row) => {
                            return (
                                <div
                                    className={`${getEventStyle(
                                        row
                                    )} d-flex align-items-center py-2`}
                                >
                                    <div className="event-box" />
                                    <RenderText
                                        className="m-0 ml-2 event-title"
                                        value={value}
                                    />
                                </div>
                            );
                        }}
                    >
                        Titulo
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="training_group"
                        dataFormat={(value) => {
                            if (value && value.name)
                                return (
                                    <div title={value.name}>{value.name}</div>
                                );
                            return '';
                        }}
                        width="12rem"
                    >
                        Grupo de formación
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="is_save"
                        dataFormat={(value, row) => {
                            if (
                                [
                                    GROUP_MENTORING,
                                    INDIVIDUAL_MENTORING,
                                ].includes(row.type)
                            )
                                return <RenderCheckBox value={value} />;
                        }}
                        width="10rem"
                    >
                        ¿Asistencia Tomada?
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="start"
                        dataFormat={toDateTz}
                        dataSort={true}
                        width="7rem"
                    >
                        Fecha
                    </TableHeaderColumn>
                </Table>
            </div>
        </React.Fragment>
    );
}
