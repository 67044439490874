import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router';

import TransferForm from './TransferForm';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import useUpdate from '../../../../../hooks/useUpdate';
import LoaderContext from '../../../../../context/LoaderContext';
import MovmentContext from '../../../../../context/MovmentContext';
import { NotificationManager } from 'react-notifications';
import dayjs from 'dayjs';
import useCreate from '../../../../../hooks/useCreate';
import Swal from 'sweetalert2';

import './DetailTable.css';
import MissingProductsContext from '../../../../../context/MissingProductsContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PrintFormat from '../../Utils/PrintFormat';

import TransferLogo from '../../../../../../../assets/img/traslado-logo.svg';
import TitleIcon from '../../Utils/TitleIcon';

const CreateUpdate = ({ path_back }) => {
    // radio button state (movment)
    // 1 product
    // 2 school kit
    const { movment } = useContext(MovmentContext);
    const isProduct = movment === 1;

    const location = useLocation();
    const isViewed = location.pathname.includes('view');

    const { data, title, update, updateData } = useUpdate(
        'transfer',
        path_back
    );
    const { saveData: saveTransfer } = useCreate(
        'transfer',
        '/inventory/transfers'
    );

    const { loader } = useContext(LoaderContext);

    const [transfer, setTransfer] = useState([]);

    const makeRandomId = () => {
        let a = new Date();
        let b = a.toISOString();
        let id = b.replace(/[-T:.Z]/g, '');
        return id;
    };

    const saveData = () => {
        if (transfer.length < 1) {
            // no product added
            NotificationManager.error(
                'Agrega al menos 1 Artículo',
                'ERROR',
                6000
            );
        } else {
            // Formatting the data to a format acceptable to the transfer api
            const details = []; /// array with the formatted products
            transfer.map((product_transfer) => {
                const {
                    product,
                    serial_number,
                    observations,
                    location,
                    location_destination,
                    quantity,
                } = product_transfer;

                const isKit = product_transfer.product.school_kit; // true false
                const serie =
                    serial_number === 'NO_SERIAL' ? '' : serial_number;
                // If it is a kit, separate the products and format the data
                if (isKit) {
                    const kit_products = product_transfer.product.school_kit;
                    const school_kit = product_transfer.product.id;
                    kit_products.map((kit_product) => {
                        // Total products to enter
                        const total_quantity = quantity * kit_product.quantity;
                        details.push({
                            product: kit_product.product.id,
                            serial_number: serie,
                            observations,
                            location: location.id,
                            destination: location_destination.id,
                            school_kit,
                            quantity: parseInt(total_quantity),
                        });
                    });
                } else {
                    const school_kit = '';
                    // format the data
                    details.push({
                        product: product.product,
                        serial_number: serie,
                        observations,
                        location: location.id,
                        destination: location_destination.id,
                        school_kit,
                        quantity: parseInt(quantity),
                    });
                }
            });
            // Acceptable data format for the api
            const date_transfer = dayjs(transfer[0].date).format(
                'YYYY-MM-DD HH:mm:ss'
            );
            const origin = transfer[0].location.id; // issue
            const destination = transfer[0].location_destination.id; // entry
            const formatData = {
                type: 'traslado',
                date: date_transfer,
                origin,
                destination,
                details,
            };

            // send data to api
            Swal.fire({
                icon: 'warning',
                title: 'Atención',
                text: 'Al guardar el traslado, ya no se podran revertir los cambios',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    saveTransfer(formatData);
                }
            });
        }
    };

    const onSubmit = (dataForm, event) => {
        const body = { ...dataForm };
        if (!update) {
            if (isProduct) {
                body.description = body.product.description;
            } else {
                body.description = body.product.name;
            }
            body.location_name = body.location.name;
            body.location_destination_name = body.location_destination.name;
            body.serial_number = body.product.serial_number || '';
            body.observations = '';
            body.id = makeRandomId();
            setTransfer([...transfer, body]);
            event.reset({
                location: body.location,
                location_destination: body.location_destination,
                date: body.date,
                quantity: '1',
            });
        } else {
            if (body.assigned_to) body.assigned_to = body.assigned_to.id;
            body.product = body.product.id;
            updateData(body);
        }
    };

    // global context, to get the missing products (the state is set in useCreate)
    const { missingProducts } = useContext(MissingProductsContext);
    return (
        <React.Fragment>
            {/* button to download pdf (hidden)
            download by reference id (element.click()) */}
            <PDFDownloadLink
                document={<PrintFormat data={missingProducts} />}
                fileName="artículos-faltantes.pdf"
                style={{ display: 'none' }}
            >
                <button id="download-pdf">
                    Descargar PDF
                    <span style={{ display: 'none' }}>
                        {!missingProducts.length}
                    </span>
                </button>
            </PDFDownloadLink>
            <LoadMask loading={loader} blur>
                <div>
                    <TitleIcon title="Traslados" image={TransferLogo} />
                    <TransferForm
                        transferData={data}
                        onSubmit={onSubmit}
                        update={update}
                        path_back={path_back}
                        isViewed={isViewed}
                        transfer={transfer}
                        setTransfer={setTransfer}
                        onClick={saveData}
                    />
                </div>
            </LoadMask>
            <br />
        </React.Fragment>
    );
};

export default CreateUpdate;
