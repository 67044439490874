import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Assistance from './Assistance';
import KpiAssistance from './KpiAssistance';
import List from './List';

export default function School() {
    return (
        <Routes>
            <Route path="" element={<List />} />
            <Route path="/assistance" element={<Assistance />} />
            <Route path="/kpi-assistance" element={<KpiAssistance />} />
        </Routes>
    );
}
