import _ from 'lodash';
import { useContext } from 'react';
import OfflineContext from '../context/OfflineContext';
import { NotificationManager } from 'react-notifications';

export default function useIsOffline() {
    const { offline, setOffline } = useContext(OfflineContext);

    const isOffline = (res) => {
        if (_.get(res, 'offline', false) == true) {
            if (!_.isEqual(offline, true)) {
                setOffline(true);
                NotificationManager.info(
                    'Modo offline se ha activado, las funciones a realizar estarán limitadas.',
                    '',
                    5000
                );
            }
        } else {
            if (!_.isEqual(offline, false)) {
                setOffline(false);
                NotificationManager.info(
                    'Estas en linea de nuevo, se habilitaran todas las funciones habituales.',
                    '',
                    5000
                );
            }
        }
    };
    return { isOffline };
}
