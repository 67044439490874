import { useContext } from 'react';
import { DateTime } from 'luxon';
import { api } from 'api';
import { NotificationManager } from 'react-notifications';
import useIsOffline from './useIsOffline';
import LoaderContext from '../context/LoaderContext';
import _ from 'lodash';

export default function useEvents() {
    const { isOffline } = useIsOffline();
    const { setLoader, loader } = useContext(LoaderContext);

    const getEvents = async (parameters = {}, dateParameters = {}) => {
        try {
            setLoader(true);
            const dateNow = DateTime.now();
            const _params_date = {
                start: dateNow.set({ day: 1 }).toISO(),
                end: dateNow.set({ day: dateNow.daysInMonth }).toISO(),
                ...dateParameters,
            };

            const _params = { ...parameters };
            const params = {};

            if (_params.mentoring_group) {
                params.mentoring_group = _params.mentoring_group;
                if (_params.mentoring_group_school)
                    params.school = _params.mentoring_group_school.id;
                if (_params.mentoring_group_mentor)
                    params.mentor = _params.mentoring_group_mentor.id;
                if (_params.mentoring_group_beneficiary)
                    params.beneficiary = _params.mentoring_group_beneficiary.id;
            }
            if (_params.mentoring_individual) {
                params.mentoring_individual = _params.mentoring_individual;
                if (_params.mentoring_individual_school)
                    params.school = _params.mentoring_individual_school.id;
                if (_params.mentoring_individual_mentor)
                    params.mentor = _params.mentoring_individual_mentor.id;
                if (_params.mentoring_individual_beneficiary)
                    params.beneficiary =
                        _params.mentoring_individual_beneficiary.id;
            }
            if (_params.personal_event) {
                params.personal_event = _params.personal_event;
                if (_params.personal_event_mentor) {
                    params.mentor = _params.personal_event_mentor.id;
                }
            }
            if (_params.sena) {
                params.sena = _params.sena;
                if (_params.sena_school) params.school = _params.sena_school.id;
                if (_params.sena_grade) params.grade = _params.sena_grade;
            }

            const _data = await api.get('event', {
                ...params,
                ..._params_date,
            });
            isOffline(_data);
            if (_data && _data.results) {
                const loadEvents = _data.results.map((item) => ({
                    ...item,
                    start: DateTime.fromISO(item.start).toJSDate(),
                    end: DateTime.fromISO(item.end).toJSDate(),
                }));
                return loadEvents;
            }
            return [];
        } catch (e) {
            let msj = 'No se pudo obtener los eventos';
            if (e && e.detail) msj = e.detail;
            else if (_.isArray(e)) msj = e;
            NotificationManager.error(msj, 'ERROR', 6000);
        } finally {
            setLoader(false);
        }
    };

    return { getEvents, loader };
}
