import React, { useState, useMemo, useEffect } from 'react';
import Table from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';

export default function DetailTable({ data, getReport }) {
    const [page, setPage] = useState(1);
    const formatTextEmpty = (cell, row) => {
        if (!cell) return '---';
        return (
            <React.Fragment>
                <div
                    data-for={row.id + '-name_cell-'}
                    data-tip={`
                    ${cell}
                `}
                >
                    {cell}
                </div>
            </React.Fragment>
        );
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const columns = useMemo(
        () => [
            { name: 'Si', dataField: 'yes', width: '7rem' },
            { name: 'Media sesión', dataField: 'half_session', width: '7rem' },
            { name: 'Doble sesión', dataField: 'doble_session', width: '7rem' },
            { name: 'No da razón', dataField: 'no_reason', width: '7rem' },
            { name: 'Asincronía', dataField: 'asynchronous', width: '7rem' },
            {
                name: 'Calamidad doméstica',
                dataField: 'domestic_calamity',
                width: '7rem',
            },
            { name: 'Castigo', dataField: 'punishment', width: '7rem' },
            {
                name: 'Cita medica',
                dataField: 'medical_appointment',
                width: '7rem',
            },
            {
                name: 'Condición climática',
                dataField: 'weather_condition',
                width: '7rem',
            },
            {
                name: 'Dificultad de conexión',
                dataField: 'connection_difficulty',
                width: '7rem',
            },
            {
                name: 'Dificultad de movilidad',
                dataField: 'mobility_difficulty',
                width: '7rem',
            },
            {
                name: 'Encargo familiar',
                dataField: 'family_commission',
                width: '7rem',
            },
            { name: 'Enfermedad', dataField: 'disease', width: '7rem' },
            { name: 'Estudio', dataField: 'study', width: '7rem' },
            { name: 'Excusa del joven', dataField: 'excuse', width: '7rem' },
            {
                name: 'No le gusta el acompañamiento a niños',
                dataField: 'not_like_children',
                width: '7rem',
            },
            { name: 'Trabajo', dataField: 'worked', width: '7rem' },
        ],
        []
    );
    return (
        <div id="report-table">
            {data.results.map((item) => (
                <ReactTooltip
                    className={'detail-tooltip'}
                    id={item.id + '-name_cell-'}
                    key={item.id + '-tooltip-'}
                    resizeHide={true}
                    multiline={true}
                    insecure={true}
                    effect="float"
                    place="bottom"
                    type="dark"
                />
            ))}
            <Table
                data={data}
                id={'report-table'}
                page={page}
                onPageChange={(page) => {
                    getReport(page);
                    setPage(page);
                }}
            >
                <TableHeaderColumn isKey dataField="id" width="0px">
                    Id
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="session_name"
                    width="20rem"
                    dataFormat={formatTextEmpty}
                >
                    Sesión
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="start"
                    width="10rem"
                    dataFormat={formatTextEmpty}
                >
                    Fecha de Sesión
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="training_group"
                    width="15rem"
                    dataFormat={formatTextEmpty}
                >
                    Grupo de Beneficiarios
                </TableHeaderColumn>
                {columns.map((column) => (
                    <TableHeaderColumn
                        dataField={column.dataField}
                        width={column.width}
                        dataAlign="right"
                    >
                        <span className="mx-1" title={column.name}>
                            {column.name}
                        </span>
                    </TableHeaderColumn>
                ))}
            </Table>
        </div>
    );
}
