import TableBeneficiary from './TableBeneficiary';
import TableCalendar from './TableCalendar';
import TableSchool from './TableSchool';
import TableBeneficiaryGroup from './TableBeneficiaryGroup';
import TableInventory from './TableInventory';
import TableTrainingGroup from './TableTrainingGroup';
import TableTrainingPlan from './TableTrainingPlan';
import TableSession from './TableSession';
import TableOperationalUser from './TableOperationalUser';

export {
    TableBeneficiary,
    TableCalendar,
    TableSchool,
    TableBeneficiaryGroup,
    TableInventory,
    TableTrainingGroup,
    TableTrainingPlan,
    TableSession,
    TableOperationalUser,
};
