import { useEffect, useState, useContext } from 'react';

import { api } from 'api';
import { INITIAL_DATA } from '../../utility/constants';
import { NotificationManager } from 'react-notifications';
import LoaderContext from '../context/LoaderContext';
import OfflineContext from '../context/OfflineContext';
import _, { isEmpty, isFinite } from 'lodash';
import useIsOffline from './useIsOffline';

export const useList = (name_api, initial_params = {}) => {
    const [data, setData] = useState(INITIAL_DATA);
    const [page, setPage] = useState(1);
    const { setLoader } = useContext(LoaderContext);
    const { offline } = useContext(OfflineContext);
    const { isOffline } = useIsOffline();

    const getData = async (page = 1, params = {}) => {
        setLoader(true);

        const _params = {};
        for (let [key, value] of Object.entries({
            ...initial_params,
            ...params,
        })) {
            if (!isEmpty(value) || isFinite(value)) {
                _params[key] = value;
            }
        }

        _params.page = page;
        try {
            const _data = await api.get(name_api, _params);
            setData(_data);
            setPage(page);
            isOffline(_data);
        } catch (e) {
            let msj = 'No se pudo obtener los registros';
            if (e && e.detail) msj = e.detail;
            else if (_.isArray(e)) msj = e;
            NotificationManager.error(msj, 'ERROR', 6000);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (!offline) getData(1, initial_params);
    }, [offline]);

    return { data, page, getData, offline };
};

export default useList;
