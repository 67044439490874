import React from 'react';
import RadioButton from '../../../Utils/RadioButton';

const Body = ({ dataModal, radio, setRadio, onCloseModal, onClick }) => {
    const products_found = dataModal.results.map(
        (found) =>
            `${found.description} / ${
                found.sub_location || 'sin sub-ubicación'
            }`
    );
    return (
        <React.Fragment>
            <RadioButton
                options={products_found}
                value={radio}
                setValue={setRadio}
                className="flex-column"
                alignRow
            />
            <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                <button
                    type="button"
                    className="btn btn-secondary mt-4 mt-md-0"
                    onClick={onCloseModal}
                >
                    Cancelar
                </button>

                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={onClick}
                >
                    Continuar
                </button>
            </div>
        </React.Fragment>
    );
};

export default Body;
