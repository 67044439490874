import React, { useRef, useEffect, useState } from 'react';
import AddImageIcon from '../../../../../../assets/img/add-image.png';
import './upload.css';

export default function Upload(props) {
    const [srcFile, setSrcFile] = useState(undefined);
    const input = useRef();
    const disabled = props.disabled || false;

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setSrcFile(reader.result);
        };
        if (typeof props.file === 'string') {
            setSrcFile(props.file);
        } else if (props.file instanceof File) {
            reader.readAsDataURL(props.file);
        } else if (props.file === null || props.file === undefined) {
            setSrcFile(undefined);
        }
    }, [props.file]);

    const onFileChange = (e, file) => {
        file = file || e.target.files[0];
        const fileType = '.jpg|.jpeg|.png';
        if (file && !!file.type.match(fileType)) {
            props.onChange(file);
        }
    };
    const onClick = () => {
        if (!disabled) input.current.click();
    };

    const onDrop = (e) => {
        e.preventDefault();
        if (!disabled) onFileChange(e, e.dataTransfer.files[0]);
    };
    const onDragOver = (e) => {
        e.preventDefault();
    };

    const onRemove = () => {
        if (input.current) input.current.value = '';
        if (props.onRemove) props.onRemove(null);
    };

    return (
        <React.Fragment>
            {srcFile ? (
                <React.Fragment>
                    <div className="upload-container-ads">
                        <div
                            id="uploaded-image-container-ads"
                            className="uploaded-image-container-ads"
                        >
                            {!disabled && (
                                <button
                                    className="is-button-remove-ads"
                                    type="button"
                                    onClick={onRemove}
                                    disabled={disabled}
                                >
                                    Remover
                                </button>
                            )}
                        </div>
                        <img
                            src={srcFile}
                            className="uploaded-file-image-ads"
                        />
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div
                        key="label"
                        className="upload-container-ads"
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onClick={onClick}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            style={{ display: 'none' }}
                            ref={input}
                        ></input>
                        <div className=" is-flex is-justify-content-center-ads">
                            <img
                                width="75"
                                src={AddImageIcon}
                                disabled={disabled}
                            />
                        </div>
                        {props.uploadText && (
                            <React.Fragment>
                                <p className="upload-text-ads p-2 m-0">
                                    {props.uploadText || ''}
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
