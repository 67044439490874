import React from 'react';
import Select from 'react-select';
import _ from 'lodash';

const customStylesReactSelect = {
    option: (provided, state) => {
        return {
            ...provided,
            color: state.isDisabled ? '#999D9D' : '#484848',
            padding: '0.5rem',
            backgroundColor: state.isFocused ? '#FBD0CB' : '#fff',
            ':active': {
                backgroundColor: '#ef5543',
                color: '#fff',
            },
        };
    },
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#f5f6f7' : '#f1f1f1',
        color: '#484848',
        borderRadius: '1rem',
        borderColor: state.isFocused ? '#FBD0CB' : provided.borderColor,
        border: 'unset',
        boxShadow: 'unset',
    }),
};

export default function InputSelect({
    disabled,
    isClearable,
    onChange,
    isMulti,
    isSearchable,
    withAsync = false,
    options = [],
    value,
    defaultInputValue,
    placeholder,
    labelKey = 'label',
    labelKey2 = 'label2',
    valueKey = 'value',
    className = '',
    customStyles = {},
    ...props
}) {
    const _options = [];
    options.forEach((option) => {
        const _label1 = _.get(option, labelKey, '');
        const _label2 = _.get(option, labelKey2, '');
        _options.push({
            ...option,
            label: `${_label1} ${_label2}`,
            value: option[valueKey],
        });
    });
    if (value !== null && value !== undefined) {
        const keyValue = _.get(value, valueKey, undefined);
        if (withAsync && keyValue !== undefined) {
            const label1 = _.get(value, labelKey, '');
            const label2 = _.get(value, labelKey2, '');
            value = {
                value: keyValue,
                label: `${label1} ${label2}`,
            };
        } else {
            value = _.find(_options, { value });
        }
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={`react-select-container ${className || ''}`}
                styles={{ ...customStylesReactSelect, ...customStyles }}
                backspaceRemovesValue={false}
                classNamePrefix="react-select"
                isMulti={isMulti}
                isSearchable={false}
                defaultValue={defaultInputValue || null}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    if (withAsync) {
                        onChange(e);
                    } else {
                        onChange(e ? e[valueKey] : null);
                    }
                }}
                value={value}
                isDisabled={disabled}
                {...props}
            />
        </React.Fragment>
    );
}
