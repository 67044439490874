import React, { useContext, useState } from 'react';

import { NotificationManager } from 'react-notifications';

import { useLocation } from 'react-router';
import SchoolKitForm from './SchoolKitForm';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import useUpdate from '../../../../../hooks/useUpdate';
import LoaderContext from '../../../../../context/LoaderContext';
import useCreate from '../../../../../hooks/useCreate';

const CreateUpdate = ({ path_back }) => {
    const location = useLocation();
    const isViewed = location.pathname.includes('view');
    const isUpdate = location.pathname.includes('update');

    const { data, update, updateData } = useUpdate('school_kit', path_back);
    const { saveData: saveSchoolKit } = useCreate('school_kit', path_back);

    const { loader } = useContext(LoaderContext);

    const [kit, setKit] = useState([]);

    const makeRandomId = () => {
        let a = new Date();
        let b = a.toISOString();
        let id = b.replace(/[-T:.Z]/g, '');
        return id;
    };

    const saveData = () => {
        // Formatting the data to a format acceptable to the api
        if (kit.length < 1) {
            NotificationManager.error(
                'Agrega al menos 1 Artículo',
                'ERROR',
                6000
            );
        } else {
            const name = kit[0].name;
            const details = kit.map((product) => {
                if (isUpdate) {
                    return {
                        id: product.id,
                        product: product.product.id,
                        quantity: product.quantity,
                    };
                }
                return {
                    product: product.product.id,
                    quantity: product.quantity,
                };
            });
            // Acceptable data format for the api
            const formatData = {
                name,
                details,
            };
            if (!update) {
                saveSchoolKit(formatData);
            } else {
                updateData(formatData);
            }
        }
    };

    const onSubmit = (dataForm) => {
        const body = { ...dataForm };
        //saveMultipleData();
        // set data
        body.description = body.product.description;
        body.code = body.product.code;
        body.category = body.product.category;
        body.id = makeRandomId();
        setKit([...kit, body]);
    };

    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="py-5">
                    <h1 className="title-2 cl-orange">
                        {/*title*/} Kit escolar
                    </h1>
                    <SchoolKitForm
                        entryData={data}
                        onSubmit={onSubmit}
                        update={update}
                        path_back={path_back}
                        kit={kit}
                        isViewed={isViewed}
                        isUpdate={isUpdate}
                        setKit={setKit}
                        onClick={saveData}
                    />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default CreateUpdate;
