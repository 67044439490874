import { useRef, useEffect } from "react"

/**
 * @param {any} value
 * @returns {boolean}
 */
const useHasChanged= (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
}

/**
 * @param {any} value
 * @returns {any} previous value
 */
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}


export { useHasChanged, usePrevious }
