import React, { useEffect, useState, useContext } from 'react';
import Table from '../../../../../Utils/Grid';
import { standardActions } from '../../../../../Utils/Grid/StandardActions';
import AsyncSelect from 'react-select/async';
import useAsyncOptions from '../../../../../../hooks/useAsyncOptions';
import { customStylesReactSelect } from '../../../../../Utils/renderField/renderField';
import KitExpandTable from './KitExpandTable';
import TitleUnderline from '../../../../../Utils/TitleUnderline';
import TitleSubTitle from '../../../Utils/TitleSubTitle';
import dayjs from 'dayjs';
import UserContext from '../../../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    SCHOOL_TRAINER,
} from '../../../../../../../utility/constants';

const data = {
    count: 3,
    next: null,
    previous: null,
    results: [],
};

const DetailTable = ({ entries, isViewed, entryData, setEntries }) => {
    const { user } = useContext(UserContext);
    const [entryViewed, setEntryViewed] = useState({
        supplier_name: '',
        date_entry: '',
        destination: '',
    });
    // Allow row expand
    const [openExpand, setOpenExpand] = useState(false);

    if (isViewed) {
        useEffect(() => {
            if (entryData.supplier) {
                const date_entry = dayjs(entryData.date).format('YYYY-MM-DD');
                setEntryViewed({
                    supplier_name: entryData.supplier.name,
                    date_entry,
                    destination: entryData.destination.name,
                });
            }
        }, [entryData]);
    }
    // set entries to results
    data.results = entries;

    // Get input changes
    const changeData = (dataObj, e) => {
        const changedFormValues = {
            ...dataObj,
            [e.target.name]: e.target.value,
        };
        return changedFormValues;
    };

    // Set input changes to entries
    const handleChange = (id, e) => {
        const changedEntry = entries.map((entry) => {
            return entry.id === id ? changeData(entry, e) : entry;
        });
        setEntries(changedEntry);
    };
    // Get operational users
    const { asyncOptions: getOperationalUsers } = useAsyncOptions('user', {
        operational: 1,
    });

    // Format to price field
    function format(cell, row) {
        const money = new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 2,
        }).format(cell);
        return money;
    }

    // Allow row expand
    const options = {
        onRowClick: (row, i) => {
            // if the row product was clicked
            if (i === 1) {
                // Expand row
                setOpenExpand(true);
            } else {
                setOpenExpand(false);
            }
        },
    };
    const formatText = (cell) => {
        if (cell === '' || !cell) {
            return '---';
        }
        return cell;
    };
    return (
        <React.Fragment>
            {isViewed && (
                <div className="d-flex flex-md-row flex-column flex-wrap mb-3">
                    <TitleSubTitle
                        title="Ubicación"
                        subTitle={entryViewed.destination}
                    />
                    <TitleSubTitle
                        title="Proveedor"
                        subTitle={entryViewed.supplier_name}
                    />
                    <TitleSubTitle
                        title="Fecha de entrada"
                        subTitle={entryViewed.date_entry}
                    />
                </div>
            )}
            <TitleUnderline title="Detalle de Ingreso" className="mt-4" />
            <Table
                className="mt-2"
                data={data}
                expandableRow={(row) => {
                    // if it is a school kit, show expand row
                    if (row.product.school_kit && openExpand) return true;
                    return false;
                }}
                expandComponent={(row) => {
                    // formatted data for table and display table
                    const school_kit = row.product.school_kit;
                    const products = school_kit.map((kit) => {
                        const { quantity, product } = kit;
                        const { code, description, category } = product;
                        const total_quantity = quantity * row.quantity;
                        return {
                            code,
                            description,
                            category,
                            quantity,
                            total_quantity,
                        };
                    });

                    // Return the table of products, with the formatted data
                    return <KitExpandTable products={products} />;
                }}
                options={options}
                // onPageChange={(page) => getData(page)}
                // page={page}
                // loading={loader}
            >
                {!isViewed && (
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width="5rem"
                        dataFormat={standardActions({
                            erase: (id) => {
                                const changedEntries = entries.filter(
                                    (entry) => entry.id !== id
                                );
                                setEntries(changedEntries);
                            },
                        })}
                    ></TableHeaderColumn>
                )}
                <TableHeaderColumn
                    className="mt-5"
                    width="12rem"
                    isKey={isViewed}
                    dataField="description"
                >
                    Artículo
                </TableHeaderColumn>
                <TableHeaderColumn width="7rem" dataField="quantity">
                    Cantidad
                </TableHeaderColumn>
                {![SCHOOL_COORDINATOR, SCHOOL_TRAINER].includes(user.type) && (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="price"
                        dataFormat={format}
                    >
                        Precio unitario
                    </TableHeaderColumn>
                )}
                {!isViewed && (
                    <TableHeaderColumn width="10rem" dataField="supplier_name">
                        Proveedor
                    </TableHeaderColumn>
                )}
                <TableHeaderColumn
                    width="10rem"
                    dataField="sub_location"
                    dataFormat={(value, data) => {
                        const { id } = data;
                        if (!isViewed) {
                            return (
                                <input
                                    className="form-control w-100"
                                    placeholder="Ingrese sub locación"
                                    name="sub_location"
                                    onChange={(e) => handleChange(id, e)}
                                    value={value}
                                    onClick={() => setOpenExpand(false)}
                                    disabled={isViewed}
                                />
                            );
                        }
                        return value ? value : '---';
                    }}
                >
                    Sub Ubicación
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="serie"
                    dataFormat={(value, data) => {
                        const { id } = data;
                        if (!isViewed) {
                            return (
                                <input
                                    className="form-control w-100"
                                    placeholder="Ingrese Serie"
                                    name="serie"
                                    onChange={(e) => handleChange(id, e)}
                                    value={value}
                                    onClick={() => setOpenExpand(false)}
                                    disabled={isViewed}
                                />
                            );
                        }
                        return value ? value : '---';
                    }}
                >
                    Serie
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="observations"
                    dataFormat={(value, data) => {
                        const { id } = data;
                        if (!isViewed) {
                            return (
                                <input
                                    className="form-control w-100"
                                    placeholder="Ingrese Observacion"
                                    name="observations"
                                    onChange={(e) => handleChange(id, e)}
                                    value={value}
                                    onClick={() => setOpenExpand(false)}
                                    disabled={isViewed}
                                />
                            );
                        }
                        return value ? value : '---';
                    }}
                >
                    Observación
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="assigned_to"
                    dataFormat={(value, data) => {
                        const { id } = data;
                        if (!isViewed) {
                            return (
                                <AsyncSelect
                                    styles={customStylesReactSelect}
                                    isSearchable={true}
                                    menuPortalTarget={document.querySelector(
                                        '#portable'
                                    )}
                                    defaultOptions
                                    loadOptions={getOperationalUsers}
                                    placeholder="Usuario"
                                    onChange={(e) => {
                                        const target = {
                                            target: {
                                                name: 'assigned_to',
                                                value: e,
                                            },
                                        };
                                        e = { ...e, ...target };
                                        handleChange(id, e);
                                    }}
                                    value={value}
                                    valueKey="id"
                                    labelKey="first_name"
                                    labelKey2="last_name"
                                    getOptionValue={(option) => option['id']}
                                    getOptionLabel={(option) =>
                                        `${option['first_name']} ${
                                            option['last_name'] || ''
                                        }`
                                    }
                                    onClick={() => setOpenExpand(false)}
                                    disabled={isViewed}
                                />
                            );
                        }
                        return value ? value : '---';
                    }}
                >
                    Asignar a
                </TableHeaderColumn>
                {isViewed && (
                    <TableHeaderColumn
                        width="7rem"
                        dataField="school_kit_name"
                        dataFormat={formatText}
                    >
                        Kit escolar
                    </TableHeaderColumn>
                )}
                {/* <div id="portal" style={{ position: 'absolute', top: 0 }}></div> */}
            </Table>
        </React.Fragment>
    );
};

export default DetailTable;
