import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../../Utils/Grid';
import { standardActions } from '../../../../Utils/Grid/StandardActions';
import { useList } from '../../../../../hooks/useList';
import LoaderContext from '../../../../../context/LoaderContext';
import TitleUnderline from '../../../../Utils/TitleUnderline';
import _ from 'lodash';

import Search from '../../../../Utils/Search';
import Select from 'react-select';
import { customStylesReactSelect } from '../../../../Utils/renderField/renderField';
import { TYPE_SUPPLIER_OPTIONS } from '../../../../../../utility/constants';

const LocationList = () => {
    const [type, setType] = useState(null);
    const [filters, setFilter] = useState({
        search: '',
        type: '',
    });
    const { loader } = useContext(LoaderContext);
    const { data, getData, page } = useList('supplier', {
        ordering: '-id',
        search: filters.search,
        type: filters.type,
    });
    const format = (cell) => {
        try {
            return cell.name ? cell.name : cell;
        } catch (error) {
            return '---';
        }
    };
    // If the select changed, send the filter to the api
    const onChangeCategory = (e) => {
        if (e !== null) {
            setFilter({ ...filters, type: e.value }); //so that the filters remain fixed, until they are removed
            getData(1, { type: e.value }).then(); // load the data again with filters applied
            setType(e);
        } else {
            setFilter({ ...filters, type: '' }); //so that the filters remain fixed, until they are removed
            getData(1, { type: '' }).then(); // load the data again with filters applied
            setType(e);
        }
    };
    return (
        <React.Fragment>
            <h1 className="title-text pt-5">Proveedores</h1>
            <div className="d-flex flex-column flex-md-row">
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Búsqueda</label>
                    <Search
                        onSearch={(value) => {
                            getData(1, { search: value }).then();
                            setFilter({ ...filters, search: value }); //so that the filters remain fixed, until they are removed
                        }}
                        placeholder="Buscar por Nombre o Apellido"
                    />
                </div>
                <div className="form-group d-flex flex-column flex-md-row has-feedback m-2 flex-1">
                    <div className="form-group has-feedback m-2 flex-1"></div>
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Tipo</label>
                        <Select
                            styles={customStylesReactSelect}
                            backspaceRemovesValue={false}
                            classNamePrefix="react-select"
                            isSearchable={true}
                            isClearable={true}
                            options={TYPE_SUPPLIER_OPTIONS}
                            placeholder="Filtrar por tipo"
                            onChange={onChangeCategory}
                            value={type}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between mt-5">
                <TitleUnderline title="Listado de proveedores" />
                <Link
                    to="/inventory/supplier/create"
                    className="btn btn-primary"
                >
                    Registrar Proveedor
                </Link>
            </div>
            <Table
                className="mt-5"
                data={data}
                onPageChange={(page) => getData(page)}
                page={page}
                loading={loader}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/inventory/supplier',
                        update: '/inventory/supplier',
                        // erase: async (id) => {
                        //     await deleteData(id);
                        //     getData();
                        // },
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="name"
                    dataFormat={format}
                >
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="cellphone"
                    dataFormat={format}
                >
                    Teléfono
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="email"
                    dataFormat={format}
                >
                    Correo
                </TableHeaderColumn>
                {['', undefined, null].includes(filters.type) && (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="type"
                        dataFormat={format}
                    >
                        Tipo
                    </TableHeaderColumn>
                )}
            </Table>
        </React.Fragment>
    );
};

export default LocationList;
