import React, { useContext, useState } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { Link } from 'react-router-dom';
import Table from '../../../Utils/Grid';
import Search from '../../../Utils/Search';
import useList from '../../../../hooks/useList';
import useDelete from '../../../../hooks/useDelete';
import LoaderContext from '../../../../context/LoaderContext';
import UserContext from '../../../../context/UserContext';
import {
    SCHOOL_COORDINATOR,
    INVITED,
    ASSISTANT,
    TRAINER,
    SCHOOL_TRAINER,
    SUPER_ADMIN,
    COORDINATOR
} from '../../../../../utility/constants'
import InputSelect from '../../../Utils/InputSelect';
import {
    PROGRAM_OPTIONS,
    LEVEL_TRAINING_PLAN
} from '../../../../../utility/constants';

const Sessions = (props) => {
    const { data, page, getData } = useList('session');
    const { deleteData } = useDelete('session');
    const [search, setSearch] = useState(null);
    const { loader } = useContext(LoaderContext);
    const { user }= useContext(UserContext);
    const [filterActive, setFilterActive] = useState({
        level: null,
        type_program: null,
    });

    return (
        <React.Fragment>
            <div className="pt-5" />
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <h1 className="title-text">Sesiones</h1>
                { (user.type === SUPER_ADMIN || user.type === COORDINATOR) &&(
                <Link to="/session/create" className="btn btn-primary">
                    Registrar Sesión
                </Link>
                )}
            </div>
            <div className="d-flex flex-column-reverse flex-sm-row mt-3">
                <Search
                    onSearch={(value) => {
                        getData(1, {
                            search: value,
                            level: filterActive.level, 
                            type_program: filterActive.type_program
                        }).then();
                        setSearch(value);
                    }}
                    placeholder="Buscar por código y titulo ..."
                />

                <div className="d-flex flex-1 justify-content-sm-end">
                    <InputSelect
                        options={PROGRAM_OPTIONS}
                        value={filterActive}
                        isClearable={true}
                        className="filter-select mt-3 mt-md-0"
                        placeholder="Filtrar por programa"
                        onChange={(value) => {
                            getData(1, {
                                ...filterActive,
                                type_program: value,
                            });
                            setFilterActive((prev) => ({...prev, type_program: value}))
                        }}
                    />
                </div>

                <div className="">
                    <InputSelect
                        options={LEVEL_TRAINING_PLAN}
                        value={filterActive}
                        isClearable={true}
                        className="filter-select mt-3 mt-md-0"
                        placeholder="Filtrar por Nivel"
                        onChange={(value) => {
                            getData(1, {
                                ...filterActive,
                                level: value,
                            });
                            setFilterActive((prev) => ({...prev, level: value}))
                        }}
                    />
                </div>

                
            </div>

            <Table
                className="mt-5"
                data={data}
                loading={loader}
                onPageChange={(page) => getData(page, {
                    search: search,
                    level: filterActive.level, 
                    type_program: filterActive.type_program  })}
                page={page}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/session',
                        update_session_list: '/session',
                        erase_session: (id) => deleteData(id, getData),
                        type:user.type,
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn dataField="module" dataFormat={(value, row)=>{return row.module.module}} width="10rem">
                    Título del módulo
                </TableHeaderColumn>
                <TableHeaderColumn dataField="code" width="10rem">
                    Código
                </TableHeaderColumn>
                <TableHeaderColumn dataField="title" width="10rem">
                    Titulo
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="type_program"
                    width="10rem"
                    dataFormat={(value) => {
                        const type = _.find(PROGRAM_OPTIONS, {
                            value: value,
                        });
                        if (type) return type.label;
                        else return '-----';
                    }}
                >
                    Tipo de Programa
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="level"
                    width="10rem"
                    dataFormat={(value) => {
                        const type = _.find(LEVEL_TRAINING_PLAN, {
                            value: value,
                        });
                        if (type) return type.label;
                        else return '-----';
                    }}
                >
                    Nivel
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default Sessions;
