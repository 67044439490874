import React from 'react';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import {
    renderField,
    renderSelectField,
    renderFieldRadio,
} from '../../../Utils/renderField/renderField';
import { required } from '../../../../../utility/validation';
import {
    LEVEL_TRAINING_PLAN,
    CULMINATION,
    GRADUATION,
    CLOSING,
} from '../../../../../utility/constants';

export default function TrainingPlanForm({
    submitRef,
    onSubmit,
    initialValues = {},
}) {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{ last_session: CULMINATION, ...initialValues }}
        >
            {({ handleSubmit, form, valid, values, errors }) => {
                return (
                    <form
                        name="TrainingPlanForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="code">
                                    Nombre (Nivel/Grado)
                                </label>
                                <Field
                                    name="name"
                                    component={renderField}
                                    type="text"
                                    validate={required}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="code">Nivel</label>
                                <Field
                                    name="level"
                                    component={renderSelectField}
                                    options={LEVEL_TRAINING_PLAN}
                                    isClearable={true}
                                    isSearchable={true}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-3">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label className="mb-2" htmlFor="code">
                                    ¿Que sera la ultima sesión?
                                </label>
                                <Field
                                    name="last_session"
                                    component={renderFieldRadio}
                                    type="radio"
                                    parse={(val) => +val}
                                    label="Culminación"
                                    value={CULMINATION}
                                />
                                <Field
                                    name="last_session"
                                    component={renderFieldRadio}
                                    type="radio"
                                    parse={(val) => +val}
                                    label="Graduación (Gradúa solo los prácticos)"
                                    value={GRADUATION}
                                    className="show-error"
                                    validate={required}
                                />
                                <Field
                                    name="last_session"
                                    component={renderFieldRadio}
                                    type="radio"
                                    parse={(val) => +val}
                                    label="Cierre (Gradúa solo los teóricos/nivelación)"
                                    value={CLOSING}
                                    className="show-error"
                                    validate={required}
                                />
                            </div>
                        </div>
                        <button
                            ref={submitRef}
                            type="submit"
                            className="d-none"
                        ></button>
                    </form>
                );
            }}
        </Form>
    );
}
