import React, { useState } from 'react';
import Table from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import useDayjsUtils from '../../../../hooks/useDayjsUtils';
import { textWithTooltip } from '../../../Utils/renderField/renderReadField';
import useList from '../../../../hooks/useList';
import { paginate } from '../../../../../utility/utils';
import { Actions } from '../../../Utils/Grid/StandardActions';
import useUpdate from '../../../../hooks/useUpdate';
import useAccount from '../../../../hooks/useAccount';
const localizedFormat = require('dayjs/plugin/localizedFormat');
import dayjs from 'dayjs';

export default function HistorySessionTable({
    id,
    sessions,
    view,
    updateSession,
}) {
    const { toTimeTz, toDateTz } = useDayjsUtils();
    const { user } = useAccount();
    const [page, setPage] = useState(1);

    // if user is admin or coordinator
    const havePermission = user.type === 1 || user.type === 2;

    const changeIsSaved = (code) => {
        updateSession(code, { is_save: false });
    };

    const _sessions = sessions.filter((session) => {
        if (session['is_save'] === true) return true;
        return false;
    }).reverse();
    dayjs.extend(localizedFormat);
    function trClassFormat(row) {
        if (row.status_session == "CANCELADA") return 'tr-not-active';
        if (_.get(row, 'alert_session', false)) return 'tr-alert-assistance';
    }
    return (
        <React.Fragment>
            <Table
                page={page}
                onPageChange={setPage}
                trClassName={trClassFormat}
                data={{
                    results: paginate(_sessions, page),
                    count: _sessions.length,
                }}

            >
                {havePermission && !view && (
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        width="10rem"
                        disabled={view}
                        dataFormat={(cell, row) => {
                            return (
                                <Actions
                                    id={row}
                                    eraseHistorySession={(id) => {
                                        return changeIsSaved(id);
                                    }}
                                />
                            );
                        }}
                    >
                        Herramientas
                    </TableHeaderColumn>
                )}
                <TableHeaderColumn isKey dataField="code" width="10rem">
                    Código
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="title"
                    width="15rem"
                    dataFormat={textWithTooltip}
                >
                    Titulo
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataSort
                    dataField="start"
                    width="10rem"
                    dataFormat={(value) => toDateTz(value)}
                >
                    Fecha
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="start"
                    width="10rem"
                    dataFormat={(value) => toTimeTz(value)}
                >
                    Hora inicio
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="end"
                    width="10rem"
                    dataFormat={(value) => toTimeTz(value)}
                >
                    Hora fin
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
}
