import React, { useMemo } from 'react';

import { renderFieldCheck } from '../../../../Utils/renderField/renderField';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';

const CheckboxFilters = ({ filterFields, btnReset, onSubmit }) => {
    const initialFields = useMemo(() => {
        const _values = {};
        for (let field of filterFields) {
            _values[field.name] = false;
        }
        return _values;
    }, [filterFields]);

    const onSubmitMiddleware = (values) => {
        const _values = { ...values };
        delete _values.check;
        return onSubmit(_values);
    };

    return (
        <React.Fragment>
            <div className="form-group mt-5" style={{ marginBottom: '-20px' }}>
                <label>Seleccione los campos para mostrar en el reporte</label>
            </div>
            <hr />
            <Form
                initialValues={initialFields}
                onSubmit={onSubmitMiddleware}
                render={({ handleSubmit, submitting, form, values }) => {
                    const onChangeAllCheck = (check, values) => {
                        const _values = { ...values };
                        for (let field of filterFields) {
                            _values[field.name] = check;
                        }
                        form.reset(_values);
                    };

                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="form-validate mb-lg"
                        >
                            {filterFields.length > 0 && (
                                <div className="d-flex justify-content-end form-group my-4">
                                    <Field
                                        name="check"
                                        component={renderFieldCheck}
                                        parse={(value) => {
                                            onChangeAllCheck(value, values);
                                            return value;
                                        }}
                                    />
                                    <label
                                        htmlFor="checkbox"
                                        title="Seleccionar todos"
                                    >
                                        Seleccionar todos
                                    </label>
                                </div>
                            )}
                            <div className="d-flex flex-row flex-wrap justify-content-start pl-3">
                                {filterFields.map((field, i) => (
                                    <div
                                        className="form-group has-feedback d-flex mr-5"
                                        style={{
                                            minWidth: '130px',
                                            maxWidth: '130px',
                                        }}
                                        key={i}
                                    >
                                        <Field
                                            name={field.name}
                                            component={renderFieldCheck}
                                        />
                                        <label
                                            htmlFor="checkbox"
                                            title={field.value}
                                            style={{
                                                color: '#5F5F5F',
                                                marginRight: '10px',
                                                maxWidth: '100px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {field.value}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex flex-column-reverse flex-md-row justify-content-end my-5">
                                <button
                                    type="button"
                                    ref={btnReset}
                                    onClick={() => {
                                        form.reset();
                                    }}
                                    className="d-none"
                                />
                                {filterFields.length > 0 && (
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={submitting}
                                        style={{
                                            marginTop: '-20px',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        Filtrar
                                    </button>
                                )}
                            </div>
                        </form>
                    );
                }}
            />
        </React.Fragment>
    );
};

export default CheckboxFilters;
