import React from 'react';

import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { required } from '../../../../../../utility/validation';
import {
    renderAsyncSelectField,
    renderField,
} from '../../../../Utils/renderField/renderField';
import useAsyncOptions from '../../../../../hooks/useAsyncOptions';

const LocationForm = ({ locationData, onSubmit, view, update }) => {
    const { asyncOptions: getSchool } = useAsyncOptions('school');
    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                initialValues={locationData}
                render={({ handleSubmit, submitting }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form-validate mb-lg"
                    >
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="name">
                                    Nombre de la ubicación
                                </label>
                                <Field
                                    name="name"
                                    component={renderField}
                                    placeholder="Ingrese nombre de la ubicación"
                                    type="text"
                                    className="form-control w-100"
                                    validate={required}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="school">
                                    Colegio (Opcional)
                                </label>
                                <Field
                                    name="school"
                                    component={renderAsyncSelectField}
                                    loadOptions={getSchool}
                                    valueKey="id"
                                    labelKey="name"
                                    labelKey2="code"
                                    type="text"
                                    className="w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/inventory/location"
                                className="btn btn-secondary mt-4 mt-md-0"
                            >
                                Regresar
                            </Link>
                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={submitting}
                                >
                                    {update ? 'Actualizar' : 'Guardar'}
                                </button>
                            )}
                        </div>
                    </form>
                )}
            />
        </React.Fragment>
    );
};

export default LocationForm;
