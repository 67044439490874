import React from 'react';

import PlusLogo from '../../../../../../../assets/img/plus.png';
import './Button.css';

const ButtonAdd = ({ onClick }) => {
    return (
        <img
            src={PlusLogo}
            alt="plus logo"
            className="btn-icon-add"
            onClick={onClick}
        />
    );
};

export default ButtonAdd;
