import React from 'react';
import './TitleIcon.css';

const TitleIcon = ({ title, image }) => {
    return (
        <div className="d-flex pt-5 mb-5 align-items-end">
            <h1 className="title-2 cl-dark-blue title-icon pb-1 mr-5">
                {title}
            </h1>
            <img
                src={image}
                alt={`logo ${title}`}
                className={title === 'Traslados' ? 'pb-2' : ''}
                width="120"
            />
        </div>
    );
};

export default TitleIcon;
