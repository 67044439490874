import React from 'react';
import { Field, Form } from 'react-final-form';
import { renderField } from '../../Utils/renderField';
import { required } from '../../../../utility/validation';

const ForgotPasswordFrom = (props) => {
    const { onSubmit } = props;
    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit, submitting, pristine }) => (
                <form
                    name="ForgotPasswordFrom"
                    className="form-validate mb-lg m-4"
                    onSubmit={handleSubmit}
                >
                    <div className="form-group has-feedback">
                        <Field
                            name="username"
                            label="Usuario"
                            placeholder="No. Identificación"
                            component={renderField}
                            type="text"
                            validate={required}
                            className="form-control"
                        />
                    </div>

                    <div className="buttons-box mt-4">
                        <button
                            type="submit"
                            className="btn btn-primary align-self-center w-100"
                            disabled={submitting || pristine}
                        >
                            Enviar
                        </button>
                    </div>
                </form>
            )}
        </Form>
    );
};

export default ForgotPasswordFrom;
