import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CreateUpdate from './CreateUpdate';
import List from './List';

export default function School() {
    return (
        <Routes>
            <Route path="" element={<List />} />
            <Route path="create" element={<CreateUpdate />} />
            <Route path=":id/update" element={<CreateUpdate />} />
            <Route path=":id/view" element={<CreateUpdate />} />
        </Routes>
    );
}
