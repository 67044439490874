import React, { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import LoaderContext from '../../../../context/LoaderContext';
import ModuleForm from './ModuleForm';
import useCreate from '../../../../hooks/useCreate';
import useUpdate from '../../../../hooks/useUpdate';

const CreateUpdateModule = (props) => {
    const location = useLocation();
    const { loader } = useContext(LoaderContext);
    const { saveData } = useCreate('module', '/module');
    const { data, updateData, update } = useUpdate('module', '/module');

    const onSubmit = (dataForm) => {
        const body = {
            module: dataForm.module,
            ext: dataForm.ext,
            description: dataForm.description
        };
        if (!update) saveData(body);
        else updateData(body);
    };

    const isViewed = location.pathname.includes('view');
    const isUpdated = location.pathname.includes('update');
    const isCreated = location.pathname.includes('create');

    return (
        <React.Fragment>
            <LoadMask loading={loader} blur>
                <div className="pt-5">
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <h1 className="title-2 cl-orange">
                            {`${
                                isUpdated
                                    ? 'Actualizar'
                                    : isCreated
                                    ? 'Registrar'
                                    : ''
                            }`}{' '}
                            Módulo
                        </h1>
                    </div>
                    <ModuleForm
                        onSubmit={onSubmit}
                        initialValues={data}
                        view={isViewed}
                        created={isCreated}
                        updated={isUpdated}
                        goBackButton={() => (
                            <Link
                                to="/module"
                                className="btn btn-secondary align-self-center mt-4 m-md-0"
                            >
                                Regresar
                            </Link>
                        )}
                    />
                    <br />
                    <br />
                </div>
            </LoadMask>
        </React.Fragment>
    );
};

export default CreateUpdateModule;
