import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
    renderField,
    renderDatePicker,
    renderSelectField,
    renderTextArea,
    renderAsyncSelectField,
} from '../../Utils/renderField/renderField';
import {
    composeValidators,
    required,
    maxLength,
    minLength,
    date,
    isOlder,
    email,
    password,
} from '../../../../utility/validation';
import {
    GENDER_OPTIONS,
    COUNTRY_OPTIONS,
    STUDY_LEVEL_OPTIONS,
    SCHOOL_WORK_POSITIONS_OPTIONS,
    FUNCTION_IWOKA_OPTIONS,
} from '../../../../utility/constants';
import TermsAndConditions from '../../Utils/TermsAndConditions';
import TitleUnderline from '../../Utils/TitleUnderline';
import useAsyncOptions from '../../../hooks/useAsyncOptions';

const validates = (values) => {
    const errors = {};
    if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Contraseña no coincide';
    }
    return errors;
};

const DEFAULT_VALUES = {
    country: 'Colombia',
    edu_flag: true,
};

export default function SchoolStaffForm(props) {
    const { onSubmit, initialValues, view } = props;
    const [agree, setAgree] = useState(false);
    const { asyncOptions } = useAsyncOptions('school');

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{ ...DEFAULT_VALUES, ...initialValues }}
            validate={validates}
        >
            {({ handleSubmit, submitting }) => {
                return (
                    <form
                        name="schoolStaffForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        <TitleUnderline
                            title="Datos personales"
                            blue
                            calculationByCharacters={true}
                        />
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="identifiacion">
                                    No. Identificación
                                </label>
                                <Field
                                    name="username"
                                    label="identification"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="first_name">Nombre</label>
                                <Field
                                    name="first_name"
                                    label="Nombre"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="last_name">Apellido</label>
                                <Field
                                    name="last_name"
                                    label="Apellido"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="birthday">
                                    Fecha nacimiento
                                </label>
                                <Field
                                    name="birthday"
                                    label="Fecha nacimiento"
                                    component={renderDatePicker}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        date,
                                        isOlder
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="gender">Sexo</label>
                                <Field
                                    name="gender"
                                    component={renderSelectField}
                                    options={GENDER_OPTIONS}
                                    label="Hombre"
                                    validate={required}
                                    disabled={view}
                                />
                            </div>
                            <div className="d-none d-md-flex m-2 mx-lg-4 flex-1" />
                        </div>
                        <br />
                        <TitleUnderline
                            title="Contacto"
                            blue
                            calculationByCharacters={true}
                        />
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="email">
                                    Correo Institucional
                                </label>
                                <Field
                                    name="email"
                                    label="Correo Institucional"
                                    component={renderField}
                                    type="email"
                                    validate={composeValidators(
                                        required,
                                        email
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2  mx-lg-4 flex-1">
                                <label htmlFor="indicative">
                                    Indicativo de celular
                                </label>
                                <Field
                                    name="indicative"
                                    label="Indicativo de celular"
                                    component={renderField}
                                    type="number"
                                    validate={maxLength(5)}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="telephone">Celular</label>
                                <Field
                                    name="telephone"
                                    label="Celular"
                                    component={renderField}
                                    type="number"
                                    validate={composeValidators(
                                        required,
                                        maxLength(15)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="social_network">
                                    Facebook (Nombre de usuario)
                                </label>
                                <Field
                                    name="facebook"
                                    label="Link Facebook"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(200)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="social_network">
                                    Instagram (Nombre de usuario)
                                </label>
                                <Field
                                    name="instagram"
                                    label="Link Instagram"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(200)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="telephone">
                                    Twitter (Nombre de usuario)
                                </label>
                                <Field
                                    name="twitter"
                                    label="Link Twitter"
                                    component={renderField}
                                    type="number"
                                    validate={maxLength(200)}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <br />
                        <TitleUnderline
                            title="Dirección de residencia"
                            blue
                            calculationByCharacters={true}
                        />
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="country">País</label>
                                <Field
                                    name="country"
                                    label="País"
                                    component={renderSelectField}
                                    options={COUNTRY_OPTIONS}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(50)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="department">Departamento</label>
                                <Field
                                    name="department"
                                    label="Departamento"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(75)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="city">Municipio</label>
                                <Field
                                    name="city"
                                    label="Municipio"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(100)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-2">
                                <label htmlFor="address">Dirección</label>
                                <Field
                                    name="address"
                                    label="Dirección"
                                    component={renderTextArea}
                                    type="text"
                                    validate={required}
                                    rows={2}
                                    className="form-control form-control-max-width w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="d-none d-md-flex m-2 mx-lg-4 flex-1" />
                        </div>
                        <br />
                        <TitleUnderline
                            title="Información académica y profesional"
                            blue
                            calculationByCharacters={true}
                        />
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="level_studies">
                                    Nivel de estudios
                                </label>
                                <Field
                                    name="level_studies"
                                    label="Nivel de estudios"
                                    component={renderSelectField}
                                    options={STUDY_LEVEL_OPTIONS}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(50)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="profession">Profesión</label>
                                <Field
                                    name="profession"
                                    label="Profesión"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="work_position">
                                    Posición de trabajo
                                </label>
                                <Field
                                    name="work_position"
                                    label="Posición de trabajo"
                                    component={renderSelectField}
                                    options={SCHOOL_WORK_POSITIONS_OPTIONS}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="work_position">
                                    Función equipo Iwoka
                                </label>
                                <Field
                                    name="rol_in_iwoka"
                                    label="Función equipo Iwoka"
                                    component={renderSelectField}
                                    options={FUNCTION_IWOKA_OPTIONS}
                                    type="text"
                                    validate={required}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="d-none d-md-flex m-2 mx-lg-4 flex-1" />
                            <div className="d-none d-md-flex m-2 mx-lg-4 flex-1" />
                        </div>
                        <br />
                        <TitleUnderline
                            title="Datos del sistema"
                            blue
                            calculationByCharacters={true}
                        />
                        <div className="d-flex flex-column flex-md-row">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="password">Colegio</label>
                                <Field
                                    name="school"
                                    component={renderAsyncSelectField}
                                    loadOptions={asyncOptions}
                                    validate={required}
                                    valueKey="id"
                                    labelKey="name"
                                    isSearchable
                                    placeholder="Buscar colegio"
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="password">Contraseña</label>
                                <Field
                                    name="password"
                                    label="Contraseña"
                                    component={renderField}
                                    type="password"
                                    validate={composeValidators(
                                        required,
                                        minLength(8),
                                        password
                                    )}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-lg-4 flex-1">
                                <label htmlFor="confirmPassword">
                                    Confirmar Contraseña
                                </label>
                                <Field
                                    name="confirmPassword"
                                    label="Confirmar Contraseña"
                                    component={renderField}
                                    type="password"
                                    validate={required}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <br />
                        <TermsAndConditions
                            onChange={(value) => setAgree(value)}
                        />
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between mt-4 mb-5">
                            <Link
                                to="/login"
                                className="btn btn-secondary align-self-center mt-4 m-md-0"
                            >
                                Regresar
                            </Link>
                            <button
                                type="submit"
                                className="btn btn-primary align-self-center"
                                disabled={!agree || submitting}
                            >
                                Registrarte
                            </button>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
}
