import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CreateUpdate from './CreateUpdate';
import List from './List';
import NotFound from '../../layout/NotFound/NotFound';

export default function OperationalUsers() {
    return (
        <Routes>
            <Route path="" element={<List />} />
            <Route path="create" element={<CreateUpdate />} />
            <Route path=":id/update" element={<CreateUpdate />} />
            <Route path=":id/view" element={<CreateUpdate />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
