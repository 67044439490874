import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { api } from 'api';
import localees from 'dayjs/locale/es';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
const localizedFormat = require('dayjs/plugin/localizedFormat');
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import OfflineContext from '../../../../context/OfflineContext';
import AsyncSelectInput from '../../../Utils/AsyncSelectInput';
import InputSelect from '../../../Utils/InputSelect';
import RemoveSvgIcon from '../SvgIcons/RemoveSvgIcon';
import { paginate } from '../../../../../utility/utils';
import CulminationSvgIcon from '../SvgIcons/CulminationSvgIcon';
import GraduationSvgIcon from '../SvgIcons/GraduationSvgIcon';
import AsyncSelectField from '../../../Utils/AsyncSelectInput';
import AsyncSelectFieldAssistance from '../../../Utils/AsyncSelectInputAssistence';
import './Assistance.css';
import Table from '../../../Utils/Grid';
import {
    CULMINATION,
    GRADUATION,
    CLOSING,
    YOUNG_BENEFICIARY,
    CONNECTION_TYPE,
    KID_BENEFICIARY,
    GROUP_MENTORING,
    INDIVIDUAL_MENTORING,
    TYPE_ASSISTANCE_OPTIONS,
    ACTIVE,
    PROGRAM_OPTIONS
} from '../../../../../utility/constants';
import ReactTooltip from 'react-tooltip';
import DisabledBeneficiaryForm from './Beneficiary/ModalDisabled';
import DisabledBeneficiaryPracticeForm from './Beneficiary/ModalDisabledPractice'
import DisabledBeneficiaryLevelingForm from './Beneficiary/ModalDisabledLeveling';
import _ from 'lodash';

dayjs.extend(localizedFormat);

function trClassFormat(row) {
    if (row.status !== ACTIVE  && row.beneficiary__status !== ACTIVE
        && row.status_practice !== ACTIVE && row.beneficiary__status_practice !== ACTIVE
        && row.status_leveling !== ACTIVE && row.beneficiary__status_leveling !== ACTIVE
    ) return 'tr-not-active';
    if (_.get(row, 'alert_assistance', false)) return 'tr-alert-assistance';
}

function trClassFormatTeoretical(row) {
    if (row.status !== ACTIVE ) return 'tr-not-active';
    if (_.get(row, 'alert_assistance_teoretical', false)) return 'tr-alert-assistance';
}

function trClassFormatPractica(row) {
    if (row.status_practice !== ACTIVE ) return 'tr-not-active';
    if (_.get(row, 'alert_assistance_practice', false)) return 'tr-alert-assistance';
}

function trClassFormatLeveling(row) {
    if (row.status_leveling !== ACTIVE ) return 'tr-not-active';
    if (_.get(row, 'alert_assistance_leveling', false)) return 'tr-alert-assistance';
}

const customStylesReactSelect = {
    option: (provided, state) => {
        return {
            ...provided,
            color: '#484848',
            padding: '0.5rem',
            backgroundColor: state.isFocused ? '#FBD0CB' : '#fff',
            ':active': {
                backgroundColor: '#ef5543',
                color: '#fff',
            },
        };
    },
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#f5f6f7' : '#f1f1f1',
        color: '#484848',
        borderRadius: '1rem',
        borderColor: state.isFocused ? '#FBD0CB' : provided.borderColor,
        border: 'unset',
        boxShadow: 'unset',
    }),
};

const formatTextEmpty = (cell, row) => {
    if (!cell) return '---';
    return (
        <React.Fragment>
            <div
                data-for={row.id + '-name_cell-'}
                data-tip={`
                ${cell}
            `}
            >
                {cell}
            </div>
        </React.Fragment>
    );
};

const AssistanceForm = (props) => {
    const {
        typeConnection,
        setTypeConnection,
        validateConnection,
        data,
        addOption,
        changeGodfather,
        updateAssistance,
        beneficiaries,
        training_group,
        start,
        selectedSessionOptions,
        setSelectedSessionOptions,
    } = props;

    const [dataModal, setDataModal] = useState({});
    const [dataModalPractice, setDataModalPractice] = useState({});
    const [dataModalLeveling, setDataModalLeveling] = useState({});
    const { offline } = useContext(OfflineContext);
    const [page, setPage] = useState(1);

    const { asyncOptions: asyncGodfathers } = useAsyncOptions('beneficiary/goodfather', {
        type: YOUNG_BENEFICIARY,
        school: _.get(props, 'data.school', undefined),
        status:ACTIVE,
    });

    const { asyncOptions: asyncGodfather } = useAsyncOptions('beneficiary', {
        type: YOUNG_BENEFICIARY,
        school: _.get(props, 'data.school', undefined),
    });

    const [loading, setLoading] = useState(false);

    const filterSession = (inputValue, sessionOptions) => {
        return sessionOptions.filter((i) => {
                        return i.title.toLowerCase().includes(inputValue.toLowerCase());
        });
    };

    const selectedOption = PROGRAM_OPTIONS.find(option => option.value === data.type_program);

    /**
     * @param {string} inputValue
     * @returns {Promise} Promise
     * @example promiseOptions(' ') => Promise {<pending>} // return all sessions available
     */
    const promiseOptions = (inputValue) => {
        const trainingGroupId = training_group.id || training_group;
        if (trainingGroupId) {
            setLoading(true);
            let fecha = start.split('T')[0];
            let id_event = data.id;
            return api
                .get(
                    `/training_group/${trainingGroupId}/events_of_the_day?date=${fecha}&event_actually=${id_event}`
                )
                .then((res) => {
                    setLoading(false);
                    return filterSession(inputValue, res.data);
                });
        }
    };

    const formatTrClass = (data) => {

        if (data.type_program === 'practico') {
            return trClassFormatPractica;
        } else if (data.type_program === 'nivelacion') {
            return trClassFormatLeveling;
        } else if (data.type_program === 'teorico') {
            return trClassFormatTeoretical;
        }else{
            return trClassFormat;
        }
    };

    /// The code from the line 81 to 90 is logical for when it is offline
    const [godfathers, setGodFathers] = useState([]);
    useEffect(() => {
        if (offline === true) {
            asyncGodfather(undefined).then((options) => {
                // Filtramos solo los padrinos que pertenecen al colegion del evento
                const filteredGodfathers = options.filter(godfather => godfather.school === data.school);
                setGodFathers(filteredGodfathers);
            });
        }
    }, [offline, data.school]);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const showGodFathers =
        data.type === GROUP_MENTORING && data.type_group === KID_BENEFICIARY;

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-md-row mt-2">
                {data.type == GROUP_MENTORING && (
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Titulo del módulo</label>
                        <div>{data.module || 'Sin titulo'}</div>
                    </div>
                )}
                {training_group && (
                    <div
                        className="form-group has-feedback m-2 flex-2"
                        style={{ display: props.data.is_save ? 'none' : '' }}
                    >
                        <label>Agregar sesión a la asistencia</label>
                        <AsyncSelectField
                            loadOptions={promiseOptions}
                            labelKey="title"
                            valueKey="id"
                            placeholder={
                                loading
                                    ? 'Cargando sesiones'
                                    : 'Buscar por titulo del modulo, código o sesión'
                            }
                            isSearchable={true}
                            isMulti={true}
                            isOptionDisabled={() =>
                                selectedSessionOptions.length >= 20
                            }
                            onChange={setSelectedSessionOptions}
                            value={selectedSessionOptions}
                            noOptionsMessage={() =>
                                'No hay sesiones disponibles'
                            }
                        />
                    </div>
                )}
                {training_group &&
                    props.data.is_save &&
                    selectedSessionOptions.length > 0 && (
                        <div className="form-group has-feedback m-2 flex-2">
                            <label>Sesiones de la asistencia</label>
                            <ul>
                                {selectedSessionOptions.map((e, index) => (
                                    <li type="circle">{e.title}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                {training_group &&
                    props.data.is_save &&
                    selectedSessionOptions.length == 0 &&
                    props.data.event_head_title && (
                        <div className="form-group has-feedback m-2 flex-2">
                            <label>
                                Sesion en la que se tomo la asistencia:
                            </label>
                            <br />
                            <span
                                onDoubleClick={() => {
                                    window.open(
                                        `/#/calendar/${props.data.event_head}/assistance/`,
                                        '_blank'
                                    );
                                }}
                            >
                                <span className="continerSpan">
                                    <span style={{ fontSize: '1.5rem' }}>
                                        →
                                    </span>{' '}
                                    {props.data.event_head_title}{' '}
                                </span>
                            </span>
                        </div>
                    )}
                {data.type == INDIVIDUAL_MENTORING && (
                    <div className="form-group has-feedback m-2 flex-1">
                        <label>Mentor Individual</label>
                        <div>{data.individual_mentor_text || 'Sin Mentor'}</div>
                    </div>
                )}
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Estado de sesión</label>
                    <InputSelect
                        options={CONNECTION_TYPE}
                        placeholder="Seleccione conexión"
                        value={typeConnection}
                        onChange={setTypeConnection}
                    />
                    {validateConnection && !typeConnection && (
                        <div className="d-flex invalid-feedback">
                            *Campo obligatorio
                        </div>
                    )}
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row mt-2">
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Titulo de la Sesión</label>
                    <div>{data.title}</div>
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Tipo de Programa</label>
                    <div>{selectedOption ? selectedOption.label : data.type_program}</div>
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Fecha de la Sesión</label>
                    <div>{dayjs(data.start).format('DD/MM/YYYY')}</div>
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Hora Inicio de la Sesión</label>
                    <div>{dayjs(data.start).format('h:mm A')}</div>
                </div>
                <div className="form-group has-feedback m-2 flex-1">
                    <label>Hora Fin de la Sesión</label>
                    <div>{dayjs(data.end).format('h:mm A')}</div>
                </div>
            </div>
            <div className="pt-3" />
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <h1 className="title-2 cl-orange">
                            Fecha de Asistencia
                        </h1>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        {dayjs(data.start).locale(localees).format('ll')}
                    </div>
                </div>
                <div className="d-flex">
                    {data.type_session === GRADUATION && (
                        <GraduationSvgIcon
                            width={150}
                            height={150}
                            color="#e3e3e3"
                        />
                    )}
                    {data.type_session === CULMINATION && (
                        <CulminationSvgIcon
                            width={150}
                            height={150}
                            color="#e3e3e3"
                        />
                    )}
                    {data.type_session === CLOSING && (
                        <CulminationSvgIcon
                            width={150}
                            height={150}
                            color="#e3e3e3"
                        />
                    )}
                </div>
            </div>
            {beneficiaries.map((item) => (
                <ReactTooltip
                    className={'detail-tooltip'}
                    id={item.id + '-name_cell-'}
                    key={item.id + '-tooltip-'}
                    resizeHide={true}
                    multiline={true}
                    insecure={true}
                    effect="float"
                    place="top"
                    type="dark"
                />
            ))}
            <Table
                className="mt-5"
                data={{
                    results: paginate(beneficiaries, page),
                    count: beneficiaries.length,
                }}
                page={page}
                onPageChange={setPage}
                trClassName={formatTrClass(data)}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    dataFormat={(value, row) => {
                        return (
                            <React.Fragment>
                                <RemoveSvgIcon
                                    width="1.6rem"
                                    height="1.6rem"
                                    onClick={() => {
                                        if (data.type_program == "practico") {
                                            setDataModalPractice(row);
                                        }
                                        else if (data.type_program == "nivelacion") {
                                            setDataModalLeveling(row);
                                        }
                                        else {
                                            setDataModal(row);
                                        }

                                    }}
                                />
                            </React.Fragment>
                        );
                    }}
                    sizePerPage={10}
                    pagination={true}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="beneficiary__first_name"
                    dataFormat={formatTextEmpty}
                >
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="beneficiary__last_name"
                    dataFormat={formatTextEmpty}
                >
                    Apellido
                </TableHeaderColumn>

                {data.type_program == 'practico' ? (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="assistance"
                        dataFormat={(value, row) => {
                            if (row.status_practice !== ACTIVE) return;
                            const tooltipValue = _.get(
                                _.find(TYPE_ASSISTANCE_OPTIONS, { value: value }),
                                'label',
                                ''
                            );
                            return (
                                <div
                                    className="mr-3"
                                    data-for={row.id + '-name_cell-'}
                                    data-tip={`${tooltipValue}`}
                                >
                                    <InputSelect
                                        isClearable={false}
                                        customStyles={{
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 2000,
                                            }),
                                        }}
                                        menuPortalTarget={document.body}
                                        isSearchable={true}
                                        options={TYPE_ASSISTANCE_OPTIONS}
                                        value={value || null}
                                        onChange={(e) => {
                                            addOption(e, row.id);
                                        }}
                                    />
                                </div>
                            );
                        }}
                    >
                        Asistencia
                    </TableHeaderColumn>

                ) : data.type_program === 'nivelacion' ? (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="assistance"
                        dataFormat={(value, row) => {
                            if (row.status_leveling !== ACTIVE) return;
                            const tooltipValue = _.get(
                                _.find(TYPE_ASSISTANCE_OPTIONS, { value: value }),
                                'label',
                                ''
                            );
                            return (
                                <div
                                    className="mr-3"
                                    data-for={row.id + '-name_cell-'}
                                    data-tip={`${tooltipValue}`}
                                >
                                    <InputSelect
                                        isClearable={false}
                                        customStyles={{
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 2000,
                                            }),
                                        }}
                                        menuPortalTarget={document.body}
                                        isSearchable={true}
                                        options={TYPE_ASSISTANCE_OPTIONS}
                                        value={value || null}
                                        onChange={(e) => {
                                            addOption(e, row.id);
                                        }}
                                    />
                                </div>
                            );
                        }}
                    >
                        Asistencia
                    </TableHeaderColumn>
                ) : data.type_program === 'teorico' ? (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="assistance"
                        dataFormat={(value, row) => {
                            if (row.status !== ACTIVE) return;
                            const tooltipValue = _.get(
                                _.find(TYPE_ASSISTANCE_OPTIONS, { value: value }),
                                'label',
                                ''
                            );
                            return (
                                <div
                                    className="mr-3"
                                    data-for={row.id + '-name_cell-'}
                                    data-tip={`${tooltipValue}`}
                                >
                                    <InputSelect
                                        isClearable={false}
                                        customStyles={{
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 2000,
                                            }),
                                        }}
                                        menuPortalTarget={document.body}
                                        isSearchable={true}
                                        options={TYPE_ASSISTANCE_OPTIONS}
                                        value={value || null}
                                        onChange={(e) => {
                                            addOption(e, row.id);
                                        }}
                                    />
                                </div>
                            );
                        }}
                    >
                        Asistencia
                    </TableHeaderColumn>
                ): (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="assistance"
                        dataFormat={(value, row) => {
                            //if (row.status !== ACTIVE) return;
                            const tooltipValue = _.get(
                                _.find(TYPE_ASSISTANCE_OPTIONS, { value: value }),
                                'label',
                                ''
                            );
                            return (
                                <div
                                    className="mr-3"
                                    data-for={row.id + '-name_cell-'}
                                    data-tip={`${tooltipValue}`}
                                >
                                    <InputSelect
                                        isClearable={false}
                                        customStyles={{
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 2000,
                                            }),
                                        }}
                                        menuPortalTarget={document.body}
                                        isSearchable={true}
                                        options={TYPE_ASSISTANCE_OPTIONS}
                                        value={value || null}
                                        onChange={(e) => {
                                            addOption(e, row.id);
                                        }}
                                    />
                                </div>
                            );
                        }}
                    >
                        Asistencia
                    </TableHeaderColumn>
                )}

                <TableHeaderColumn
                    width="10rem"
                    dataField="beneficiary__cellphone"
                    dataFormat={formatTextEmpty}
                >
                    Celular
                </TableHeaderColumn>

                {data.type_program == 'practico' ?(
                    <TableHeaderColumn
                        width="10rem"
                        dataField="absence_practice"
                        dataFormat={formatTextEmpty}
                    >
                        Asistencia
                    </TableHeaderColumn>

                 ): data.type_program === 'nivelacion' ? (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="absence_leveling"
                        dataFormat={formatTextEmpty}
                    >
                        Asistencia
                    </TableHeaderColumn>
                 ): data.type_program === 'teorico' ? (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="absence_teoretical"
                        dataFormat={formatTextEmpty}
                    >
                        Asistencia
                    </TableHeaderColumn>
                 ):(
                    <TableHeaderColumn
                        width="10rem"
                        dataField="absence"
                        dataFormat={formatTextEmpty}
                    >
                        Asistencia
                    </TableHeaderColumn>
                )}

                {showGodFathers && (
                    <TableHeaderColumn
                        width="10rem"
                        dataField="godfather"
                        dataFormat={(value, row) => {
                            let tooltipValue = _.get(value, 'first_name', '');
                            tooltipValue = ' ' + _.get(value, 'last_name', '');
                            return (
                                <div
                                    data-for={row.id + '-name_cell-'}
                                    data-tip={`${tooltipValue}`}
                                >
                                    {offline ? (
                                        <InputSelect
                                            customStyles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 2000,
                                                }),
                                            }}
                                            isSearchable={true}
                                            withAsync={true}
                                            menuPortalTarget={document.body}
                                            placeholder="Padrinos"
                                            onChange={(e) => {
                                                changeGodfather(e, row.id);
                                            }}
                                            options={godfathers}
                                            value={value || null}
                                            labelKey="first_name"
                                            labelKey2="last_name"
                                            valueKey="id"
                                        />
                                    ) : (
                                        <AsyncSelectFieldAssistance
                                            customStyles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 2000,
                                                }),
                                            }}
                                            isSearchable={true}
                                            menuPortalTarget={document.body}
                                            loadOptions={asyncGodfathers}
                                            placeholder="Padrinos"
                                            onChange={(e) => {
                                                changeGodfather(e, row.id);
                                            }}
                                            value={value || null}
                                            labelKey="first_name"
                                            labelKey2="last_name"
                                            valueKey="id"
                                            goodfather_relation={row.godfather_relation}                                           
                                        />
                                    )}
                                </div>
                            );
                        }}
                    >
                        Padrino
                    </TableHeaderColumn>
                )}
            </Table>
            <div>
                <p className="font-weight-bolder m-0">Nota: </p>
                <p>
                    Los beneficiarios en color naranja son los que no han
                    asistido a las ultimas dos sesiones.
                </p>
            </div>
            <br />

            {dataModal && dataModal.id && (
                <Modal open={open} onClose={() => setDataModal({})} center>
                    <DisabledBeneficiaryForm
                        onCloseModal={() => setDataModal({})}
                        onSubmit={(dataForm) => {
                            updateAssistance(dataForm);
                            setDataModal({});
                        }}
                        initialValues={dataModal}
                    />
                </Modal>
            )}

            {dataModalPractice && dataModalPractice.id && (
                <Modal open={open} onClose={() => setDataModalPractice({})} center>
                    <DisabledBeneficiaryPracticeForm
                        onCloseModal={() => setDataModalPractice({})}
                        onSubmit={(dataForm) => {
                            updateAssistance(dataForm);
                            setDataModalPractice({});
                        }}
                        initialValues={dataModalPractice}
                    />
                </Modal>
            )}

            {dataModalLeveling && dataModalLeveling.id && (
                <Modal open={open} onClose={() => setDataModalLeveling({})} center>
                    <DisabledBeneficiaryLevelingForm
                        onCloseModal={() => setDataModalLeveling({})}
                        onSubmit={(dataForm) => {
                            updateAssistance(dataForm);
                            setDataModalLeveling({});
                        }}
                        initialValues={dataModalLeveling}
                    />
                </Modal>
            )}

        </React.Fragment>
    );
};

export default AssistanceForm;
