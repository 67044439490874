import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from '../LoadMask/LoadMask';

import './grid.css';

export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
        static_table: false,
    };

    render() {
        const {
            static_table,
            loading,
            data,
            page,
            remote = true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            ...other
        } = this.props;
        const options = {
            sizePerPage: 10,
            hideSizePerPage: true,
            paginationSize: 5,
            alwaysShowAllBtns: true,
            noDataText: loading ? 'Cargando...' : 'No hay datos',
            page,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand: onExpand,
        };

        const paginar = !(pagination === false);

        return (
            <div id={this.props.id || 'table'}>
                <LoadMask loading={loading} blur>
                    {!static_table && (
                        <BootstrapTable
                            expandableRow={expandableRow}
                            expandComponent={expandComponent}
                            trClassName={trClassName}
                            tableHeaderClass="table-header"
                            tableBodyClass="table-body"
                            containerClass="table-container"
                            tableContainerClass="table-container-container"
                            headerContainerClass="table-header-container"
                            bodyContainerClass="table-body-container"
                            cellEdit={cellEditProp}
                            data={loading ? [] : data.results}
                            afterSaveCell={afterSave}
                            remote={remote}
                            pagination={paginar}
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            {...other}
                        />
                    )}
                    {static_table && (
                        <BootstrapTable
                            expandableRow={expandableRow}
                            expandComponent={expandComponent}
                            trClassName={trClassName}
                            tableHeaderClass="table-header"
                            tableBodyClass="table-body"
                            containerClass="table-container"
                            tableContainerClass="table-container-container"
                            headerContainerClass="table-header-container"
                            bodyContainerClass="table-body-container"
                            cellEdit={cellEditProp}
                            data={loading ? [] : data.results}
                            afterSaveCell={afterSave}
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                            pagination
                            {...other}
                        />
                    )}
                </LoadMask>
            </div>
        );
    }
}
