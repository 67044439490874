import React, { useContext, useState } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import dayjs from 'dayjs';
import Table from '../../../Utils/Grid';
import AsyncSelectField from '../../../Utils/AsyncSelectInput';
import Modal from 'react-responsive-modal';
import ModalReuseHolidays from '../ModalHoliday/ModalReuseHolidays';
import UserContext from '../../../../context/UserContext';

const HolidayTable = (props) => {
    const filterYear = window.filterYear;
    const { user }= useContext(UserContext);

    const {
        data,
        page,
        getData,
        deleteData,
        loader,
        onChangeYear,
        asyncOptions,
        year,
        setLoadTable,
        reload,
        setReload,
    } = props;

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <React.Fragment>
            <div className="d-flex flex-column flex-md-row mt-1">
                <div className="form-group has-feedback m-2 mr-lg-4 flex-1">
                    <label htmlFor="date_filter"></label>
                        <button
                            className="btn btn-secondary align-self-center mt-1"
                            onClick={onOpenModal}
                        >
                            Reutilizar dias festivos
                        </button>
                </div>
                {open && (
                     <Modal open={open} onClose={onCloseModal} center>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between" style={{width: 600}}>
                            <h1 className="title-2 cl-orange">
                                Reutilizar dias festivos
                            </h1>
                        </div>
                        <hr />
                        <ModalReuseHolidays
                            setOpen={setOpen}
                            asyncOptions={asyncOptions}
                            setLoadTable={setLoadTable}
                            setReload={setReload}
                            reload={reload}
                        />
                   </Modal>
                )}

                <div className="form-group has-feedback m-2 mr-lg-4 flex-1"></div>
                <div className="form-group has-feedback m-2 mr-lg-4 flex-1">
                    {reload ? (
                        <div>
                            <label htmlFor="date_filter">Filtrar por año</label>
                            <AsyncSelectField
                                loadOptions={asyncOptions}
                                valueKey="id"
                                labelKey="year"
                                value={year}
                                isClearable={false}
                                isSearchable={false}
                                className="mr-md-3"
                                disabled={filterYear && filterYear.id ? true : false}
                                placeholder="Seleccione Año..."
                                onChange={(e) => {
                                    onChangeYear(e);
                                }}
                            />
                        </div>
                    ):(
                        <React.Fragment>
                            <label htmlFor="date_filter">Filtrar por año</label>
                            <AsyncSelectField
                                loadOptions={asyncOptions}
                                valueKey="id"
                                labelKey="year"
                                value={year}
                                isClearable={false}
                                isSearchable={false}
                                className="mr-md-3"
                                disabled={filterYear && filterYear.id ? true : false}
                                placeholder="Seleccione Año..."
                                onChange={(e) => {
                                    onChangeYear(e);
                                }}
                            />
                        </React.Fragment>
                    )}
                </div>
            </div>

            <Table
                className="mt-5"
                data={data}
                loading={loader}
                onPageChange={(page) => getData(page)}
                page={page}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        update_holiday: '/calendar',
                        erase_holiday: (id) => deleteData(id, getData),
                        type:user.type,
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="date"
                    width="10rem"
                    dataFormat={(value, data) => {
                        return dayjs(value).format('DD/MM/YYYY');
                    }}
                >
                    Fecha
                </TableHeaderColumn>
                <TableHeaderColumn dataField="holiday" width="10rem">
                    Dia festivo
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default HolidayTable;
