import React from 'react';
import Table from '../../../../../Utils/Grid';

const data = {
    count: 3,
    next: null,
    previous: null,
    results: [],
};

const KitExpandTable = ({ products }) => {
    data.results = products;
    return (
        <Table className="mt-2" data={data} pagination={false}>
            <TableHeaderColumn
                className="mt-5"
                width="12rem"
                dataField="description"
            >
                Artículo
            </TableHeaderColumn>
            <TableHeaderColumn isKey width="10rem" dataField="code">
                Código
            </TableHeaderColumn>
            <TableHeaderColumn width="10rem" dataField="category">
                Categoría
            </TableHeaderColumn>
            <TableHeaderColumn width="7rem" dataField="quantity">
                Cantidad Kit
            </TableHeaderColumn>
            <TableHeaderColumn width="7rem" dataField="total_quantity">
                Cantidad Total
            </TableHeaderColumn>
        </Table>
    );
};

export default KitExpandTable;
