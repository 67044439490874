import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAccount from './common/hooks/useAccount';

// maquetas base
import SiderBar from './common/components/layout/Sidebar/SideBar';
import Navbar from './common/components/layout/Navbar/Navbar';

export default function PrivateRouteBase(props) {
    const [toggleOpen, setToggleOpen] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [showNavbar, setShowNavbar] = useState(true);
    const { user, getMe } = useAccount();
    const location = useLocation();
    const navigate = useNavigate();
    const url = window.location.href;

    const isAuthenticated = () => {
        if (user && !user.username) {
            getMe();
            return 'Verifying';
        } else {
            if (user.has_temp_pwd && !url.includes('change-password')) {
                navigate(`/change-password/${user.username}`);
            }
            return true;
        }
    };

    useEffect(() => {
        if (url.includes('change-password')) {
            setShowNavbar(false);
            setShowSidebar(false);
        } else {
            setShowNavbar(true);
            setShowSidebar(true);
        }
    }, [location.pathname]);

    const isAuth = isAuthenticated();

    return (
        <React.Fragment>
            {isAuth === true && (
                <div>
                    {showSidebar ? (
                        <React.Fragment>
                            <SiderBar
                                toggleOpen={toggleOpen}
                                navToggle={() => setToggleOpen(!toggleOpen)}
                                closedToggle={() => setToggleOpen(false)}
                            />
                            <main
                                className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2"
                                style={{ zIndex: 10 }}
                            >
                                <div className="main-navbar bg-light-gray sticky-top">
                                    <div className="p-0 container">
                                        {showNavbar && (
                                            <Navbar
                                                navToggle={() =>
                                                    setToggleOpen(!toggleOpen)
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="main-content-container bg-white px-4 container-fluid">
                                    {props.children}
                                </div>
                            </main>
                        </React.Fragment>
                    ) : (
                        <div className="main-content-container">
                            {props.children}
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
