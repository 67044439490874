import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import ProtectedRoute from './ProtectedRoute';
import ProtectedGroupTraining from './ProtectedGroupTraining'
import ProtectedUsers from './ProtectedUsers'
import ProtectedResume from './ProtectedResume'
import NotFound from './common/components/layout/NotFound/NotFound';

// Styles
import '../assets/fonts/fonts.css';
import 'react-responsive-modal/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
require('../style/index.css');

// App components
import Login, { Register } from './common/components/LoginRegister';
import {
    ChangePassword,
    ForgotPassword,
} from './common/components/PasswordRecovery';
import User from './common/components/App/Users';
import School from './common/components/App/School';
import Session from './common/components/App/Session';
import Module from './common/components/App/Module';
import Dashboard from './common/components/App/Dashboard';
import DataPolicy from './common/components/App/DataPolicy';
import Inventory from './common/components/App/Inventory';
import Beneficiary from './common/components/App/Beneficiary';
import TrainingPlan from './common/components/App/TrainingPlan';
import Group from './common/components/App/Group';
import GroupStudent from './common/components/App/GroupStudent';
import Calendar from './common/components/App/Calendar';
import Reporting from './common/components/App/Reporting';

module.exports = (
    <div>
        <div className="container__content">
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                {/* password recovery */}
                <Route path="/password-recovery" element={<ForgotPassword />} />
                <Route
                    path="/password-recovery/:username/:token"
                    element={<ChangePassword />}
                />
                {/* change temporary password */}
                <Route
                    path="/change-password/:username"
                    element={
                        <ProtectedRoute>
                            <ChangePassword />
                        </ProtectedRoute>
                    }
                />
                {/* CRUD  Users */}
                <Route
                    path="/user/*"
                    element={
                        <ProtectedRoute>
                            <User />
                        </ProtectedRoute>
                    }
                />

                {/* CRUD School */}
                <Route
                    path="/school/*"
                    element={
                        <ProtectedRoute>
                            <School />
                        </ProtectedRoute>
                    }
                />
                {/* CRUD Session */}
                <Route
                    path="/session/*"
                    element={
                        <ProtectedRoute>
                            <Session />
                        </ProtectedRoute>
                    }
                />

                {/* CRUD Module */}
                <Route
                    path="/module/*"
                    element={
                        <ProtectedRoute>
                            <Module />
                        </ProtectedRoute>
                    }
                />

                {/* CRUD Beneficiary */}
                <Route
                    path="/beneficiary/*"
                    element={
                        <ProtectedRoute>
                            <Beneficiary />
                        </ProtectedRoute>
                    }
                />

                {/* Inventory */}
                <Route
                    path="/inventory/*"
                    element={
                        <ProtectedRoute>
                            <Inventory />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/inventory"
                    element={
                        <ProtectedRoute>
                            <Inventory />
                        </ProtectedRoute>
                    }
                />

                {/* dashboard */}
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                {/* calendar */}
                <Route
                    path="/calendar/*"
                    element={
                        <ProtectedRoute>
                            <Calendar />
                        </ProtectedRoute>
                    }
                />
                {/* group beneficiaries */}
                <Route
                    path="/group_beneficiaries/*"
                    element={
                        <ProtectedRoute>
                            <GroupStudent />
                        </ProtectedRoute>
                    }
                />
                {/* training group */}
                <Route
                    path="/training_group/*"
                    element={
                        <ProtectedRoute>
                            <Group />
                        </ProtectedRoute>
                    }
                />

                {/* training plan */}
                <Route
                    path="/training_plan/*"
                    element={
                        <ProtectedRoute>
                            <TrainingPlan />
                        </ProtectedRoute>
                    }
                />

                {/* Reporting */}
                <Route
                    path="/reporting/*"
                    element={
                        <ProtectedRoute>
                            <Reporting />
                        </ProtectedRoute>
                    }
                />
                

                {/* Data Policy */}
                <Route path="/data-policy" element={<DataPolicy />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
        <NotificationContainer />
    </div>
);
