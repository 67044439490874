import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { api } from 'api';
import useUpdate from '../../../../hooks/useUpdate';
import LoaderContext from '../../../../context/LoaderContext';
import AssistanceForm from './AssistenceForm';
import { SwalError } from '../../../Utils/SwalAlerts';
import Minute from './Minute';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import CulminationSvgIcon from '../SvgIcons/CulminationSvgIcon';
import GraduationSvgIcon from '../SvgIcons/GraduationSvgIcon';
import './Assistance.css';
import {
    CULMINATION,
    GRADUATION,
    CLOSING,
    GROUP_MENTORING,
    KID_BENEFICIARY,
} from '../../../../../utility/constants';
import { v4 as uuidv4 } from 'uuid';
import { SwalSuccess } from '../../../../components/Utils/SwalAlerts';
import { useNavigatingAway } from '../../../../hooks/useNavigatingAway';
import { areObjectsEqual } from '../../../Utils/Funcs';
import { DialogBeforeUnLoad } from '../../../Utils/DialogBeforeUnLoad';
import {
    DESERTED,
    ACTIVE,
    NOT_ENTER,
    DESERTED_OPTIONS,
    ASSISTANT,
    SUPER_ADMIN,
    COORDINATOR
} from '../../../../../utility/constants';
import UserContext from '../../../../context/UserContext';

const Assistance = () => {
    const { id } = useParams();
    const { setLoader, loader } = useContext(LoaderContext);
    const path_module = '/calendar';
    const { data } = useUpdate('assistance');
    const { training_group, start } = data;

    // console.log('data more_events', data.more_events);
    const [steps, setSteps] = useState(1);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [selectedSessionOptions, setSelectedSessionOptions] = useState([]);

    //TypeConnection
    const [typeConnection, setTypeConnection] = useState('PRESENCIAL');
    const [validateConnection, setValidateConnection] = useState(false);

    //Files
    const [files, setFiles] = useState([]);
    const [oldFiles, setOldFiles] = useState([]);
    const [removeFiles, setRemoveFiles] = useState([]);
    const [dataUpdateCopy, setDataUpdateCopy] = useState(data);
    const [firstValuesOnRender, setFirstValuesOnRender] = useState(null);
    const [isChanged, setIsChangedData] = useState(false);
    const [canShowDialogBeforeUnLoad, setCanShowDialogBeforeUnLoad] =
        useState(isChanged);
    const navigate = useNavigate();

    const { user }= useContext(UserContext);

    const onChangeFile = async (file) => {
        let files_data = [...files];

        let file_exist = false;
        files.map((item) => {
            if (item.name == file.name) {
                file_exist = true;
            }
        });

        oldFiles.map((item) => {
            if (item.name == file.name) {
                file_exist = true;
            }
        });

        if (file_exist) {
            let mensajeError = 'Archivo de evidencia ya existente';
            NotificationManager.error(mensajeError, 'Error', 7000);
        } else {
            files_data.push({ name: file.name, file: file });
            setFiles(files_data);
        }
    };

    const onRemoveOldFile = async (file) => {
        let files_data = [...oldFiles];
        let data_removeFiles = [...removeFiles];
        data_removeFiles.push(file.uuid);

        const index = files_data.indexOf(file);
        if (index > -1) {
            files_data.splice(index, 1);
        }
        setRemoveFiles(data_removeFiles);
        setOldFiles(files_data);
    };

    const onRemoveFile = async (file) => {
        let files_data = [...files];

        const index = files_data.indexOf(file);
        if (index > -1) {
            files_data.splice(index, 1);
        }

        setFiles(files_data);
    };

    const guardar = async (data, files = [], params) => {
        setLoader(true);
        await api
            .postAttachments(`assistance/${id}/save`, data, files)
            .then(async (res) => {
                SwalSuccess('Registro exitoso', '').then((result) => {
                    if (result.isConfirmed) {
                        if (path_module) navigate(path_module);
                    } else {
                        if (path_module) navigate(path_module);
                    }
                });
            })
            .catch((error) => {
                let mensajeError = 'Error al cargar archivo de evidencia';
                if (error && error.detail) mensajeError = error.detail;
                if (error && error.msg && error.msg == 'No autorizado')
                    mensajeError = error.msg;
                NotificationManager.error(mensajeError, 'Error', 7000);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    useEffect(() => {
        if (data && data.beneficiary) {
            setBeneficiaries(data.beneficiary);
        }
        if (data && data.files) {
            setOldFiles(data.files);
        }
        if (data && data.status_session) {
            setTypeConnection(data.status_session);
        }
        if (data && data.is_save && data.more_events.length > 0) {
            setSelectedSessionOptions(data.more_events);
        }
        setDataUpdateCopy(data);
    }, [data]);

    const addAssistance = (status, id_beneficiary) => {
        let data_beneficiary = [...beneficiaries];

        data_beneficiary = data_beneficiary.map((beneficiary) => {
            if (beneficiary.id == id_beneficiary) {
                let beneficiary_coppy;
                beneficiary_coppy = { ...beneficiary };
                beneficiary_coppy.assistance = status;
                return beneficiary_coppy;
            }
            return beneficiary;
        });

        setBeneficiaries(data_beneficiary);
    };

    const updateAssistance = (updatedData) => {
                let data_beneficiary = [...beneficiaries];

        data_beneficiary = data_beneficiary.map((beneficiary) => {
            if (beneficiary.id == updatedData.id) {
                if (
                    updatedData.status == ACTIVE ||
                    updatedData.status == NOT_ENTER
                ) {
                    updatedData.desertion_reason = null;
                    return updatedData;
                }
                return updatedData;
            }
            return beneficiary;
        });

        setBeneficiaries(data_beneficiary);
    };

    const changeGodfather = (godfather, id_beneficiary) => {
        let data_beneficiary = [...beneficiaries];

        data_beneficiary = data_beneficiary.map((beneficiary) => {
            if (beneficiary.id == id_beneficiary) {
                beneficiary.godfather = godfather;
            }
            return beneficiary;
        });

        setBeneficiaries(data_beneficiary);
    };

    const onSubmit = async (dataForm) => {
        let data_files = [...files];
        const body = { ...dataForm };

        let dataBeneficiary = [...beneficiaries];
        dataBeneficiary = dataBeneficiary.map((item) => {
            if (body.type === GROUP_MENTORING && body.type_group === KID_BENEFICIARY) {
                // Verificar si godfather.id existe y no es null/undefined, luego asignar su valor, de lo contrario asignar null
                item.godfather = item.godfather && item.godfather.id !== null ? item.godfather.id : null;
            } else {
                // Eliminar el campo godfather
                delete item.godfather;
            }
            return item;
        });
        

        body.beneficiary = [...dataBeneficiary];
        body.id_event = data.id;

        let new_files = data_files.map((file) => {
            return { name: file.name, uuid: uuidv4() };
        });

        body.new_files = new_files;
        body.remove_files = removeFiles;
        body.status_session = typeConnection;
        body.selectedSessionOptions = selectedSessionOptions;
        setIsChangedData(false);
        setCanShowDialogBeforeUnLoad(false);
        setFirstValuesOnRender(null);
        setDataUpdateCopy(null);
        await guardar(body, data_files);
    };

    const getTitle = (type_session) => {
        if (type_session === GRADUATION) return 'Asistencia de Graduación';
        if (type_session === CULMINATION) return 'Asistencia de Culminación';
        if (type_session === CLOSING) return 'Asistencia de Cierre';
        return 'Asistencia';
    };

    const handleDataChange = (newData) => {
        if (!firstValuesOnRender) {
            if (
                newData &&
                newData.beneficiary &&
                newData.beneficiary.length > 0
            ) {
                setFirstValuesOnRender(newData);
            }
        } else {
            const isDataChanged = !areObjectsEqual(
                firstValuesOnRender,
                newData
            );
            setIsChangedData(isDataChanged);
            setCanShowDialogBeforeUnLoad(isDataChanged);
        }
    };

    const [showDialogBeforeUnLoad, confirmNavigation, cancelNavigation] =
        useNavigatingAway(canShowDialogBeforeUnLoad);

    useEffect(() => {
        return () => {
            // unmounting : reseting the state
            setFirstValuesOnRender(null);
            setIsChangedData(false);
            setCanShowDialogBeforeUnLoad(false);
            setDataUpdateCopy(null);
        };
    }, []);

    useEffect(() => {
        let data_files = [...files];
        const data_copy = { ...dataUpdateCopy };
        
        let dataBeneficiary = [...beneficiaries];
        
        dataBeneficiary = dataBeneficiary.map((item) => {
          const updatedItem = { ...item };
        
          if (
            data_copy.type === GROUP_MENTORING &&
            data_copy.type_group === KID_BENEFICIARY
          ) {
            if (updatedItem.godfather) {
              updatedItem.godfather = updatedItem.godfather.id;
            }
          } else {
            delete updatedItem.godfather;
          }
          
          return updatedItem;
        });

        delete data_copy.beneficiary;
        data_copy.beneficiary = [...dataBeneficiary];
        data_copy.id_event = data.id;

        let new_files = data_files.map((file) => {
            return { name: file.name, uuid: uuidv4() };
        });

        if (new_files && new_files.length > 0) {
            data_copy.new_files = new_files;
        } else {
            delete data_copy.new_files;
        }

        if (removeFiles && removeFiles.length > 0) {
            data_copy.remove_files = removeFiles;
        } else {
            delete data_copy.remove_files;
        }

        data_copy.status_session = typeConnection;
        data_copy.selectedSessionOptions = selectedSessionOptions;

        handleDataChange(data_copy);
        setDataUpdateCopy(data_copy);
    }, [
        beneficiaries,
        oldFiles,
        typeConnection,
        selectedSessionOptions,
        files,
        removeFiles,
    ]);

    return (
        <React.Fragment>
            <DialogBeforeUnLoad
                showDialog={showDialogBeforeUnLoad}
                setShowDialog={setCanShowDialogBeforeUnLoad}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
            <div className="d-flex align-items-center pt-5">
                {data.type_session === GRADUATION && (
                    <GraduationSvgIcon width={30} height={30} />
                )}
                {data.type_session === CULMINATION && (
                    <CulminationSvgIcon width={30} height={30} />
                )}
                {data.type_session === CLOSING && (
                    <CulminationSvgIcon width={30} height={30} />
                )}
                <h1 className="title-2 cl-orange m-2">
                    {getTitle(data.type_session)}
                </h1>
            </div>
            <br />
            <LoadMask loading={loader}>
                {steps == 1 && (
                    <AssistanceForm
                        typeConnection={typeConnection}
                        setTypeConnection={setTypeConnection}
                        validateConnection={validateConnection}
                        setValidateConnection={setValidateConnection}
                        data={data}
                        beneficiaries={beneficiaries}
                        addOption={addAssistance}
                        changeGodfather={changeGodfather}
                        updateAssistance={updateAssistance}
                        training_group={training_group}
                        setSelectedSessionOptions={setSelectedSessionOptions}
                        selectedSessionOptions={selectedSessionOptions}
                        start={start}
                    />
                )}
                {steps == 2 && (
                    <Minute
                        setSteps={setSteps}
                        onSubmit={onSubmit}
                        initialValues={data}
                        id={id}
                        files={files}
                        onChangeFile={onChangeFile}
                        onRemoveFile={onRemoveFile}
                        onRemoveOldFile={onRemoveOldFile}
                        oldFiles={oldFiles}
                    />
                )}

                {steps == 1 && (
                    <React.Fragment>
                        <hr />
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/calendar"
                                className="btn btn-secondary align-self-center mt-4 m-md-0"
                            >
                                Regresar
                            </Link>

                            { (user.type !== ASSISTANT) &&(
                            <button
                                className="btn btn-primary align-self-center"
                                onClick={() => {
                                    const dataBeneficiary = [...beneficiaries];
                                    let no_godfather = 0;
                                    let has_deserted = false;
                                    if (data.type === GROUP_MENTORING && data.type_group === KID_BENEFICIARY) {
                                        dataBeneficiary.forEach((item) => {
                                            if (item.beneficiary__type === KID_BENEFICIARY && item.godfather === null) {
                                                no_godfather += 1;
                                                if (item.status === 'desertado') {
                                                    has_deserted = true;
                                                }
                                            }
                                        });
                                    }

                                    if (!typeConnection) {
                                        setValidateConnection(true);
                                    } else if (no_godfather > 0 && !has_deserted) {
                                        SwalError(
                                            'Error',
                                            'Todos los niños deben de tener un padrino asignado.'
                                        );
                                    } else {
                                        setValidateConnection(false);
                                        setSteps(2);
                                    }
                                }}
                            >
                                Siguiente
                            </button>
                            )}

                        </div>
                        <br />
                    </React.Fragment>
                )}
            </LoadMask>
        </React.Fragment>
    );
};

export default Assistance;
