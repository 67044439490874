import React, { useContext, useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';

import {
    renderDatePicker,
    renderField,
    renderAsyncSelectField,
    renderSelectField,
} from '../../../Utils/renderField/renderField';
import {
    required,
    composeValidators,
    maxLength,
    email,
    date,
} from '../../../../../utility/validation';
import {
    GENDER_OPTIONS,
    DESERTED,
    STATUS_BENEFICIARY_OPTIONS,
    STATUS_BENEFICIARY_OPTIONS_LEVELING,
    STATUS_BENEFICIARY_PRACTICE_OPTIONS,
    COUNTRY_OPTIONS,
    ACTIVE,
    ABANDONMENT,
    LEVEL_BENEFICIARY_EDUCATIVE_OPTIONS,
} from '../../../../../utility/constants';
import useAsyncOptions from '../../../../hooks/useAsyncOptions';
import OfflineContext from '../../../../context/OfflineContext';
import { DiligenceAuthorization } from './DiligenceAuthorization';
import { Link } from 'react-router-dom';

const DEFAULT_VALUES = {
    status: ACTIVE,
    country: 'Colombia',
};

export default function EducativeForm(props) {
    const { onSubmit, view, initialValues, onGoBack, isCreated } = props;
    const { offline } = useContext(OfflineContext);
    const { asyncOptions: asyncSchool } = useAsyncOptions('school');

    /// The code from the line 81 to 90 is logical for when it is offline
    const [schools, setSchools] = useState([]);
    useEffect(() => {
        asyncSchool(undefined).then((options) => {
            setSchools(options);
        });
    }, []);

    return (
        <Form
            initialValues={{ ...DEFAULT_VALUES, ...initialValues }}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, submitting, values, form }) => {
                props.handleDataChange(values);
                return (
                    <form
                        name="educativeForm"
                        className="form-validate mb-lg"
                        onSubmit={handleSubmit}
                    >
                        <div className="d-flex flex-column flex-md-row">
                            <DiligenceAuthorization
                                values={values}
                                form={form}
                                isCreated={isCreated}
                                initialValues={initialValues}
                            />
                        </div>
                        {!isCreated && (
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="status">
                                        Estado Teórico
                                    </label>
                                    <Field
                                        name="status"
                                        component={renderSelectField}
                                        options={STATUS_BENEFICIARY_OPTIONS}
                                        isSearchable={false}
                                        validate={required}
                                        className="form-control w-100"
                                        disabled={true}
                                    />
                                </div>
                                {values.status === DESERTED ? (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                        <label htmlFor="desertion_reason">
                                            Razón de deserción
                                        </label>
                                        <Field
                                            name="desertion_reason"
                                            component={renderField}
                                            type="text"
                                            className="form-control w-100"
                                            disabled={true}
                                        />
                                        {values.last_desertion && (
                                            <span className="mt-2 ml-2 font-color-primary">
                                                {' '}
                                                El beneficiario fue desertado el{' '}
                                                {values.last_desertion}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1"></div>
                                )}
                            </div>
                        )}

                        {!isCreated && (
                            <div className="d-flex flex-column flex-md-row mt-md-4">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="status_practice">
                                        Estado práctico
                                    </label>
                                    <Field
                                        name="status_practice"
                                        component={renderSelectField}
                                        options={STATUS_BENEFICIARY_PRACTICE_OPTIONS}
                                        isSearchable={false}
                                        validate={required}
                                        className="form-control w-100"
                                        disabled={true}
                                    />
                                </div>
                                {values.status_practice === ABANDONMENT ? (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                        <label htmlFor="desertion_reason_practice">
                                            Razón de Abandono
                                        </label>
                                        <Field
                                            name="desertion_reason_practice"
                                            component={renderField}
                                            type="text"
                                            className="form-control w-100"
                                            disabled={true}
                                        />
                                        {values.last_desertion_practice && (
                                            <span className="mt-2 ml-2 font-color-primary">
                                                {' '}
                                                El beneficiario abandonó el{' '}
                                                {values.last_desertion_practice}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1"></div>
                                )}
                            </div>
                        )}

                        {!isCreated && (
                            <div className="d-flex flex-column flex-md-row mt-md-4">
                                <div className="form-group has-feedback m-2 flex-1">
                                    <label htmlFor="status_leveling">
                                        Estado Nivelación
                                    </label>
                                    <Field
                                        name="status_leveling"
                                        component={renderSelectField}
                                        options={STATUS_BENEFICIARY_OPTIONS_LEVELING}
                                        isSearchable={false}
                                        validate={required}
                                        className="form-control w-100"
                                        disabled={true}
                                    />
                                </div>
                                {values.status_leveling === DESERTED? (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                        <label htmlFor="desertion_reason_leveling">
                                            Razón de Deserción
                                        </label>
                                        <Field
                                            name="desertion_reason_leveling"
                                            component={renderField}
                                            type="text"
                                            className="form-control w-100"
                                            disabled={true}
                                        />
                                        {values.last_desertion_leveling && (
                                            <span className="mt-2 ml-2 font-color-primary">
                                                {' '}
                                                El beneficiario desertó el{' '}
                                                {values.last_desertion_leveling}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className="form-group has-feedback m-2 mx-md-5 flex-1"></div>
                                )}
                            </div>
                        )}

                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="id">No. Identificación</label>
                                <Field
                                    name="identification"
                                    label="identification"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="first_name">Nombre</label>
                                <Field
                                    name="first_name"
                                    label="Nombre"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="last_name">Apellido</label>
                                <Field
                                    name="last_name"
                                    label="Apellido"
                                    component={renderField}
                                    type="text"
                                    validate={composeValidators(
                                        required,
                                        maxLength(150)
                                    )}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="email">Correo</label>
                                <Field
                                    name="email"
                                    label="Correo"
                                    component={renderField}
                                    type="email" 
                                    validate={email}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="cellphone">Celular</label>
                                <Field
                                    name="cellphone"
                                    label="Celular"
                                    component={renderField}
                                    type="number"
                                    validate={maxLength(15)}
                                    className="form-control"
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="birthday">
                                    Fecha nacimiento
                                </label>
                                <Field
                                    name="birthday"
                                    label="Fecha nacimiento"
                                    component={renderDatePicker}
                                    type="text"
                                    validate={date}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="gender">Sexo</label>
                                <Field
                                    name="gender"
                                    label="Sexo"
                                    component={renderSelectField}
                                    options={GENDER_OPTIONS}
                                    validate={required}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="profession">Profesión</label>
                                <Field
                                    name="profession"
                                    component={renderField}
                                    validate={maxLength(200)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="school">Colegio</label>
                                {offline ? (
                                    <Field
                                        name="school"
                                        component={renderSelectField}
                                        isSearchable={true}
                                        withAsync={true}
                                        labelKey="name"
                                        valueKey="id"
                                        options={schools}
                                        className="w-100"
                                        disabled={view}
                                    />
                                ) : (
                                    <Field
                                        name="school"
                                        component={renderAsyncSelectField}
                                        isSearchable={true}
                                        labelKey="name"
                                        valueKey="id"
                                        loadOptions={asyncSchool}
                                        validate={required}
                                        className="w-100"
                                        disabled={view}
                                    />
                                )}
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="country">País</label>
                                <Field
                                    name="country"
                                    component={renderSelectField}
                                    options={COUNTRY_OPTIONS}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="department">Departamento</label>
                                <Field
                                    name="department"
                                    component={renderField}
                                    validate={maxLength(75)}
                                    disabled={view}
                                />
                            </div>
                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="city">Municipio</label>
                                <Field
                                    name="city"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(100)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-md-4">
                            <div className="form-group has-feedback m-2 flex-1">
                                <label htmlFor="address">Dirección</label>
                                <Field
                                    name="address"
                                    component={renderField}
                                    type="text"
                                    validate={maxLength(200)}
                                    className="form-control w-100"
                                    disabled={view}
                                />
                            </div>

                            <div className="form-group has-feedback m-2 mx-md-5 flex-1">
                                <label htmlFor="level">Nivel</label>
                                <Field
                                    name="level"
                                    component={renderSelectField}
                                    options={
                                        LEVEL_BENEFICIARY_EDUCATIVE_OPTIONS
                                    }
                                    className="form-control w-100"
                                    disabled={initialValues.status_group}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column-reverse flex-md-row justify-content-between my-5">
                            <Link
                                to="/beneficiary"
                                className="btn btn-secondary align-self-center mt-4 m-md-0"
                            >
                                Regresar
                            </Link>

                            {!view && (
                                <button
                                    type="submit"
                                    className="btn btn-primary align-self-center"
                                    disabled={submitting}
                                >
                                    Guardar
                                </button>
                            )}
                        </div>
                    </form>
                );
            }}
        </Form>
    );
}
