import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import Table from '../../../Utils/Grid';
import Search from '../../../Utils/Search';
import LoaderContext from '../../../../context/LoaderContext';
import useList from '../../../../hooks/useList';
import useDelete from '../../../../hooks/useDelete';
import './listUsers.css';
import InputSelect from '../../../Utils/InputSelect';
import UserContext from '../../../../context/UserContext';
import { USER_STATUS_OPTIONS,SUPER_ADMIN } from '../../../../../utility/constants';

const ListOperationalUsers = () => {
    const { loader } = useContext(LoaderContext);
    const { user }= useContext(UserContext);
    const [filterActive, setFilterActive] = useState(null);
    const [search, setSearch] = useState('');
    const { data, getData, page } = useList('user');
    const { deleteData } = useDelete('user');

    return (
        <React.Fragment>
            <div className="pt-5" />
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <h1 className="title-2 cl-dark-blue">Usuarios operativos</h1>
                { user.type === SUPER_ADMIN &&(
                <Link to="/user/create" className="btn btn-primary">
                    Crear Usuario
                </Link>
                )}
            </div>
            <div className="d-flex flex-column-reverse flex-sm-row mt-3">
                <Search
                    onSearch={(value) => {
                        getData(1, {
                            search: value,
                            status: filterActive,
                        }).then();

                        setSearch(value);
                    }}
                    placeholder="Buscar por nombre, apellido y correo ..."
                    className="flex-1"
                />
                <div className="d-flex flex-1 justify-content-sm-end">
                    <InputSelect
                        options={USER_STATUS_OPTIONS}
                        value={filterActive}
                        isClearable={true}
                        className="filter-select mt-3 mt-md-0"
                        placeholder="Filtrar por estado"
                        onChange={(value) => {
                            setFilterActive(value);
                            getData(1, {
                                status: value,
                                search: search,
                            });
                        }}
                    />
                </div>
            </div>
            <Table
                className="mt-5"
                data={data}
                onPageChange={(page) =>
                    getData(page, { search: search, type: filterActive })
                }
                page={page}
                loading={loader}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    width="10rem"
                    isKey
                    dataFormat={standardActions({
                        view: '/user',
                        update: '/user',
                        erase: (id) => deleteData(id, getData),
                        type:user.type,
                    })}
                >
                    Herramientas
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="first_name">
                    Nombre
                </TableHeaderColumn>
                <TableHeaderColumn width="10rem" dataField="last_name">
                    Apellido
                </TableHeaderColumn>
                <TableHeaderColumn width="15rem" dataField="email">
                    Correo institucional
                </TableHeaderColumn>
                {/* <TableHeaderColumn width="15rem" dataField="personal_email">
                    Correo personal
                </TableHeaderColumn>
                <TableHeaderColumn
                    width="10rem"
                    dataField="institutional_telephone"
                >
                    Teléfono institucional
                </TableHeaderColumn> */}
                <TableHeaderColumn width="10rem" dataField="telephone">
                    Celular
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    );
};

export default ListOperationalUsers;
