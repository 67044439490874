import React from 'react';
import Select, { Creatable } from 'react-select';
import AsyncSelect from 'react-select/async';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader';
import PhotoUploader from '../PhotoUploader/PhotoUploader';
import DatePicker from '../DayPicker';
import TimePicker from '../TimePicker';
import dayjs from 'dayjs';
import _ from 'lodash';
import locale_es from 'antd/es/date-picker/locale/es_ES';

export const renderField = ({
    input,
    disabled,
    placeholder,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={input.type}
                disabled={disabled || false}
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFieldName = ({
    input,
    disabled,
    placeholder,
    meta: { touched, error },
    valor
}) => {
    const invalid = touched && error;

    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={input.type}
                disabled={disabled || false}
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
                value={valor}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderTextArea = ({
    input,
    placeholder,
    rows,
    meta: { touched, error },
    className = 'form-control',
}) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: 'none' }}
                rows={rows || 3}
                className={classNames(className, {
                    'is-invalid': invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderNumber = ({
    input,
    decimalScale,
    placeholder,
    meta: { touched, error },
    prefix = '',
    suffix = '',
    numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderCurrency = ({
    input,
    meta: { touched, error },
    prefix = 'Q ',
    placeholder,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', {
                    'is-invalid': invalid,
                })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSwitch = ({
    input,
    meta: { touched, error },
    label,
    disabled,
    width = 36,
    height = 18,
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                {...input}
                onColor="#ef5543"
                height={height}
                width={width}
                activeBoxShadow="0 0 2px 3px #EF554346"
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            />
            &nbsp;{label}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSwitchClass = ({
    input,
    meta: { touched, error },
    label,
    disabled,
    width = 36,
    classNameCustom,
    height = 18,
}) => {
    const invalid = touched && error;
    return (
        <div className={classNameCustom} >
            <Switch
                {...input}
                onColor="#ef5543"
                height={height}
                width={width}
                activeBoxShadow="0 0 2px 3px #EF554346"
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            />
            &nbsp;{label}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
export const renderFieldCheck = ({
    input,
    label,
    value,
    disabled,
    type,
    className,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <React.Fragment>
            <div className={`checkbox c-checkbox ${className || ''}`}>
                <label className="needsclick">
                    <input
                        {...input}
                        type="checkbox"
                        disabled={disabled}
                        onChange={(e) => {
                            input.onChange(!input.value);
                        }}
                        checked={input.value ? input.value : false}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderFieldRadio = ({
    input,
    label,
    value,
    disabled,
    className,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className={`${className}`}>
                <div className="radio-button c-radio c-radio-nofont c-radio-rounded d-flex">
                    <label className="negro font-weight-normal">
                        <input
                            disabled={disabled}
                            {...input}
                            type="radio"
                            className={classNames('', {
                                'is-invalid': invalid,
                            })}
                        />
                        <span />
                        &nbsp;{label}
                    </label>
                </div>
                {invalid && <div className="invalid-feedback">{error}</div>}
            </div>
        </React.Fragment>
    );
};

export const customStylesReactSelect = {
    option: (provided, state) => {
        return {
            ...provided,
            color: '#484848',
            padding: '0.5rem',
            backgroundColor: state.isFocused ? '#FBD0CB' : '#fff',
            ':active': {
                backgroundColor: '#ef5543',
                color: '#fff',
            },
        };
    },
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#f5f6f7' : '#f1f1f1',
        color: '#484848',
        borderRadius: '1rem',
        borderColor: state.isFocused ? '#FBD0CB' : provided.borderColor,
        border: 'unset',
        boxShadow: 'unset',
    }),
};

export const renderSelectField = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options = [],
    defaultInputValue,
    withAsync = false, // when this component is interchangeable with async select in offline
    placeholder,
    labelKey = 'label',
    valueKey = 'value',
    labelKey2 = undefined,
    modal = false,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: `${option[labelKey]} ${option[labelKey2] || ''}`,
            value: option[valueKey],
        });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        const keyValue = _.get(value, valueKey, undefined);
        if (_.isArray(value)) {
            const _values = [];
            for (const _item of Array.from(value)) {
                const item = _.find(_options, { value: _item });
                if (item) _values.push(item);
            }
            value = _values;
        } else if (withAsync && keyValue !== undefined) {
            const label1 = _.get(value, labelKey, '');
            const label2 = _.get(value, labelKey2, '');
            value = {
                value: keyValue,
                label: `${label1} ${label2}`,
            };
        } else {
            value = _.find(_options, { value });
        }
    }

    return (
        <React.Fragment>
            {!modal && (
                <Select
                    isClearable={isClearable}
                    className={classNames('react-select-container', {
                        'is-invalid': invalid,
                    })}
                    styles={customStylesReactSelect}
                    backspaceRemovesValue={false}
                    classNamePrefix="react-select"
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    defaultValue={defaultInputValue || null}
                    options={_options}
                    placeholder={placeholder}
                    onChange={(e) => {
                        if (withAsync) {
                            input.onChange(e);
                        } else if (!isMulti) {
                            input.onChange(e ? e[valueKey] : null);
                        } else if (_.isArray(e)) {
                            input.onChange(e.map((e) => e.value));
                        }
                    }}
                    value={value}
                    isDisabled={disabled}
                />
            )}

            {modal && (
                <Select
                    isClearable={isClearable}
                    className={classNames('react-select-container', {
                        'is-invalid': invalid,
                    })}
                    styles={{
                        ...customStylesReactSelect,
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 103000, 
                        }),
                    }}
                    menuPortalTarget={document.body}
                    backspaceRemovesValue={false}
                    classNamePrefix="react-select"
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    defaultValue={defaultInputValue || null}
                    options={_options}
                    placeholder={placeholder}
                    onChange={(e) => {
                        input.onChange(e ? e[valueKey] : null);
                    }}
                    value={value}
                    isDisabled={disabled}
                />
            )}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderSelectField2 = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options = [],
    defaultInputValue,
    withAsync = false,
    placeholder,
    labelKey = 'label',
    valueKey = 'value',
    labelKey2 = undefined,
    modal = false,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = options.map((option) => ({
        ...option,
        label: `${option[labelKey]} ${option[labelKey2] || ''}`,
        value: option[valueKey],
    }));

    let value = input.value;
    if (value !== null && value !== undefined) {
        const keyValue = _.get(value, valueKey, undefined);
        if (_.isArray(value)) {
            value = value.map((val) => _.find(_options, { value: val })).filter(Boolean);
        } else if (withAsync && keyValue !== undefined) {
            const label1 = _.get(value, labelKey, '');
            const label2 = _.get(value, labelKey2, '');
            value = {
                value: keyValue,
                label: `${label1} ${label2}`,
            };
        } else {
            value = _.find(_options, { value });
        }
    }

    const customStylesReactSelectWithScroll = {
        ...customStylesReactSelect,
        menuList: (provided) => ({
            ...provided,
            maxHeight: '90px', // Altura máxima para el menú desplegable
            overflowY: 'auto', // Activa el desplazamiento
        }),
    };

    return (
        <React.Fragment>
            {!modal && (
                <Select
                    isClearable={isClearable}
                    className={classNames('react-select-container', {
                        'is-invalid': invalid,
                    })}
                    styles={customStylesReactSelectWithScroll}
                    backspaceRemovesValue={false}
                    classNamePrefix="react-select"
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    defaultValue={defaultInputValue || null}
                    options={_options}
                    placeholder={placeholder}
                    onChange={(e) => {
                        if (withAsync) {
                            input.onChange(e);
                        } else if (!isMulti) {
                            input.onChange(e ? e[valueKey] : null);
                        } else if (_.isArray(e)) {
                            input.onChange(e.map((item) => item.value));
                        }
                    }}
                    value={value}
                    isDisabled={disabled}
                />
            )}
            {modal && (
                <Select
                    isClearable={isClearable}
                    className={classNames('react-select-container', {
                        'is-invalid': invalid,
                    })}
                    styles={{
                        ...customStylesReactSelectWithScroll,
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 10000,
                        }),
                    }}
                    menuPortalTarget={document.body}
                    backspaceRemovesValue={false}
                    classNamePrefix="react-select"
                    isMulti={isMulti}
                    isSearchable={isSearchable}
                    defaultValue={defaultInputValue || null}
                    options={_options}
                    placeholder={placeholder}
                    onChange={(e) => {
                        input.onChange(e ? e[valueKey] : null);
                    }}
                    value={value}
                    isDisabled={disabled}
                />
            )}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderAsyncSelectField = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable = true,
    loadOptions,
    placeholder,
    className,
    onChange,
    labelKey = 'label',
    labelKey2 = undefined,
    valueKey = 'value',
    modal = false,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <React.Fragment>
            <div
                className={`d-flex flex-column ${className || ''}`}
                style={{ maxWidth: '30rem' }}
            >
                {!modal && (
                    <AsyncSelect
                        styles={customStylesReactSelect}
                        isClearable={isClearable}
                        cacheOptions
                        classNamePrefix="async-select"
                        className={classNames(
                            `react-select-container ${className || ''} `,
                            {
                                'is-invalid': invalid,
                            }
                        )}
                        backspaceRemovesValue={false}
                        isSearchable={isSearchable || false}
                        defaultOptions
                        isMulti={isMulti || false}
                        loadOptions={loadOptions}
                        placeholder={placeholder}
                        onChange={(e) => {
                            input.onChange(e ? e : null);
                        }}
                        getOptionValue={(option) => option[valueKey]}
                        getOptionLabel={(option) =>
                            `${option[labelKey]} ${option[labelKey2] || ''}`
                        }
                        value={input.value}
                        isDisabled={disabled}
                    />
                )}
                {modal && (
                    <AsyncSelect
                        menuPortalTarget={document.body}
                        styles={{
                            ...customStylesReactSelect,
                            menuPortal: (base) => ({ ...base, zIndex: 103000 }),
                        }}
                        isClearable={isClearable}
                        cacheOptions
                        classNamePrefix="async-select"
                        className={classNames(
                            `react-select-container ${className || ''} `,
                            {
                                'is-invalid': invalid,
                            }
                        )}
                        backspaceRemovesValue={false}
                        isSearchable={isSearchable || false}
                        defaultOptions
                        isMulti={isMulti || false}
                        loadOptions={loadOptions}
                        placeholder={placeholder}
                        onChange={(e) => {
                            input.onChange(e ? e : null);
                        }}
                        getOptionValue={(option) => option[valueKey]}
                        getOptionLabel={(option) =>
                            `${option[labelKey]} ${option[labelKey2] || ''}`
                        }
                        value={input.value}
                        isDisabled={disabled}
                    />
                )}

                {invalid && <div className="invalid-feedback">{error}</div>}
            </div>
        </React.Fragment>
    );
};

export const CreatableSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    options,
    placeholder,
    labelKey = 'label',
    valueKey = 'value',
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', {
                    'is-invalid': invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFileUploader = ({
    photo,
    setFile,
    className,
    disabled,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames({ 'is-invalid': invalid })}>
            <FileUploader
                onChange={(e) => {
                    input.onChange(e);
                }}
                disabled={disabled || false}
                file={input.value}
                className={className}
            />
            {invalid && <div className="d-flex invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDayPicker = ({
    className,
    disabled,
    maxDate,
    minDate,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(e) => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDatePicker = ({
    className,
    disabled,
    placeholder,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    let value = null;
    if (dayjs(input.value).isValid()) {
        value = dayjs(input.value);
    }

    return (
        <div className={classNames({ 'is-invalid': invalid })}>
            <DatePicker
                onChange={(e) => {
                    input.onChange(e);
                }}
                locale={locale_es}
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                disabled={disabled}
                value={value}
                className={className}
            />
            {invalid && <div className="d-flex invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderTimePicker = ({
    className,
    disabled,
    placeholder,
    minuteStep = 1,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    let value = null;
    if (dayjs(input.value).isValid()) {
        value = dayjs(input.value);
    }

    return (
        <div className={classNames({ 'is-invalid': invalid })}>
            <TimePicker
                onChange={(e) => {
                    input.onChange(e);
                }}
                use12Hours
                locale={locale_es}
                format="h:mm a"
                disabled={disabled}
                minuteStep={minuteStep}
                value={value}
                className={className}
                onSelect={(e) => input.onChange(e)}
            />
            {invalid && <div className="d-flex invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderPhotoUploader = ({
    className,
    disabled,
    placeholder,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <div className={classNames({ 'is-invalid': invalid })}>
            <PhotoUploader
                onChange={(e) => {
                    input.onChange(e);
                }}
                disabled={disabled || false}
                file={input.value}
                className={className}
            />
            {invalid && <div className="d-flex invalid-feedback">{error}</div>}
        </div>
    );
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    renderSwitchClass,
};
